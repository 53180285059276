<template>
  <v-app :class="{ 'challenge-background': showDefBackground, 'bg-login-bg': userLoggedIn == 'false', }" id="my-node">

    <notifications :position="$vuetify.display.smAndUp ? 'bottom right' : 'top'" group="foo" animation-type="velocity"
      :width="$vuetify.display.smAndUp ? '350' : '100%'">
      <template #body="props">
        <v-sheet elevation="2" class="vue-notification py-8" @click="notificationClick(props.item)">
          <v-row class="t-1rem px-4">
            {{ props.item.title }}
            <v-spacer></v-spacer>

          </v-row>

          <v-row class="t-h6Reg mt-6 px-4">
            {{ props.item.text }}
          </v-row>
        </v-sheet>
      </template>
    </notifications>

    <notifications :position="$vuetify.display.smAndUp ? 'bottom right' : 'top'" group="reload"
      animation-type="velocity" :class="{ 'mb-2 mr-2': $vuetify.display.smAndUp }"
      :width="$vuetify.display.smAndUp ? '350' : '100%'">
      <template #body="props">
        <v-sheet elevation="2" class="default-dialog py-5">
          <v-row class="pa-0 px-7">
            <v-col cols="auto" class="pa-0">
              <span class="t-1rem">{{ props.item.title }}</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto" class="pa-0">
              <v-icon @click="closeNotification(props.item)"> mdi-close</v-icon>
            </v-col>

          </v-row>
          <v-divider class="my-5 px-7" :thickness="2" color="background"></v-divider>

          <v-row class="t-h6Reg pa-0 px-7 mb-2">
            {{ props.item.text }}
          </v-row>

          <v-row class="t-1rem pa-0 px-7">
            <v-btn class="refresh-btn t-btn text-none" density="default" variant="outlined" dark
              @click="notificationReloadClick(props.item)">
              Refresh Now
            </v-btn>
          </v-row>
        </v-sheet>
      </template>
    </notifications>

    <SelfHelpGuideComponent v-if="showPages" ></SelfHelpGuideComponent>

    <v-main transition="slide-x-transition ma-0 pa-0"
      :class="{ 'challenge-background': showDefBackground, 'bg-login-bg': userLoggedIn == 'false', }" v-if="dataLoaded">

      <app-bar-new v-if="userLoggedIn == 'false'"></app-bar-new>
      <NavigationDrawer v-if="showPages" :show="showPages" class="nav-background"></NavigationDrawer>
      <v-container fluid class="pa-0 page-color ma-0" :class="{ 'h-100': userLoggedIn != 'false' }" v-resize="onResize">
        <NotificationComponent v-if="showPages" :show="showPages" />
        <v-snackbar v-model="linkSnackbarState" :timeout="-1" color="cardBackgroundColor">
          <span class="submit-message-text text-report-red">{{ linkSnackbarError }}</span>
          <template v-slot:actions>
            <v-btn density="compact" color="#8e8ea7" @click.stop="closeSnackBar()" icon="mdi-close"></v-btn>
          </template>
        </v-snackbar>
        <LazyLoading :show="loaderCount > 0"></LazyLoading>
        <SubscriptionUpgradeDialog v-if="openUpgradeDialog"></SubscriptionUpgradeDialog>
        

        <router-view v-if="showPages" v-slot="{ Component }">
          <component :is="Component" />
        </router-view>
      </v-container>
    </v-main>
    <v-snackbar v-model="showCopyMessage" color="cardBackgroundColor" content-class="tab-2" location="top right">
      <v-row dense class="1rem">
        <span class="submit-message-text">Assignment Copied - <span class="text-primaryGrey">{{ copyAssignment.title
            }}</span></span>
      </v-row>
      <v-row dense v-if="pasteAssignmentHere" style="padding-top: 14px;">
        <v-btn class="refresh-btn t-btn text-none" density="default" variant="outlined" dark
          @click.stop="pasteAssignmentAction()">Paste here
        </v-btn>
      </v-row>
      <template v-slot:actions>
        <v-tooltip z-index="4000" location="bottom">
          <template #activator="{ props }">
            <v-icon small class="mr-2" @click.stop="closeSnackBarCopyMessage()" v-bind="props" color="#8e8ea7">
              mdi-close
            </v-icon>
          </template>
          <span>Clear copied assignment</span>
        </v-tooltip>
      </template>
    </v-snackbar>
    <Uploading />
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { useDisplay } from "vuetify";
import { initializeAWSPinpointAnalytics } from "@/services/awsPinpointAnalyticsService";
import NavigationDrawer from "@/components/appNavigation/NavigationDrawer.vue";
import LazyLoading from "@/components/LazyLoading.vue";

import AppBar from "@/components/appNavigation/AppBar.vue";
import AppBarNew from "@/components/newLogin/AppBarNew.vue"
import SubscriptionUpgradeDialog from "@/components/subscription/SubscriptionUpgradeDialog.vue"
import {
  AnalyticsEvents,
  recordAnalyticsEvent,
} from "@/services/awsPinpointAnalyticsService";
import { getDeviceId, setTempUserId } from "@/services/authService";
import { v4 as uuidv4 } from "uuid";
import { createFirebaseApp } from "./services/firebaseMessagingService"
import { useNotification } from "@kyvg/vue3-notification";
import { handleNotify } from "./services/firebaseMessagingService"
import { signInLoad } from "@/services/signinService";
import { isEqual, get, isEmpty } from 'lodash'
import moment from "moment-timezone";
import TeacherModeComponent from "@/components/TeacherModeComponent";
import NotificationComponent from "@/components/notification/NotificationComponent.vue";
import ParentDetailsComponent from "@/components/parentDetails/ParentDetailsComponent.vue";
import SelfHelpGuideComponent from "@/components/selfHelpGuide/SelfHelpGuideComponent.vue";

import _ from "lodash";
import { getAppTitle } from "./flavors";
import { getTitleForWhiteLabel, isWhiteLabelApp } from "./services/whiteLabelService";
import Uploading from "./components/Uploading";

export default {
  name: "App",
  setup() {
    const { mdAndUp } = useDisplay();

    return { mdAndUp };
  },

  data: () => ({
    showPages: false,
    dataLoaded: false,
    pasteAssignmentHere: false,
    isWhiteLabelApp: false,
  }),
  components: {
    NavigationDrawer,
    AppBar,
    AppBarNew,
    LazyLoading,
    SubscriptionUpgradeDialog,
    TeacherModeComponent,
    NotificationComponent,
    ParentDetailsComponent,
    Uploading,
    SelfHelpGuideComponent,
  },
  async created() {
    await this.$store.dispatch("getAPIUrlsForFlavorAndVersion", {})
    this.checkWhiteLabelApp();
    if (this.isWhiteLabelApp) {
      await this.$store.dispatch("loadCourseDetails", { appUrl: window.location.origin })
    }
    let titleForWhiteLabel = getTitleForWhiteLabel();
    if(titleForWhiteLabel != null){
      document.title = titleForWhiteLabel;
    }
    else{
      document.title = getAppTitle();
    }
    this.dataLoaded = true;
    const self = this
    if ("BroadcastChannel" in window) {
      const channel = new window.BroadcastChannel("sw-messages");
      channel.addEventListener('message', (event) => {
        console.log("in vue", event)
        if ("clicked" in event.data && event.data.clicked == "true") {
          const route = event.data.fcm_data.data.notificationRoute
          self.$router.push(route)
        }
        else {
          handleNotify(event.data)
        }
      })
    }
    this.$store.commit("getLoggedInUserDetails");
    
    initializeAWSPinpointAnalytics();
    var currentUrl = window.location.pathname;
    
    let referralCode = null
    if (currentUrl.includes('rl')) {
      try {
        referralCode = currentUrl.split('/')[2]

      } catch (error) {
        //
      }
    }
    if (referralCode != null) {
      recordAnalyticsEvent(AnalyticsEvents.v2AppLoaded, { referralCode: referralCode });
    }

    if (this.userLoggedIn == 'true') {
      if (this.isUserSuperAdmin) {
        this.$store.dispatch("getCustomersList");
      }
      else {
        await this.$store.dispatch("onBoardingUserDetails");
      }
      await createFirebaseApp()
      await this.$store.dispatch("getProfileDetails", {});
      this.$store.dispatch("getFeatureFlagsList", {});
      await this.$store.dispatch('getNotificationsList', {});
      this.$store.commit("assignmentsLoadedFromServer", false)
      await this.$store.dispatch("loadAssignments", {
        isCurrent: true,
        currentDate: moment().format("YYYY-MM-DD")
      })
      await this.$store.dispatch("getUserPreferences", {});
      this.showPages = true;

    }
    else {
      this.showPages = true;

    }
    await this.$store.dispatch("fetchSelfHelpGuideScript");
  },
  computed: {
    ...mapState(["loading", "teacherModeDialog", "linkSnackbarState", "linkSnackbarError", "navDrawer", "userLoggedIn", "showBackground", "config", "isUserSuperAdmin", "copyAssignment", "courseId", "openUpgradeDialog", "loaderCount"]),
    showDefBackground: {
      get() {
        return (this.userLoggedIn == 'true') && this.showBackground
      }
    },
    showCopyMessage: {
      get() {
        return !isEmpty(this.copyAssignment);
      }
    },
  },

  watch: {
  },

  methods: {
    handleResize() {
      // Your resize logic here
    },
    initialize() { },
    notificationClick(item) {
      console.log(item);
      const notification = useNotification()
      notification.notify.close(item.id)
      const route = item.data.notificationRoute
      this.$router.push(route)

    },
    notificationReloadClick(item) {
      const notification = useNotification()
      notification.notify.close(item.id)
      this.$router.go();
    },
    closeNotification(item) {
      const notification = useNotification()
      notification.notify.close(item.id)
    },
    onResize() {
      //console.log(`window resized...:: ${window.innerWidth} || ${window.innerHeight}`);
      if (!this.mdAndUp) {
        this.hideDrawer();
      } else {
        this.showDrawer();
      }
    },
    showDrawer() {
      this.$store.commit("toggleDrawer", true);
    },
    hideDrawer() {
      this.$store.commit("toggleDrawer", false);
    },
    closeSnackBarCopyMessage() {
      this.$store.commit("copyAssignment", {});
    },
    pasteAssignmentAction() {
      this.$eventBus.emit("pasteAssignment", {});
    },
    closeSnackBar() {
      this.$store.commit("linkSnackbar", false)
    },
    checkWhiteLabelApp() {
      this.isWhiteLabelApp = isWhiteLabelApp();
    }
  },
  mounted() {
    this.pasteAssignmentHere = false;
    this.$eventBus.on('assignmentEvent', (evt) => {
      if (evt) {
        this.pasteAssignmentHere = evt.isPasteHere;

      }
    })
  },
  unmounted() {
    this.$eventBus.off('assignmentEvent', () => {
      this.pasteAssignmentHere = false;
    })
  },
};
</script>

<style>
html {
  overflow: hidden !important;
}

.nav-background {
  background-color: #3D3D54;
}

.challenge-background {
  background: url('/public/assets/images/new_app_background_v2.png') repeat center center fixed !important;
}

#confetti-canvas {
  z-index: 1008;
}

.bg-login-bg {
  background: #160925 url("~@/assets/mentomind/background_sky1.svg") no-repeat center center fixed !important;
  background-size: cover !important;
  /* background-repeat: no-repeat !important;
  height: 100% !important;
  background-position: center !important; */
  background-attachment: fixed;
}

@media only screen and (max-width: 600px) {
  .bg-login-bg {
    background: #160925 url("~@/assets/mentomind/Onboarding_3.svg") no-repeat center center fixed !important;
    background-size: cover !important;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Inter");
@import url("https://fonts.googleapis.com/css2?family=Prompt");

html,
body {
  font-family: "Inter", sans-serif !important;
  background-color: white !important;
}

html {
  overflow-y: auto !important;
}

#app {
  font-family: "Inter", sans-serif !important;
}

.btn-rd {
  border-radius: 6px !important;
}

.htmlClass {
  color: black !important;
  font-family: "Inter", sans-serif !important;
  font-size: 0.875rem !important;
}

.htmlClassTest {
  font-family: "Inter", sans-serif !important;
  font-size: 1rem !important;
}

.t-h6 {
  font-family: "Inter", sans-serif !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
}

.t-h6Bold {
  font-family: "Inter", sans-serif !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 700 !important;
}

.t-h5 {
  font-family: "Inter", sans-serif !important;
  font-size: 1.125rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
}


.t-h5Reg {
  font-family: "Inter", sans-serif !important;
  font-size: 1.125rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
}

.t-h125 {
  font-family: "Inter", sans-serif !important;
  font-size: 1.25rem !important;
  font-style: normal !important;
  font-weight: 700;
}

.t-h1125Med {
  font-family: "Inter", sans-serif !important;
  font-size: 1.125rem !important;
  font-style: normal !important;
  font-weight: 600;
}

.t-h125Med {
  font-family: "Inter", sans-serif !important;
  font-size: 1.25rem !important;
  font-style: normal !important;
  font-weight: 600;
}

.t-h125Reg {
  font-family: "Inter", sans-serif !important;
  font-size: 1.25rem !important;
  font-style: normal !important;
  font-weight: 500;
}

.t-h4 {
  font-family: "Inter", sans-serif !important;
  font-size: 1.4rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
}

.t-h4Reg {
  font-family: "Inter", sans-serif !important;
  font-size: 1.4rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
}

.t-subtitle {
  font-family: "Inter", sans-serif !important;
  font-size: 0.8rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
}

.t-subtitleGrey {
  font-family: "Inter", sans-serif !important;
  font-size: 0.8rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  color: rgba(255, 255, 254, 0.6) !important;

}

.t-para {
  font-family: "Inter", sans-serif !important;
  font-size: 0.8rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
}

.t-btn {
  font-family: "Inter", sans-serif !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
}

.t-h6Reg {
  font-family: "Inter", sans-serif !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
}

.v-alert-title {
  font-family: "Inter", sans-serif !important;
  font-size: 1.125rem !important;
  font-style: normal !important;
}

.v-alert__content {
  font-family: "Inter", sans-serif !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
}

.v-toolbar-title {
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  font-size: 1.1rem !important;
}

.dialog-bx {
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
}

.v-avatar {
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
}

.avatarText {
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  font-size: 0.6rem !important;

}

.t-small-subtitle {
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  font-size: 0.64rem !important;
  font-weight: 500 !important;

}

.grey-bx {
  /* box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important; */
}

.t-1rem {
  font-family: "Inter", sans-serif !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 700;

}

.t-1remMed {
  font-family: "Inter", sans-serif !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 600;
}

.t-1remReg {
  font-family: "Inter", sans-serif !important;
  font-size: 1rem !important;
  font-style: normal !important;

}

.t-938remReg {
  font-family: "Inter", sans-serif !important;
  font-size: 0.938rem !important;
  font-style: normal !important;

}

.t-938_600rem {
  font-family: "Inter", sans-serif !important;
  font-size: 0.938rem !important;
  font-style: normal !important;
  font-weight: 600;
}

.selector .v-field.v-field {
  cursor: pointer;
  color: white;
}

.button-color {
  background: #A174FD;
  mix-blend-mode: luminosity;
  border: 1.5px solid #A174FD;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
}

.breadcum-back-button {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20.069px;
}

.text-first-capitalize {
  display: block;
  text-transform: lowercase !important;
}

.text-first-capitalize::first-letter {
  text-transform: capitalize !important;

}

.vue-notification {


  background: rgb(var(--v-theme-cardBackgroundColor)) !important;
  cursor: pointer;
  border: 5px solid rgb(var(--v-theme-primaryPurple)) !important;
  margin: 0 !important;
  margin-bottom: 10px !important;
  color: white !important;

}

.default-dialog {
  /* background: rgb(var(--v-theme-primaryGrey)); */
  border-radius: 12px !important;
  /* border: 2px solid #c3c3c3; */
  border: 2px solid rgba(255, 255, 255, 0.22) !important;
  /* background: #232238; */
  background-color: rgb(var(--v-theme-reportBgColor)) !important;
  height: 100%;
  color: white !important;
  margin: 0 !important;
  margin-bottom: 10px !important;
}

.refresh-btn {
  background: linear-gradient(#303146, #303146) padding-box,
    linear-gradient(180deg, #ab77ff 0%, #585ef3 100%) border-box !important;
  border: 2px solid transparent !important;
  color: white !important;
  border-radius: 29.4079px !important;
}

.text-grey {
  opacity: 0.7 !important;
  color: white !important;
}

table mjx-container {
  text-align: center !important;
}

.pointer {
  cursor: pointer;
}

.test-subtitle {
  color: #8e8ea7;
}

.breadcrumb-title {
  cursor: pointer;
}

.teacher-form {
  /* background: rgb(var(--v-theme-primaryGrey)); */
  border-radius: 12px !important;
  /* border: 2px solid #c3c3c3; */
  border: 2px solid rgba(255, 255, 255, 0.22);
  /* background: #232238; */
  background-color: rgb(var(--v-theme-reportBgColor)) !important;
  height: 100%;
}

.tab-2 {
  border: 1px solid rgb(var(--v-theme-iconGrey));
  border-radius: 10px !important;
}

.tox-tinymce-aux {
  z-index: 2200 !important;
}

.pointer-cursor {
  cursor: pointer;
}

.highlight-navItem{
  border: 2px solid #A174FD !important;
  background: #A475FE29 !important;
  animation: myAnim 1s ease 0s 1 normal forwards;
  /* box-shadow: 0px 0px 16px 1px #A174FD; */
}

.highlight-tabItem{
  border: 2px solid #A174FD !important;
  background: #A475FE29 !important;
  animation: myAnim 1s ease 0s 1 normal forwards;
  box-shadow: 0px 0px 5px 0px #A174FD !important;
}

.highlight-cardItem{
  border: 2px solid #A174FD !important;
  box-shadow: 0px 0px 5px 0px #A174FD !important;
  animation: myAnim 1s ease 0s 1 normal forwards;
}

.highlight-button{
  border: 2px solid #A174FD !important;
  box-shadow: 0px 0px 5px 0px #A174FD !important;
  animation: myAnim 1s ease 0s 1 normal forwards;
}

.highlight-token{
  border: 2px solid #A174FD !important;
  box-shadow: 0px 0px 5px 0px #A174FD !important;
  border-radius: 50% !important;
  animation: myAnim 1s ease 0s 1 normal forwards;
}

.highlight-inputItem .v-input__control{
  border: 2px solid #A174FD !important;
  box-shadow: 0px 0px 5px 0px #A174FD !important;
  border-radius: 6px !important;
  animation: myAnim 1s ease 0s 1 normal forwards;
}

.highlight-inputSwitch{
  border: 2px solid #A174FD !important;
  box-shadow: 0px 0px 5px 0px #A174FD !important;
  border-radius: 6px !important;
  animation: myAnim 1s ease 0s 1 normal forwards;
}

.tox-tinymce-aux {
  z-index: 3000 !important;
}

.tox-statusbar {
  display: none !important;
}
math-field#formula {
  width: 95%;
  border-radius: 8px;
  padding: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
textarea#latex {
  width: 95%;
  height: 200px !important;
  margin-top: 22px !important;
  border: 1px solid;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #ddd;
  padding: 8px;
  margin-top: 1em;
  font-family: "IBM Plex Mono", "Fira Code", "Source Code Pro", monospace;
  line-height: 1.2;
}

math-field {
  background: #fff;
  border-radius: 4px;
  border: 1px solid #ddd;
  padding: 8px;
}
math-field#formula:focus,
math-field#formula:focus-within,
math-field#formula:active {
  outline: Highlight auto 1px;
  outline: -webkit-focus-ring-color auto 1px;
  margin-top: 2px;
}
.ML__keyboard {
  z-index: 3010 !important;
}
.matrix {
  width: 40px;
  height: 40px;
}
.plainmatrix {
  width: 30px;
  height: 30px;
}
.int {
  width: 50px;
  height: 50px;
}

@keyframes myAnim {
	0%,
	50%,
	100% {
		opacity: 1;
	}

	25%,
	75% {
		opacity: 0.2;
	}
}


</style>
