<template>
  <!-- ScribbleComponent -->
  <ScribbleComponent
    v-if="showScribbleImage"
    :isTeacherNotesAttached="currentQ.isTeacherNotesAttached"
    :screenshotElement="screenshotElement"
    @closeNotesDialog="closeNotesDialog"
    :testInstanceId="testInstanceId"
    :questionId="currentQ.qId"
    :isOpenedFromTeacherView="isOpenedFromTeacherView"
    @upateTeacherNote="upateTeacherNote()"
  />

  <v-container fluid class="pa-0 ma-0 h-100">
    <div class="bgW">
      <div v-if="loadedFromServer" class="w-100 h-100">
        <v-dialog
          absolute
          class="bottom dialog-bx"
          height="700px"
          width="800px"
          v-model="questionDialog"
        >
          <v-card height="700px" color="background">
            <v-card-text class="pa-8">
              <v-row justify="space-between" class="t-h5 px-3">
                <v-col class="ml-0 pl-0">
                  {{ isOpenedFromParentView? "Child's responses" : isOpenedFromTeacherView ? "Student's responses" : "Your responses" }}
                </v-col>
                <v-col class="text-right">
                  <v-icon @click="questionDialog = false"> mdi-close </v-icon>
                </v-col>
              </v-row>
              <v-row>
                <v-divider class="mt-2 mb-5"> </v-divider>
              </v-row>
              <v-row>
                <v-col cols="auto" class="">
                  <v-avatar size="40" variant="outlined" class="mr-2 correct">
                    {{ currentSection.correctQuestions }} </v-avatar
                  ><span> Correct </span>
                </v-col>
                <v-col cols="auto" class="">
                  <v-avatar size="40" variant="outlined" class="mr-2 incorrect">
                    {{ currentSection.incorrectQuestions }} </v-avatar
                  ><span> Incorrect </span> </v-col
                ><v-col cols="auto" class="">
                  <v-avatar size="40" variant="outlined" class="mr-2 unattempted">
                    {{ currentSection.unattemptedQuestions }} </v-avatar
                  ><span> Not Attempted </span>
                </v-col>
                <v-col cols="auto">
                  <v-avatar size="40" class="">
                    <v-icon color="black" icon="mdi-paperclip" size="25"></v-icon>
                  </v-avatar>
                  <span> Notes Attached </span>
                </v-col>
              </v-row>
              <v-row>
                <v-divider class="my-5"> </v-divider>
              </v-row>

              <v-row class="my-4">
                <v-col v-if="currentSection.visibleOnApp" cols="12" class="t-h5Reg">
                  {{ currentSection.sectionName }}
                </v-col>

                <v-row>
                  <v-col
                    cols="auto"
                    class="mx-2 makePositionRelative"
                    v-for="(item, qIndex) in modifiedQuestions"
                    :key="qIndex"
                  >
                    <div v-if="item.isTeacherNotesAttached == true" class="notesAttached">
                      <v-icon size="20px" icon="mdi-paperclip"></v-icon>
                    </div>
                    <v-avatar
                      style="cursor: pointer"
                      @click="openQFromDialog(qIndex)"
                      size="40"
                      variant="outlined"
                      :class="{
                        unattempted: item.isCorrect == null,
                        correct: item.isCorrect != null && item.isCorrect == true,
                        incorrect: item.isCorrect != null && item.isCorrect == false,
                      }"
                    >
                      {{ item.qDisplayNo }}
                    </v-avatar>
                  </v-col>
                </v-row>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-app-bar color="cardBackgroundColor" elevation="0" extension-height="100%">
          <v-app-bar-title>
            <template v-slot:text>
              <span class>
                {{ testInstanceQuestionSummary.testName }}
              </span>
            </template>
          </v-app-bar-title>

          <v-spacer></v-spacer>

          <v-row v-if="$vuetify.display.mdAndUp" class="mr-4">
            <v-col cols="12">
              <v-select
                bg-color="#26253A00"
                base-color="#ffffff"
                color="#ffffff"
                :focused="false"
                hide-details="true"
                class="text-white selector"
                item-color="black"
                variant="solo"
                v-model="selectedModule"
                :items="currentTestResultJson"
                item-title="sectionName"
                item-value="sectionName"
                density="compact"
                return-object
                single-line
                :flat="true"
                elevation="0"
                @update:modelValue="selectModule"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-icon v-if="$vuetify.display.xs" @click="toggleFilter" class="mr-4">
            mdi-filter</v-icon
          >

          <v-icon @click="backFromDialog" class="mr-4"> mdi-close</v-icon>

          <template v-slot:extension v-if="showFilter || !$vuetify.display.xs">
            <v-card class="testCard w-100">
              <v-card-text
                class="pa-0"
                :class="$vuetify.display.mdAndUp ? 'px-10' : 'px-3'"
              >
                <v-row v-if="!$vuetify.display.mdAndUp">
                  <v-col cols="12">
                    <v-select
                      bg-color="#26253A00"
                      base-color="#ffffff"
                      color="#ffffff"
                      :focused="false"
                      hide-details="true"
                      item-color="black"
                      class="text-white selector mt-3 mt-4"
                      variant="solo"
                      v-model="selectedModule"
                      :items="currentTestResultJson"
                      item-title="sectionName"
                      item-value="sectionName"
                      density="compact"
                      return-object
                      single-line
                      :flat="true"
                      elevation="0"
                      @update:modelValue="selectModule"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-divider
                  v-if="!$vuetify.display.mdAndUp"
                  :thickness="1"
                  color="background"
                  class="mx-5"
                ></v-divider>
                <v-row class="text-white my-3" align="center" dense>
                  <v-col cols="auto">
                    <span class="t-1remReg text-bold">Filters</span>
                  </v-col>
                  <v-spacer v-if="$vuetify.display.mdAndUp"></v-spacer>
                  <v-col cols="auto">
                    <div class="filter-switch">
                      <v-switch
                        color="primaryPurple"
                        v-model="showSolution"
                        hide-details
                        label="Show Solution"
                        @update:modelValue="toggleSolution"
                      ></v-switch>
                    </div>
                  </v-col>
                  <v-col
                    cols="auto"
                    class="mx-2 makePositionRelative"
                    v-if="showScribble()"
                  >
                    <v-tooltip
                      :text="
                        isOpenedFromTeacherView
                          ? currentQ.isTeacherNotesAttached
                            ? 'Edit Notes'
                            : 'Add Notes'
                          : 'View Notes'
                      "
                      location="end"
                    >
                      <template v-slot:activator="{ props }">
                        <div
                          v-if="
                            currentQ.isTeacherNotesAttached == true &&
                            isOpenedFromTeacherView
                          "
                          class="notesAttached2"
                        >
                          <v-icon size="20px" icon="mdi-paperclip"></v-icon>
                        </div>
                        <v-btn
                          height="35"
                          width="35"
                          max-width="35"
                          v-bind="props"
                          variant="flat"
                          class="outline-color pa-0 mr-1"
                          @click="addEditNotes()"
                          :icon="
                            isOpenedFromTeacherView
                              ? 'mdi-note-edit-outline'
                              : 'mdi-note-outline'
                          "
                        >
                        </v-btn>
                      </template>
                    </v-tooltip>
                  </v-col>

                  <v-col cols="auto">
                    <div class="filter-dropdown">
                      <v-select
                        density="compact"
                        :items="questionTypeList"
                        v-model="filterQuestions.questionTypes"
                        label="Type"
                        item-title="name"
                        multiple
                        variant="outlined"
                        return-object
                        hide-details
                        prepend-inner-icon="mdi-help-circle"
                      >
                        <template v-slot:prepend-item>
                          <v-list-item title="All" @click="toggleQuestionType">
                            <template v-slot:prepend>
                              <v-checkbox-btn
                                :indeterminate="
                                  filterQuestions.questionTypes.length > 0 &&
                                  !(
                                    filterQuestions.questionTypes.length ==
                                    questionTypeList.length
                                  )
                                "
                                :model-value="
                                  filterQuestions.questionTypes.length ==
                                  questionTypeList.length
                                "
                              ></v-checkbox-btn>
                            </template>
                          </v-list-item>

                          <v-divider class="mt-2"></v-divider>
                        </template>
                        <template v-slot:selection="{ item, index }">
                          <span
                            v-if="
                              filterQuestions.questionTypes.length ==
                                questionTypeList.length && index == 0
                            "
                          >
                            All
                          </span>
                          <span
                            v-else-if="
                              filterQuestions.questionTypes.length > 1 && index == 0
                            "
                          >
                            +{{ filterQuestions.questionTypes.length }}
                          </span>
                          <span v-else-if="index == 0" class="filter-selected-text">
                            {{ item.title }}
                          </span>
                        </template>
                      </v-select>
                    </div>
                  </v-col>
                  <v-col cols="auto">
                    <div class="filter-dropdown">
                      <v-select
                        density="compact"
                        :items="difficultyList"
                        v-model="filterQuestions.difficulties"
                        label="Difficulty"
                        item-title="name"
                        multiple
                        variant="outlined"
                        return-object
                        hide-details
                        prepend-inner-icon="mdi-podium"
                      >
                        <template v-slot:prepend-item>
                          <v-list-item title="All" @click="toggleDifficulty">
                            <template v-slot:prepend>
                              <v-checkbox-btn
                                :indeterminate="
                                  filterQuestions.difficulties.length > 0 &&
                                  !(
                                    filterQuestions.difficulties.length ==
                                    difficultyList.length
                                  )
                                "
                                :model-value="
                                  filterQuestions.difficulties.length ==
                                  difficultyList.length
                                "
                              ></v-checkbox-btn>
                            </template>
                          </v-list-item>

                          <v-divider class="mt-2"></v-divider>
                        </template>
                        <template v-slot:selection="{ item, index }">
                          <span
                            v-if="
                              filterQuestions.difficulties.length ==
                                difficultyList.length && index == 0
                            "
                          >
                            All
                          </span>
                          <span
                            v-else-if="
                              filterQuestions.difficulties.length > 1 && index == 0
                            "
                          >
                            +{{ filterQuestions.difficulties.length }}
                          </span>
                          <span v-else-if="index == 0" class="filter-selected-text">
                            {{ item.title }}
                          </span>
                        </template>
                      </v-select>
                    </div>
                  </v-col>
                  <v-col cols="auto">
                    <div class="filter-dropdown">
                      <v-select
                        :items="timeSpentList"
                        v-model="filterQuestions.timeSpents"
                        label="Duration"
                        item-title="name"
                        multiple
                        variant="outlined"
                        return-object
                        hide-details
                        density="compact"
                        prepend-inner-icon="mdi-clock-time-four"
                      >
                        <template v-slot:prepend-item>
                          <v-list-item title="All" @click="toggleTimeSpent">
                            <template v-slot:prepend>
                              <v-checkbox-btn
                                :indeterminate="
                                  filterQuestions.timeSpents.length > 0 &&
                                  !(
                                    filterQuestions.timeSpents.length ==
                                    timeSpentList.length
                                  )
                                "
                                :model-value="
                                  filterQuestions.timeSpents.length ==
                                  timeSpentList.length
                                "
                              ></v-checkbox-btn>
                            </template>
                          </v-list-item>

                          <v-divider class="mt-2"></v-divider>
                        </template>
                        <template v-slot:selection="{ item, index }">
                          <span
                            v-if="
                              filterQuestions.timeSpents.length == timeSpentList.length &&
                              index == 0
                            "
                          >
                            All
                          </span>
                          <span
                            v-else-if="
                              filterQuestions.timeSpents.length > 1 && index == 0
                            "
                          >
                            +{{ filterQuestions.timeSpents.length }}
                          </span>
                          <span v-else-if="index == 0" class="filter-selected-text">
                            {{ item.title }}
                          </span>
                        </template>
                      </v-select>
                    </div>
                  </v-col>

                  <v-col cols="auto">
                    <div class="filter-dropdown">
                      <v-select
                        :items="categoryList"
                        v-model="filterQuestions.categoryIds"
                        label="Content"
                        item-title="label"
                        return-object
                        multiple
                        variant="outlined"
                        hide-details
                        density="compact"
                        prepend-inner-icon="mdi-book-open-blank-variant"
                      >
                        <template v-slot:prepend-item v-if="categoryList.length > 0">
                          <v-list-item title="All" @click="toggleCategory">
                            <template v-slot:prepend>
                              <v-checkbox-btn
                                :indeterminate="
                                  filterQuestions.categoryIds.length > 0 &&
                                  !(
                                    filterQuestions.categoryIds.length ==
                                    categoryList.length
                                  )
                                "
                                :model-value="
                                  filterQuestions.categoryIds.length ==
                                  categoryList.length
                                "
                              ></v-checkbox-btn>
                            </template>
                          </v-list-item>

                          <v-divider class="mt-2"></v-divider>
                        </template>
                        <template v-slot:selection="{ item, index }">
                          <span
                            v-if="
                              filterQuestions.categoryIds.length == categoryList.length &&
                              index == 0
                            "
                          >
                            All
                          </span>
                          <span
                            v-else-if="
                              filterQuestions.categoryIds.length > 1 && index == 0
                            "
                          >
                            +{{ filterQuestions.categoryIds.length }}
                          </span>
                          <span v-else-if="index == 0" class="filter-selected-text">
                            {{ item.title }}
                          </span>
                        </template>
                      </v-select>
                    </div>
                  </v-col>
                  <v-col cols="auto">
                    <div class="filter-dropdown">
                      <v-select
                        :items="topicList"
                        v-model="filterQuestions.topicIds"
                        label="Topic"
                        item-title="label"
                        return-object
                        multiple
                        variant="outlined"
                        hide-details
                        density="compact"
                        prepend-inner-icon="mdi-book-open-variant"
                      >
                        <template v-slot:prepend-item v-if="topicList.length > 0">
                          <v-list-item title="All" @click="toggleTopic">
                            <template v-slot:prepend>
                              <v-checkbox-btn
                                :indeterminate="
                                  filterQuestions.topicIds.length > 0 &&
                                  !(filterQuestions.topicIds.length == topicList.length)
                                "
                                :model-value="
                                  filterQuestions.topicIds.length == topicList.length
                                "
                              ></v-checkbox-btn>
                            </template>
                          </v-list-item>

                          <v-divider class="mt-2"></v-divider>
                        </template>
                        <template v-slot:selection="{ item, index }">
                          <span
                            v-if="
                              filterQuestions.topicIds.length == topicList.length &&
                              index == 0
                            "
                          >
                            All
                          </span>
                          <span
                            v-else-if="filterQuestions.topicIds.length > 1 && index == 0"
                          >
                            +{{ filterQuestions.topicIds.length }}
                          </span>
                          <span v-else-if="index == 0" class="filter-selected-text">
                            {{ item.title }}
                          </span>
                        </template>
                      </v-select>
                    </div>
                  </v-col>
                  <v-col cols="auto" class="d-flex justify-end">
                    <v-btn
                      prepend-icon="mdi-close"
                      class="t-btn text-white not-activity-btn text-none"
                      @click="onClearFilter"
                      :disabled="isClearFilterDisable()"
                      width="125"
                    >
                      <template v-slot:prepend>
                        <v-icon color="primaryPurple" class="pl-2"></v-icon>
                      </template>
                      Clear Filter
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
        </v-app-bar>
        <!-- <v-dialog absolute class="bottom dialog-bx" max-width="800px" v-model="reportDialog">
          <FeedbackComponent @closeDialog="reportDialog = false" :test-type="testType" :q-id="currentQ.qId"
            :test-id="testId" :test-instance-id="testInstanceId" :currentQ="currentQ"></FeedbackComponent>
        </v-dialog> -->
        <DragableFeedbackDialog
          v-if="reportDialog"
          @closeDialog="reportDialog = false"
          :test-type="testType"
          :q-id="currentQ.qId"
          :test-id="testId"
          :test-instance-id="testInstanceId"
          :q-obj="currentQ"
        >
        </DragableFeedbackDialog>
      
        <v-container
          fluid
          class="solution-height"
          :class="{
            'solution-height-md': $vuetify.display.md,
            'solution-height-sm': $vuetify.display.sm,
            'solution-height-xs': $vuetify.display.xs,
          }"
        >
          <v-container fluid  class="ma-0 pa-0 whiteBackground">
            <v-row
              justify="start"
              :class="{
                'w-70': $vuetify.display.mdAndUp && modifiedQuestions.length > 0,
                'w-100 h-100': modifiedQuestions.length <= 0,
              }"
            >
              <v-col cols="12">
                <v-card
                  v-if="modifiedQuestions.length > 0"
                  elevation="0"
                  color="white"
                  scrollable
                >
                  <section id="mainQuestionScreen" class="whiteBackground mx-5 mt-5">
                    <div class="mb-8">
                      <v-row class="align-start">
                        <v-col cols="10" sm="auto" class="ma-0 pa-0">
                          <v-row align="start">
                            <v-col cols="auto">
                              <span class="qNoClass font-weight-bold ml-3 mr-5">
                                Question {{ currentQ.qDisplayNo }}
                              </span>
                            </v-col>

                            <v-col
                              cols="12"
                              sm="9"
                              class="ma-0 pa-0 ml-3"
                              v-if="$vuetify.display.xs"
                            >
                              <v-row align="start" class="ma-0 pa-0">
                                <v-col cols="auto" class="mx-3 mx-sm-5 my-1 my-sm-0">
                                  <v-tooltip
                                    :text="currentQ.difficulty?.trim()"
                                    location="bottom"
                                  >
                                    <template v-slot:activator="{ props }">
                                      <v-row align="center" v-bind="props">
                                        <v-icon size="24" class="icon-opacity"
                                          >mdi-podium</v-icon
                                        >
                                        <span class="ml-1 qNoClass">
                                          {{ currentQ.difficulty?.trim() }}
                                        </span>
                                      </v-row>
                                    </template>
                                  </v-tooltip>
                                </v-col>
                                <v-col cols="auto" class="mx-3 mx-sm-5 my-1 my-sm-0">
                                  <v-tooltip
                                    :text="currentQ.timeSpent.toString() + ' seconds'"
                                    location="bottom"
                                  >
                                    <template v-slot:activator="{ props }">
                                      <v-row align="center" v-bind="props">
                                        <v-icon size="24" class="icon-opacity"
                                          >mdi-clock-time-four</v-icon
                                        >
                                        <span class="ml-1 qNoClass">
                                          {{ currentQ.timeSpent }} seconds
                                        </span>
                                      </v-row>
                                    </template>
                                  </v-tooltip>
                                </v-col>
                                <v-col cols="auto" class="mx-3 mx-sm-5 my-1 my-sm-0">
                                  <v-tooltip
                                    :text="currentQ.categoryName?.trim()"
                                    location="bottom"
                                  >
                                    <template v-slot:activator="{ props }">
                                      <v-row align="center" v-bind="props">
                                        <v-icon size="24" class="icon-opacity"
                                          >mdi-book-open-blank-variant</v-icon
                                        >
                                        <span
                                          class="ml-1 qNoClass text-truncate"
                                          style="max-width: 200px"
                                        >
                                          {{ currentQ.categoryName?.trim() }}
                                        </span>
                                      </v-row>
                                    </template>
                                  </v-tooltip>
                                </v-col>

                                <v-col cols="auto" class="mx-3 mx-sm-5 my-1 my-sm-0">
                                  <v-tooltip
                                    :text="currentQ.topicName?.trim()"
                                    location="bottom"
                                  >
                                    <template v-slot:activator="{ props }">
                                      <v-row align="center" v-bind="props">
                                        <v-icon size="24" class="icon-opacity"
                                          >mdi-book-open-variant</v-icon
                                        >
                                        <span
                                          class="ml-1 qNoClass text-truncate"
                                          style="max-width: 200px"
                                        >
                                          {{ currentQ.topicName?.trim() }}
                                        </span>
                                      </v-row>
                                    </template>
                                  </v-tooltip>
                                </v-col>

                                <v-col
                                  v-if="currentQ.concepts.length > 0"
                                  cols="auto"
                                  class="mx-3 mx-sm-5 my-1 my-sm-0"
                                >
                                  <v-tooltip
                                    :text="currentQ.concepts[0]['label']?.trim()"
                                    location="bottom"
                                  >
                                    <template v-slot:activator="{ props }">
                                      <v-row align="center" v-bind="props">
                                        <v-icon size="24" class="icon-opacity"
                                          >mdi-book-open-variant</v-icon
                                        >
                                        <span
                                          class="ml-1 qNoClass text-truncate"
                                          style="max-width: 200px"
                                        >
                                          {{ currentQ.concepts[0]["label"]?.trim() }}
                                        </span>
                                      </v-row>
                                    </template>
                                  </v-tooltip>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col
                          cols="12"
                          sm="9"
                          class="ma-0 pa-0"
                          v-if="!$vuetify.display.xs"
                        >
                          <v-row align="start" class="ma-0 pa-0">
                            <v-col cols="auto" class="mx-3 mx-sm-5 my-1 my-sm-0">
                              <v-tooltip
                                :text="currentQ.difficulty?.trim()"
                                location="bottom"
                              >
                                <template v-slot:activator="{ props }">
                                  <v-row align="center" v-bind="props">
                                    <v-icon size="24" class="icon-opacity"
                                      >mdi-podium</v-icon
                                    >
                                    <span class="ml-1 qNoClass">
                                      {{ currentQ.difficulty?.trim() }}
                                    </span>
                                  </v-row>
                                </template>
                              </v-tooltip>
                            </v-col>
                            <v-col cols="auto" class="mx-3 mx-sm-5 my-1 my-sm-0">
                              <v-tooltip
                                :text="currentQ.timeSpent.toString() + ' seconds'"
                                location="bottom"
                              >
                                <template v-slot:activator="{ props }">
                                  <v-row align="center" v-bind="props">
                                    <v-icon size="24" class="icon-opacity"
                                      >mdi-clock-time-four</v-icon
                                    >
                                    <span class="ml-1 qNoClass">
                                      {{ currentQ.timeSpent }} seconds
                                    </span>
                                  </v-row>
                                </template>
                              </v-tooltip>
                            </v-col>
                            <v-col cols="auto" class="mx-3 mx-sm-5 my-1 my-sm-0">
                              <v-tooltip
                                :text="currentQ.categoryName?.trim()"
                                location="bottom"
                              >
                                <template v-slot:activator="{ props }">
                                  <v-row align="center" v-bind="props">
                                    <v-icon size="24" class="icon-opacity"
                                      >mdi-book-open-blank-variant</v-icon
                                    >
                                    <span
                                      class="ml-1 qNoClass text-truncate"
                                      style="max-width: 200px"
                                    >
                                      {{ currentQ.categoryName?.trim() }}
                                    </span>
                                  </v-row>
                                </template>
                              </v-tooltip>
                            </v-col>

                            <v-col cols="auto" class="mx-3 mx-sm-5 my-1 my-sm-0">
                              <v-tooltip
                                :text="currentQ.topicName?.trim()"
                                location="bottom"
                              >
                                <template v-slot:activator="{ props }">
                                  <v-row align="center" v-bind="props">
                                    <v-icon size="24" class="icon-opacity"
                                      >mdi-book-open-variant</v-icon
                                    >
                                    <span
                                      class="ml-1 qNoClass text-truncate"
                                      style="max-width: 200px"
                                    >
                                      {{ currentQ.topicName?.trim() }}
                                    </span>
                                  </v-row>
                                </template>
                              </v-tooltip>
                            </v-col>

                            <v-col
                              v-if="currentQ.concepts.length > 0"
                              cols="auto"
                              class="mx-3 mx-sm-5 my-1 my-sm-0"
                            >
                              <v-tooltip
                                :text="currentQ.concepts[0]['label']?.trim()"
                                location="bottom"
                              >
                                <template v-slot:activator="{ props }">
                                  <v-row align="center" v-bind="props">
                                    <v-icon size="24" class="icon-opacity"
                                      >mdi-book-open-variant</v-icon
                                    >
                                    <span
                                      class="ml-1 qNoClass text-truncate"
                                      style="max-width: 450px"
                                    >
                                      {{ currentQ.concepts[0]["label"]?.trim() }}
                                    </span>
                                  </v-row>
                                </template>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-spacer> </v-spacer>
                        <v-col cols="2" sm="1" class="ma-0 pa-0" v-if="isQuestionReviewer">
                          <v-tooltip
                            :text="feedbackOrQuestionReiewIconTooltipText"
                            location="end"
                          >
                            <template v-slot:activator="{ props }">
                              <v-btn
                                size="small"
                                v-bind="props"
                                variant="outlined"
                                class="outline-color"
                                @click="showReportDialog()"
                                :icon="feedbackOrQuestionReiewIconName"
                              ></v-btn>
                            </template>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </div>
                    <section>
                      <div>
                        <HtmlRender
                          :htmlString="currentQ.qText"
                          class="htmlClassTest"
                        ></HtmlRender>
                      </div>
                    </section>
                    <section>
                      <div>
                        <HtmlRender
                          :htmlString="currentQ.qTextForSecondColumn"
                          class="htmlClassTest mt-1"
                        ></HtmlRender>
                      </div>
                    </section>

                    <div class="mt-15">
                      <v-row class="mt-4 mb-10 mx-1" v-if="currentQ.isCorrect == null">
                        <v-alert
                          color="info"
                          icon="$info"
                          variant="tonal"
                          text="This question was not attempted"
                        ></v-alert>
                      </v-row>
                      <!-- case 1 -->
                      <section v-if="currentQ.qType == '1'">
                        <v-radio-group v-model="currentQ.userAnswerSingleChoice" readonly>
                          <v-row v-for="option in currentQ.options" :key="option.key">
                            <v-col
                              class="mb-0 pb-0"
                              cols="12"
                              v-if="
                                userAnswerSingleChoice(currentQ, option.key) &&
                                showSolution
                              "
                            >
                              <span
                                v-if="correctAnswerSingleChoice(currentQ, option.key)"
                                class="text-green"
                              >
                              {{ answerResponseMessage.correctAnswerMsg }}
                              </span>
                              <span
                                v-if="!correctAnswerSingleChoice(currentQ, option.key)"
                                class="text-red"
                              >
                              {{ answerResponseMessage.incorrectAnswerMsg }}
                              </span>
                            </v-col>
                            <v-col
                              class="mb-0 pb-0"
                              cols="12"
                              v-if="
                                !userAnswerSingleChoice(currentQ, option.key) &&
                                correctAnswerSingleChoice(currentQ, option.key) &&
                                showSolution
                              "
                            >
                              <span class="text-green"> Correct Answer </span>
                            </v-col>
                            <v-col cols="12">
                              <v-hover v-slot="{ isHovering, props }">
                                <v-sheet
                                  class="bx"
                                  :class="{
                                    'on-hover': isHovering,
                                    selected:
                                      userAnswerSingleChoice(currentQ, option.key) &&
                                      !showSolution,
                                    'green-border':
                                      correctAnswerSingleChoice(currentQ, option.key) &&
                                      showSolution,
                                    'red-border':
                                      !correctAnswerSingleChoice(currentQ, option.key) &&
                                      userAnswerSingleChoice(currentQ, option.key) &&
                                      showSolution,
                                  }"
                                  v-bind="props"
                                >
                                  <v-radio
                                    readonly
                                    density="compact"
                                    color="primary"
                                    class="custom-radio testOption"
                                    dense
                                    :value="option.key"
                                  >
                                    <template v-slot:label>
                                      <v-row class="ma-0 pa-0 pr-3 pl-3" align="center">
                                        <v-col class="ma-0 pa-0" cols="auto">
                                          <v-avatar
                                            elevation="2"
                                            size="25"
                                            class="t-h6 outline-color-grey"
                                            :class="{
                                              'outline-color-white  text-primaryPurple':
                                                userAnswerSingleChoice(
                                                  currentQ,
                                                  option.key
                                                ) && !showSolution,
                                            }"
                                            variant="outlined"
                                          >
                                            {{ option.key.toUpperCase() }}
                                          </v-avatar>
                                        </v-col>
                                        <v-col class="ma-0 pa-0">
                                          <HtmlRender
                                            class="htmlClassTest htmlClassTestOption ml-2 py-4 w-100"
                                            :htmlString="option.value"
                                          >
                                          </HtmlRender>
                                        </v-col>
                                      </v-row>
                                    </template>
                                  </v-radio>
                                </v-sheet>
                              </v-hover>
                            </v-col>
                          </v-row>
                        </v-radio-group>
                      </section>

                      <section v-if="currentQ.qType == '2'">
                        <v-row v-for="option in currentQ.options" :key="option.key">
                          <v-col
                            class="mb-0 pb-0"
                            cols="12"
                            v-if="
                              userAnswerMultipleChoice(currentQ, option.key) &&
                              showSolution
                            "
                          >
                            <span
                              v-if="correctAnswerMultiChoice(currentQ, option.key)"
                              class="text-green"
                            >
                              {{ answerResponseMessage.correctAnswerMsg }}
                            </span>
                            <span
                              v-if="!correctAnswerMultiChoice(currentQ, option.key)"
                              class="text-red"
                            >
                              {{ answerResponseMessage.incorrectAnswerMsg }}
                            </span>
                          </v-col>
                          <v-col
                            class="mb-0 pb-0"
                            cols="12"
                            v-if="
                              !userAnswerMultipleChoice(currentQ, option.key) &&
                              correctAnswerMultiChoice(currentQ, option.key) &&
                              showSolution
                            "
                          >
                            <span class="text-green"> Correct Answer </span>
                          </v-col>
                          <v-col cols="12">
                            <v-hover v-slot="{ isHovering, props }">
                              <v-sheet
                                v-bind="props"
                                class="bx pa-0 ma-0"
                                :class="{
                                  'on-hover': isHovering,
                                  selected:
                                    userAnswerMultipleChoice(currentQ, option.key) &&
                                    !showSolution,
                                  'green-border':
                                    correctAnswerMultiChoice(currentQ, option.key) &&
                                    showSolution,
                                  'red-border':
                                    !correctAnswerMultiChoice(currentQ, option.key) &&
                                    userAnswerMultipleChoice(currentQ, option.key) &&
                                    showSolution,
                                }"
                              >
                                <v-checkbox
                                  density="compact"
                                  hide-details
                                  v-model="currentQ.userAnswers"
                                  readonly
                                  color="primary"
                                  class="testOption custom-radio"
                                  :value="option.key"
                                >
                                  <template v-slot:label>
                                    <v-row class="ma-0 pa-0 pr-3 pl-3" align="center">
                                      <v-col class="ma-0 pa-0" cols="auto">
                                        <v-avatar
                                          elevation="2"
                                          size="25"
                                          class="t-h6 outline-color-grey"
                                          :class="{
                                            'outline-color-white  text-primaryPurple':
                                              userAnswerMultipleChoice(
                                                currentQ,
                                                option.key
                                              ) && !showSolution,
                                          }"
                                          variant="outlined"
                                        >
                                          {{ option.key.toUpperCase() }}
                                        </v-avatar>
                                      </v-col>
                                      <v-col class="ma-0 pa-0">
                                        <HtmlRender
                                          class="htmlClassTest htmlClassTestOption ml-2 py-4 w-100"
                                          :htmlString="option.value"
                                        >
                                        </HtmlRender>
                                      </v-col>
                                    </v-row>
                                  </template>
                                </v-checkbox>
                              </v-sheet>
                            </v-hover>
                          </v-col>
                        </v-row>
                      </section>

                      <section v-if="currentQ.qType == '3'">
                        <v-row>
                          <v-col class="mb-0 pb-0" cols="12">
                            <span v-if="currentQ.isCorrect != null && showSolution">
                              <span v-if="currentQ.isCorrect" class="text-green">
                                {{ answerResponseMessage.correctAnswerMsg }}
                              </span>
                              <span v-if="!currentQ.isCorrect" class="text-red">
                                {{ answerResponseMessage.incorrectAnswerMsg }}
                              </span>
                            </span>
                          </v-col>
                          <v-col v-if="currentQ.isCorrect != null" cols="8" md="3" lg="2">
                            <v-text-field
                              @click.prevent=""
                              readonly
                              v-model="currentQ.userAnswerInteger"
                              :label="
                              isOpenedFromParentView? `Child's Answer` : isOpenedFromTeacherView? `Student's Answer` : `Your Answer`"
                              variant="outlined"
                              :color="
                                showSolution ? (currentQ.isCorrect ? 'green' : 'red') : ''
                              "
                              focused
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row
                          v-if="
                            (currentQ.isCorrect == null || !currentQ.isCorrect) &&
                            showSolution
                          "
                        >
                          <v-col class="mb-0 pb-0 text-green" cols="12">
                            <span> Correct Answer </span>
                          </v-col>
                          <v-col cols="8" md="3" lg="2">
                            <v-text-field
                              readonly
                              v-model="currentQ.answerInteger"
                              label="Answer"
                              variant="outlined"
                              color="green"
                              focused
                              base-color="green"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </section>

                      <section v-if="currentQ.qType == '4'">
                        <v-row>
                          <v-col class="mb-0 pb-0" cols="12">
                            <span v-if="currentQ.isCorrect != null && showSolution">
                              <span v-if="currentQ.isCorrect" class="text-green">
                                {{ answerResponseMessage.correctAnswerMsg }}
                              </span>
                              <span v-if="!currentQ.isCorrect" class="text-red">
                                {{ answerResponseMessage.incorrectAnswerMsg }}
                              </span>
                            </span>
                          </v-col>
                          <v-col v-if="currentQ.isCorrect != null" cols="8" md="3" lg="2">
                            <v-text-field
                              readonly
                              label="Answer"
                              v-model="currentQ.userAnswerDecimal"
                              :color="
                                showSolution ? (currentQ.isCorrect ? 'green' : 'red') : ''
                              "
                              focused
                              variant="outlined"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row v-if="showSolution">
                          <v-col class="mb-0 pb-0 text-green" cols="12">
                            <span> Correct Answer </span>
                          </v-col>
                          <v-col cols="8" md="3" lg="2">
                            <v-text-field
                              readonly
                              v-model="correctAnswerDecimal"
                              label="Answer"
                              variant="outlined"
                              color="green"
                              focused
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </section>
                    </div>
                  </section>

                  <section class="mx-4 whiteBackground" id="solutionSection" v-if="currentQ.solution != null && showSolution">
                    <v-row class="t-h5 mt-4">
                      <v-col cols="12"> Solution </v-col>
                    </v-row>
                    <v-row>
                      <div>
                        <v-col cols="12">
                          <HtmlRender
                            :htmlString="currentQ.solution"
                            class="htmlClassTest"
                          ></HtmlRender>
                        </v-col>
                      </div>
                    </v-row>
                  </section>
                </v-card>
                <v-card v-else elevation="0" scrollable class="h-100 w-100">
                  <v-card-text class="d-flex align-center justify-center h-100 w-100">
                    <span class="t-h5 font-weight-bold">No Question Found</span>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-container>
      </div>

      <v-footer
        v-if="loadedFromServer && modifiedQuestions.length > 0"
        app
        class="bgWhite px-4 pt-4 pb-6 ma-0"
        elevation="2"
      >
        <v-row justify="space-between" class="footer-row px-5 align-center">
          <v-col class="align-center">
            <v-btn
              v-if="$vuetify.display.smAndUp"
              class="t-btn text-capitalize btn-prev"
              :disabled="prevButtonDisabled"
              @click="prevQuestion"
              :size="$vuetify.display.smAndUp ? 'large' : 'default'"
              :width="
                $vuetify.display.mdAndUp
                  ? isPrevModuleButton
                    ? '170'
                    : '130'
                  : isPrevModuleButton
                  ? '165'
                  : '125'
              "
              elevation="0"
              :color="!prevButtonDisabled ? 'secondaryBackground' : undefined"
            >
              {{ isPrevModuleButton ? "Previous Module" : "Previous" }}
              <template v-slot:prepend>
                <v-icon>mdi-chevron-left</v-icon>
              </template>
            </v-btn>

            <v-btn
              v-else
              density="compact"
              size="large"
              icon="mdi-chevron-left"
              :disabled="prevButtonDisabled"
              @click="prevQuestion"
            >
            </v-btn>
          </v-col>

          <v-col cols="auto">
            <v-btn
              @click="questionDialog = true"
              color="secondaryBackground"
              density="compact"
              elevation="0"
              size="large"
              :icon="questionDialog == true ? 'mdi-chevron-down' : 'mdi-chevron-up'"
            ></v-btn>
            <span class="ml-2">
              {{ currentQNo }} of {{ this.modifiedQuestions.length }}
            </span>
          </v-col>

          <v-col class="text-end align-center">
            <v-btn
              v-if="$vuetify.display.smAndUp"
              class="t-btn text-capitalize btn-grad text-primaryTextColor"
              :size="$vuetify.display.smAndUp ? 'large' : 'default'"
              :width="
                $vuetify.display.mdAndUp
                  ? isNextModuleButton
                    ? '170'
                    : '130'
                  : isNextModuleButton
                  ? '165'
                  : '125'
              "
              elevation="0"
              @click="nextQuestion"
              :disabled="nextButtonDisabled"
              :color="!nextButtonDisabled ? 'secondaryBackground' : undefined"
            >
              {{ isNextModuleButton ? "Next Module" : "Next" }}
              <template v-slot:append>
                <v-icon color="white">mdi-chevron-right</v-icon>
              </template>
            </v-btn>
            <v-btn
              v-else
              density="compact"
              size="large"
              icon="mdi-chevron-right"
              @click="nextQuestion"
              color="primaryPurple"
              :disabled="nextButtonDisabled"
            >
            </v-btn>
          </v-col>
        </v-row>
      </v-footer>

      <QuestionActionsComponent
        v-if="loadedFromServer && modifiedQuestions.length > 0"
        :test-type="testType"
        :q-id="currentQ.qId"
        :test-id="testId"
        :test-instance-id="testInstanceId"
        :q-obj="currentQ"
        ref="questionActions"
        :screenshotQuestionElement = "screenshotQuestionElement"
        :screenshotSolutionElement = "screenshotSolutionElement"
        :chatbotConfig="chatbotConfig"
        :chatbotAllowed = "chatbotAllowed"
        :class="{
          'tool-height': true,
          'tool-height-md': $vuetify.display.md,
          'tool-height-sm': $vuetify.display.sm
        }"
      ></QuestionActionsComponent>
    </div>
  </v-container>

  <!-- <ChatBotComponent :testId="testId" :testInstanceId="testInstanceId" ref="chatbot">
  </ChatBotComponent> -->
</template>

<script>
import { mapState, mapGetters } from "vuex";
import _ from "lodash";
import HtmlRender from "@/components/Test/HtmlRender.vue";
import {
  AnalyticsEvents,
  recordAnalyticsEvent,
} from "@/services/awsPinpointAnalyticsService";
import DragableFeedbackDialog from "@/components/Test/TestQuestionPageComponents/DragableFeedbackDialog.vue";
import ChatBotComponent from "@/components/practice/ChatBotComponent.vue";
import { getShowSolution, setShowSolution } from "@/services/authService";
import ScribbleComponent from "@/components/scribble/ScribbleComponent.vue";
import QuestionActionsComponent from '@/components/questionTools/QuestionActionsComponent.vue';

const marked = require("marked");

const renderer = new marked.Renderer();
renderer.link = function link(href, title, text) {
  return `<a href="${href}" title="${title}" target="_blank">${text}</a>`;
};
export default {
  name: "TestResultPage",
  data: () => ({
    questionDialog: false,
    loadedFromServer: false,
    exitDialog: false,
    testId: "",
    openChatbotForFirstTime: false,
    testInstanceId: "",
    reportDialog: false,
    isFromReport: false,
    showFilter: false,
    showSolution: false,
    testType: "",
    questionTypeList: [
      { name: "Correct", value: true },
      { name: "Incorrect", value: false },
      { name: "Unanswered", value: null },
    ],
    difficultyList: [
      { name: "Easy", value: [1, 2, 3] },
      { name: "Medium", value: [4, 5, 6] },
      { name: "Hard", value: [7, 8, 9, 10] },
    ],
    timeSpentList: [
      { name: "Less then 30 seconds", lower: 0, higher: 29 },
      { name: "30 seconds to 2 minutes", lower: 30, higher: 119 },
      { name: "More than 2 minutes", lower: 120, higher: null },
    ],
    filterQuestions: {
      questionTypes: [],
      difficulties: [],
      timeSpents: [],
      topicIds: [],
      categoryIds: [],
    },
    showChatbot: true,
    modifiedQuestions: [],
    selectedModule: {},
    isOpenedFromTeacherView: false,
    isOpenedFromParentView: false,
    showScribbleImage: false,
    screenshotElement: null,
    screenshotQuestionElement: {},
		screenshotSolutionElement: undefined,
    questionActionsRef : null,
    chatbotConfig: {
      currentMode: "",
      chatbotMode: ""
    },
    chatbotAllowed: true
  }),
  components: {
    HtmlRender,
    DragableFeedbackDialog,
    // ChatBotComponent,
    ScribbleComponent,
    QuestionActionsComponent
  },
  async mounted() {
    this.$store.commit("appbarVisible", false);
    this.$store.commit("navbarVisible", false);
    this.$store.commit("showNotification", false);
    this.showSolution = getShowSolution() == "true";
    // console.log(this.$route);
    this.$store.commit("updateCurrentTestState", {
      sectionIndex: 0,
      qIndex: 0,
    });
    const self = this;
    this.chatbotConfig = {
      currentMode: "practiceSolution",
      chatbotMode: "mockTestSolution_solution"
    }
    if ("testId" in this.$route.params) {
      const test = this.$route.params.testId;
      const instance = this.$route.query.instance;
      this.testId = test;
      this.testInstanceId = instance;
      this.isFromReport = this.$route.query.report;
      const quiz = this.$route.query.quiz ?? false;
      const moduleId = this.$route.query.moduleId;
      console.log("quiz", quiz);

      if (quiz) {
        this.testType = "quizSolution";
      }
      const challenge = this.$route.query.challenge ?? false;
      console.log("challenge", challenge);
      if (challenge) {
        this.testType = "challengeSolution";
      }
      const mockTest = this.$route.query.mocktest ?? false;
      console.log("mockTest", mockTest);
      if (challenge) {
        this.testType = "mockTestSolution";
      }
      recordAnalyticsEvent(AnalyticsEvents.v2MockTestSolutionScreenOpen, {
        testId: test,
        testInstanceId: instance,
      });

      if (this.$route.query.teacher && this.isUserTeacher) {
        this.isOpenedFromTeacherView = true;
      }
      if(this.$route.query.parent && this.isUserParent){
        this.isOpenedFromParentView = true;
      }

      this.$store.commit("clearTestResult");
      this.$store.commit("testResultLoadedFromServer", false);
      this.$store.dispatch("getTestTopicsData", { testId: test });
      this.$store.dispatch("getTestCategoriesData", { testId: test });
      this.$store
        .dispatch("loadTestResult", { testId: test, instance: instance })
        .then(() => {
          if (moduleId) {
            const moduleIndex = _.findIndex(
              self.currentTestResultJson,
              (e) => e.sectionId == moduleId
            );
            if (moduleIndex >= 0) {
              this.$store.commit("updateCurrentTestState", {
                sectionIndex: moduleIndex,
                qIndex: 0,
              });
            }
          }
          self.modifiedQuestions =
            self.currentTestResultJson[self.currentTestState.sectionIndex].questions;
          self.selectedModule =
            self.currentTestResultJson[self.currentTestState.sectionIndex];
          self.loadedFromServer = true;
          setTimeout(() => {
            window.MathJax.typesetPromise();
          }, 10);
        });
    }
  },
  computed: {
    ...mapState([
      "currentTestResult",
      "currentTestResultJson",
      "currentTestState",
      "newLearnNodeActivity",
      "currentActivityId",
      "currentTopicId",
      "isUserTeacher",
      "isUserParent",
      "config",
    ]),
    ...mapGetters([
      "isQuestionReviewer",
      "isLevel1Reviewer",
      "isLevel2Reviewer",
      "isLevel3Reviewer",
    ]),
    isDevEnvironment: {
      get(){
        return this.config.environment=="dev";
      }
    },
    testInstanceQuestionSummary: {
      get() {
        return this.currentTestResult.testInstanceQuestionSummary;
      },
    },
    currentQNo: {
      get() {
        return this.currentTestState.qIndex + 1;
      },
    },
    currentQ: {
      get() {
        if (this.currentTestResultJson.length == 0) {
          return [];
        }
        return this.modifiedQuestions[this.currentTestState.qIndex];
      },
    },
    currentSection: {
      get() {
        return this.currentTestResultJson[this.currentTestState.sectionIndex];
      },
    },
    prevButtonDisabled: {
      get() {
        return this.currentTestState.qIndex == 0 &&
          this.currentTestState.sectionIndex == 0
          ? true
          : false;
      },
    },
    isPrevModuleButton: {
      get() {
        return this.currentTestState.qIndex == 0 &&
          this.currentTestState.sectionIndex != 0
          ? true
          : false;
      },
    },
    nextButtonDisabled: {
      get() {
        return this.currentTestState.qIndex == this.modifiedQuestions.length - 1 &&
          this.currentTestState.sectionIndex == this.currentTestResultJson.length - 1
          ? true
          : false;
      },
    },
    isNextModuleButton: {
      get() {
        return this.currentTestState.qIndex == this.modifiedQuestions.length - 1 &&
          this.currentTestState.sectionIndex != this.currentTestResultJson.length - 1
          ? true
          : false;
      },
    },
    correctAnswerDecimal: {
      get() {
        if (this.currentQ.answerDecimalHigh == this.currentQ.answerDecimalLow) {
          return this.currentQ.answerDecimalHigh;
        }
        return `${this.currentQ.answerDecimalHigh} - ${this.currentQ.answerDecimalLow}`;
      },
    },
    topicList: {
      get() {
        const foundTopics = _.find(this.$store.state.testTopicsData, {
          moduleSubject: this.currentSection.moduleSubject,
        });
        return _.get(foundTopics, "topics", []);
      },
    },
    categoryList: {
      get() {
        const foundCategories = _.find(this.$store.state.testCategoriesData, {
          moduleSubject: this.currentSection.moduleSubject,
        });
        return _.get(foundCategories, "categories", []);
      },
    },
    feedbackOrQuestionReiewIconName: {
      get() {
        var retVal = "mdi-message-alert-outline"; //"Report a Problem" for users/students
        if (this.isQuestionReviewer) {
          retVal = "mdi-shield-check-outline"; // for internal Question reviewers
        }
        return retVal;
      },
    },
    feedbackOrQuestionReiewIconTooltipText: {
      get() {
        var retVal = "Report a problem"; ////"Report a Problem" for users/students
        if (this.isQuestionReviewer) {
          retVal = "Review Feedback & Corrections"; // for internal Question reviewers
        }
        return retVal;
      },
    },
    answerResponseMessage: {
      get(){
        let response = {};
        if(this.isOpenedFromParentView){
          response.correctAnswerMsg = "Child's Answer - Correct";
          response.incorrectAnswerMsg = "Child's Answer - Incorrect";
        }
        else if(this.isOpenedFromTeacherView){
          response.correctAnswerMsg = "Student's Answer - Correct";
          response.incorrectAnswerMsg = "Student's Answer - Incorrect";
        }
        else{
          response.correctAnswerMsg = "Your Answer - Correct";
          response.incorrectAnswerMsg = "Your Answer - Incorrect";
        }
        return response;
      }
    },
  },

  watch: {
    showSolution: {
      handler(newVal){
        if(newVal){
          const checkElement = async () => {
            this.screenshotSolutionElement = document.getElementById("solutionSection");
            
            console.log("....................................",this.screenshotSolutionElement)
            if (!this.screenshotSolutionElement) {
              setTimeout(checkElement, 100); // Retry after 100ms if element is not found
            }
          };

          checkElement();
        }else{
          this.screenshotSolutionElement = undefined;
        }
      }
    },
    currentSection: {
      handler(newVal) {
        if (!newVal) {
          this.chatbotAllowed = false;
          this.$refs.questionActions?.$refs.chatbot?.hideChatbot();
          return;
        }
        // if(!this.isDevEnvironment){
        //   this.chatbotAllowed = (newVal.moduleSubjectType == "readingandwriting");
        // }else{
        // }
        const checkElement = async () => {
        this.screenshotQuestionElement = document.getElementById("mainQuestionScreen");
        if(this.showSolution){
          this.screenshotSolutionElement = document.getElementById("solutionSection");
        }
        console.log("....................................",this.screenshotSolutionElement)
        if (this.screenshotQuestionElement) {
          if(this.showSolution && this.screenshotSolutionElement){
            this.chatbotAllowed = true;
          }else if(!this.showSolution){
            this.chatbotAllowed = true;
          }
          // if (this.screenshotElement.offsetHeight < window.innerHeight) {
          //   this.screenshotElement.style.height = `${window.innerHeight - 40}px`;
          // }
          // await this.$refs.questionActions?.$refs.chatbot?.getNudgeInfoForCurrentQuestion();
          // this.$refs.questionActions?.getNudgeInfo(this.currentQ);
          } else {
            setTimeout(checkElement, 100); // Retry after 100ms if element is not found
          }
        };

        checkElement();
        // this.chatbotAllowed = ;
        // if (newVal.moduleSubjectType != "readingandwriting") {
        //   this.$refs.questionActions?.$refs.chatbot?.hideChatbot();
        //   return;
        // }

        this.showChatbot = true;
        this.$refs.questionActions?.$refs.chatbot?.showChatbot();
      },
      deep: true,
    },
    currentQ: {
      handler(newVal, oldVal) {
        console.log(this.currentSection);
        console.log(this.modifiedQuestions);

        if (this.modifiedQuestions.length == 0) {
          this.$refs.questionActions?.$refs.chatbot?.hideChatbot();
          return;
        }
        if (
          this.currentSection &&
          this.currentSection.moduleSubjectType != "readingandwriting"
        ) {
          setTimeout(() => {
            window.MathJax.typesetPromise();
          }, 10);
          this.$refs.questionActions?.getNudgeInfo(this.currentQ);
          return;
        }
        if (this.modifiedQuestions.length > 0) {
          if (newVal && oldVal) {
            console.log("old new both ", newVal.qId, oldVal.qId);
            if (newVal.qId == oldVal.qId) {
              return;
            }
            
            this.chatbotConfig = {
              currentMode: "practiceSolution",
              chatbotMode: "mockTestSolution_solution"
            }
            this.$refs.questionActions?.getNudgeInfo(this.currentQ);

            if (!this.openChatbotForFirstTime) {
              this.openChatbotForFirstTime = true;
              if (this.$vuetify.display.smAndDown) {
                this.$refs.questionActions?.$refs.chatbot?.closeChatbot();
              } else {
                this.$refs.questionActions?.$refs.chatbot?.openChatbot();
              }
            }
          } else if (newVal && !oldVal) {
            console.log("only new ", newVal);
            this.chatbotConfig = {
              currentMode: "practiceSolution",
              chatbotMode: "mockTestSolution_solution"
            }
            this.$refs.questionActions?.getNudgeInfo(this.currentQ);
            if (!this.openChatbotForFirstTime) {
              this.openChatbotForFirstTime = true;
              if (this.$vuetify.display.smAndDown) {
                this.$refs.questionActions?.$refs.chatbot?.closeChatbot();
              } else {
                this.$refs.questionActions?.$refs.chatbot?.openChatbot();
              }
            }

          } else {
            //pass
          }
        }
      },
      deep: true,
    },
    filterQuestions: {
      handler(val) {
        const filterObject = {
          questionTypes: val.questionTypes.map((e) => e.value),
          difficulties: [].concat(...val.difficulties.map((e) => e.value)),
          timeSpents: val.timeSpents,
          topicIds: val.topicIds.map((e) => e.topicKey),
          categoryIds: val.categoryIds.map((e) => e.key),
        };
        let check = false;
        const questionData = this.currentTestResultJson[
          this.currentTestState.sectionIndex
        ].questions;
        let modifiedQuestionData = questionData;
        if (filterObject.questionTypes.length > 0) {
          check = true;
          modifiedQuestionData = _.filter(modifiedQuestionData, (q) =>
            filterObject.questionTypes.includes(q.isCorrect)
          );
        }
        if (filterObject.difficulties.length > 0) {
          check = true;
          modifiedQuestionData = _.filter(modifiedQuestionData, (q) =>
            filterObject.difficulties.includes(parseInt(q.difficultyLevel))
          );
        }
        if (filterObject.timeSpents.length > 0) {
          check = true;
          modifiedQuestionData = _.filter(modifiedQuestionData, (q) => {
            let check = false;
            _.forEach(filterObject.timeSpents, function (timeSpentData) {
              if (timeSpentData.lower <= q.timeSpent && !check) {
                if (timeSpentData.higher) {
                  check = timeSpentData.higher > q.timeSpent;
                } else {
                  check = true;
                }
              }
            });
            return check;
          });
        }
        if (filterObject.topicIds.length > 0) {
          check = true;
          modifiedQuestionData = _.filter(modifiedQuestionData, (q) =>
            filterObject.topicIds.includes(q.topicId)
          );
        }
        if (filterObject.categoryIds.length > 0) {
          check = true;
          modifiedQuestionData = _.filter(modifiedQuestionData, (q) =>
            filterObject.categoryIds.includes(q.categoryId)
          );
        }
        this.modifiedQuestions = modifiedQuestionData;
        if (check) {
          this.$store.commit("updateCurrentTestState", {
            qIndex: 0,
            sectionIndex: this.currentTestState.sectionIndex,
          });
        }
      },
      deep: true,
    },
  },

  methods: {
    getCategoryName(id) {
      let data = "";
      let found = this.categoryList.find((e) => e.key == id);
      if (found) {
        data = found.label ?? "";
      }
      return data;
    },
    getTopicName(id) {
      let data = "";
      let found = this.topicList.find((e) => e.topicKey == id);
      if (found) {
        data = found.label ?? "";
      }
      return data;
    },
    showReportDialog() {
      // recordAnalyticsEvent(AnalyticsEvents.v2TestSectionInstructionsDuringTest, {
      //   testId: this.currentTestId,
      //   testName: this.startTestInfo.testmetadata.testName,
      //   testInstanceId: this.startTestInfo.testInstanceId,
      //   sectionId: this.currentSection.sectionId,

      // });
      this.reportDialog = true;
    },
    correctAnswerSingleChoice(item, key) {
      if (item.answerSingleChoice == null) {
        return false;
      }
      if (item.answerSingleChoice == key) {
        return true;
      }
      return false;
    },
    userAnswerSingleChoice(item, key) {
      if (item.userAnswerSingleChoice == null) {
        return false;
      }
      if (item.userAnswerSingleChoice == key) {
        return true;
      }
      return false;
    },
    correctAnswerMultiChoice(item, key) {
      if (item.answers == null) {
        return false;
      }
      if (item.answers.includes(key)) {
        return true;
      }
      return false;
    },
    userAnswerMultipleChoice(item, key) {
      if (item.userAnswers == null) {
        return false;
      }
      if (item.userAnswers.includes(key)) {
        return true;
      }
      return false;
    },
    openQFromDialog(qIndex) {
      this.$store.commit("updateCurrentTestState", {
        qIndex: qIndex,
        sectionIndex: this.currentTestState.sectionIndex,
      });

      this.questionDialog = false;

      // setTimeout(() => {
      //   window.MathJax.typesetPromise();
      // }, 10);
    },
    getMarkDownHtml(markdown) {
      if (markdown == null) {
        return "";
      }
      let markDownText = markdown;
      let out = false;
      const regex = /\\/gm;
      const escapedString = markDownText.replace(regex, "\\\\");

      out = marked.parseInline(escapedString, { renderer });

      return out;
    },
    nextQuestion() {
      recordAnalyticsEvent(AnalyticsEvents.v2MockTestSolutionNextButtonClicked, {
        testId: this.testId,
        testInstanceId: this.testInstanceId,
        moduleId: this.currentSection.sectionId,
        questionId: this.currentQ.qId,
      });
      if (this.currentTestState.qIndex == this.modifiedQuestions.length - 1) {
        // if (
        //   this.currentTestState.sectionIndex ==
        //   this.currentTestResultJson.length - 1
        // ) {
        //   // console.log("review");
        //   // this.reviewScreen = true
        //   this.backFromDialog();
        // } else {
        this.filterQuestions = {
          questionTypes: [],
          difficulties: [],
          timeSpents: [],
          topicIds: [],
          categoryIds: [],
        };
        this.selectedModule = this.currentTestResultJson[
          this.currentTestState.sectionIndex + 1
        ];
        recordAnalyticsEvent(AnalyticsEvents.v2MockTestSolutionChangeModule, {
          testId: this.testId,
          testInstanceId: this.testInstanceId,
          prevModuleId: this.currentSection.sectionId,
          moduleId: this.selectedModule.sectionId,
        });
        this.$store.commit("updateCurrentTestState", {
          qIndex: 0,
          sectionIndex: this.currentTestState.sectionIndex + 1,
        });

        // }
      } else {
        this.$store.commit("updateCurrentTestState", {
          qIndex: this.currentTestState.qIndex + 1,
          sectionIndex: this.currentTestState.sectionIndex,
        });
      }

      // setTimeout(() => {
      //   window.MathJax.typesetPromise();
      // }, 10);
    },
    prevQuestion() {
      recordAnalyticsEvent(AnalyticsEvents.v2MockTestSolutionPreviousButtonClicked, {
        testId: this.testId,
        testInstanceId: this.testInstanceId,
        moduleId: this.currentSection.sectionId,
        questionId: this.currentQ.qId,
      });
      if (this.currentTestState.qIndex == 0) {
        if (this.currentTestState.sectionIndex == 0) {
          console.log("first section");
        } else {
          this.filterQuestions = {
            questionTypes: [],
            difficulties: [],
            timeSpents: [],
            topicIds: [],
            categoryIds: [],
          };
          this.selectedModule = this.currentTestResultJson[
            this.currentTestState.sectionIndex - 1
          ];
          recordAnalyticsEvent(AnalyticsEvents.v2MockTestSolutionChangeModule, {
            testId: this.testId,
            testInstanceId: this.testInstanceId,
            prevModuleId: this.currentSection.sectionId,
            moduleId: this.selectedModule.sectionId,
          });
          this.$store.commit("updateCurrentTestState", {
            qIndex:
              this.currentTestResultJson[this.currentTestState.sectionIndex - 1].questions
                .length - 1,
            sectionIndex: this.currentTestState.sectionIndex - 1,
          });
        }
      } else {
        // changes ui to next question
        this.$store.commit("updateCurrentTestState", {
          qIndex: this.currentTestState.qIndex - 1,
          sectionIndex: this.currentTestState.sectionIndex,
        });
      }

      // setTimeout(() => {
      //   window.MathJax.typesetPromise();
      // }, 10);
    },
    backFromDialog() {
      recordAnalyticsEvent(AnalyticsEvents.v2MockTestSolutionCloseButtonClicked, {
        testId: this.testId,
        testInstanceId: this.testInstanceId,
      });
      if (this.isFromReport) {
        recordAnalyticsEvent(
          AnalyticsEvents.v2LearnTopicQuizReportViewSolutionClosedAfterSubmit,
          event
        );
      } else {
        recordAnalyticsEvent(
          AnalyticsEvents.v2LearnTopicQuizAttemptReportViewSolutionClosed,
          event
        );
      }
      this.exitDialog = false;
      this.$router.back();
    },
    toggleQuestionType() {
      if (this.filterQuestions.questionTypes.length == this.questionTypeList.length) {
        this.filterQuestions.questionTypes = [];
      } else {
        this.filterQuestions.questionTypes = this.questionTypeList.slice();
      }
    },
    toggleDifficulty() {
      if (this.filterQuestions.difficulties.length == this.difficultyList.length) {
        this.filterQuestions.difficulties = [];
      } else {
        this.filterQuestions.difficulties = this.difficultyList.slice();
      }
    },
    toggleTimeSpent() {
      if (this.filterQuestions.timeSpents.length == this.timeSpentList.length) {
        this.filterQuestions.timeSpents = [];
      } else {
        this.filterQuestions.timeSpents = this.timeSpentList.slice();
      }
    },
    toggleTopic() {
      if (this.filterQuestions.topicIds.length == this.topicList.length) {
        this.filterQuestions.topicIds = [];
      } else {
        this.filterQuestions.topicIds = this.topicList.slice();
      }
    },
    toggleCategory() {
      if (this.filterQuestions.categoryIds.length == this.categoryList.length) {
        this.filterQuestions.categoryIds = [];
      } else {
        this.filterQuestions.categoryIds = this.categoryList.slice();
      }
    },
    selectModule(item) {
      recordAnalyticsEvent(AnalyticsEvents.v2MockTestSolutionChangeModule, {
        testId: this.testId,
        testInstanceId: this.testInstanceId,
        prevModuleId: this.currentSection.sectionId,
        moduleId: item.sectionId,
      });
      const sIndex = _.findIndex(this.currentTestResultJson, (e) => {
        return e == item;
      });
      this.selectedModule = item;
      this.filterQuestions = {
        questionTypes: [],
        difficulties: [],
        timeSpents: [],
        topicIds: [],
        categoryIds: [],
      };
      this.$store.commit("updateCurrentTestState", {
        qIndex: 0,
        sectionIndex: sIndex,
      });
    },
    isClearFilterDisable() {
      return _.every(this.filterQuestions, (e) => _.isEmpty(e));
    },
    onClearFilter() {
      this.filterQuestions = {
        questionTypes: [],
        difficulties: [],
        timeSpents: [],
        topicIds: [],
        categoryIds: [],
      };
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },
    toggleSolution(item) {
      if (item && this.currentSection.moduleSubjectType == "math") {
        setTimeout(() => {
          window.MathJax.typesetPromise();
        }, 10);
      }
      setShowSolution(item);
    },
    addEditNotes() {
      try {
        this.$store.commit("increamentLoader");
        this.screenshotElement = document.getElementById("mainQuestionScreen");
				if(this.screenshotElement.offsetHeight < window.innerHeight){
					this.screenshotElement.style.height = `${window.innerHeight - 40}px`;
				}
        this.showScribbleImage = true;
        let event = {
          userType: this.isUserTeacher? 'teacher': 'student',
          isOpenedFromTeacherView: this.isOpenedFromTeacherView? true: false,
          buttonCurrentAction: this.isOpenedFromTeacherView? this.currentQ.isTeacherNotesAttached? 'edit': 'add': 'view',
          testInstanceId: this.testInstanceId,
          questionId: this.currentQ.qId, 
        }
        recordAnalyticsEvent(AnalyticsEvents.v2NotesButtonClicked, event); 
      } catch (error) {
        this.$store.commit("decreamentLoader");
        let event = {
          userType: this.isUserTeacher? 'teacher': 'student',
          isOpenedFromTeacherView: this.isOpenedFromTeacherView? true: false,
          buttonCurrentAction: this.isOpenedFromTeacherView? this.currentQ.isTeacherNotesAttached? 'edit': 'add': 'view',
          testInstanceId: this.testInstanceId,
          questionId: this.currentQ.qId, 
          error: true,
          errorMessage: error
        }
        recordAnalyticsEvent(AnalyticsEvents.v2NotesButtonClicked, event); 
      }

      return;
    },
    showScribble() {
      if (this.isOpenedFromTeacherView) {
        return true;
      } else {
        return this.currentQ.isTeacherNotesAttached;
      }
    },
    closeNotesDialog() {
      this.screenshotElement.style.height = 'fit-content';
      let event= {
        testInstanceId: this.testInstanceId,
        questionId: this.currentQ.qId,
      };
      recordAnalyticsEvent(AnalyticsEvents.v2NotesClosed, event);  
      this.showScribbleImage = false;
    },
    upateTeacherNote() {
      this.currentTestResultJson[this.currentTestState.sectionIndex].questions[
        this.currentTestState.qIndex
      ].isTeacherNotesAttached = true;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.outline-color-grey {
  border-color: #010101b3 !important;
  border: 1px solid;
  color: #010101b3 !important;
}

.outline-color-white {
  border-color: white !important;
  background-color: white;
}

.solution-height {
  padding-top: 200px;
  height: 100%;
}

@media only screen and (min-width: 1400px) {
  .solution-height {
    padding-top: 160px !important;
  }
}

.solution-height-md {
  padding-top: 200px !important;
}

.solution-height-sm {
  padding-top: 280px !important;
}

.solution-height-xs {
  padding-top: 0px !important;
}



.filter-switch {
  width: 160px !important;
}

.filter-dropdown {
  width: 180px !important;
}

.selector {
  border: 2px solid rgb(var(--v-theme-primaryPurple));
  color: rgb(var(--v-theme-primaryTextColor));
  border-radius: 12px;
}

.filter-selected-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.testCard {
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  background: rgb(var(--v-theme-cardBackgroundColor));
  cursor: pointer;
  border-radius: 0 !important;
}

.recommended-border {
  background: linear-gradient(#393850, #393850) padding-box,
    linear-gradient(180deg, #ab77ff 0%, #585ef3 100%) border-box;
  border-radius: 12px;
  border: 2px solid transparent;
}

.sub-box {
  background-color: transparent;
  border-radius: 12px;
  border: 2px solid transparent;
}

.category-heading {
  color: rgb(var(--v-theme-report-categoryHeading));
  font-size: 0.875rem;
  align-self: stretch;
}

.not-activity-btn {
  border: 2px solid rgb(var(--v-theme-primaryPurple));
  border-radius: 12px;
  background: rgb(var(--v-theme-cardBackgroundColor));
}

.disabled-btn {
  background-color: #00000000;
  opacity: 0.2;
}

.icon-opacity {
  opacity: 0.4;
  /* color: #A7A6B0; */
}

.no-events {
  pointer-events: none;
}

.correct {
  background: #26b99aa6 !important;
  color: white !important;
}

.success {
  background: #26b99aa6 !important;
}

.timer-color {
  color: #26b99aa6 !important;
}

.unanswered {
  border: 2px solid #949494 !important;
}

.marked {
  border: 2.5px solid #ff894f !important;
}

.option {
  /* box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important; */
  border-radius: 10px;
  border: 1px rgb(var(--v-theme-secondaryBackground)) solid;
}

.v-sheet.on-hover {
  cursor: pointer;
  /* border: 1px solid rgb(var(--v-theme-primary)); */
  border-color: transparent;
  background-color: white;
  box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;
}

.green-border {
  border: 2.5px solid green !important;
}

.red-border {
  border: 2.5px solid #ff8a80 !important;
}

.incorrect {
  background: #ff8a80 !important;
  color: white !important;
}

.btn-grad {
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%);
  border-radius: 29.4079px;
}

.btn-prev {
  border-radius: 29.4079px;
}

.bgWhite {
  background-color: white !important;
}

.unattempted {
  border: 2px solid #949494 !important;
}

.notesAttached {
  position: absolute;
  top: -2px;
  right: 0;
}

.notesAttached2 {
  position: absolute;
  top: -8px;
  right: -8px;
}

.makePositionRelative {
  position: relative;
}

.bx {
  background-color: rgb(var(--v-theme-navColor));
  border: 1px solid #e7e7e7;
  border-radius: 4px;
}

.card-bg-color {
  background-color: rgb(var(--v-theme-navColor)) !important;
  border: 0.5px solid rgba(0, 0, 0, 0.15) !important;
  border-radius: 12px !important;
}

.outline-color {
  border-color: rgb(var(--v-theme-secondaryBackground)) !important;
}

.bottom {
  position: absolute !important;
  bottom: 10px !important;
}

.w-70 {
  max-width: 70%;
}

.v-overlay__content {
  position: absolute !important;
  bottom: 10px !important;
}

.bgW {
  height: 100%;
  width: 100%;
  background-color: white;
  position: relative;
}

.scroll {
}

.dialog-bx {
  position: fixed !important;
}

.htmlClassTestOption span {
  top: 0 !important;
  vertical-align: middle;
}

.htmlClassTest img {
  top: 0 !important;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

.qNoClass {
  font-size: 0.875rem;
  font-family: "Inter", sans-serif !important;
}

.selected {
  cursor: pointer;
  /* border: 1px solid rgb(var(--v-theme-primary)); */
  border-color: transparent;
  background-color: rgb(var(--v-theme-primaryPurple)) !important;
  color: white !important;
}

.text-primaryPurple {
  color: rgb(var(--v-theme-primaryPurple)) !important;
}

.whiteBackground {
  background-color: white !important;
}
</style>
<style>
.htmlClassTestOption span {
  top: 0 !important;
  vertical-align: middle;
}

.htmlClassTest img {
  top: 0 !important;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

.htmlClassTest span {
  top: 0 !important;
  vertical-align: middle;
}

.testOption .v-selection-control--inline {
  min-width: 100% !important;
}

.testOption .v-label {
  width: 100% !important;
  opacity: 1 !important;
}

.custom-radio{
  margin: 0;
  padding: 0;
  width: 100%;
}

.custom-radio .v-selection-control__wrapper {
  display: none;
}

.v-label {
  opacity: 1 !important;
}

.tool-height .question-nav-item{
  padding-top: 205px !important;
  height: 100%;
}

@media only screen and (min-width: 1400px) {
  .tool-height .question-nav-item{
    padding-top: 165px !important;
  }
}

.tool-height-md .question-nav-item{
  padding-top: 205px !important;
}

.tool-height-sm .question-nav-item{
  padding-top: 285px !important;
}

.tool-height .question-action-box{
  top: 30px !important;
}

</style>
