<template>
  <v-main class="pt-0 s-size d-flex flex-column justify-space-between outer-container">
    <v-container style="color: white" class="pt-0">
      <div class="pa-0 main-container">

        <!-- Questionaire Form -->
        <v-row v-if="currentQuestion" justify="center" align="center" class="mt-8 mb-8">
          <v-col cols="12" sm="6" lg="5" xl="3">
            <!-- Question -->
            <v-row class="text-sm-center w-100 pa-0 mx-0 justify-sm-center text-wrap">
              <h3 class="t-q-title mx-2 mx-sm-0 text-wrap">
                {{ currentQuestion.questionText }}
              </h3>
            </v-row>

            <!-- Options -->
            <v-row class="mx-auto pa-0 mt-8 ma-0" justify="center" align="center">
              <v-col cols="6" sm="6" class="ma-0 pa-0 d-flex justify-center"
                style="background: none; text-align: center; color: white" v-for="option in currentQuestion.options"
                :key="option.optionId" color="white">
                <v-hover v-slot="{ isHovering, props }">
                  <v-sheet :width="mdAndUp ? 300 : '100%'" :class="{
          'selectedOption':
            currentQuestionResponse?.optionId === option.optionId, 'on-hover': isHovering && $vuetify.display.mdAndUp
        }" class="ma-2 pa-4 btnStyle d-flex justify-center align-center" height="100%" rounded="lg"
                    @click="onSelectedOption(option)" v-bind="props">

                    <v-row justify="center" align="center" style="min-height: 110px; max-width: 175px; gap: 1rem"
                      align-content="center">
                      <v-col class="ma-0 pa-0" cols="12">
                        <v-avatar class="ma-0" size="30px" rounded="0">
                          <v-img :src="getImageUrl(option.image)"></v-img>
                        </v-avatar>
                      </v-col>
                      <v-col class="ma-0 pa-0" cols="12">
                        <v-card-title style="color: white" class="t-o-title py-0 text-wrap ">
                          {{ option.text }}
                        </v-card-title>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-hover>
              </v-col>
            </v-row>

          </v-col>
        </v-row>
        <!-- Registration Form -->
        <v-row v-else-if="isInputFormShow" class="">
          <v-card color="#ffffff00" class="mx-auto mt-0 pa-2" elevation="0">
            <v-row align="center" justify="center" style="color: white" class="mb-1 text-center mt-4 pa-0 ma-0">
              <v-col class="pa-0 ma-0">
                <h1 class="title">
                  Fun and effective way to learn and <br />
                  improve your skills for the SAT!
                </h1>
              </v-col>
            </v-row>
            <v-row justify="center" align="center" class="mt-6">
              <v-col cols="12" sm="9" style="max-width: 360px;">
                <v-form v-model="form" @submit.prevent="onInputSubmit">
                  <p style="color: white; font-weight: 500; margin-bottom: 0.875rem" class="text-left">
                    What should we call you?
                  </p>
                  <v-text-field hide-details="true" placeholder="Full Name" v-model="editedValue.name"
                    @keydown="nameKeydown($event)" color="#000000" :readonly="loading" class="mb-8" rounded="8px"
                     variant="solo" flat bg-color="#fff" density="compact"></v-text-field>

                  <p style="color: white; font-weight: 500; margin-bottom: 0.875rem" class="text-left">
                    Please enter your phone number or email
                  </p>

                  <v-sheet class="toggler-outer-bg text-primaryTextColor pa-1 px-0 px-1 w-100 d-flex"
                    style="margin-bottom: 0.875rem;">
                    <v-btn v-for="tab in contactTypeList" :key="tab.value" @click="changeIdentityType(tab.value)"
                      :value="tab.value" class="py-2 px-3 flex-grow-1"
                      :class="[tab.value == identityType ? 'active-button' : 'normal-button']"
                      :variant="tab.value == identityType ? 'elevated' : 'plain'" elevation="0">
                      <span class="text-none button-text t-h6Reg">{{ tab.name }}</span>
                    </v-btn>

                  </v-sheet>


                  <vue-tel-input v-if="identityType == 'phone'" class="custom-input" v-model="displayPhoneNumber" @on-input="updateUserPhoneNumberObject"
                    v-bind="bindProps" style="
                    height: 40px;
                    background: white;
                    outline: none;
                    font-size: 16px;
                    border-radius: 4px;
                    font-family: 'Inter', sans serif;
                    font-weight: 400; width: 100%; margin-bottom: 0.875rem;"></vue-tel-input>

                  <v-text-field v-if="identityType == 'email'" placeholder="Email Address" v-model="editedValue.email"
                    color="#000000" :readonly="loading" rounded="8px"  variant="solo" flat bg-color="#fff"
                    style="width: 100%; margin-bottom: 0.875rem" :hide-details="true" density="compact"></v-text-field>

                  <div v-if="loginError != ''" role="alert" class="text-wrap auth-error pa-0 mb-3 font-weight-bold">
                    {{ loginError }}
                  </div>

                  <p v-if="identityType == 'phone'"
                    style="color: white; font-weight: 500; font-size: 0.875rem; margin-bottom: 0.875rem"
                    class="text-left">
                    We will text you to confirm your number. Standard message and data rates apply.
                  </p>
                  <p v-if="identityType == 'email'"
                    style="color: white; font-weight: 500; font-size: 0.875rem; margin-bottom: 0.875rem"
                    class="text-left">
                    We will email you to confirm your email address.
                  </p>

                  <v-row justify="center" align="center">
                    <v-spacer></v-spacer>
                    <v-col>
                      <v-btn
                        :class="[!form || !editedValue.name.trim().length != 0 || checkValidity() ? 'deselectedButton' : 'button-bg-clr']"
                        :disabled="!form || !editedValue.name.trim().length != 0 || !editedValue.name || checkValidity()"
                        class="btn-h-size font-weight-bold  mx-auto text-none mt-6 rounded-pill" :loading="loading"
                        color="progress" size="large" type="submit" variant="elevated" style="width: 230px">
                        Next
                      </v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>

                  <v-row v-if="!isWhiteLabelApp" align="center" justify="center">
                    <v-col cols="5">
                      <v-divider class="border-opacity-50" color="white"></v-divider>
                    </v-col>
                    <v-col cols="2">
                      <span class="text-white">OR</span>
                    </v-col>
                    <v-col cols="5">
                      <v-divider class="border-opacity-50" color="white"></v-divider>
                    </v-col>
                  </v-row>

                  <!-- Google login -->
                  <v-row v-if="!isWhiteLabelApp" justify="center" align="center" class="ma-0" dense>
                    <v-spacer></v-spacer>
                    <v-col>
                      <v-btn @click="onCustomGoogleSignIn"
                        class="btn-h-size px-2 font-weight-bold text-none mx-auto mt-2 rounded-pill" size="large"
                        variant="elevated" style="width: 230px">
                        <v-icon size="35" class="ma-0 pa-0 mr-2"><v-img
                            :src="require('@/assets/google.svg')"></v-img></v-icon>
                        <span class="btn-h-text nav-text">Sign in with Google</span>

                      </v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>

                  <v-row justify="center" align="center" class="text-center mt-6">
                    <v-col class="text-center">
                      <p class="terms-text">
                        Clicking next means you agree to these <a target="blank" :href="mentoTermsOfUse"
                          class="link-text">terms of use</a>
                      </p>
                    </v-col>
                  </v-row>
                </v-form>

              </v-col>
            </v-row>
          </v-card>

        </v-row>
        <!-- OTP Form -->
        <v-row v-else-if="isOtpFormShow">
          <v-card color="#ffffff00" class="mx-auto mt-0 pa-2" elevation="0">
            <v-row align="start" justify="center" style="color: white" class="mb-1 text-center mt-4 pa-0 ma-0">
              <v-col cols="auto" class="py-0 pl-0 mt-2">
                <v-icon class="pa-0 ma-0" @click="showPrevQuestion" icon="mdi-arrow-left" color="white"
                  size="24"></v-icon>
              </v-col>
              <v-col class="pa-0 ma-0">
                <h1 class="title">
                  Let's begin your <br /> learning journey!
                </h1>
              </v-col>
            </v-row>

            <v-row justify="center" align="center" class="mt-10 text-left">
              <v-col cols="12" style="max-width: 350px;">
                <v-form v-model="otpForm" @submit.prevent="onOtpSubmit">
                  <!-- <h3 style="color: white" class="mb-4 mt-8 text-center">
                    Enter OTP!
                  </h3>
                  <p style="color: white" class="mb-4 text-center">
                    OTP sent to the provided number
                  </p> -->
                  <p v-if="identityType == 'phone'" style="color: white; font-weight: 500; margin-bottom: 1rem">
                    Enter the secure code we sent over SMS
                  </p>
                  <p v-if="identityType == 'email'" style="color: white; font-weight: 500; margin-bottom: 1rem">
                    Enter the secure code we sent you in an email
                  </p>

                  <v-text-field hide-details="true" type="number" placeholder="Secure Code" v-model="otpValue"
                    color="primary" :readonly="loading" class="mb-4" :class="{ 'error-field-otp': otpError }"
                    rounded="8px"  variant="solo" flat bg-color="#fff" density="compact"></v-text-field>

                  <v-row align="start" class="px-0">
                    <v-col v-show="otpError" cols="auto text-left">
                      <p role="alert" class="text-wrap auth-error font-weight-bold; font-size: 12.8px">
                        {{ otpError }}
                      </p>
                    </v-col>

                    <v-col class="ma-0">
                      <p style="color: white; font-size: 14px" class="mb-4 text-right">
                        Did not receive?<span style="
                                cursor: pointer;
                                font-weight: 600;
                                color: #55BCFF;
                              " @click="resendOtp">
                          Resend
                        </span>
                      </p>
                    </v-col>
                  </v-row>


                  <v-row justify="center" align="center">
                    <v-spacer></v-spacer>
                    <v-col>
                      <v-btn class="btn-h-size font-weight-bold mt-8 rounded-pill text-none"
                        :class="[otpValue.length !== 6 ? 'deselectedButton' : 'button-bg-clr']"
                        :disabled="otpValue.length !== 6" :loading="loading" block color="progress" size="large"
                        type="submit" variant="elevated" style="width: 200px">
                        Next
                      </v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-card>
        </v-row>
      </div>
    </v-container>

    <footer-component v-if="isFooterShow" class="mt-8 pb-4" style="flex: unset" />
  </v-main>
</template>

<script>
// import AppBarNew from "./AppBarNew.vue";
import { useDisplay } from "vuetify";
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
import _ from "lodash";
import { mapState } from "vuex";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/vue-tel-input.css";
import { v4 as uuidv4 } from "uuid";
import { getDeviceId, isUserLoggedIn, getTempUserId, getResourceLink, clearResourceLink } from "@/services/authService";
import { createFirebaseApp } from "@/services/firebaseMessagingService"

import FooterComponent from "./FooterComponent.vue";
import { googleSdkLoaded } from "vue3-google-login"
import moment from "moment-timezone";
import { signInLoad, teacherAssignment, apiCallsOnSigningUp } from "@/services/signinService";
import { isWhiteLabelApp } from "@/services/whiteLabelService";
// import OtpComponent from "./OtpComponent.vue";

export default {
  name: "QuestionairePage",
  components: { VueTelInput, FooterComponent },
  setup() {
    // Destructure only the keys we want to use
    const { xs, smAndUp, mdAndUp } = useDisplay();

    return { xs, smAndUp, mdAndUp };
  },
  data() {
    return {
      loading: false,
      contactTypeList: [
        {
          name: 'Phone Number',
          value: 'phone'
        },
        {
          name: 'Email',
          value: 'email'
        },
      ],
      identityType: 'phone',
      activeQuestionNumber: 0,
      isAnswerSelected: false,
      currentQuestionResponse: {},
      answerStep: [],
      isInputFormShow: true,
      // loginError: "",
      mentoTermsOfUse: "https://mentomind.ai/terms-of-use/",
      isOtpFormShow: false,
      form: false,
      otpForm: false,
      isInvalidOTP: false,
      otpError: "",
      otpValue: "",
      defaultInputValue: {
        mobileCountryCode: "",
        mobileCountry: "",
        mobileNumber: "",
        name: "",
        email: "",
      },
      editedValue: {
        mobileCountryCode: "",
        mobileCountry: "",
        mobileNumber: "",
        name: "",
        email: "",
      },
      isValidPhoneNumber: false,
      phoneProps: {
        autoFormat: true,
        required: true,
        disabledFetchingCountry: false,
        enabledFlags: true,
        dropdownOptions: {
          showDialCodeInSelection: true,
          showDialCodeInList: true,
          showFlags: true,
        },
        inputOptions: {
          placeholder: "Phone Number",
          type: "tel",
        },
        mode: "international",
        validCharactersOnly: true,
        defaultCountry: "US",
        onlyCountries: ["US", "IN"],
      },
      isFooterShow: true,
      isWhiteLabelApp: false,
      displayPhoneNumber: '',
    };
  },
  created() {
    if (!_.get(this.onBoardingResponse, "status", null) || !getTempUserId()) {
      this.$router.replace("/");
    }
    if (localStorage.getItem('isInputFormShow')) {
      this.editedValue = Object.assign({}, this.defaultInputValue);
      this.isInputFormShow = true;
    }
    if (this.$store.state.onBoardingQuestions.length === 0) {
      recordAnalyticsEvent(AnalyticsEvents.v2OnboardingRegistrationScreenOpen);
      this.editedValue = Object.assign({}, this.defaultInputValue);
      this.isInputFormShow = true;
      localStorage.setItem('isInputFormShow', true);
    } else {
      recordAnalyticsEvent(AnalyticsEvents.v2OnboardingQuestionScreenLoaded, {
        questionId: this.$store.state.onBoardingQuestions[this.activeQuestionNumber].questionId
      });
    }
    const currTz = moment
      .tz(Date.now(), Intl.DateTimeFormat().resolvedOptions().timeZone)
      .zoneName();

    if (currTz === "IST") {
      this.phoneProps.defaultCountry = "IN";
    }
    this.checkWhiteLabelApp();
    if(this.isWhiteLabelApp){
      this.contactTypeList = [
        {
          name: 'Email',
          value: 'email'
        },
        {
          name: 'Phone Number',
          value: 'phone'
        },
      ];
      this.identityType = 'email';
    }
  },
  methods: {
    changeIdentityType(value) {
      this.identityType = value;
    },
    checkValidity() {
      if (this.identityType == 'email') {
        const emailRegex = /^\s*[^\s@]+@[^\s@]+\.[^\s@]+\s*$/;
        return !emailRegex.test(this.editedValue.email);
      }
      else if (this.identityType == 'phone') {
        return !this.isValidPhoneNumber
      }
    },
    getImageUrl(imagePath) {
      return this.$store.state.app_s3url + imagePath;
    },
    onSelectedOption(option) {
      recordAnalyticsEvent(AnalyticsEvents.v2OnboardingQuestionScreenAnswerClicked, {
        questionId: _.get(this.currentQuestion, "questionId", ""),
        answerId: _.get(this.currentQuestionResponse, "optionId", "")
      });
      this.currentQuestionResponse = _.cloneDeep(option);
      this.isAnswerSelected = true;

      // For the MCQ questions
      if (this.activeQuestionNumber < this.questionaireSteps.length) {
        this.$store.dispatch("onBoardingResponse", {
          tempUserId: _.get(this.onBoardingResponse, "tempUserId", ""),
          questionId: _.get(this.currentQuestion, "questionId", ""),
          optionId: _.get(this.currentQuestionResponse, "optionId", ""),
          isSkipped: false,
        });
        this.answerStep[this.activeQuestionNumber] = _.cloneDeep(
          this.currentQuestionResponse
        );
        this.currentQuestionResponse = {};
        this.isAnswerSelected = false;
        if (this.activeQuestionNumber == this.questionaireSteps.length - 1) {
          this.editedValue = Object.assign({}, this.defaultInputValue);
          this.isInputFormShow = true;
          localStorage.setItem('isInputFormShow', true);
          this.isOtpFormShow = false;

        }
        setTimeout(() => {
          this.activeQuestionNumber++;
        }, 300);
      }
    },
    showPrevQuestion() {
      if (this.activeQuestionNumber == 0) {
        this.$router.back();
      } else if (this.activeQuestionNumber < this.questionaireSteps.length) {
        this.currentQuestionResponse = _.cloneDeep(
          this.answerStep[this.activeQuestionNumber]
        );
      } else if (this.activeQuestionNumber == this.questionaireSteps.length) {
        this.isInputFormShow = false;
        localStorage.removeItem('isInputFormShow');
        this.isOtpFormShow = false;
      } else if (
        this.activeQuestionNumber ==
        this.questionaireSteps.length + 1
      ) {
        this.isInputFormShow = true;
        localStorage.setItem('isInputFormShow', true);
        this.isOtpFormShow = false;
      }
      // this.loginError= "";
      this.$store.commit("clearLoginError");
      this.otpError = "";
      this.activeQuestionNumber--;
    },
    showNextQuestion() {
      if (this.activeQuestionNumber < this.questionaireSteps.length) {
        this.$store.dispatch("onBoardingResponse", {
          tempUserId: _.get(this.onBoardingResponse, "tempUserId", ""),
          questionId: _.get(this.currentQuestion, "questionId", ""),
          optionId: _.get(this.currentQuestionResponse, "optionId", ""),
          isSkipped: false,
        });
        // this.answerStep[this.activeQuestionNumber] = _.cloneDeep(
        //   this.currentQuestionResponse
        // );
        this.currentQuestionResponse = {};
        this.isAnswerSelected = false;
        if (this.activeQuestionNumber == this.questionaireSteps.length - 1) {
          recordAnalyticsEvent(AnalyticsEvents.v2OnboardingRegistrationScreenOpen);
          this.editedValue = Object.assign({}, this.defaultInputValue);
          this.isInputFormShow = true;
          localStorage.setItem('isInputFormShow', true);
          this.isOtpFormShow = false;

          // this.$router.push("/validate");
        }
        // this.loginError= "";
        this.$store.commit("clearLoginError");
        this.otpError = "";
        this.activeQuestionNumber++;
      }
    },
    onInputSubmit() {
      if(this.identityType == 'phone' && this.isValidPhoneNumber == false){
        return
      }
      recordAnalyticsEvent(AnalyticsEvents.v2OnboardingRegistrationScreenNextButton, {
        mobileCountryCode: this.editedValue.mobileCountryCode,
        mobileNumber: this.editedValue.mobileNumber,
        name: this.editedValue.name,
        identityType: this.identityType,
        email: this.editedValue.email,
      });
      if (!this.form) return;
      if (this.editedValue.name.trim().length == 0) {
        //  this.loginError= "Missing Name Field";
        this.$store.commit("setLoginError", "Missing Name Field");
        return;
      }
      let deviceId = getDeviceId();
      if (
        !deviceId ||
        deviceId == "" ||
        deviceId.toLowerCase() == "undefined"
      ) {
        try {
          deviceId = crypto.randomUUID();
        } catch (err) {
          console.log(
            `Error in generating crypto.randomUUID : ${JSON.stringify(err)}`
          );
          deviceId = uuidv4();
        }
      }
      this.editedValue.name = this.editedValue.name.trim();
      this.editedValue.email = this.editedValue.email.trim();
      this.$store
        .dispatch("onBoardingLogin", {
          tempUserId: _.get(this.onBoardingResponse, "tempUserId", ""),
          deviceId: deviceId,
          identityType: this.identityType,
          ...this.editedValue,
        })
        .then((response) => {
          if (
            response.tempUserId &&
            response.tempUserId != "" &&
            response.tempUserId.toLowerCase() != "undefined"
            && response.success == true
          ) {
            this.otpValue = "";
            // this.loginError= "";
            this.$store.commit("clearLoginError");
            this.isInputFormShow = false;
            localStorage.removeItem('isInputFormShow');
            this.isOtpFormShow = true;
            this.activeQuestionNumber++;
            recordAnalyticsEvent(AnalyticsEvents.v2OnboardingRegistrationOTPScreenOpen, {
              tempUserId: response.tempUserId
            });
          }
          else {
            recordAnalyticsEvent(AnalyticsEvents.v2OnboardingRegistrationScreenNextButtonError, {
              mobileCountryCode: this.editedValue.mobileCountryCode,
              error: response.error,
              mobileNumber: this.editedValue.mobileNumber,
              email: this.editedValue.email,
              identityType: this.identityType,
              name: this.editedValue.name,
            });
            // this.loginError = response.error;
            this.$store.commit("setLoginError", response.error);
          }
        });
    },
    resendOtp() {
      recordAnalyticsEvent(AnalyticsEvents.v2OnboardingRegistrationOTPScreenResendButton, {
        tempUserId: _.get(this.onBoardingResponse, "tempUserId", "")
      });
      let deviceId = getDeviceId();
      if (
        !deviceId ||
        deviceId == "" ||
        deviceId.toLowerCase() == "undefined"
      ) {
        try {
          deviceId = crypto.randomUUID();
        } catch (err) {
          console.log(
            `Error in generating crypto.randomUUID : ${JSON.stringify(err)}`
          );
          deviceId = uuidv4();
        }
      }
      this.$store
        .dispatch("onBoardingLogin", {
          tempUserId: _.get(this.onBoardingResponse, "tempUserId", ""),
          deviceId: deviceId,
          identityType: this.identityType,
          ...this.editedValue,
        })
        .then((response) => {
          if (
            response.userId &&
            response.userId != "" &&
            response.userId.toLowerCase() != "undefined"
          ) {
            this.otpValue = "";
          }
        });
    },
    onOtpSubmit() {
      recordAnalyticsEvent(AnalyticsEvents.v2OnboardingRegistrationOTPScreenNextButton, {
        tempUserId: _.get(this.onBoardingResponse, "tempUserId", "")
      });
      if (!this.otpForm) return;
      let deviceId = getDeviceId();
      if (
        !deviceId ||
        deviceId == "" ||
        deviceId.toLowerCase() == "undefined"
      ) {
        try {
          deviceId = crypto.randomUUID();
        } catch (err) {
          console.log(
            `Error in generating crypto.randomUUID : ${JSON.stringify(err)}`
          );
          deviceId = uuidv4();
        }
      }
      this.$store
        .dispatch("onBoardingValidate", {
          tempUserId: _.get(this.onBoardingResponse, "tempUserId", ""),
          deviceId: deviceId,
          identityType: this.identityType,
          verificationCode: this.otpValue
        }).then(async () => {
          if (isUserLoggedIn() == 'true') {
            this.isOtpFormShow = false;
            this.isInputFormShow = false;
            this.isFooterShow = false;
            await createFirebaseApp()
            recordAnalyticsEvent(AnalyticsEvents.v2OnboardingRegistrationOTPScreenNextButtonSuccess, {
              tempUserId: _.get(this.onBoardingResponse, "tempUserId", "")
            });
            this.$store.commit('appbarVisible', true);
            this.$store.commit('navbarVisible', true);
            localStorage.setItem('newUserShowWarningInPractice', true);
            localStorage.setItem("newUserShowWarningInMentoAIHub", true);
            await signInLoad();
            this.$store.commit("pricingModelsLoadedFromServer", false);
            let res = await teacherAssignment();
            await apiCallsOnSigningUp();
            if (res == null) {
              const redirectResourceLink = getResourceLink() ?? null
              if(redirectResourceLink){
                clearResourceLink();
                this.$router.push(redirectResourceLink);
              }else{
                this.$router.push('/');
              }
            }
          } else {
            recordAnalyticsEvent(AnalyticsEvents.v2OnboardingRegistrationOTPScreenNextButtonError, {
              tempUserId: _.get(this.onBoardingResponse, "tempUserId", "")
            });
            this.isInvalidOTP = true;
            if (this.isInvalidOTP == true) {
              this.otpError = "Invalid secure code"
            }
            else {
              this.otpError = "";
            }
          }
        });
    },
    handleOtpChange(otp) {
      return (this.otpValue = otp);
    },
    nameKeydown(e) {
      if (/^[^ \w]$/.test(e.key)) {
        e.preventDefault();
      }
    },
    async onCustomGoogleSignIn() {
      await recordAnalyticsEvent(AnalyticsEvents.v2SignInWithGoogleNewUser, {
        tempUserId: getTempUserId()
      }, true);
      let deviceId = getDeviceId();
      if (
        !deviceId ||
        deviceId == "" ||
        deviceId.toLowerCase() == "undefined"
      ) {
        try {
          deviceId = crypto.randomUUID();
        } catch (err) {
          console.log(
            `Error in generating crypto.randomUUID : ${JSON.stringify(err)}`
          );
          deviceId = uuidv4();
        }
      }
      googleSdkLoaded((google) => {
        const state = {
          userType: "new",
          differentDomain: this.isWhiteLabelApp,
          url: window.location.origin,
          testId: this.$store.state.teacherTestId,
          ...this.$store.state.shareCollectionLinkOpened
        };
        google.accounts.oauth2.initCodeClient({
          client_id: _.get(this.config, 'web.client_id', ''),
          scope: 'email profile openid',
          ux_mode: "redirect",
          redirect_uri: _.get(this.config, 'web.redirect_uris', [])[0],
          state: window.btoa(JSON.stringify(state)),
          callback: (response) => {
            console.log("Handle the response", response)
          }
        }).requestCode()
      });
    },
    checkWhiteLabelApp(){
      this.isWhiteLabelApp = isWhiteLabelApp();
    },
    updateUserPhoneNumberObject(val, phoneObject){
      this.editedValue.mobileCountryCode = phoneObject.countryCallingCode;
      this.editedValue.mobileCountry = phoneObject.countryCode;
      this.editedValue.mobileNumber = phoneObject.nationalNumber;
      this.isValidPhoneNumber = phoneObject.valid ?? false;
    }
  },
  computed: {
    ...mapState(["onBoardingResponse", "config", "loginError", "courseId"]),

    questionaireSteps: {
      get() {
        return this.$store.state.onBoardingQuestions;
      },
    },
    currentQuestion: {
      get() {
        if (this.activeQuestionNumber < this.questionaireSteps.length) {
          return this.questionaireSteps[this.activeQuestionNumber];
        }
        // this.$router.push('/validate');
        return null;
      },
    },
    questionaireLength: {
      get() {
        return this.questionaireSteps.length + 2;
      },
    },
    questionProgressBarLength: {
      get() {
        return (
          ((this.activeQuestionNumber + 1) / this.questionaireLength) * 100
        );
      },
    },
    bindProps() {
      return this.phoneProps;
    },
  },
  watch: {
    editedValue: {
      deep: true,
      handler() {
        if (this.loginError != "") {
          this.$store.commit("clearLoginError");
        }
      }
    },
    'editedValue.name'(newVal) {
      this.editedValue.name = newVal.replace(/[^\w\s]/gi, "");
    },
    'editedValue.email'(newVal) {
      if (this.existingLoginError != "") {
        this.$store.commit("clearExistingLoginError");
      }
    },
    'editedValue.mobileNumber'(newVal) {
      if (this.existingLoginError != "") {
        this.$store.commit("clearExistingLoginError");
      }
    },
    otpValue: {
      deep: true,
      handler() {
        this.otpError = "";
        this.isInvalidOTP = false;
      }
    },
    identityType(newVal) {
      if (newVal == 'phone') {
        this.$store.commit("clearExistingLoginError");
        this.loginError = "";
        this.editedValue.email = "";
      }
      else if (newVal == 'email') {
        this.$store.commit("clearExistingLoginError");
        this.loginError = "";
        this.editedValue.mobileCountry = "";
        this.editedValue.mobileCountryCode = "";
        this.editedValue.mobileNumber = "";
        this.displayPhoneNumber = "";
      }
    },

  },
};
</script>

<style scoped>
.title {
  font-size: 31.5px !important;
}

@media only screen and (min-width: 950px) {
  .s-size{
    min-height: calc(100vh - 80px) !important;
  }
}

@media only screen and (max-width: 600px) {
  .title {
    font-size: 1.25rem !important;
    line-height: 30px;
  }

  .outer-container {
    overflow-y: auto;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .outer-container::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .outer-container {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .s-size {
    /* margin-top: 16px !important; */
    height: calc(100vh - 100px) !important;
  }
}

.s-size {
  min-height: calc(100vh - 100px) !important;
}

.btnStyle {
  background-color: #3D3D54 !important;
  transition: all 0.3s ease;
  border: 4px solid #A174FD !important;
  color: #fff !important;
  border-color: transparent !important;
  border-radius: 12px !important;
  cursor: pointer;
}

.on-hover,
.btnStyle:active {
  cursor: pointer;
  /* border: 1px solid #e7e7e7 !important; */
  background-color: #3D3D54 !important;
  color: #fff !important;
  filter: drop-shadow(0px 0px 25px #A274FD80);
  /* border: 4px solid #538dfe !important; */
  border: 4px solid #A174FD !important;

}

.deselectedButton {
  background: #3a4951 !important;
}

.button-bg-clr {
  background: linear-gradient(180deg, #AB77FF 0%, #585EF3 100%) !important;
}

.auth-error {
  font-size: 0.8rem;
  font-weight: 700;
  color: #FF786E;
  height: 0.8rem;
}

.btn-h-size {
  font-size: 14px !important;
  height: 48px !important;
}

.t-q-title {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.t-o-title {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: black;
}

.error-field-otp {
  border: 2.5px solid #FF786E;
  border-radius: 6px;
}

.terms-text {
  font-size: 0.64rem;
  color: #C6C6C6;
}

.link-text {
  cursor: pointer;
  padding-bottom: 2px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #C6C6C6;
  width: fit-content;
  color: #C6C6C6;
  text-decoration: none !important;
}

.btn-h-text {
  font-size: 14px !important;
}


.toggler-outer-bg {
  border-radius: 12px !important;
  background: #2A2941;
}

.active-button {
  border-radius: 8px;
  background: linear-gradient(#494960, #494960) padding-box,
    linear-gradient(180deg, #AB77FF 0%, #585EF3 100%) border-box;
  border: 2px solid transparent;
  color: rgb(var(--v-theme-primaryTextColor)) !important;
}

.normal-button {
  border-radius: 8px;
  background: none;
  color: rgb(var(--v-theme-primaryTextColor)) !important;
  border: 2px solid transparent;
}
</style>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
