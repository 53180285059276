import { Storage } from "aws-amplify";
import moment from "moment";
import store from "../store";

export async function uploadFile(blobInfo, success, failure, progress) {
  const date = Date.now();
  const dirName = moment(new Date()).format("YYYYMMDD");
  const maskedName = blobInfo
    .filename()
    .replace(/[`~!@#$%^&*()_|+\-=?;:'",<>\\{\\}\\[\]\\\\/]/gi, "");
  const fileName = `${date}-${maskedName}`;
  // directory key for s3 bucket
  const key = `${dirName}/${fileName}`;
  try {
    const bucket = store.state.config.bucket.thumbnailBucket;
    const result = await Storage.put(key, blobInfo.blob(), {
      progressCallback(progrs) {
        progress((progrs.loaded / progrs.total) * 100);
      },
      bucket: bucket,
      region: "us-east-1",
      customPrefix: {
        public: "",
      },
      level: ""
    });
    console.log(`storage result : ${JSON.stringify(result)}`);
    success(`${result.key}`);
  } catch (err) {
    console.log(`storage error : ${err}`);
    failure("file upload failed. Error res:" + err);
  }
}

export async function uploadImageFile(file) {
  const date = Date.now();
  const maskedName = file.name.replace(
    /[`~!@#$%^&*()_|+\-=?;:'",<>\\{\\}\\[\]\\\\/]/gi,
    ""
  );
  const fileName = `ai/${moment().format("YYYY-MM-DD")}/${new Date()
    .getTime()
    .toString()}_${maskedName}`;
  const key = `${fileName}`;
  try {
    return await Storage.put(key, file, {
      bucket: "chatgpt-input-images",
      region: "us-east-1",
      customPrefix: {
        public: "",
      },
    });
  } catch (err) {
    console.log(`storage error : ${err}`);
    throw err;
  }
}

export async function uploadFiles(file, dir, bucket = null) {
  if (bucket == null) {
    bucket = store.state.config.bucket.scribbleBucket;
  }
  const maskedName = file.name.replace(
    /[`~!@#$%^&*()_|+\-=?;:'",<>\\{\\}\\[\]\\\\/]/gi,
    ""
  );
  const fileName = `${dir}/${moment().format("YYYY-MM-DD")}/${maskedName}`;
  const key = `${fileName}`;
  try {
    console.log("store.", store.state.config);
    return await Storage.put(key, file, {
      bucket: bucket,
      region: "us-east-1",
      level: "public",
      customPrefix: {
        public: "",
      },
    });
  } catch (err) {
    console.log(`storage error : ${err}`);
    throw err;
  }
}

export async function uploadJsonFiles(json, dir, uuid) {
  const fileName = `${dir}/${moment().format("YYYY-MM-DD")}/${uuid}.json`;
  const key = `${fileName}`;
  try {
    console.log("store.", store.state.config);

    return await Storage.put(key, json, {
      bucket: store.state.config.bucket.scribbleBucket,
      region: "us-east-1",
      customPrefix: {
        public: "",
      },
      level: "",
      contentType: "application/json",
    });
  } catch (err) {
    console.log(`storage error : ${err}`);
    throw err;
  }
}

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export function uploadVideoFile(
  bucket,
  file,
  dirName,
  progressChange,
  complete,
  index
) {
  const maskedName = file.name.replace(
    /[`\s~!@#$%^&*()_|+\-=?;:'",<>\\{\\}\\[\]\\\\/]/gi,
    ""
  );
  const fileName = `${moment().format("YYYY-MM-DD")}/${new Date()
    .getTime()
    .toString()}_${maskedName}`;
  // directory key for s3 bucket
  const key = `${dirName}/${fileName}`;

  console.log(fileName);
  console.log(key);

  // return;
  try {
    const upload = Storage.put(key, file, {
      bucket: bucket,
      resumable: true,
      region: "us-east-1",
      level: "",
      customPrefix: {
        public: "",
      },
      completeCallback(eve) {
        console.log("complete");
        console.log(eve);
        complete(key, index);
      },
      errorCallback(err) {
        console.log(`my error : ${err}`);
      },
      progressCallback(prog) {
        console.log((prog.loaded / prog.total) * 100);
        progressChange((prog.loaded / prog.total) * 100, index);
      },
    });

    return upload;
  } catch (err) {
    console.log(`storage error : ${err}`);
    throw err;
  }
}

export async function fileUploadCancel(upload) {
  await Storage.cancel(upload);
}

export async function fileUploadPause(upload) {
  await upload.pause();
}

export async function fileUploadResume(upload) {
  await upload.resume();
}

export async function fileRemove(file) {
  await Storage.remove(file);
}
