<template>
    <v-overlay style="backdrop-filter: blur(12.5px);" persistent v-model="show" class="align-center justify-center px-2"
        max-width="800px" z-index="2501">
        <v-card max-width="100%"
        max-height="85vh" elevation="0" class="practice-report-dialog pa-2 pb-5">

                <v-row justify="center" align="center" class="mb-3">
                    <v-col cols="11" class="t-h5 text-center">
                        <v-row justify="center" align="center" class="mt-2">
                            <v-spacer></v-spacer>
                            <v-col cols="auto" class="t-h4 text-start my-0 py-0">
                                Mento AI Hub
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="auto" class="">
                                <v-btn icon="mdi-close" class="t-btn text-none rounded-info-btn mx-4" density="compact"
                                    variant="outlined" color="white" @click="onReject">
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

            <v-card-text class="scrollable-content">
                <v-row v-if="policyContent" class="" justify="center" align="center">
                    <v-col v-if="policyContent.heading" cols="11" class="t-h5 text-justify my-0 py-0">
                        {{ policyContent.heading }}
                    </v-col>
                    
                    <v-col v-if="policyContent.subheading" cols="11" class="text-justify my-0 py-0 mt-2">
                        {{ policyContent.subheading }}
                    </v-col>

                    <v-col v-if="policyContent.content" cols="11" class="text-justify my-0 py-0">

                        <ol v-for="contentItem, index in policyContent.content" :key="index" class="text-justify my-4 ">
                            
                            <span v-if="contentItem.heading" class="t-h6">{{ contentItem.heading }}</span>
                            <br/>
                            <div class="t-subtitle"> {{ contentItem.text }} </div>
                        </ol>

                    </v-col>

                    <v-col cols="11" class="text-right">
                        <v-btn class="t-btn text-white activity-btn text-none" density="default" variant="flat" @click="onAcknowledge">
                            I Acknowledge
                        </v-btn>
                    </v-col>    
                </v-row>
                <v-row v-else justify="center" align="center">

                    <v-col cols="11" class="t-h5 text-start my-0 py-0">
                        Best practices for AI usage
                    </v-col>

                    <v-col cols="11" class="text-justify my-0 py-0">

                        <ol v-for="point, index in points" :key="index" class="text-justify my-4 t-subtitle">
                            {{ index + 1 }}. {{ point }}
                        </ol>

                    </v-col>

                    <v-col cols="11" class="text-right">

                        <v-btn class="t-btn text-white activity-btn text-none" density="default" variant="flat" @click="onAcknowledge">
                            I Acknowledge
                        </v-btn>
                    </v-col>
                </v-row>

            </v-card-text>
        </v-card>
    </v-overlay>




</template>

<script>
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";



export default {
    name: 'AIWarnComponent',
    data: () => ({
        show: true,
        points: [
            "Check for Bias: AI might occasionally produce biased or incorrect content. Always double-check before sharing with students.",
            "The 80-20 Approach: Use AI for initial work, but make sure to add your final touch, review for bias and accuracy, and contextualize appropriately for the last 20%.",
            "Your Judgment Matters: See AI-assisted work as a starting point, not the final solution. Always adhere to 'test and verify' approach.",
            "Protect Privacy: Don’t include personal student details like names or addresses. We strive to promptly remove any accidentally submitted information."
        ]
    }),
    props: {
        policyContent: {
            type: Object
        },
        onAcknowledge: {
            type: Function,
            required: true
        },
        onReject: {
            type: Function,
            required: true
        }
    },
    setup() {

    },
    mounted() {


    },
    unmounted() {
    },
    async created() {
    },
    components: {

    },

    computed: {

    },

    methods: {

    },
    watch: {

    }



}
</script>

<style scoped>
.dialog-bx {
    position: fixed !important;
}

.start-quiz-loading {
    background-color: #3D3D54 !important;
    color: #fff;
    border-radius: 15px;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    height: 300px;
    width: 480px;
}

.practice-report-dialog {
    background-color: #3D3D54 !important;
    color: #fff;
    border-radius: 15px;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);
    max-height: 85vh;
    display: flex;
    flex-direction: column;
}

.scrollable-content {
    max-height: 85vh;
    overflow-y: auto;
}

.scrollable-content::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}

.scrollable-content::-webkit-scrollbar-track {
  background: rgb(var(--v-theme-report-subCardBg));
  border-radius: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background-color: rgba(156, 156, 156, 0.4);
  border-radius: 20px;
  cursor: pointer;
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
}

.start-quiz-loading-title {
    font-weight: 700;
    letter-spacing: 0.05em;
    font-size: 25px !important;
}

.start-quiz-loading-subtitle {
    font-size: 0.875rem;
    font-weight: 600;
    color: #c4c4ce;
}

.activity-btn {
    background: linear-gradient(180deg, #AB77FF 0%, #585EF3 100%);
    border-radius: 10px;
}

.main{
    max-height: 100%;
    overflow-y: auto;
}
</style>

<style></style>