<template>

  <v-container v-if="dataLoaded && !dialogViewQuestions" fluid class="pa-5">
    <v-row class="mb-5 align-center d-flex">
      <v-col cols="auto" ref="StudentBookmarksScreen__bookmarksComponent--subjectBox">
        <v-btn v-for="(tab, index) in subjectList" :key="index" @click="filterSubject(tab)" :value="tab.subjectId"
          class="assignment-btn t-1remReg text-none text-white flex-grow-1" :class="{
            'assignment-activity-btn': ((filterItem.subjectIds.length == subjectList.length - 1)?(tab.subjectId == ''):filterItem.subjectIds.includes(tab.subjectId)),
            'mr-2': index != subjectList.length - 1,
          }" density="default" variant="flat" height="40" elevation="0">
          <span class="text-none button-text t-h6Reg">{{
            tab.subjectName
          }}</span>
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>


      <v-col cols="12" sm="auto" class="pa-0 pr-sm-3" :class="{ 'pa-3': xs }">
        <div class="w-100" :class="{ 'filter-dropdown': !xs }">
          <v-select class="filter-box" base-color="report-extraText" color="report-extraText" item-color="black" flat
            density="compact" :items="categoriesList" v-model="filterItem.categoryId" label="Content Domain"
            item-title="categoryName" item-value="categoryId" variant="outlined" hide-details
            @update:modelValue="filterCategory"
            ref="StudentBookmarksScreen__bookmarksComponent--filter0"
            >

            <!-- <template v-slot:selection="{ item, index }">
              <span v-if="filterItem.categoryIds.length == categoriesList.length && index == 0">All</span>
              <span v-else-if="filterItem.categoryIds.length > 1 && index == 0"> +{{
                filterItem.categoryIds.length }}</span>
              <span v-else-if="index == 0 && item.title.length <= 8"> {{ item.title }} </span>
              <span v-else-if="index == 0 && item.title.length > 8"> {{ item.title.substring(0, 8) }}... </span>
            </template> -->

          </v-select>
        </div>
      </v-col>

      <v-col cols="12" sm="auto" class="pa-0 mr-6" :class="{ 'mr-0 pa-3 mb-3': xs }">
        <div class="w-100" :class="{ 'filter-dropdown': !xs }">
          <v-select class="filter-box" base-color="report-extraText" color="report-extraText" item-color="black" flat
            density="compact" :items="topicList" v-model="filterItem.topicId" label="Topic" item-title="entityLabel"
            item-value="entityId" variant="outlined" hide-details @update:modelValue="filterTopic"
            ref="StudentBookmarksScreen__bookmarksComponent--filter1"
            >

            <!-- <template v-slot:selection="{ item, index }">
              <span v-if="filterItem.topicIds.length == topicList.length && index == 0">All</span>
              <span v-else-if="filterItem.topicIds.length > 1 && index == 0"> +{{
                filterItem.topicIds.length }}</span>
              <span v-else-if="index == 0 && item.title.length <= 8"> {{ item.title }} </span>
              <span v-else-if="index == 0 && item.title.length > 8"> {{ item.title.substring(0, 8) }}... </span>
            </template> -->

          </v-select>
        </div>
      </v-col>

      <v-col cols="12" sm="auto" class="pa-0 pr-sm-3" :class="{ 'pa-3': xs }">
        <div class="w-100" :class="{ 'filter-dropdown': !xs }">
          <v-select class="filter-box" base-color="report-extraText" color="report-extraText" item-color="black" flat
            density="compact" :items="tagList" v-model="filterItem.tag" label="Tag" item-title="text" item-value="value"
            variant="outlined" hide-details @update:modelValue="filterTag"
            ref="StudentBookmarksScreen__bookmarksComponent--filter2"
            >
            <!-- <template v-slot:selection="{ item, index }">
              <span v-if="filterItem.tags.length == tagList.length && index == 0">All</span>
              <span v-else-if="filterItem.tags.length > 1 && index == 0"> +{{
                filterItem.tags.length }}</span>
              <span v-else-if="index == 0 && item.title.length <= 8"> {{ item.title }} </span>
              <span v-else-if="index == 0 && item.title.length > 8"> {{ item.title.substring(0, 8) }}... </span>
            </template> -->
          </v-select>
        </div>
      </v-col>

    </v-row>
    <div v-if="bookmarkLoad" class="">
      <v-row v-for="(bookmark, i) in paginatedBookmarkList" :key="bookmark.bookmarkId"
        class="list-item text-white align-center pa-0 ma-0 mb-2 card-border" dense @click="openQuestionView(bookmark)"
        :ref="i == 0? `StudentBookmarksScreen__bookmarksComponent__bookmarkList--card${i}`: ''"
        >
        <v-col cols="12" class="px-2 py-0 ma-0 my-3">
          <v-row dense class="pa-0 ma-0 pl-1" :class="`border-left-${bookmark.tag}`">
            <v-col cols="12" class="py-0">
              <v-row dense class="align-center">
                <v-col cols="6" sm="auto" class="t-h6Reg text-primaryGrey" :class="{ 'mb-3': xs }">Question {{ getQuestionNumber(bookmark.bookmarkId) }}</v-col>
                <v-col v-if="xs" cols="6" class="text-right mb-3">
                  <span class="mr-2 t-h6Reg text-primaryGrey">{{ getDate(bookmark.updatedTime * 1000) }}</span>
                </v-col>

                <v-col cols="auto"  class="mx-3" :class="{ 'mb-3': xs }">
                  <v-tooltip :text="getTopicName(bookmark)" location="bottom">
                    <template v-slot:activator="{ props }">
                      <v-row align="center" v-bind="props" class="text-border">
                        <span class="ml-1 t-h6Reg text-primaryGrey text-truncate" style="max-width: 200px">
                          {{ getTopicName(bookmark) }}
                        </span>
                      </v-row>
                    </template>
                  </v-tooltip>
                </v-col>
                <v-spacer v-if="!xs"></v-spacer>
                <v-col v-if="!xs" cols="auto" class="text-right">
                  <span class="mr-2 t-h6Reg text-primaryGrey">{{ getDate(bookmark.updatedTime * 1000) }}</span>
                </v-col>
              </v-row>

            </v-col>

            <v-col cols="12" class="py-0 mt-2">
              <HtmlRender class="htmlClassTest t-h6Reg three-lines" :html-string="bookmark.qText"></HtmlRender>
            </v-col>

            <v-col cols="12" class="py-0 mt-2 px-2 t-h6Reg text-grey-lighten-1" v-if="bookmark.notes?.message?.length > 0">
               {{ bookmark.notes?.message ?? '' }}
            </v-col>
          </v-row>
        </v-col>



      </v-row>
      <v-row v-if="paginatedBookmarkList.length == 0" class="list-item text-white align-center"
        ref="StudentBookmarksScreen__bookmarksComponent__bookmarkList--card0">
        <v-col cols="12" class="pa-3 text-center t-h6Reg">
          <span>No Bookmark Found</span>
        </v-col>
      </v-row>

      <!-- pagination -->
      <v-row v-if="totalPages>1" class="ma-0" justify="center">
        <v-col cols="auto" class="pa-0">
          <v-pagination v-model="currentPageNumber" :length="totalPages" total-visible="5" size="small" color="white" rounded="lg"></v-pagination>
        </v-col>
      </v-row>
    </div>
  </v-container>

  <v-dialog fullscreen persistent :model-value="dialogViewQuestions" scrollable>
    <v-card class="h-100 w-100">
      <v-card-text class="ma-0 pa-0 h-100 w-100">
        <v-app-bar app color="breadCrumbBackColor">

          <v-app-bar-title>
            <template v-slot:text>
              <span class>
                Bookmark
              </span>
            </template>

          </v-app-bar-title>

          <v-spacer></v-spacer>


          <v-icon @click.stop="backFromDialog" class="mr-4"> mdi-close</v-icon>

        </v-app-bar>

        <v-container fluid v-if="currentQIndex != -1" class="solution-height">
          <PracticeResult :currentQuestion="currentQ" :currentQResponseProp="currentQResponse"
            :currentQSolutionProp="currentQSolution" :practiceInfo="practiceInfo" :bookmark="true"
            :bookmarkNo="currentQIndex + 1"></PracticeResult>
        </v-container>

        <v-footer app class="bgWhite px-4 pt-4 pb-6 ma-0" elevation="2">
          <v-row justify="space-between" class=" footer-row px-5 align-center">

            <v-col cols="auto" class="align-center">
              <v-btn v-if="$vuetify.display.smAndUp" class="t-btn text-capitalize btn-grad  text-primaryTextColor"
                :size="$vuetify.display.smAndUp ? 'large' : 'default'" :width="$vuetify.display.mdAndUp ? '130' : '125'"
                elevation="0" @click="prevQuestion" :color="!prevButtonDisabled ? 'secondaryBackground' : undefined"
                :disabled="prevButtonDisabled">
                Previous
                <template v-slot:prepend>
                  <v-icon>mdi-chevron-left</v-icon>
                </template>
              </v-btn>
              <v-btn v-else density="compact" size="large" icon="mdi-chevron-left" @click="prevQuestion"
                :disabled="prevButtonDisabled" color="primaryPurple">
              </v-btn>

            </v-col>

            <v-col cols="auto">
              <span class="ml-2 t-1remReg">
                {{ currentQ.qDisplayNo }} of {{ noOfQuestions }}
              </span>
            </v-col>

            <v-col cols="auto" class="text-end  align-center">
              <v-btn v-if="$vuetify.display.smAndUp" class="t-btn text-capitalize btn-grad  text-primaryTextColor"
                :size="$vuetify.display.smAndUp ? 'large' : 'default'" :width="$vuetify.display.mdAndUp ? '130' : '125'"
                elevation="0" @click="nextQuestion" color="secondaryBackground">
                {{ nextButtonDisabled ? 'Finish' : 'Next' }}
                <template v-slot:append>
                  <v-icon>{{ nextButtonDisabled ? 'mdi-debug-step-over' : 'mdi-chevron-right' }} </v-icon>
                </template>
              </v-btn>
              <v-btn v-else density="compact" size="large"
                :icon="nextButtonDisabled ? 'mdi-debug-step-over' : 'mdi-chevron-right'" @click="nextQuestion"
                color="primaryPurple">
              </v-btn>
            </v-col>
          </v-row>
        </v-footer>
        <QuestionActionsComponent testType="bookmark" :qId="currentQ.qId" :testId="practiceInfo.testId"
          :testInstanceId="practiceInfo.testInstanceId" :qObj="currentQ" ref="questionActions"
          :screenshotQuestionElement = "screenshotQuestionElement"
          :screenshotSolutionElement = "screenshotSolutionElement"
          :chatbotConfig="chatbotConfig"
          :chatbotAllowed = "chatbotAllowed"
          ></QuestionActionsComponent>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
//   import { mapState } from "vuex";
  import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
//   import { createFirebaseApp } from "@/services/firebaseMessagingService";
//   import { getDeviceId } from "@/services/authService";
//   import { v4 as uuidv4 } from "uuid";
import HtmlRender from "@/components/Test/HtmlRender.vue";
import moment from "moment-timezone";
import { useDisplay } from "vuetify";
import _ from "lodash";
import { mapGetters, mapState } from "vuex";
import PracticeResult from "@/components/practice/PracticeResult.vue";
import QuestionActionsComponent from '@/components/questionTools/QuestionActionsComponent.vue';
import { updateHighlightedElements } from "@/shared/selfHelpGuide/updateHighlightedElement";

export default {
  name: "BookmarkComponent",

  setup() {
    // Destructure only the keys we want to use
    const { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp } = useDisplay();

    return { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp };
  },

  async created() {
    recordAnalyticsEvent(AnalyticsEvents.v2StudentBookmarkScreenOpen, {});
    this.dataLoaded = false;
    this.$store.commit("appbarVisible", true);
    this.$store.commit("navbarVisible", true);
    this.$store.commit('switchingViewData', false);
    this.$store.commit("entitiesLoadedFromServer", false);
    await this.$store.dispatch("loadNewLearnNodes");
    console.log("created", this.subjectList);
    this.filterItem = {
      subjectIds: _.map(this.newLearnNodes, (item) => item.subjectId),
      categoryId: "",
      topicId: "",
      tag: ""
    }
    // if (_.head(this.subjectList)?.subjectType == 'readingandwriting') {
    //   this.chatbotAllowed = true;
    // } else {
    //   this.chatbotAllowed = false;
    // }
    // if(!this.isDevEnvironment){
    //   if (_.head(this.subjectList)?.subjectType == 'readingandwriting') {
    //     this.chatbotAllowed = true;
    //   }
    // }else{
    //   const checkElement = async () => {
    //   console.log("check element")
    //   this.screenshotElement = document.getElementById("mainSolutionScreen");
    //   if (this.screenshotElement) {
    //     if (this.screenshotElement.offsetHeight < window.innerHeight) {
    //       this.screenshotElement.style.height = `${window.innerHeight - 40}px`;
    //     }
    //     this.chatbotAllowed = true;
    //     // this.$refs.questionActions?.getNudgeInfo(this.currentQ);
    //     } else {
    //       this.chatbotAllowed = false;
    //       setTimeout(checkElement, 100); // Retry after 100ms if element is not found
    //     }
    //   };

    //   checkElement();
    // }
    this.dataLoaded = true;
  },
  components: {
    HtmlRender, PracticeResult, QuestionActionsComponent
  },
  mounted(){
    this.$store.commit("updateCurrentScreenName", this.currentScreenName);
  },
  unmounted(){
    this.$store.commit("updateCurrentScreenName", null);
  },
  data: () => ({
    currentScreenName: "StudentSavedPageScreen",
    dataLoaded: false,
    bookmarkLoad: false,
    dialogViewQuestions: false,
    filterItem: {
      subjectIds: [],
      categoryId: "",
      topicId: "",
      tag: ""
    },
    screenshotQuestionElement: {},
		screenshotSolutionElement: undefined,
    tagList: [
      { text: 'All', value: '' },
      { text: 'Red', value: 'red' },
      { text: 'Green', value: 'green' },
      { text: 'Blue', value: 'blue' }
    ],
    currentQResponse: {
      qId: "",
      qType: "",
      checkedOption: "",
      checkedOptions: [],
      integerOption: "",
      decimalOption: "",
      markForReview: false,
      crossedOutOptions: false,
      crossedOutOptionsList: [],
      aiCrossedOutOptionsList: []
    },
    currentQSolution: {
      "correct": null,
      "solution": {
        "solutionText": "<div style=\"text-align: left;\"><strong>Key Explanation: Choice B</strong> is the best answer because it refers to the time when something is&nbsp;</div>\n<div style=\"text-align: justify;\">completely grown into an adult stage. It fits the context of saying that bald eagles are of different colors&nbsp;</div>\n<div style=\"text-align: justify;\">until they become adults at about five years old.</div>\n<div style=\"text-align: justify;\"><strong>Distractor Explanations: Choices A and C</strong> are incorrect because they refer to being first or higher&nbsp;</div>\n<div style=\"text-align: justify;\">in a ranking, but there is no indication that the eagles must be more important than other eagles in&nbsp;</div>\n<div style=\"text-align: justify;\">some way to get the coloration of an adult. <strong>Choice D</strong> is incorrect because it refers to the state of&nbsp;</div>\n<div style=\"text-align: justify;\">being protected from harm, not to a growth phase.</div>",
        "solutionVideo": null,
        "answers": null,
        "answerSingleChoice": "b",
        "answerInteger": null,
        "answerDecimalLow": null,
        "answerDecimalHigh": null,
        "solutionDifficultyLevel": null
      }
    },
    currentQIndex: -1,
    currentQ: {},
    chatbotConfig: {
      currentMode: "practiceSolution",
      chatbotMode: "bookmark"
    },
    chatbotAllowed: true,
    currentPageNumber: 1,
    itemsPerPage: 25,
  }),

  computed: {
    ...mapState(["newLearnNodes", "config"]),
    ...mapGetters(['currentHighlightedElements']),
    isDevEnvironment: {
      get(){
        return this.config.environment=="dev";
      }
    },
    subjectList: {
      get() {
        const subjects = _.map(this.newLearnNodes, (item) =>
          _.pick(item, ["subjectId", "subjectName", "subjectType"])
        );
        return [{ subjectId: "", subjectName: "All", subjectType: "all_subject" }, ...subjects];
      },
    },
    categoriesList: {
      get() {
        let items = _.filter(this.newLearnNodes, (item) => {
          return this.filterItem.subjectIds.includes(item.subjectId);
        });
        const categories = [];
        for(let item of items){
          categories.push(..._.map(item?.categories, (item) =>
            _.pick(item, ["categoryId", "categoryName", "categoryDescription"])
          ));
        }
        return [{ categoryId: "", categoryName: "All" }, ...categories];
      },
    },
    topicList: {
      get() {
        let items = _.filter(this.newLearnNodes, (item) => {
          return this.filterItem.subjectIds.includes(item.subjectId);
        });
        let topics = [];
        const categoriesList = [];
        for(let item of items){
          categoriesList.push(..._.map(item?.categories, (item) =>
            _.pick(item, ["categoryId" ,"entity"])
          ))
        }
        if(this.filterItem.categoryId == ""){
          for (let categories of categoriesList) {
            topics.push(..._.map(categories?.entity, (item) =>
              _.pick(item, ["entityId", "entityLabel", "enitityType"])
            ));
          }
        }else{
          let categories = _.find(categoriesList, (item) => {
            return item.categoryId == this.filterItem.categoryId;
          });
          topics = _.map(categories?.entity, (item) =>
            _.pick(item, ["entityId", "entityLabel", "enitityType"])
          );
        }
        return [{ entityId: "", entityLabel: "All" }, ...topics];
      },
    },
    bookmarkList: {
      get() {
        return _.orderBy(this.$store.state.questionBookmarkList, ['updatedTime'], 'desc');
      },
    },
    prevButtonDisabled: {
      get() {
        return this.currentQIndex == 0 ? true : false;
      }
    },
    nextButtonDisabled: {
      get() {
        return (this.currentQIndex == (this.bookmarkList.length - 1)) ? true : false;
      }
    },
    noOfQuestions: {
			get() {
				return this.bookmarkList.length;
			}
		},
    totalPages: {
      get(){
        return Math.ceil(this.bookmarkList.length/this.itemsPerPage);
      }
    },
    paginatedBookmarkList: {
      get(){
        return this.paginatedData(this.bookmarkList);
      }
    },
  },

  watch: {
    filterItem: {
      handler(val) {
        if (val.subjectIds.length > 0) {
          recordAnalyticsEvent(AnalyticsEvents.v2StudentBookmarkFilterData, {
            subjectIds: JSON.stringify(val.subjectIds),
            categoryId: val.categoryId,
            topicId: val.topicId,
            tag: val.tag
          });
          this.bookmarkLoad = false;
          this.$store.dispatch('getAllBookmark', val).then(()=>{
            this.bookmarkLoad = true;
            setTimeout(() => {
              window.MathJax.typesetPromise();
            }, 10)
          });
        }
      },
      immediate: true,
      deep: true,
    },
    bookmarkList: {
      handler(){
        this.currentPageNumber = 1;
      }
    },
    currentHighlightedElements: {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          updateHighlightedElements(oldVal, newVal, this.$refs);
        });
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    filterSubject(value) {
      let subjectIds = [];
      if(value.subjectId == ""){
        subjectIds = _.map(this.newLearnNodes, (item) => item.subjectId);
      }else{
        subjectIds.push(value.subjectId);
      }
      this.filterItem = {
        ...this.filterItem,
        subjectIds: subjectIds,
        categoryId: "",
        topicId: ""
      }
      // if(!this.isDevEnvironment){
      //   if (value.subjectType == 'readingandwriting') {
      //     this.chatbotAllowed = true;
      //   } else {
      //     this.chatbotAllowed = false;
      //   }
      // }else{
      //   this.chatbotAllowed = true;
      // }
      this.chatbotAllowed = true;
    },
    filterCategory(value) {
      this.filterItem = {
        ...this.filterItem,
        categoryId: value,
        topicId: ""
      }
    },
    filterTopic(value) {
      this.filterItem = {
        ...this.filterItem,
        topicId: value
      }
    },
    filterTag(value) {
      this.filterItem = {
        ...this.filterItem,
        tag: value
      }
    },
    getCategoryName(categoryId) {
      const foundCategory = _.find(this.categoriesList, (item) => {
        return item.categoryId == categoryId;
      });
      if (foundCategory) {
        return foundCategory.categoryName?.trim();
      }
      return ""
    },
    getTopicName(bookmark) {
      const item = _.find(this.newLearnNodes, (item) => {
        return item.subjectId == bookmark.subjectId;
      });
      const categories = _.find(item?.categories, (item) => {
        return item.categoryId == bookmark.categoryId;
      });
      const foundTopic = _.find(categories?.entity, (item) => {
        return item.entityId == bookmark.topicId;
      });
      if (foundTopic) {
        return foundTopic.entityLabel?.trim();
      }
      return ""
    },
    getDate(date) {

      if (date == null || date.length == 0) {
        return "";
      }
      let d = new Date(date);

      let displayDate = moment
        .tz(d, Intl.DateTimeFormat().resolvedOptions().timeZone)
      // const displayDate = moment.tz(
      //   d,
      //   Intl.DateTimeFormat().resolvedOptions().timeZone
      // );

      // Check for today, yesterday, or other dates
      if (displayDate.isSame(moment(), "day")) {
        return "Today ";
      } else if (displayDate.isSame(moment().subtract(1, "days"), "day")) {
        return "Yesterday ";
      } else if (displayDate.isSame(moment().add(1, "days"), "day")) {
        return "Tomorrow ";
      } else {
        // Format as MM/DD/YYYY 12:00 AM
        return displayDate.format("ll");
      }
    },
    openQuestionView(bookmark) {
      this.currentQIndex = _.findIndex(this.bookmarkList, { bookmarkId: bookmark.bookmarkId });
      this.practiceInfo = Object.assign({}, bookmark);
      // this.$store.dispatch('getQuestionBookmarkAndQueryDetails', { qId: this.practiceInfo.qId });
      this.$store.dispatch("getQuestionAnswer", { questionId: this.practiceInfo.qId, isAIQuestion: _.get(this.practiceInfo, "isAIQuestion", false) }).then((questionAnswer) => {
        if (questionAnswer) {
          this.currentQSolution.solution = _.pick(questionAnswer, ['solutionText', "answers", 'answerSingleChoice', 'answerInteger', 'answerDecimalLow', 'answerDecimalHigh']);
          this.currentQ = Object.assign({}, questionAnswer);
          this.currentQ.qDisplayNo = this.currentQIndex + 1;
          recordAnalyticsEvent(AnalyticsEvents.v2StudentBookmarkQuestionViewOpened, {
            qId: this.currentQ.qId,
            bookmarkId: bookmark.bookmarkId,
            qDisplayNo: this.currentQ.qDisplayNo
          });
          
          // if(!this.isDevEnvironment){
          //   if (this.currentQ?.subjectType == 'readingandwriting') {
          //     this.chatbotAllowed = true;
          //   } else {
          //     this.chatbotAllowed = false;
          //   }
          // }else{
          // }
          const checkElement = async () => {
          console.log("check element")
          this.screenshotQuestionElement = document.getElementById("mainSolutionScreen");
          this.screenshotSolutionElement = document.getElementById("solutionSection");
          if (this.screenshotQuestionElement &&  this.screenshotSolutionElement) {
            // if (this.screenshotElement.offsetHeight < window.innerHeight) {
            //   this.screenshotElement.style.height = `${window.innerHeight - 40}px`;
            // }
            this.chatbotAllowed = true;
            // this.$refs.questionActions?.getNudgeInfo(this.currentQ);
            } else {
              this.chatbotAllowed = false;
              setTimeout(checkElement, 100); // Retry after 100ms if element is not found
            }
          };

          checkElement();
          this.dialogViewQuestions = true;
        }
      })
    },
    prevQuestion() {
      this.currentQIndex--;
      this.practiceInfo = Object.assign({}, this.bookmarkList[this.currentQIndex]);
      this.$store.dispatch("getQuestionAnswer", { questionId: this.practiceInfo.qId, isAIQuestion: _.get(this.practiceInfo, "isAIQuestion", false) }).then((questionAnswer) => {
        if (questionAnswer) {
          this.currentQSolution.solution = _.pick(questionAnswer, ['solutionText', "answers", 'answerSingleChoice', 'answerInteger', 'answerDecimalLow', 'answerDecimalHigh']);
          this.currentQ = _.cloneDeep(questionAnswer);
          setTimeout(() => {
            window.MathJax.typesetPromise();
          }, 10)
          // if(!this.isDevEnvironment){
          //   if (this.currentQ?.subjectType == 'readingandwriting') {
          //     this.chatbotAllowed = true;
          //     this.$refs.questionActions.getNudgeInfo(this.currentQ);
          //   } else {
          //     this.chatbotAllowed = false;
          //     this.$store.dispatch('getQuestionBookmarkAndQueryDetails', { qId: this.practiceInfo.qId });
          //   }
          // }else{
          //   this.$refs.questionActions.getNudgeInfo(this.currentQ);
          // }
          this.chatbotAllowed = true;
          this.$refs.questionActions.getNudgeInfo(this.currentQ);
          this.currentQ.qDisplayNo = this.currentQIndex + 1;
          recordAnalyticsEvent(AnalyticsEvents.v2StudentBookmarkQuestionViewPrevButtonClicked, {
            qId: this.currentQ.qId,
            bookmarkId: this.practiceInfo.bookmarkId,
            qDisplayNo: this.currentQ.qDisplayNo
          });
        }
      })
    },
    nextQuestion() {
      if (this.nextButtonDisabled) {
        this.backFromDialog();
        return;
      }
      this.currentQIndex++;
      this.practiceInfo = Object.assign({}, this.bookmarkList[this.currentQIndex]);
      this.$store.dispatch("getQuestionAnswer", { questionId: this.practiceInfo.qId, isAIQuestion: _.get(this.practiceInfo, "isAIQuestion", false) }).then((questionAnswer) => {
        if (questionAnswer) {
          this.currentQSolution.solution = _.pick(questionAnswer, ['solutionText', "answers", 'answerSingleChoice', 'answerInteger', 'answerDecimalLow', 'answerDecimalHigh']);
          this.currentQ = _.cloneDeep(questionAnswer);
          setTimeout(() => {
            window.MathJax.typesetPromise();
          }, 10)
          // if(!this.isDevEnvironment){
          //   if (this.currentQ?.subjectType == 'readingandwriting') {
          //     this.chatbotAllowed = true;
          //     this.$refs.questionActions.getNudgeInfo(this.currentQ);
          //   } else {
          //     this.chatbotAllowed = false;
          //     this.$store.dispatch('getQuestionBookmarkAndQueryDetails', { qId: this.practiceInfo.qId });
          //   }
          // }else{
          //   this.$refs.questionActions.getNudgeInfo(this.currentQ);
          // }
          this.chatbotAllowed = true;
          this.$refs.questionActions.getNudgeInfo(this.currentQ);
          this.currentQ.qDisplayNo = this.currentQIndex + 1;
          recordAnalyticsEvent(AnalyticsEvents.v2StudentBookmarkQuestionViewNextButtonClicked, {
            qId: this.currentQ.qId,
            bookmarkId: this.practiceInfo.bookmarkId,
            qDisplayNo: this.currentQ.qDisplayNo
          });
        }
      })
    },
    backFromDialog() {
      recordAnalyticsEvent(AnalyticsEvents.v2StudentBookmarkQuestionViewClosed, {
        qId: this.currentQ.qId,
        bookmarkId: this.practiceInfo.bookmarkId,
        qDisplayNo: this.currentQ.qDisplayNo
      });
      this.$store.commit("appbarVisible", true);
      this.$store.commit("navbarVisible", true);
      this.currentQIndex = -1;
      this.practiceInfo = {}
      this.currentQ = {}
      this.currentQSolution.solution = {}
      this.$store.dispatch('getAllBookmark', this.filterItem).then(()=>{
        setTimeout(() => {
          window.MathJax.typesetPromise();
        }, 10)
      });
      this.dialogViewQuestions = false;
    },
    paginatedData(items){
      const start = (this.currentPageNumber-1)*this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return items.slice(start, end);
    },
    getQuestionNumber(id){
      return _.findIndex(this.bookmarkList, { bookmarkId: id }) + 1;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.border-left-red {
  border-left: 4px solid #F44336;
}

.border-left-green {
  border-left: 4px solid #4CAF50;
}

.border-left-blue {
  border-left: 4px solid #2196F3;
}


.assignment-btn {
  border-radius: 12px !important;
  background: rgb(var(--v-theme-report-subCardBg));
  cursor: pointer;
  border: 2px solid transparent !important;
}

.filter-dropdown {
  width: 180px !important;
}

.assignment-activity-btn {
  background: linear-gradient(#303146, #303146) padding-box,
    linear-gradient(180deg, #ab77ff 0%, #585ef3 100%) border-box !important;
  border: 2px solid transparent !important;
  color: white !important;
}

.list-item {
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  background-color: rgb(var(--v-theme-report-subCardBg));
  padding: 5px 8px;
}

.list-item:not(:last-child) {
  margin-bottom: 8px !important;
}

.card-border {
  border: 2px solid transparent;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  cursor: pointer;
}

.card-border:hover {
  border: 2px solid #9461fc;
}

.text-border {
  border: 1px solid rgb(var(--v-theme-report-extraText));
  border-radius: 8px;
  padding: 2px 4px;
}

.htmlClassTest img {
  top: 0 !important;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

.htmlClassTest span {
  top: 0 !important;
  vertical-align: middle;
}

.three-lines {
  /* Set the line height to ensure 3 lines */
  overflow: hidden;
  /* Hide the overflow */
  text-overflow: ellipsis;
  /* Add ellipsis if text overflows */
  display: -webkit-box;
  /* Flexbox model for multi-line ellipsis */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* Limit the text to 3 lines */
  line-clamp: 2;
}

.btn-grad {
  background: linear-gradient(180deg, #AB77FF 0%, #585EF3 100%);
  border-radius: 29.4079px;
}

.solution-height {
  padding-top: 60px;
  padding-bottom: 100px;
}

.solution-height-sm {
  padding-top: 40px !important;
}
</style>

<style>
.filter-box .v-label,
.filter-box .v-field__input {
  color: #BFBEC6 !important;
}
</style>