<template>
  <!-- Content Message Dialog -->
 <v-dialog style="backdrop-filter: blur(12.5px)" v-model="showContentMessage" width="fit-content" persistent>
   <v-card elevation="0" class="assignment-form text-white pa-4" :width="$vuetify.display.smAndUp ? 400 : '95%'">
     <v-card-text class="text-white pa-0 ma-0">
       <span class="assignment-form-title">This resource is currently unavailable to you. Please reach out to your teacher for assistance.</span>
       </v-card-text
     >
     <v-card-actions class="pa-0 ma-0 mt-2">
       <v-spacer></v-spacer>
       <v-btn
         class="save-btn t-btn text-capitalize text-primaryTextColor"
         density="default"
         variant="flat"
         height="40"
         @click.stop="closeContentMessage()"
         >Ok</v-btn
       >
       <v-spacer></v-spacer>
     </v-card-actions>
   </v-card>
 </v-dialog>

 <!-- Share Link Dialog -->
 <v-dialog style="backdrop-filter: blur(12.5px)" v-model="dialogShareLink" width="fit-content">
   <v-card elevation="0" class="assignment-form text-white" width="fit-content">
     <v-card-text class="text-white px-2  pb-3">
       <v-row dense class="t-1remReg mb-1 mx-2" justify="center">
         <v-col class="text-center">
           Share your resource
         </v-col>
         <v-col class="text-right" cols=auto>
           <v-icon @click.stop="dialogShareLink = false" size=20>mdi-close</v-icon>
         </v-col>
       </v-row>
       <v-row dense class="t-subtitle text-center mb-7 mx-4 mx-sm-auto" justify="center">
         Copy the link below and share it with your friends.
       </v-row>

       <v-row v-if="$vuetify.display.smAndUp" justify="space-between" align="center"
         class=" my-4 mx-2 border-code py-2">
         <v-col cols="auto" class="my-0 py-0 t-subtitle  text-primaryTextColor"> {{ shareableLink }} </v-col>
         <v-col cols="" class="my-0 py-0 text-right">
           <v-icon @click="copyCode()" color="grey" size="20" class="copy">
             mdi-content-copy
           </v-icon>
         </v-col>
       </v-row>

       <v-row v-else justify="center" align="center" class="mx-2 mb-4">
         <v-col cols="auto" class="pa-0">
           <v-btn append-icon="mdi-content-copy" class="t-btn text-white activity-btn text-none px-3 pr-5"
             density="default" variant="flat" @click="copyCode()">
             <template v-slot:append>
               <v-icon color="primaryTextColor"></v-icon>
             </template>
             Copy Link
           </v-btn>
         </v-col>
       </v-row>
     </v-card-text>
   </v-card>
 </v-dialog>
 
 <v-row v-if="dataLoaded" class="ma-0 pt-4 px-4" :justify="selectedResourceFolderId!='-1'? 'space-between': 'end'" align="center" :class="{ heightBread: $vuetify.display.mdAndUp }">
   <v-col v-if="selectedResourceFolderId!='-1'" class="ma-0 pa-0">
     <v-breadcrumbs :items="learnNodesBreadcrumbs" class="no-new-line t-h6Reg text-white text-primaryTextColor">
       <template v-slot:divider>
         <v-icon icon="mdi-chevron-right"></v-icon>
       </template>
       <template v-slot:prepend>
         <v-btn v-if="learnNodesBreadcrumbs.length > 0" color="#26253A" class="ma-0 mr-2 text-primaryTextColor"
           size="x-small" @click="back()" icon="mdi-chevron-left">
         </v-btn>
       </template>
       <template v-slot:title="{item}">
         <span @click.stop="breadCrumbRoute(item)" class="breadcrumb-title">{{ item.title }}</span>
       </template>
     </v-breadcrumbs>
   </v-col>
   <v-col cols="auto" class="pa-0">
       <SelfHelpGuideTogglerComponent />
     </v-col>
   <v-divider></v-divider>
 </v-row>
 <v-layout v-if="dataLoaded" :class="{ layoutLarge: $vuetify.display.mdAndUp }">
   <v-container fluid class="mt-3 container-width">
     <v-row align="center" justify="start" dense class="mb-2">
       <v-col cols="auto">
         <span v-if="selectedResourceFolderId == '-1'" class="t-h125 text-center mb-1 text-primaryTextColor"> {{ selectedResourceFolderId == '-1' ? 'Resources' :
           learnNodesBreadcrumbs[learnNodesBreadcrumbs.length - 1].title}}
           <!-- <span v-if="!folderNodeContents.isEmpty"> ({{ folderNodeContents.length }})</span> -->
         </span>
       </v-col>
     </v-row>
     <div v-if="!folderNodeContents.isEmpty" class="w-100">

        <v-row class="ma-0 pa-0" >
            <vue-horizontal class="w-100 scroll-horizontal" ref="content-container" >
            
            <v-card elevation="0" class="my-2 pa-0 main-card card-border content-card"
              v-for="(item, i) in folderNodeContents.contents" :key="item.resourceCenterFolderContentId"
              @click="learnNodeClicked(item)" :ref="`StudentResourceCenterPage__resourceList--card${i}`"
              min-width="264px" max-width="264px">
              <v-card-text class="pa-0 text-primaryTextColor w-100 h-100">
                <v-row class="ma-0 pa-0" justify="start" dense :class="{ 'pt-1': $vuetify.display.xs }">
                  <v-col cols="12" class="pa-0 ma-0" v-if="isVideoContent(item)">
                    <v-img cover lazy-src="https://picsum.photos/id/11/100/60" height="145px" width="260px"
                      class="white--text align-end rounded-lg" :src="getYoutubeVideoThumbnail(item.youTubeUrl)"
                      gradient="to bottom, rgba(0,0,0,.2), rgba(0,0,0,.8)">
                      <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                        </v-row>
                      </template>
                      <div class="bottom-right">
                        <v-icon :icon="getPlaceHolderIcon(item) " class="" size="30"
                        :color="getPlaceHolderIconColor(item)"></v-icon>
                      </div>
                    </v-img>
                  </v-col>
                  <v-col cols="12" class="ma-0 pa-0" v-else-if="isContentThumbnail(item)">
                    <v-img lazy-src="https://picsum.photos/id/11/100/60" height="145px" width="260px"
                      class="text-white align-end rounded-lg" :src="getImageSrc(item.thumbnailUrl)"
                      gradient="to bottom, rgba(0,0,0,.2), rgba(0,0,0,.8)">
                      <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                        </v-row>
                      </template>
                      <div class="bottom-right">
                        <v-icon :icon="getPlaceHolderIcon(item) " class="" size="30"
                        :color="getPlaceHolderIconColor(item)"></v-icon>
                      </div>
                    </v-img>
                  </v-col>
                  <v-col cols="12" class="pa-0 ma-0" v-else>
                    <div class="rounded-lg content-image">
                      <v-icon :icon="getPlaceHolderIcon(item) " class="" size="60"
                        :color="getPlaceHolderIconColor(item)"></v-icon>
                    </div>
                  </v-col>
                  <v-tooltip location="top" max-width="300px" open-delay="300">
                    <template #activator="{ props }">

                     <v-col cols="10" class="two-lines" v-bind="props">
                       {{ getNodeName(item) }}
                     </v-col>
                   </template>
                   <span>{{ getNodeName(item) }}</span>
                 </v-tooltip>
                 <v-spacer></v-spacer>
                 <v-col cols="1">
                   <v-menu
                     v-if="(isPDFContent(item) || isVideoContent(item) || isFolder(item) || isSubFolder(item)) && item.isPublished">
                     <template v-slot:activator="{ props }">
                       <v-icon v-bind="props">
                         mdi-dots-vertical
                       </v-icon>
                     </template>
                     <v-list density="compact" nav>
                       <v-list-item
                         v-if="(isPDFContent(item) || isVideoContent(item) || isFolder(item) || isSubFolder(item)) && item.isPublished"
                         @click.stop="shareLink(item)">
                         <div class="d-flex align-center">
                           <v-icon :height="20" :width="20" class="mr-2">
                             mdi-share
                           </v-icon>
                           <v-list-item-title>
                             Share
                           </v-list-item-title>
                         </div>
                       </v-list-item>
                     </v-list>
                   </v-menu>
                 </v-col>
                 <!-- <v-tooltip location="top" max-width="300px">
                   <template #activator="{ props }">

                     <v-col cols="12" v-if="item.description" class="two-lines" v-bind="props">
                       {{ item.description }}
                     </v-col>
                   </template>
                   <span>{{ item.description }}</span>
                 </v-tooltip> -->
                 <v-spacer></v-spacer>
               </v-row>
             </v-card-text>
           </v-card>
           </vue-horizontal>
       </v-row>

        <div class="mt-2" v-for="(folder, i) in folderNodeContents.folders" :key="folder.resourceCenterFolderId">
          <v-row>
            <v-col cols="12" v-if="((selectedResourceFolderId == '-1')|| (folderNodeContents.contents.length>0))?true:(i>0)">
              <v-divider :thickness="2" color="background"></v-divider>
            </v-col>

           <v-col cols="12">
             <v-row class="ma-0 pa-0 align-center text-white">
               <v-col cols="auto" class="ma-0 pa-0" style="max-width: 75%;">
                 {{ getNodeName(folder) }}
               </v-col>
               <!-- <v-spacer></v-spacer> -->
               <v-col cols="auto" class="ma-0 pa-0 text-right" v-if="(selectedResourceFolderId == '-1') || !getFolderDetails(folder).isSubFolder">
                 <v-tooltip location="top">
                   <template #activator="{ props }">
                     <v-btn
                       append-icon="mdi-chevron-right"
                       class="t-btn text-white view-all-button text-none ml-4"
                       density="default"
                       variant="flat"
                       @click.stop="learnNodeClicked(folder)" 
                       v-bind="props"
                     >
                       View All
                     </v-btn>
                   </template>
                   <span>View All</span>
                 </v-tooltip>
               </v-col>
             </v-row>

            </v-col>
            <v-col v-if="folder.description.length>0" cols="12" class="py-0">
              <v-tooltip location="top" max-width="300px" open-delay="300">
                <template #activator="{ props }">

                  <span class="t-h6Reg two-lines text-white" v-bind="props">
                    {{ folder.description }}
                  </span>
                </template>
                <span>{{ folder.description }}</span>
              </v-tooltip>
            </v-col>

            <v-col cols="12" class="">
              <vue-horizontal class="w-100 scroll-horizontal" :ref="`folder-container-${i}`">
                <v-card elevation="0" class="pa-0 main-card card-border content-card"
                  v-for="(item, i) in getFolderDetails(folder).contents" :key="item.resourceCenterFolderContentId"
                  @click="learnNodeClicked(item)" :ref="`StudentResourceCenterPage__resourceList--card${i}`"
                  min-width="264px" max-width="264px">
                  <v-card-text class="pa-0 text-primaryTextColor w-100 h-100">
                    <v-row class="ma-0 pa-0" justify="start" dense :class="{ 'pt-1': $vuetify.display.xs }">
                      <v-col cols="12" class="ma-0 pa-0" v-if="isVideoContent(item)">
                        <v-img cover lazy-src="https://picsum.photos/id/11/100/60" height="145px" width="260px"
                          class="text-white align-end rounded-lg" :src="getYoutubeVideoThumbnail(item.youTubeUrl)"
                          gradient="to bottom, rgba(0,0,0,.2), rgba(0,0,0,.8)">
                          <template v-slot:placeholder>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                              <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                            </v-row>
                          </template>
                          <div class="bottom-right">
                            <v-icon :icon="getPlaceHolderIcon(item) " class="" size="30"
                            :color="getPlaceHolderIconColor(item)"></v-icon>
                          </div>
                        </v-img>
                      </v-col>
                      <v-col cols="12" class="ma-0 pa-0" v-else-if="isContentThumbnail(item)">
                        <v-img lazy-src="https://picsum.photos/id/11/100/60" height="145px" width="260px"
                          class="text-white align-end rounded-lg" :src="getImageSrc(item.thumbnailUrl)"
                          gradient="to bottom, rgba(0,0,0,.2), rgba(0,0,0,.8)">
                          <template v-slot:placeholder>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                              <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                            </v-row>
                          </template>
                          <div class="bottom-right">
                            <v-icon :icon="getPlaceHolderIcon(item) " class="" size="30"
                            :color="getPlaceHolderIconColor(item)"></v-icon>
                          </div>
                        </v-img>
                      </v-col>
                      <v-col cols="12" class="ma-0 pa-0" v-else>
                        <div class="rounded-lg content-image">
                          <v-icon :icon="getPlaceHolderIcon(item)" class="" size="60"
                            :color="getPlaceHolderIconColor(item)"></v-icon>
                        </div>
                      </v-col>
                      <v-tooltip location="top" max-width="300px" open-delay="300">
                        <template #activator="{ props }">

                         <v-col cols="10" class="two-lines" v-bind="props">
                           {{ getNodeName(item) }}
                         </v-col>
                       </template>
                       <span>{{ getNodeName(item) }}</span>
                     </v-tooltip>
                     <v-spacer></v-spacer>
                     <v-col cols="1">
                       <v-menu
                         v-if="(isPDFContent(item) || isVideoContent(item) || isFolder(item) || isSubFolder(item)) && item.isPublished">
                         <template v-slot:activator="{ props }">
                           <v-icon v-bind="props">
                             mdi-dots-vertical
                           </v-icon>
                         </template>
                         <v-list density="compact" nav>
                           <v-list-item
                             v-if="(isPDFContent(item) || isVideoContent(item) || isFolder(item) || isSubFolder(item)) && item.isPublished"
                             @click.stop="shareLink(item)">
                             <div class="d-flex align-center">
                               <v-icon :height="20" :width="20" class="mr-2">
                                 mdi-share
                               </v-icon>
                               <v-list-item-title>
                                 Share
                               </v-list-item-title>
                             </div>
                           </v-list-item>
                         </v-list>
                       </v-menu>
                     </v-col>
                     <!-- <v-tooltip location="top" max-width="300px">
                       <template #activator="{ props }">

                         <v-col cols="12" v-if="item.description" class="two-lines" v-bind="props">
                           {{ item.description }}
                         </v-col>
                       </template>
                       <span>{{ item.description }}</span>
                     </v-tooltip> -->

                     <v-spacer></v-spacer>

                   </v-row>
                 </v-card-text>
               </v-card>
             </vue-horizontal>
           </v-col>
         </v-row>

       </div>


     </div>

     <v-card v-if="folderNodeContents.isEmpty" class="card-bg card-border px-2 mb-3 py-2" elevation="0"
       ref="StudentResourceCenterPage__resourceList--card0">
       <v-card-text class="text-primaryTextColor pa-0">
         <v-row justify="space-between" align="center" class="ma-0 px-1">
           <v-col cols="12" class="d-flex pa-3 align-center justify-center pa-1">
             <div class="pb-0 wrap-text">
               <span class="t-h6Reg">No Content found</span>
             </div>
           </v-col>
         </v-row>
       </v-card-text>
     </v-card>
   </v-container>
   <template>
     <v-dialog v-model="ytVideoDialog" persistent width="auto">
       <v-card class="pa-1">
         <iframe :width="smAndUp ? 600 : 368" elevation="0" :height="smAndUp ? 400 : 207" :src="playVideoUrl"
           frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
           allowfullscreen></iframe>
         <v-card-actions>
           <v-spacer></v-spacer>
           <v-btn color="primary" variant="text" @click="ytVideoDialog = false">
             Close
           </v-btn>
         </v-card-actions>
       </v-card>
     </v-dialog>
   </template>
   <template>
     <v-dialog v-model="privateVideoDialog" persistent width="auto" ref="pvtVideoDialog">
       <v-card class="pa-1">
         <video id="videopId1" controls ref="privateVideoPlayer" :width="smAndUp ? 600 : 368" elevation="0"
           :height="smAndUp ? 400 : 207"></video>
         <v-card-actions>
           <v-spacer></v-spacer>
           <v-btn color="primary" variant="text" @click="privateVideoDialog = false">
             Close
           </v-btn>
         </v-card-actions>
       </v-card>
     </v-dialog>
   </template>
   <template>

     <v-dialog v-model="pdfViewerDialog" persistent fullscreen>
       <v-card class="pa-1">
         <PDFJSExpressViewer :fileUrl='pdfFileUrl'></PDFJSExpressViewer>
         <v-card-actions>
           <v-spacer></v-spacer>
           <v-btn color="primary" variant="text" @click="pdfViewerDialog = false">
             Close
           </v-btn>
         </v-card-actions>
       </v-card>
     </v-dialog>
   </template>
 </v-layout>
 <v-snackbar v-model="showSnackBar" :timeout="-1" color="cardBackgroundColor" location="bottom">
   <span class="text-easyColor">{{ snackBarMsg.text }}</span>
   <template v-slot:actions>
     <v-btn density="compact" color="#8e8ea7" @click.stop="closeSnackBar()" icon="mdi-close"></v-btn>
   </template>
 </v-snackbar>
</template>

<script>

import { mapGetters, mapState } from "vuex";
import {
 AnalyticsEvents,
 recordAnalyticsEvent,
} from "@/services/awsPinpointAnalyticsService";
import _ from "lodash";
import PDFJSExpressViewer from "@/components/learn/PDFJSExpressViewer.vue";
import Hls from 'hls.js';
import { useDisplay } from 'vuetify';
import { updateHighlightedElements } from "@/shared/selfHelpGuide/updateHighlightedElement";
import SelfHelpGuideTogglerComponent from "@/components/selfHelpGuide/SelfHelpGuideTogglerComponent.vue";
import $ from "jquery";
import VueHorizontal from "vue-horizontal";

export default {
 name: "StudentResourcePage",

 setup() {
   // Destructure only the keys we want to use
   const { xs, smAndUp, mdAndUp } = useDisplay()

   return { xs, smAndUp, mdAndUp }
 },

 async created() {
   this.$store.commit("appbarVisible", true);
   this.$store.commit("navbarVisible", true);
   this.$store.commit('switchingViewData', false);
   if(this.isResourceOpenFromLink){
     this.$store.commit("isResourceOpenFromLink", false);
   }else{
     this.$store.commit("setResourceFolderId", "-1");
     this.$store.commit("loadFolderBreadCrumbs", []);
   }
   await this.$store.dispatch("loadAllStudentResourceFoldersAndContents", {resourceCenterFolderId: this.selectedResourceFolderId});
   if(!_.isEmpty(this.resourceContent)){
     this.learnNodeClicked(this.resourceContent);
     this.$store.commit('setResourceContent', null);
   }
   
   recordAnalyticsEvent(AnalyticsEvents.v2StudentResourceScreenOpen, {
     customerId: this.courseId,
     folderId: this.selectedResourceFolderId
   });
   this.dataLoaded = true;
 },
 mounted(){
   this.$store.commit("updateCurrentScreenName", this.currentScreenName);
 },
 unmounted(){
   this.$store.commit("updateCurrentScreenName", null);
 },
 updated() {
   if (!this.folderNodeContents.isEmpty) {
     if(this.$refs['content-container']){
       this.$refs['content-container'].refresh();
     }
     console.log(this.folderNodeContents.folders.length);
     _.forEach(this.folderNodeContents.folders, (_, i) => {
       if(this.$refs[`folder-container-${i}`]){
         const className = `folder-container-${i}`;
         // console.log(`className-${i}`, className, this.$refs[className], this.$refs)
         const folderConatinerRef = this.$refs[`${className}`];
         if(Array.isArray(folderConatinerRef)){
           folderConatinerRef[0].refresh();
         }else{
           folderConatinerRef.refresh();
         }
     }
     })
     this.firstTime = true;
   }
 },
 components:
 {
   PDFJSExpressViewer,
   SelfHelpGuideTogglerComponent,
   VueHorizontal
 },
 data: () => ({
   currentScreenName: 'StudentResourceCenterPage',
   dataLoaded: false,
   ytVideoDialog: false,
   playVideoUrl: "",
   searchText: '',
   filterSearchVal: '',
   privateVideoDialog: false,
   pvtVideoDomControl: '',
   hlsInstance: null,
   pdfViewerDialog: false,
   pdfFileUrl: '',
   download: false,
   dialogShareLink: false,
   shareableLink: null,
   showSnackBar: false,
   snackBarMsg: null,
   shareId: null,
 }),

 computed: {
   ...mapState(["courseId", "selectedResourceFolderId", "isResourceOpenFromLink", "resourceContent", "showContentMessage"]),
   ...mapGetters(['currentHighlightedElements']),
   folderNodeContents: {
     get() {
       let sortedNodeContents = _.orderBy(this.$store.state.studentResourceFolderContentList.get(this.selectedResourceFolderId) || [], ['order'], ["asc"]);
       const sortedContents = [];
       const sortedFolders = [];
       _.forEach(sortedNodeContents, nodeContent => {
         if (this.isVideoPDFOrLink(nodeContent)) {
           sortedContents.push(nodeContent);
         } else {
           sortedFolders.push(nodeContent);
         }
       })
       return { isEmpty: (sortedNodeContents.length == 0), contents: sortedContents, folders: sortedFolders };
     }
   },
   learnNodesBreadcrumbs: {
     get() {
       let folders = this.$store.state.folderBreadCrumbs;
       let breadCrumbs = [];
       breadCrumbs.push({
         title: "Resources",
         disabled: false,
         parentNodeIdKeyinChildNodesMap: "-1",
         pageRoute: `/studentResource`,
       });
       for(let folder of folders){
         breadCrumbs.push({
           title: folder.folderName,
           disabled: false,
           parentNodeIdKeyinChildNodesMap: folder.folderId,
           pageRoute: `/studentResource`
         });
       }
       breadCrumbs[breadCrumbs.length-1].disabled = true;
       console.log("breadCrumbs", breadCrumbs)
       return breadCrumbs;
     }
   },
   showSelfHelpGuide: {
     get(){
       return this.$store.state.showSelfHelpGuide;
     },
     set(value){
       this.$store.commit('toggleSelfHelpGuide', value);
     }
   }
 },

 watch: {
   currentHighlightedElements: {
     handler(newVal, oldVal) {
       this.$nextTick(() => {
         updateHighlightedElements(oldVal, newVal, this.$refs);
       });
     },
     immediate: true,
     deep: true,
   },
 },

 methods: {
   closeContentMessage(){
     this.$store.commit("showContentMessage", false);
   },
   async initialCall(id){
     recordAnalyticsEvent(AnalyticsEvents.v2StudentResourceFolderFilesOpen, {
       customerId: this.courseId,
       folderId: id
     });
     await this.$store.dispatch("loadAllStudentResourceFoldersAndContents", {resourceCenterFolderId: id});
   },
   openFolderDetails(item){
     this.$store.commit("setResourceFolderId", item.resourceCenterFolderId);
     this.initialCall(item.resourceCenterFolderId);
     this.$store.commit("addFolderBreadCrumbs", {
       folderName: item.resourceCenterFolderName,
       folderId: item.resourceCenterFolderId
     })
   },
   isVideoPDFOrLink(item) {
     let isVideoPDFOrLink = false;
     if (this.isVideoContent(item)) {
       isVideoPDFOrLink = true;
     } else if (this.isPDFContent(item)) {
       isVideoPDFOrLink = true;
     } else if (this.isLink(item)) {
       isVideoPDFOrLink = true;
     }
     return isVideoPDFOrLink;
   },
   isVideoContent(item) {
     let isVideoContent = false;
     if (item.resourceCenterFolderContentType == 'VIDEO') {
       isVideoContent = true;
     }
     return isVideoContent;
   },
   isPDFContent(item) {
     let isPdfContent = false;
     if (item.resourceCenterFolderContentType == 'PDF') {
       isPdfContent = true;
     }
     return isPdfContent;
   },
   isLink(item) {
     return (item.resourceCenterFolderType == "LINK");
   },
   isFolder(item) {
     return (item.resourceCenterFolderType == "FOLDER");
   },
   isSubFolder(item) {
     return (item.resourceCenterFolderType == "SUBFOLDER");
   },
   isContentThumbnail(item){
     return !_.isEmpty(item.thumbnailUrl);
   },
   getNodeName(item) {
     let nodeName = '';
     if (item.resourceCenterFolderName) {
       nodeName = item.resourceCenterFolderName;
     } else if (item.resourceCenterFolderContentName) {
       nodeName = item.resourceCenterFolderContentName;
     } else if (item.resourceCenterFolderContentType == "VIDEO") {
       nodeName = item.resourceCenterFolderContentName;
     } else if (item.resourceCenterFolderContentType == "PDF") {
       nodeName = item.resourceCenterFolderContentName;
     } else if (item.pdfUrl) {
       nodeName = item.pdfUrl;
     }
     return nodeName;
   },
   getNodeImageURL(item) {
     let imageSrc = '';
     if (item.resourceCenterFolderImage || item.resourceCenterFolderContentImage) {
       imageSrc = item.resourceCenterFolderImage || item.resourceCenterFolderContentImage
     }
     return this.$store.state.app_s3url + imageSrc;
   },
   getImageSrc(path) {
     return this.$store.state.app_s3url + path;
   },
   getPlaceHolderIcon(item) {
     let iconName = 'mdi-folder-outline';
     if (item.resourceCenterFolderType == "FOLDER" || item.resourceCenterFolderType == "SUBFOLDER") {
       iconName = 'mdi-folder-open';
     } else if (item.resourceCenterFolderContentType == 'VIDEO') {
       iconName = 'mdi-video-outline';
     } else if (item.resourceCenterFolderContentType == 'PDF') {
       iconName = 'mdi-file-pdf-box';
     } else if (item.resourceCenterFolderType == "LINK") {
       iconName = 'mdi-link-variant';
     }
     return iconName;
   },
   getPlaceHolderIconColor(item) {
     let iconColor = 'grey';
     if (item.resourceCenterFolderType == "FOLDER" || item.resourceCenterFolderType == "SUBFOLDER") {
       iconColor = 'orange';
     } else if (item.resourceCenterFolderContentType == 'VIDEO') {
       iconColor = 'white';
     } else if (item.resourceCenterFolderContentType == 'PDF') {
       iconColor = 'red';
     } else if (item.resourceCenterFolderType == "LINK") {
       iconColor = 'blue';
     }
     return iconColor;
   },
   clearSearch() {
     this.filterSearchVal = this.searchText;
     //console.log(`clearSearch called:: this.filterSearchVal: ${this.filterSearchVal}`);
   },
   debounceSearch: _.debounce(function () {
     this.filterSearchVal = this.searchText;
     if (this.filterSearchVal) {
       // recordAnalyticsEvent(AnalyticsEvents.v2LearnSearch, { searchText: this.filterSearchVal });
     }
     //console.log(`debounceSearch called: filterSearchVal:: ${this.filterSearchVal}`);
   }, 500),
   learnNodeClicked(item) {
     if (this.isLink(item)) {
       recordAnalyticsEvent(AnalyticsEvents.v2StudentResourceFolderLinkOpened,{
         customerId: this.courseId,
         folderId: this.selectedResourceFolderId,
         contentType: 'LINK',
         contentId: item['resourceCenterFolderId'],
       });
       window.open(item.url);
     } else if (this.isVideoContent(item)) {
       if ((item.youTubeOrS3 && item.youTubeOrS3.toLowerCase() == 'youtube')) {
         this.openYoutubeVideoPlayer(item);
       }
     } else if (this.isPDFContent(item)) {
       if (!this.download) {
         this.openPDfViewer(item);
       }
     } else {
       console.log(item);
       this.openFolderDetails(item);
     }
   },
   openYoutubeVideoPlayer(item) {
     let regex =
       /(?:youtube(?:-nocookie)?\.com\/(?:[^\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
     let itemYTURL = item.youTubeUrl;
     let temp = itemYTURL.match(regex)[1];
     const youtubeEmbedTemplate = "https://www.youtube.com/embed/";
     const topOfQueue = youtubeEmbedTemplate.concat(temp);
     this.playVideoUrl = topOfQueue;
     this.ytVideoDialog = true;
     recordAnalyticsEvent(AnalyticsEvents.v2StudentResourceFolderVideoPlayed,{
       customerId: this.courseId,
       folderId: this.selectedResourceFolderId,
       contentType: 'VIDEO',
       contentId: item['resourceCenterFolderContentId'],
       youtubeUrl: item.youTubeUrl
     });
   },
   getYoutubeVideoThumbnail(url) {
     // // console.log(`A-getYoutubeVideoThumbnail: ${JSON.stringify(url)} `);
     try {
       let regex =
         /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
       let temp = regex.exec(url)[3];
       let thumbURL =
         "https://img.youtube.com/vi/" + temp.toString() + "/0.jpg";
       return thumbURL;
     } catch {
       return "";
     }
   },

   openPDfViewer(item) {
     //console.log(`inside openPDfViewer(item) : ${JSON.stringify(item)}`);
     let pdfURL = item.pdfUrl;
     this.$store.dispatch('getPdfUrl', pdfURL).then((pdf) => {
       this.pdfFileUrl = pdf.url;
       this.pdfViewerDialog = true;
       recordAnalyticsEvent(AnalyticsEvents.v2StudentResourceFolderPdfOpened,{
         customerId: this.courseId,
         folderId: this.selectedResourceFolderId,
         contentType: 'PDF',
         contentId: item['resourceCenterFolderContentId'],
        //  pdfUrl: this.pdfFileUrl
       });
     })

     //console.log(`pdf dialog should be visible now : ${fullpdfFileURL}`)
   },
   breadCrumbRoute(item){
     this.$store.commit("setResourceFolderId", item.parentNodeIdKeyinChildNodesMap);
     this.$store.commit("goToFolderBreadCrumbs", item.parentNodeIdKeyinChildNodesMap);
     if(!this.$store.state.studentResourceFolderContentList.has(item.parentNodeIdKeyinChildNodesMap)){
       this.initialCall(item.parentNodeIdKeyinChildNodesMap);
     }
   },
   back() {
     let selectedBreadcrumbs = this.learnNodesBreadcrumbs[this.learnNodesBreadcrumbs.length - 2];
     this.breadCrumbRoute(selectedBreadcrumbs);
   },
   shareLink(item) {
     if(this.isPDFContent(item) || this.isVideoContent(item)){
       this.shareId = item.resourceCenterFolderContentId;
       this.shareableLink = this.getUrl(item.resourceCenterFolderId,item.resourceCenterFolderContentId);
       this.selectedType = item.resourceCenterFolderContentType;
       recordAnalyticsEvent(AnalyticsEvents.v2StudentResourceContentShareButtonClicked, {
         customerId: this.courseId,
         folderId: this.selectedResourceFolderId,
         contentType: this.selectedType,
         contentId: this.shareId
       });
     }else{
       this.shareId = item.resourceCenterFolderId;
       this.shareableLink = this.getUrlFolder(item.resourceCenterFolderId);
       this.selectedType = 'SubFolder';
       recordAnalyticsEvent(AnalyticsEvents.v2StudentResourceFolderShareButtonClicked, {
         customerId: this.courseId,
         folderId: this.shareId
       });
     }

     this.dialogShareLink = true
   },
   getUrlFolder(id) {
     return window.location.origin + "/rc/f/" + id
   },
   getUrl(fileId, contentId) {
     return window.location.origin + `/rc/f/${fileId}/c/${contentId}`
   },
   async copyCode() {
     if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
       navigator.clipboard.writeText(this.shareableLink);

       if(this.selectedType == "SubFolder"){
         recordAnalyticsEvent(AnalyticsEvents.v2StudentResourceFolderShareLinkCopied, {
           customerId: this.courseId,
           folderId: this.shareId
         });
       }else{
         recordAnalyticsEvent(AnalyticsEvents.v2StudentResourceContentShareLinkCopied, {
           customerId: this.courseId,
           folderId: this.selectedResourceFolderId,
           contentType: this.selectedType,
           contentId: this.shareId
         });
       }

       this.updateSnackBarMsg({ text: "Your resource link has been copied to clipboard." })
       this.openSnackBar()
     }
     else {
       console.log({ text: 'The clipboard API is not available.' });
     }
   },
   updateSnackBarMsg(msg){
     this.snackBarMsg = msg;
   },
   openSnackBar(){
     this.showSnackBar = true;
   },
   closeSnackBar(){
     this.snackBarMsg = "";
     this.showSnackBar = false;
     this.selectedType = "";
     this.shareId = null;
   },
  getFolderDetails(folder){
    let sortedNodeContents =  _.orderBy(folder.contents, ["order"], "asc");
      const sortedContents = [];
      const sortedFolders = [];
      _.forEach(sortedNodeContents, nodeContent => {
        if(this.isVideoPDFOrLink(nodeContent)){
          sortedContents.push(nodeContent);
        }else{
          sortedFolders.push(nodeContent);
        }
      })
      return {isSubFolder: (sortedFolders.length == 0), contents: sortedContents, folders: sortedFolders};
  },

 //  scrollToLeft(className, index){
 //    const scrollContainer = $(`.${className}`);
 //    if (!scrollContainer.length) {
 //      console.error('Scroll container not found!');
 //      return;
 //    }
 //    console.log("scrollToLeft");
 //    try {
 //      $(`.${className}`).stop().animate({ scrollLeft: '-=218' }, 300);
 //      setTimeout(()=>{
 //        this.scrollerArray[index].left = scrollContainer.scrollLeft()>0;
 //        this.scrollerArray[index].right = (scrollContainer.scrollLeft() + scrollContainer.width() < scrollContainer[0].scrollWidth);
 //      }, 300);
 //    } catch (error) {
 //        console.log(error);
 //    }
 //  },
 //  scrollToRight(className, index){
 //    const scrollContainer = $(`.${className}`);
 //    if (!scrollContainer.length) {
 //      console.error('Scroll container not found!');
 //      return;
 //    }
 //    console.log("scrollToRight");
 //    try {
 //      $(`.${className}`).stop().animate({ scrollLeft: '+=218' }, 300);
 //      setTimeout(()=>{
 //        this.scrollerArray[index].left = scrollContainer.scrollLeft()>0;
 //        this.scrollerArray[index].right = (scrollContainer.scrollLeft() + scrollContainer.width() < scrollContainer[0].scrollWidth);
 //      }, 300);
 //    } catch (error) {
 //        console.log(error);
 //    }
 //  },
 //  showLeftScrollor(className){
 //    const scrollContainer = $(`.${className}`);
 //    if (!scrollContainer.length) {
 //     //  console.error('Scroll container not found!');
 //      return false;
 //    }
 //    const showLeftButton = scrollContainer.scrollLeft()>0;
 //    return showLeftButton;
 //  },
 //  showRightScrollor(className){
 //    const scrollContainer = $(`.${className}`);
 //    if (!scrollContainer.length) {
 //     //  console.error('Scroll container not found!');
 //      return false;
 //    }
 //    console.log("v=showRightScrollor", className, scrollContainer.scrollLeft(), scrollContainer.width(), scrollContainer[0].scrollWidth);
 //    const showRightButton = (scrollContainer.scrollLeft() + scrollContainer.width() < scrollContainer[0].scrollWidth);
 //    return showRightButton;
 //  },
 //  showScroller(){
 //    console.log("scrollerArray-1", this.scrollerArray, "this.firstTime",  this.firstTime)
 //    if(!this.folderNodeContents.isEmpty && this.firstTime){
 //      this.scrollerArray = [];
 //      this.scrollerArray.push({
 //        left: this.showLeftScrollor('content-container'),
 //        right: this.showRightScrollor('content-container')
 //      });
 //      _.forEach(this.folderNodeContents.folders, (_,i) => {
 //        this.scrollerArray.push({
 //          left: this.showLeftScrollor(`folder-container-${i}`),
 //          right: this.showRightScrollor(`folder-container-${i}`)
 //        });
 //      })
 //    }
 //    console.log("scrollerArray", this.scrollerArray)
 //  }
},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* .layoutLarge {
 height: 100%;
} */
.heightBread {
 height: 80px;
}

.container-width {
 max-width: 1120px;
}

.main-lg {
 padding-top: 50px;
}

.main-md {
 padding-top: 50px;
}

.main-card {
 box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
 /* border-radius: 20px; */
 background: transparent !important;
 /* background: rgb(var(--v-theme-cardBackgroundColor)); */
 cursor: pointer;
}

.main-card-header {
 background: #2b2b44;
 padding-top: 15px;
 padding-left: 15px;
 padding-bottom: 10px;
}

.card-bg {
 background: rgb(var(--v-theme-cardBackgroundColor2));
 border-radius: 12px;
}

.card-border {
 border: 2px solid transparent;
 transition: all 0.4s ease;
 -webkit-transition: all 0.4s ease;
 cursor: pointer;
}

.content-card {
  &:not(:first-child) {
    margin-left: 8px !important;
  }
}

.content-image {
  height: 145px;
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgb(var(--v-theme-primaryGrey));
}

.bottom-right{
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 8px;
  margin-bottom: 8px;
}

.icon-bg {
 background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%);
}

.assignment-form-main {
 overflow: auto !important;
}

.assignment-form {
 /* background: rgb(var(--v-theme-primaryGrey)); */
 border-radius: 12px !important;
 /* border: 2px solid #c3c3c3; */
 border: 2px solid rgba(255, 255, 255, 0.22);
 /* background: #232238; */
 background-color: rgb(var(--v-theme-reportBgColor));
 height: 100%;
}

.assignment-form-title {
 background: rgb(var(--v-theme-breadCrumbBackColor));
}

.cancel-btn {
 border: 2px solid rgba(255, 255, 255, 0.22);
 color: white !important;
 background: rgb(var(--v-theme-cardBackgroundColor));
 border-radius: 29.4079px;
 width: 100px;
}

.delete-btn {
 border: 2px solid red !important;
}

.save-btn {
 color: white !important;
 border-radius: 29.4079px;
 /* width: 100px; */
 background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%);
}

.share-btn {
 background: #393850;
 border-radius: 12px;
}

.border-code {
 background: rgba(241, 241, 241, 0.05);
 border-radius: 8px;
}

.share-margin{
 margin-right: 38px !important;
}

.arrow-left{
position: absolute;
left: 0;
top: 50%;
transform: translate(-25%, -75%);
z-index: 1000;
}

.arrow-right{
position: absolute;
right: 0;
top: 50%;
transform: translate(25%, -75%);
z-index: 1000;
}

.two-lines {
 /* Set the line height to ensure 3 lines */
 overflow: hidden;
 /* Hide the overflow */
 text-overflow: ellipsis;
 /* Add ellipsis if text overflows */
 display: -webkit-box;
 /* Flexbox model for multi-line ellipsis */
 -webkit-box-orient: vertical;
 -webkit-line-clamp: 2;
 /* Limit the text to 2 lines */
 line-clamp: 2;
}

.scroll-horizontal >>> .v-hl-btn-next {
 transform: translateX(25%) !important;
}

.scroll-horizontal >>> .v-hl-btn-prev {
 transform: translateX(-25%) !important;
}

.scroll-horizontal >>> .v-hl-btn-next svg{
 height: 36px !important;
 width: 36px !important;
}

.scroll-horizontal >>> .v-hl-btn-prev svg{
  height: 36px !important;
  width: 36px !important;
}

.view-all-button{
 /* background: #44445D; */
 border-radius: 50px;
 background: linear-gradient(#44445D, #44445D) padding-box,
     linear-gradient(180deg, #ab77ff 0%, #585ef3 100%) border-box;
   border: 2px solid transparent;
}

</style>
