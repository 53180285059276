import store from "../store";
import axios from 'axios';
import {
    AnalyticsEvents,
    recordAnalyticsEvent,
  } from "@/services/awsPinpointAnalyticsService";
import * as htmlToImage from "html-to-image";
  
export async function getImageUrl(screenshotElement, questionId, testInstanceId){

    const images = screenshotElement.querySelectorAll("img");

    for (let i = 0; i < images.length; i++) {
        const image = images[i];
        const imageUrl = image.src + "?ver=" + store.state.appVersion;
        if (imageUrl.includes("base64")) {
        continue;
        }
        console.log("imgg", imageUrl);
        const base64Url = await convertImageToBase64(imageUrl, questionId, testInstanceId);
        if (base64Url) {
        image.src = base64Url;
        console.log("base64", base64Url);
        }
        // image.src = image.src + "?test1";
    }
    let capturedImageUrl = await htmlToImage.toPng(screenshotElement, {
        pixelRatio: 1,
        useCORS: true
    });
    return capturedImageUrl;
}

export async function convertImageToBase64(imageUrl, questionId, testInstanceId) {
    try {
      const response = await axios.get(imageUrl, {
        responseType: "arraybuffer",
      });
      console.log("imggg", response);
      const arrayBuffer = response.data;
      const byteString = Array.from(new Uint8Array(arrayBuffer))
        .map((x) => String.fromCharCode(x))
        .join("");
      const base64 = btoa(byteString);
      console.log(base64);
      const mimeType = response.headers["content-type"];
      let event = {
        testInstanceId: testInstanceId,
        questionId: questionId,
        successfullyConverted: true,
      };
      recordAnalyticsEvent(AnalyticsEvents.v2NotesConvertingImageToBase64, event);
      return `data:${mimeType};base64,${base64}`;
    } catch (error) {
      console.error("Error fetching image:", imageUrl, error);
      this.$store.commit("resetLoader");
      this.errorDialog = true;
      let event = {
        testInstanceId: testInstanceId,
        questionId: questionId,
        successfullyConverted: false,
        errorMessage: error,
      };
      recordAnalyticsEvent(AnalyticsEvents.v2NotesConvertingImageToBase64, event);

      return null; // Or set a default image on error
    }
  }