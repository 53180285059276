<template>
  <!-- ScribbleComponent -->
  <ScribbleComponent
    v-if="showScribbleImage"
    :isTeacherNotesAttached="currentQ.isTeacherNotesAttached"
    :screenshotElement="screenshotElement"
    @closeNotesDialog="closeNotesDialog"
    :testInstanceId="testInstanceId"
    :questionId="currentQ.qId"
    :isOpenedFromTeacherView="isOpenedFromTeacherView"
    @upateTeacherNote="upateTeacherNote()"
  />

  <div class="bgW">
    <!-- Main Container -->
    <div v-if="loadedFromServer" class="">
      <v-dialog
        absolute
        class="bottom dialog-bx"
        height="700px"
        width="800px"
        v-model="questionDialog"
      >
        <v-card height="700px" color="background">
          <v-card-text class="pa-8">
            <v-row justify="space-between" class="t-h5 px-3">
              <v-col class="ml-0m pl-0">
                {{ isOpenedFromParentView? "Child's responses" : isOpenedFromTeacherView ? "Student's responses" : "Your responses" }}
              </v-col>
              <v-col class="text-right">
                <v-icon @click="questionDialog = false"> mdi-close </v-icon>
              </v-col>
            </v-row>
            <v-row>
              <v-divider class="mt-2 mb-5"> </v-divider>
            </v-row>
            <v-row>
              <v-col cols="auto" class="">
                <v-avatar size="40" variant="outlined" class="mr-2 correct">
                  {{ testInstanceQuestionSummary.correctQuestions }} </v-avatar
                ><span> Correct </span>
              </v-col>
              <v-col cols="auto" class="">
                <v-avatar size="40" variant="outlined" class="mr-2 incorrect">
                  {{ testInstanceQuestionSummary.incorrectQuestions }} </v-avatar
                ><span> Incorrect </span>
              </v-col>
              <v-col cols="auto" class="">
                <v-avatar size="40" variant="outlined" class="mr-2 unattempted">
                  {{ testInstanceQuestionSummary.unattemptedQuestions }} </v-avatar
                ><span> Not Attempted </span>
              </v-col>
              <v-col cols="auto">
                <v-avatar size="40" class="">
                  <v-icon color="black" icon="mdi-paperclip" size="25"></v-icon>
                </v-avatar>
                <span> Notes Attached </span>
              </v-col>
            </v-row>
            <v-row>
              <v-divider class="my-5"> </v-divider>
            </v-row>

            <v-row
              class="my-4"
              v-for="(item, sIndex) in currentTestResultJson"
              :key="sIndex"
            >
              <v-col v-if="item.visibleOnApp" cols="12" class="t-h5Reg">
                {{ item.sectionName }}
              </v-col>

              <v-row>
                <v-col
                  cols="auto"
                  class="mx-2 makePositionRelative"
                  v-for="(item, qIndex) in item.questions"
                  :key="qIndex"
                >
                  <div v-if="item.isTeacherNotesAttached == true" class="notesAttached">
                    <v-icon size="20px" icon="mdi-paperclip"></v-icon>
                  </div>
                  <v-avatar
                    style="cursor: pointer"
                    @click="openQFromDialog(sIndex, qIndex)"
                    size="40"
                    variant="outlined"
                    :class="{
                      unattempted: item.isCorrect == null,
                      correct: item.isCorrect != null && item.isCorrect == true,
                      incorrect: item.isCorrect != null && item.isCorrect == false,
                    }"
                  >
                    {{ qIndex + 1 }}
                  </v-avatar>
                </v-col>
              </v-row>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-app-bar color="breadCrumbBackColor">
        <v-app-bar-title>
          <template v-slot:text>
            <span class>
              {{ testInstanceQuestionSummary.testName }}
            </span>
          </template>
        </v-app-bar-title>
        <v-spacer></v-spacer>

        <v-col cols="auto" v-if="!$vuetify.display.xs" class="mr-4">
          <div class="filter-switch">
            <v-switch
              color="primaryPurple"
              v-model="showSolution"
              hide-details
              @update:modelValue="toggleSolution"
              label="Show Solution"
            ></v-switch>
          </div>
        </v-col>

        <v-col cols="auto" class="mr-4 makePositionRelative" v-if="showScribble()">
          <v-tooltip
            :text="
              isOpenedFromTeacherView
                ? currentQ.isTeacherNotesAttached
                  ? 'Edit Notes'
                  : 'Add Notes'
                : 'View Notes'
            "
            location="end"
          >
            <template v-slot:activator="{ props }">
              <div
                v-if="currentQ.isTeacherNotesAttached == true && isOpenedFromTeacherView"
                class="notesAttached2"
              >
                <v-icon size="20px" icon="mdi-paperclip"></v-icon>
              </div>
              <v-btn
                height="35"
                width="35"
                max-width="35"
                v-bind="props"
                variant="flat"
                class="outline-color mr-4 pa-0"
                @click="addEditNotes()"
                :icon="
                  isOpenedFromTeacherView ? 'mdi-note-edit-outline' : 'mdi-note-outline'
                "
              >
              </v-btn>
            </template>
          </v-tooltip>
        </v-col>

        <v-icon v-if="$vuetify.display.xs" @click="toggleFilter" class="mr-4"
          >mdi-filter</v-icon
        >

        <v-icon @click="backFromDialog" class="mr-4"> mdi-close</v-icon>
        <template v-slot:extension v-if="showFilter">
          <v-card class="testCard w-100">
            <v-card-text
              class="pa-0"
              :class="$vuetify.display.mdAndUp ? 'px-10' : 'px-3'"
            >
              <v-row class="text-white my-3" align="center" dense>
                <v-col cols="auto">
                  <span class="t-1remReg text-bold">Filters</span>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto">
                  <div class="filter-switch">
                    <v-switch
                      color="primaryPurple"
                      v-model="showSolution"
                      hide-details
                      @update:modelValue="toggleSolution"
                      label="Show Solution"
                    ></v-switch>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </v-app-bar>
      <!-- <v-dialog absolute class="bottom dialog-bx" max-width="800px" v-model="reportDialog">
				<FeedbackComponent @closeDialog="reportDialog = false" :test-type="testType" :q-id="currentQ.qId"
					:test-id="testId" :test-instance-id="testInstanceId" :currentQ="currentQ"></FeedbackComponent>
			</v-dialog> -->
      <DragableFeedbackDialog
        v-if="reportDialog"
        @closeDialog="reportDialog = false"
        :test-type="testType"
        :q-id="currentQ.qId"
        :test-id="testId"
        :test-instance-id="testInstanceId"
        :q-obj="currentQ"
      ></DragableFeedbackDialog>

      <v-container class="whiteBackground"  fluid id="scribbleQuestionScreen">
        <v-row justify="start" :class="{ 'w-70': $vuetify.display.mdAndUp }">
          <v-col cols="12">
            <v-card elevation="0" color="white" scrollable>
              <section   id="mainQuestionScreen" class="mx-5 mt-10 whiteBackground">
                <div class="mb-8">
                  <v-row class="align-start">
                    <v-col cols="10" sm="auto" class="ma-0 pa-0">
                      <v-row align="start">
                        <v-col cols="auto">
                          <span class="qNoClass font-weight-bold ml-3 mr-5">
                            Question {{ currentQNo }}
                          </span>
                        </v-col>

                        <v-col
                          cols="12"
                          sm="9"
                          class="ma-0 pa-0 ml-3"
                          v-if="$vuetify.display.xs"
                        >
                          <v-row align="start" class="ma-0 pa-0">
                            <v-col cols="auto" class="mx-3 mx-sm-5 my-1 my-sm-0">
                              <v-tooltip
                                :text="currentQ.difficulty.trim()"
                                location="bottom"
                              >
                                <template v-slot:activator="{ props }">
                                  <v-row align="center" v-bind="props">
                                    <v-icon size="24" class="icon-opacity"
                                      >mdi-podium</v-icon
                                    >
                                    <span class="ml-1 qNoClass">
                                      {{ currentQ.difficulty.trim() }}
                                    </span>
                                  </v-row>
                                </template>
                              </v-tooltip>
                            </v-col>
                            <v-col cols="auto" class="mx-3 mx-sm-5 my-1 my-sm-0">
                              <v-tooltip
                                :text="currentQ.timeSpent.toString() + ' seconds'"
                                location="bottom"
                              >
                                <template v-slot:activator="{ props }">
                                  <v-row align="center" v-bind="props">
                                    <v-icon size="24" class="icon-opacity"
                                      >mdi-clock-time-four</v-icon
                                    >
                                    <span class="ml-1 qNoClass">
                                      {{
                                        currentQ.timeSpent > 0
                                          ? `${currentQ.timeSpent} seconds`
                                          : "--"
                                      }}
                                    </span>
                                  </v-row>
                                </template>
                              </v-tooltip>
                            </v-col>
                            <v-col cols="auto" class="mx-3 mx-sm-5 my-1 my-sm-0">
                              <v-tooltip
                                :text="currentQ.categoryName.trim()"
                                location="bottom"
                              >
                                <template v-slot:activator="{ props }">
                                  <v-row align="center" v-bind="props">
                                    <v-icon size="24" class="icon-opacity"
                                      >mdi-book-open-blank-variant</v-icon
                                    >
                                    <span
                                      class="ml-1 qNoClass text-truncate"
                                      style="max-width: 200px"
                                    >
                                      {{ currentQ.categoryName.trim() }}
                                    </span>
                                  </v-row>
                                </template>
                              </v-tooltip>
                            </v-col>

                            <v-col cols="auto" class="mx-3 mx-sm-5 my-1 my-sm-0">
                              <v-tooltip
                                :text="currentQ.topicName.trim()"
                                location="bottom"
                              >
                                <template v-slot:activator="{ props }">
                                  <v-row align="center" v-bind="props">
                                    <v-icon size="24" class="icon-opacity"
                                      >mdi-book-open-variant</v-icon
                                    >
                                    <span
                                      class="ml-1 qNoClass text-truncate"
                                      style="max-width: 200px"
                                    >
                                      {{ currentQ.topicName.trim() }}
                                    </span>
                                  </v-row>
                                </template>
                              </v-tooltip>
                            </v-col>

                            <v-col
                              v-if="currentQ.concepts.length > 0"
                              cols="auto"
                              class="mx-3 mx-sm-5 my-1 my-sm-0"
                            >
                              <v-tooltip
                                :text="currentQ.concepts[0]['label'].trim()"
                                location="bottom"
                              >
                                <template v-slot:activator="{ props }">
                                  <v-row align="center" v-bind="props">
                                    <v-icon size="24" class="icon-opacity"
                                      >mdi-book-open-variant</v-icon
                                    >
                                    <span
                                      class="ml-1 qNoClass text-truncate"
                                      style="max-width: 200px"
                                    >
                                      {{ currentQ.concepts[0]["label"].trim() }}
                                    </span>
                                  </v-row>
                                </template>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="9" class="ma-0 pa-0" v-if="!$vuetify.display.xs">
                      <v-row align="start" class="ma-0 pa-0">
                        <v-col cols="auto" class="mx-3 mx-sm-5 my-1 my-sm-0">
                          <v-tooltip :text="currentQ.difficulty.trim()" location="bottom">
                            <template v-slot:activator="{ props }">
                              <v-row align="center" v-bind="props">
                                <v-icon size="24" class="icon-opacity">mdi-podium</v-icon>
                                <span class="ml-1 qNoClass">
                                  {{ currentQ.difficulty.trim() }}
                                </span>
                              </v-row>
                            </template>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="auto" class="mx-3 mx-sm-5 my-1 my-sm-0">
                          <v-tooltip
                            :text="currentQ.timeSpent.toString() + ' seconds'"
                            location="bottom"
                          >
                            <template v-slot:activator="{ props }">
                              <v-row align="center" v-bind="props">
                                <v-icon size="24" class="icon-opacity"
                                  >mdi-clock-time-four</v-icon
                                >
                                <span class="ml-1 qNoClass">
                                  {{
                                    currentQ.timeSpent > 0
                                      ? `${currentQ.timeSpent} seconds`
                                      : "--"
                                  }}
                                </span>
                              </v-row>
                            </template>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="auto" class="mx-3 mx-sm-5 my-1 my-sm-0">
                          <v-tooltip
                            :text="currentQ.categoryName.trim()"
                            location="bottom"
                          >
                            <template v-slot:activator="{ props }">
                              <v-row align="center" v-bind="props">
                                <v-icon size="24" class="icon-opacity"
                                  >mdi-book-open-blank-variant</v-icon
                                >
                                <span
                                  class="ml-1 qNoClass text-truncate"
                                  style="max-width: 200px"
                                >
                                  {{ currentQ.categoryName.trim() }}
                                </span>
                              </v-row>
                            </template>
                          </v-tooltip>
                        </v-col>

                        <v-col cols="auto" class="mx-3 mx-sm-5 my-1 my-sm-0">
                          <v-tooltip :text="currentQ.topicName.trim()" location="bottom">
                            <template v-slot:activator="{ props }">
                              <v-row align="center" v-bind="props">
                                <v-icon size="24" class="icon-opacity"
                                  >mdi-book-open-variant</v-icon
                                >
                                <span
                                  class="ml-1 qNoClass text-truncate"
                                  style="max-width: 200px"
                                >
                                  {{ currentQ.topicName.trim() }}
                                </span>
                              </v-row>
                            </template>
                          </v-tooltip>
                        </v-col>

                        <v-col
                          v-if="currentQ.concepts.length > 0"
                          cols="auto"
                          class="mx-3 mx-sm-5 my-1 my-sm-0"
                        >
                          <v-tooltip
                            :text="currentQ.concepts[0]['label'].trim()"
                            location="bottom"
                          >
                            <template v-slot:activator="{ props }">
                              <v-row align="center" v-bind="props">
                                <v-icon size="24" class="icon-opacity"
                                  >mdi-book-open-variant</v-icon
                                >
                                <span
                                  class="ml-1 qNoClass text-truncate"
                                  style="max-width: 450px"
                                >
                                  {{ currentQ.concepts[0]["label"].trim() }}
                                </span>
                              </v-row>
                            </template>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-spacer> </v-spacer>

                    <v-col cols="2" sm="1" class="ma-0 pa-0" v-if="isQuestionReviewer">
                      <v-tooltip
                        :text="feedbackOrQuestionReiewIconTooltipText"
                        location="end"
                      >
                        <template v-slot:activator="{ props }">
                          <v-btn
                            size="small"
                            v-bind="props"
                            variant="outlined"
                            class="outline-color"
                            @click="showReportDialog()"
                            :icon="feedbackOrQuestionReiewIconName"
                          ></v-btn>
                        </template>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </div>
                <section>
                  <div>
                    <HtmlRender
                      :htmlString="currentQ.qText"
                      class="htmlClassTest"
                    ></HtmlRender>
                  </div>
                </section>

                <section>
                  <div>
                    <HtmlRender
                      :htmlString="currentQ.qTextForSecondColumn"
                      class="htmlClassTest mt-1"
                    ></HtmlRender>
                  </div>
                </section>

                <div class="mt-15">
                  <v-row class="mt-4 mb-10 mx-1" v-if="currentQ.isCorrect == null">
                    <v-alert
                      color="info"
                      icon="$info"
                      variant="tonal"
                      text="This question was not attempted"
                    ></v-alert>
                  </v-row>
                  <!-- case 1 -->
                  <section v-if="currentQ.qType == '1'">
                    <v-radio-group v-model="currentQ.userAnswerSingleChoice" readonly>
                      <v-row v-for="option in currentQ.options" :key="option.key">
                        <v-col
                          class="mb-0 pb-0"
                          cols="12"
                          v-if="
                            userAnswerSingleChoice(currentQ, option.key) && showSolution
                          "
                        >
                          <span
                            v-if="correctAnswerSingleChoice(currentQ, option.key)"
                            class="text-green"
                          >
                            {{ answerResponseMessage.correctAnswerMsg }}
                          </span>
                          <span
                            v-if="!correctAnswerSingleChoice(currentQ, option.key)"
                            class="text-red"
                          >
                            {{ answerResponseMessage.incorrectAnswerMsg }}
                          </span>
                        </v-col>
                        <v-col
                          class="mb-0 pb-0"
                          cols="12"
                          v-if="
                            !userAnswerSingleChoice(currentQ, option.key) &&
                            correctAnswerSingleChoice(currentQ, option.key) &&
                            showSolution
                          "
                        >
                          <span class="text-green"> Correct Answer </span>
                        </v-col>
                        <v-col cols="12">
                          <v-hover v-slot="{ isHovering, props }">
                            <v-sheet
                              class="bx"
                              :class="{
                                'on-hover': isHovering,
                                selected:
                                  userAnswerSingleChoice(currentQ, option.key) &&
                                  !showSolution,
                                'green-border':
                                  correctAnswerSingleChoice(currentQ, option.key) &&
                                  showSolution,
                                'red-border':
                                  !correctAnswerSingleChoice(currentQ, option.key) &&
                                  userAnswerSingleChoice(currentQ, option.key) &&
                                  showSolution,
                              }"
                              v-bind="props"
                            >
                              <v-radio
                                readonly
                                density="compact"
                                color="primary"
                                class="custom-radio testOption"
                                dense
                                :value="option.key"
                              >
                                <template v-slot:label>
                                  <v-row class="ma-0 pa-0 pr-3 pl-3" align="center">
                                    <v-col class="ma-0 pa-0" cols="auto">
                                      <v-avatar
                                        elevation="2"
                                        size="25"
                                        class="t-h6 outline-color-grey"
                                        :class="{
                                          'outline-color-white  text-primaryPurple':
                                            userAnswerSingleChoice(
                                              currentQ,
                                              option.key
                                            ) && !showSolution,
                                        }"
                                        variant="outlined"
                                      >
                                        {{ option.key.toUpperCase() }}
                                      </v-avatar>
                                    </v-col>
                                    <v-col class="ma-0 pa-0">
                                      <HtmlRender
                                        class="htmlClassTest htmlClassTestOption ml-2 py-4 w-100"
                                        :htmlString="option.value"
                                      >
                                      </HtmlRender>
                                    </v-col>
                                  </v-row>
                                </template>
                              </v-radio>
                            </v-sheet>
                          </v-hover>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </section>

                  <section v-if="currentQ.qType == '2'">
                    <v-row v-for="option in currentQ.options" :key="option.key">
                      <v-col
                        class="mb-0 pb-0"
                        cols="12"
                        v-if="
                          userAnswerMultipleChoice(currentQ, option.key) && showSolution
                        "
                      >
                        <span
                          v-if="correctAnswerMultiChoice(currentQ, option.key)"
                          class="text-green"
                        >
                          {{ answerResponseMessage.correctAnswerMsg }}
                        </span>
                        <span
                          v-if="!correctAnswerMultiChoice(currentQ, option.key)"
                          class="text-red"
                        >
                          {{ answerResponseMessage.incorrectAnswerMsg }}
                        </span>
                      </v-col>
                      <v-col
                        class="mb-0 pb-0"
                        cols="12"
                        v-if="
                          !userAnswerMultipleChoice(currentQ, option.key) &&
                          correctAnswerMultiChoice(currentQ, option.key) &&
                          showSolution
                        "
                      >
                        <span class="text-green"> Correct Answer </span>
                      </v-col>
                      <v-col cols="12">
                        <v-hover v-slot="{ isHovering, props }">
                          <v-sheet
                            v-bind="props"
                            class="bx pa-0 ma-0"
                            :class="{
                              'on-hover': isHovering,
                              selected:
                                userAnswerMultipleChoice(currentQ, option.key) &&
                                !showSolution,
                              'green-border':
                                correctAnswerMultiChoice(currentQ, option.key) &&
                                showSolution,
                              'red-border':
                                !correctAnswerMultiChoice(currentQ, option.key) &&
                                userAnswerMultipleChoice(currentQ, option.key) &&
                                showSolution,
                            }"
                          >
                            <v-checkbox
                              density="compact"
                              hide-details
                              v-model="currentQ.userAnswers"
                              readonly
                              color="primary"
                              class="testOption custom-radio"
                              :value="option.key"
                            >
                              <template v-slot:label>
                                <v-row class="ma-0 pa-0 pr-3 pl-3" align="center">
                                  <v-col class="ma-0 pa-0" cols="auto">
                                    <v-avatar
                                      elevation="2"
                                      size="25"
                                      class="t-h6 outline-color-grey"
                                      :class="{
                                        'outline-color-white  text-primaryPurple':
                                          userAnswerMultipleChoice(
                                            currentQ,
                                            option.key
                                          ) && !showSolution,
                                      }"
                                      variant="outlined"
                                    >
                                      {{ option.key.toUpperCase() }}
                                    </v-avatar>
                                  </v-col>
                                  <v-col class="ma-0 pa-0">
                                    <HtmlRender
                                      class="htmlClassTest htmlClassTestOption ml-2 py-4 w-100"
                                      :htmlString="option.value"
                                    >
                                    </HtmlRender>
                                  </v-col>
                                </v-row>
                              </template>
                            </v-checkbox>
                          </v-sheet>
                        </v-hover>
                      </v-col>
                    </v-row>
                  </section>

                  <section v-if="currentQ.qType == '3'">
                    <v-row>
                      <v-col class="mb-0 pb-0" cols="12">
                        <span v-if="currentQ.isCorrect != null && showSolution">
                          <span v-if="currentQ.isCorrect" class="text-green">
                            {{ answerResponseMessage.correctAnswerMsg }}
                          </span>
                          <span v-if="!currentQ.isCorrect" class="text-red">
                            {{ answerResponseMessage.incorrectAnswerMsg }}
                          </span>
                        </span>
                      </v-col>
                      <v-col v-if="currentQ.isCorrect != null" cols="8" md="3" lg="2">
                        <v-text-field
                          @click.prevent=""
                          readonly
                          v-model="currentQ.userAnswerInteger"
                          label="Your Answer"
                          variant="outlined"
                          :color="
                            showSolution ? (currentQ.isCorrect ? 'green' : 'red') : ''
                          "
                          focused
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row
                      v-if="
                        (currentQ.isCorrect == null || !currentQ.isCorrect) &&
                        showSolution
                      "
                    >
                      <v-col class="mb-0 pb-0 text-green" cols="12">
                        <span> Correct Answer </span>
                      </v-col>
                      <v-col cols="8" md="3" lg="2">
                        <v-text-field
                          readonly
                          v-model="currentQ.answerInteger"
                          label="Answer"
                          variant="outlined"
                          color="green"
                          focused
                          base-color="green"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </section>

                  <section v-if="currentQ.qType == '4'">
                    <v-row>
                      <v-col class="mb-0 pb-0" cols="12">
                        <span v-if="currentQ.isCorrect != null && showSolution">
                          <span v-if="currentQ.isCorrect" class="text-green">
                            {{ answerResponseMessage.correctAnswerMsg }}
                          </span>
                          <span v-if="!currentQ.isCorrect" class="text-red">
                            {{ answerResponseMessage.incorrectAnswerMsg }}
                          </span>
                        </span>
                      </v-col>
                      <v-col v-if="currentQ.isCorrect != null" cols="8" md="3" lg="2">
                        <v-text-field
                          readonly
                          label="Answer"
                          v-model="currentQ.userAnswerDecimal"
                          :color="
                            showSolution ? (currentQ.isCorrect ? 'green' : 'red') : ''
                          "
                          focused
                          variant="outlined"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row v-if="showSolution">
                      <v-col class="mb-0 pb-0 text-green" cols="12">
                        <span> Correct Answer </span>
                      </v-col>
                      <v-col cols="8" md="3" lg="2">
                        <v-text-field
                          readonly
                          v-model="correctAnswerDecimal"
                          label="Answer"
                          variant="outlined"
                          color="green"
                          focused
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </section>
                </div>
              </section>

              <section  id="solutionSection"  class="ma-4 whiteBackground" v-if="currentQ.solution != null && showSolution">
                <v-row class="t-h5 mt-4">
                  <v-col cols="12"> Solution </v-col>
                </v-row>
                <v-row class="my-4">
                  <div>
                    <v-col cols="12">
                      <HtmlRender
                        :htmlString="currentQ.solution"
                        class="htmlClassTest"
                      ></HtmlRender>
                    </v-col>
                  </div>
                </v-row>
              </section>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-footer
      v-if="loadedFromServer"
      app
      class="bgWhite px-4 pt-4 pb-6 ma-0"
      elevation="2"
    >
      <v-row justify="space-between" class="footer-row px-5 align-center">
        <v-col class="align-center">
          <v-btn
            v-if="$vuetify.display.smAndUp"
            class="t-btn text-capitalize btn-prev"
            :disabled="prevButtonDisabled"
            @click="prevQuestion"
            :size="$vuetify.display.smAndUp ? 'large' : 'default'"
            :width="$vuetify.display.mdAndUp ? '130' : '125'"
            elevation="0"
            :color="!prevButtonDisabled ? 'secondaryBackground' : undefined"
          >
            Previous
            <template v-slot:prepend>
              <v-icon>mdi-chevron-left</v-icon>
            </template>
          </v-btn>

          <v-btn
            v-else
            density="compact"
            size="large"
            icon="mdi-chevron-left"
            :disabled="prevButtonDisabled"
            @click="prevQuestion"
          >
          </v-btn>
        </v-col>

        <v-col cols="auto">
          <v-btn
            @click="questionDialog = true"
            color="secondaryBackground"
            density="compact"
            elevation="0"
            size="large"
            :icon="questionDialog == true ? 'mdi-chevron-down' : 'mdi-chevron-up'"
          ></v-btn>
          <span class="ml-2">
            {{ currentQNo }} of
            {{ currentTestResultJson[currentTestState.sectionIndex].questions.length }}
          </span>
        </v-col>

        <v-col class="text-end align-center">
          <v-btn
            v-if="$vuetify.display.smAndUp"
            class="t-btn text-capitalize btn-grad text-primaryTextColor"
            :size="$vuetify.display.smAndUp ? 'large' : 'default'"
            :width="$vuetify.display.mdAndUp ? '130' : '125'"
            elevation="0"
            @click="nextQuestion"
          >
            Next
            <template v-slot:append>
              <v-icon color="white">mdi-chevron-right</v-icon>
            </template>
          </v-btn>
          <v-btn
            v-else
            density="compact"
            size="large"
            icon="mdi-chevron-right"
            @click="nextQuestion"
            color="primaryPurple"
          >
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>

    <QuestionActionsComponent
      v-if="loadedFromServer"
      :test-type="testType"
      :q-id="currentQ.qId"
      :test-id="testId"
      :test-instance-id="testInstanceId"
      :q-obj="currentQ"
      ref="questionActions"
      :screenshotQuestionElement = "screenshotQuestionElement"
      :screenshotSolutionElement = "screenshotSolutionElement"
      :chatbotConfig="chatbotConfig"
      :chatbotAllowed = "chatbotAllowed"
    ></QuestionActionsComponent>
  </div>

  <!-- <ChatBotComponent :testId="testId" :testInstanceId="testInstanceId" ref="chatbot">
  </ChatBotComponent> -->
</template>

<script>
import { mapState, mapGetters } from "vuex";
import _ from "lodash";
import HtmlRender from "@/components/Test/HtmlRender.vue";
import {
  AnalyticsEvents,
  recordAnalyticsEvent,
} from "@/services/awsPinpointAnalyticsService";
import DragableFeedbackDialog from "@/components/Test/TestQuestionPageComponents/DragableFeedbackDialog.vue";
import VueResizable from "vue-resizable";
import { securePostAPI, securePostAPIWithoutOverlay } from "@/services/apiService";
import $ from "jquery";
import { v4 as uuidv4 } from "uuid";
import { getHeaders, getShowSolution, setShowSolution } from "@/services/authService";
import ChatBotTyping from "@/components/practice/ChatBotTyping.vue";
import ChatBotComponent from "@/components/practice/ChatBotComponent.vue";
import ScribbleComponent from "@/components/scribble/ScribbleComponent.vue";
import QuestionActionsComponent from '@/components/questionTools/QuestionActionsComponent.vue';
import html2canvas from "html2canvas";

const marked = require("marked");

const renderer = new marked.Renderer();
renderer.link = function link(href, title, text) {
  return `<a href="${href}" title="${title}" target="_blank">${text}</a>`;
};
export default {
  name: "TestResultPage",
  data: () => ({
    questionDialog: false,
    loadedFromServer: false,
    exitDialog: false,
    testId: "",
    testInstanceId: "",
    reportDialog: false,
    isFromReport: false,
    testType: "",
    // showTimeTaken: false,
    currentMode: "",
    conversation: [],
    disableChatbot: false,
    aiTokensUsedForBarColor: {
      green: "green",
      orange: "orange",
      red: "red",
    },
    aiTokensAllowed: 0,
    annotateDialog: false,
    initialMessages: [],
    botTyping: false,
    chatbot: false,
    aiTokensUsed: null,
    chatbotInputText: "",
    currentMessagesList: [],
    windowHeight: window.innerHeight,
    chatbotheight: null,
    annotatedText: "",
    imgExists: false,
    showFilter: false,
    showSolution: false,
    showScribbleImage: false,
    isOpenedFromTeacherView: false,
    canvasImage: null,
    screenshotElement:null,
    screenshotQuestionElement: {},
		screenshotSolutionElement: undefined,
    isOpenedFromParentView: false,
    questionActionsRef : null,
    chatbotConfig: {
      currentMode: "",
      chatbotMode: ""
    },
    chatbotAllowed: false
  }),
  components: {
    HtmlRender,
    DragableFeedbackDialog,
    // ChatBotComponent,
    ScribbleComponent,
    // ChatBotTyping,
    // VueResizable,
    // ChatBotComponent
    QuestionActionsComponent
  },
  async mounted() {
    this.questionActionsRef = this.$refs.questionActions;
    console.log("this.questionActionsRef", this.$refs.questionActions,  this.questionActionsRef);
    this.questionActionsRef?.$refs.chatbot.hideChatbot();

    if ("testId" in this.$route.params) {
      this.showSolution = getShowSolution() == "true";
      const test = this.$route.params.testId;
      const instance = this.$route.query.instance;
      this.testId = test;
      this.testInstanceId = instance;
      this.isFromReport = this.$route.query.report;
      let quiz = this.$route.query.quiz ?? false;
      console.log("quiz", quiz);
      if (this.quiz) {
        this.testType = "quizSolution";
      }
      const challenge = this.$route.query.challenge ?? false;
      console.log("challenge", challenge);
      if (challenge) {
        this.testType = "challengeSolution";
      }
      // this.showTimeTaken = (quiz == 'true') || (challenge == 'true');
      const mockTest = this.$route.query.mocktest ?? false;
      console.log("mockTest", mockTest);
      if (challenge) {
        this.testType = "mockTestSolution";
      }

      if (this.$route.query.teacher && this.isUserTeacher) {
        this.isOpenedFromTeacherView = true;
      }
      if(this.$route.query.parent && this.isUserParent){
        this.isOpenedFromParentView = true;
      }

      // recordAnalyticsEvent(AnalyticsEvents.v2TestInstanceViewSolutionsOpen, { testId: test, testInstanceId: instance });

      // console.log(this.$route);
      this.$store.commit("updateCurrentTestState", {
        sectionIndex: 0,
        qIndex: 0,
      });
      this.$store.commit("clearTestResult");
      this.$store.commit("testResultLoadedFromServer", false);
      this.$store
        .dispatch("loadTestResult", { testId: test, instance: instance })
        .then(async () => {
          this.loadedFromServer = true;

          setTimeout(() => {
            window.MathJax.typesetPromise();
          }, 10);

          this.currentMode = "practiceSolution";
          this.chatbotConfig = {
            currentMode: this.currentMode,
            chatbotMode: "testSolution_solution"
          }
          // if(!this.isDevEnvironment){
          //   this.chatbotAllowed = (this.currentSection.moduleSubjectType == "readingandwriting");
          // }
          this.chatbotAllowed = true;
          // await this.getNudgeInfoForCurrentQuestion()
          console.log("current sec", this.currentSection.moduleSubjectType);
          this.questionActionsRef?.$refs.chatbot?.setCurrentQuestion(this.currentQ);
          
          await this.questionActionsRef?.$refs.chatbot?.getNudgeInfoForCurrentQuestion(
            this.currentMode,
            "testSolution_solution"
          );
          this.questionActionsRef?.$refs.chatbot?.showChatbot();

          if (this.$vuetify.display.smAndDown) {
            this.questionActionsRef?.$refs.chatbot?.closeChatbot();
          } else {
            this.questionActionsRef?.$refs.chatbot?.openChatbot();
          }
          // if (this.currentSection.moduleSubjectType == "readingandwriting" || this.isDevEnvironment) {
          // } else {
          //   this.chatbotAllowed = false;
          //   this.questionActionsRef?.$refs.chatbot?.hideChatbot();
          // }
        });
    }
  },
  async created() {
    this.onResize();

    this.$store.commit("appbarVisible", false);
    this.$store.commit("navbarVisible", false);
    this.$store.commit("showNotification", false);
    if (window.document.getSelection) {
      document.addEventListener("selectionchange", (e) => {
        this.annotatedText = window.getSelection().toString().trim().replace(" ", "");
      });
    }
    //if(this.isDevEnvironment){
      const checkElement = async () => {
      this.screenshotQuestionElement = document.getElementById("mainQuestionScreen");
      if(this.showSolution){
        this.screenshotSolutionElement = document.getElementById("solutionSection");
      }
		
        if (this.screenshotQuestionElement) {
          if(this.showSolution && this.screenshotSolutionElement){
            this.chatbotAllowed = true;
            await this.getNudgeInfoForCurrentQuestion()
          }else if(!this.showSolution){
            this.chatbotAllowed = true;
            await this.getNudgeInfoForCurrentQuestion()
          }
        } else {
          setTimeout(checkElement, 100); // Retry after 100ms if element is not found
        }
      };

      checkElement();
    //}
  },
  computed: {
    ...mapState([
      "currentTestResult",
      "currentTestResultJson",
      "currentTestState",
      "newLearnNodeActivity",
      "currentActivityId",
      "currentTopicId",
      "config",
      "isUserTeacher",
      "isUserParent",
    ]),
    ...mapGetters([
      "isQuestionReviewer",
      "isLevel1Reviewer",
      "isLevel2Reviewer",
      "isLevel3Reviewer",
    ]),
    testInstanceQuestionSummary: {
      get() {
        return this.currentTestResult.testInstanceQuestionSummary;
      },
    },
    isDevEnvironment: {
      get(){
        return this.config.environment=="dev";
      }
    },
    currentQNo: {
      get() {
        return this.currentTestState.qIndex + 1;
      },
    },
    currentQ: {
      get() {
        if (this.currentTestResultJson.length == 0) {
          return [];
        }
        return this.currentTestResultJson[this.currentTestState.sectionIndex].questions[
          this.currentTestState.qIndex
        ];
      },
    },
    currentSection: {
      get() {
        return this.currentTestResultJson[this.currentTestState.sectionIndex];
      },
    },
    totalQuestionsWithNotesAttached: {
      get() {
        let cnt = 0;
        this.currentSection.questions.forEach((el) => {
          if (el.isTeacherNotesAttached == true) {
            cnt++;
          }
        });
        return cnt;
      },
    },
    prevButtonDisabled: {
      get() {
        return this.currentTestState.qIndex == 0 &&
          this.currentTestState.sectionIndex == 0
          ? true
          : false;
      },
    },
    correctAnswerDecimal: {
      get() {
        if (this.currentQ.answerDecimalHigh == this.currentQ.answerDecimalLow) {
          return this.currentQ.answerDecimalHigh;
        }
        return `${this.currentQ.answerDecimalHigh} - ${this.currentQ.answerDecimalLow}`;
      },
    },
    aiTokensUsedForBar: {
      get() {
        return (this.aiTokensUsed / this.aiTokensAllowed) * 100;
      },
    },
    userName: {
      get() {
        return this.$store.state.userState.name;
      },
    },
    aiTokensUsedForBarColorComputed: {
      get() {
        if (this.aiTokensUsedForBar > 75) {
          return this.aiTokensUsedForBarColor.red;
        } else if (this.aiTokensUsedForBar > 50) {
          return this.aiTokensUsedForBarColor.orange;
        } else {
          return this.aiTokensUsedForBarColor.green;
        }
      },
    },
    feedbackOrQuestionReiewIconName: {
      get() {
        var retVal = "mdi-message-alert-outline"; //"Report a Problem" for users/students
        if (this.isQuestionReviewer) {
          retVal = "mdi-shield-check-outline"; // for internal Question reviewers
        }
        return retVal;
      },
    },
    feedbackOrQuestionReiewIconTooltipText: {
      get() {
        var retVal = "Report a problem"; ////"Report a Problem" for users/students
        if (this.isQuestionReviewer) {
          retVal = "Review Feedback & Corrections"; // for internal Question reviewers
        }
        return retVal;
      },
    },
    answerResponseMessage: {
      get(){
        let response = {};
        if(this.isOpenedFromParentView){
          response.correctAnswerMsg = "Child's Answer - Correct";
          response.incorrectAnswerMsg = "Child's Answer - Incorrect";
        }
        else if(this.isOpenedFromTeacherView){
          response.correctAnswerMsg = "Student's Answer - Correct";
          response.incorrectAnswerMsg = "Student's Answer - Incorrect";
        }
        else{
          response.correctAnswerMsg = "Your Answer - Correct";
          response.incorrectAnswerMsg = "Your Answer - Incorrect";
        }
        return response;
      }
    },
  },

  watch: {
    showSolution: {
      handler(newVal){
        if(newVal){
          const checkElement = async () => {
            this.screenshotSolutionElement = document.getElementById("solutionSection");
            
            console.log("....................................",this.screenshotSolutionElement)
            if (!this.screenshotSolutionElement) {
              setTimeout(checkElement, 100); // Retry after 100ms if element is not found
            }
          };

          checkElement();
        }else{
          this.screenshotSolutionElement = undefined;
        }
      }
    },
  },

  methods: {
    addEditNotes() {
      try {
        this.$store.commit("increamentLoader");
        this.screenshotElement = document.getElementById("scribbleQuestionScreen");
				if(this.screenshotElement.offsetHeight < window.innerHeight){
					this.screenshotElement.style.height = `${window.innerHeight - 40}px`;
				}
        this.showScribbleImage = true;
        let event = {
          userType: this.isUserTeacher? 'teacher': 'student',
          isOpenedFromTeacherView: this.isOpenedFromTeacherView? true: false,
          buttonCurrentAction: this.isOpenedFromTeacherView? this.currentQ.isTeacherNotesAttached? 'edit': 'add': 'view',
          testInstanceId: this.testInstanceId,
          questionId: this.currentQ.qId, 
        }
        recordAnalyticsEvent(AnalyticsEvents.v2NotesButtonClicked, event);        
      } catch (error) {
        this.$store.commit("decreamentLoader");
        let event = {
          userType: this.isUserTeacher? 'teacher': 'student',
          isOpenedFromTeacherView: this.isOpenedFromTeacherView? true: false,
          buttonCurrentAction: this.isOpenedFromTeacherView? this.currentQ.isTeacherNotesAttached? 'edit': 'add': 'view',
          testInstanceId: this.testInstanceId,
          questionId: this.currentQ.qId, 
          error: true,
          errorMessage: error
        }
        recordAnalyticsEvent(AnalyticsEvents.v2NotesButtonClicked, event); 
      }
    },
    getCategoryName(id) {
      let data = "";
      let found = this.categoryList.find((e) => e.key == id);
      if (found) {
        data = found.label ?? "";
      }
      return data;
    },
    getTopicName(id) {
      let data = "";
      let found = this.topicList.find((e) => e.topicKey == id);
      if (found) {
        data = found.label ?? "";
      }
      return data;
    },
    feedbackAIMessage(val, index) {
      let previousVal = this.currentMessagesList[index].feedback ?? null;
      this.currentMessagesList[index].feedback = val;
      securePostAPI(
        {
          feedback: val,
          messageId: this.currentMessagesList[index].messageId,
        },
        `mentoapp/mentopracticenudge/feedback`,
        false
      ).then((res) => {
        console.log("feedback", res);
        if ("error" in res) {
          this.currentMessagesList[index].feedback = previousVal;
        }
      });
    },
    onResize() {
      console.log("resize");
      console.log("heighjt", (window.innerHeight * (45 / 100)) / window.devicePixelRatio);
      this.chatbotheight = (window.innerHeight * (45 / 100)) / window.devicePixelRatio;
    },
    async getNudgeInfoForCurrentQuestion() {
      if (!this.config.practiceChatBotEnabled) {
        return;
      }
      this.botTyping = true;

      this.conversation = [];
      this.imgExists = false;

      let headers = getHeaders();
      console.log("currentQuestion", this.currentQ);
      let q = _.cloneDeep(this.currentQ);
      q.questionTypeId = this.currentQ.qType;
      q.questionDesc = this.currentQ.qText;
      let msg = await securePostAPI(
        {
          qType: this.currentQ.qType,
          mentoHelpMode: this.currentMode,
          subjectId: "s_1685979510490",
          question: q,
          getNudge: true,
          getTokens: this.aiTokensUsed == null ? true : false,
          checkImage: true,
        },
        `mentoapp/mentopracticenudge/organization/${headers.organizationid}/user/${headers.userid}`,
        true
      );

      this.imgExists = msg.imgExists;

      if ("aiTokensUsed" in msg) {
        this.aiTokensUsed = msg.aiTokensUsed;
      }
      if ("aiTokensAllowed" in msg) {
        this.aiTokensAllowed = msg.aiTokensAllowed;
      }
      console.log("play");
      console.log(msg);
      this.currentMessagesList = msg.messages;
      this.initialMessages = msg.messages;

      this.botTyping = false;
    },

    async chatbotButtonClicked(info) {
      console.log(info);
      this.scrollChatBotToBottom();
      if (info.tag != "customText") {
        if (info.tag == "annotate") {
          if (this.annotate().replace(" ", "").length == 0) {
            this.annotateDialog = true;
            return;
          }

          this.currentMessagesList.push({
            message: info.text + ` : <b>'${this.annotate()}'</b>`,
            type: "user",
            messageId: uuidv4(),
          });
        } else {
          this.currentMessagesList.push({
            message: info.text,
            type: "user",
            messageId: uuidv4(),
          });
        }
      }

      this.botTyping = true;
      let userPrompt = info.userPrompt ?? "";
      if (info.tag == "optionExplanation") {
        userPrompt = info.value;
      }

      console.log("currentQuestion", this.currentQ);
      let q = _.cloneDeep(this.currentQ);
      q.questionTypeId = this.currentQ.qType;
      q.questionDesc = this.currentQ.qText;
      q.subjectId = "s_1685979510490";

      let res = await securePostAPIWithoutOverlay(
        {
          question: q,
          tag: info.tag,
          session: {
            conversation: this.conversation,
          },
          userPrompt: info.tag == "annotate" ? this.annotate() : userPrompt,
          testId: this.testId,
          testInstanceId: this.testInstanceId,
          userNudgeText: info.text,
          systemTag: this.currentMode,
          subjectId: "s_1685979510490",
          imgExists: this.imgExists,
        },
        info.handlerUrl
      );
      console.log(res);

      if (!("error" in res)) {
        this.currentMessagesList.push(...res.messages);
        console.log(this.currentMessagesList);

        if ("aiTokensUsed" in res) {
          this.aiTokensUsed = res.aiTokensUsed;
        }

        if (this.aiTokensUsed >= this.aiTokensAllowed) {
          this.disableChatbot = true;
        }
        // this.disableChatbot = true

        if (res.type == "aiframework") {
          this.conversation = res.conversation;
        }
        // this.notificationSound.play()
      }

      if (info.tag == "crossout") {
        console.log(res);
        this.currentQResponse.crossedOutOptions = true;

        this.updateCrossedOptions(res.aiCrossedOutOption, true);
      }
      this.botTyping = false;
      this.scrollChatBotToBottom();
    },
    getButtonMessage(currentMessage) {
      if (this.currentMessagesList.length > 0) {
        let firstMessage = {};
        if (currentMessage == null) {
          firstMessage = this.currentMessagesList[0];
        } else {
          firstMessage = currentMessage;
        }

        console.log(firstMessage);
        if (
          firstMessage.type == "nudge" &&
          "responseCard" in firstMessage &&
          firstMessage.responseCard != null &&
          "genericAttachments" in firstMessage.responseCard
        ) {
          console.log(firstMessage.responseCard.genericAttachments[0].buttons);
          return firstMessage.responseCard.genericAttachments[0].buttons;
        }
      }

      return [];
      // currentMessagesList[0].type == 'nudge' && currentMessagesList[0].responseCard != null
    },
    annotate() {
      if (window.document.getSelection) {
        let selected = window.document.getSelection().toString();
        return selected;
      }
      return "";
    },
    chatButtonDisabled(info) {
      // if (info.tag == 'annotate') {
      // 	if (this.annotatedText.length <= 0) {
      // 		return true
      // 	}
      // }

      return false;
    },
    async chatbotSendHandler() {
      if (this.chatbotInputText.trim().replace(" ", "").length == 0) {
        return "";
      }
      let val = this.chatbotInputText;

      this.currentMessagesList.push({ message: val, type: "user", messageId: uuidv4() });
      this.chatbotInputText = "";

      this.chatbotButtonClicked({
        tag: "customText",
        userPrompt: val,
        text: val,
        handlerUrl: "mentoapp/mentopracticenudge/aimessage/customtext",
      });
    },
    scrollChatBotToBottom() {
      try {
        $(".msg_container_base")
          .stop()
          .animate({ scrollTop: $(".msg_container_base")[0].scrollHeight }, 1000);
      } catch (error) {
        console.log(error);
      }
    },
    openChatbot() {
      this.chatbot = true;
      setTimeout(() => {
        try {
          $(".msg_container_base")
            .stop()
            .animate({ scrollTop: $(".msg_container_base")[0].scrollHeight }, 0);
        } catch (error) {
          console.log(error);
        }
      }, 1);
    },
    showReportDialog() {
      // recordAnalyticsEvent(AnalyticsEvents.v2TestSectionInstructionsDuringTest, {
      //   testId: this.currentTestId,
      //   testName: this.startTestInfo.testmetadata.testName,
      //   testInstanceId: this.startTestInfo.testInstanceId,
      //   sectionId: this.currentSection.sectionId,

      // });
      this.reportDialog = true;
    },
    correctAnswerSingleChoice(item, key) {
      if (item.answerSingleChoice == null) {
        return false;
      }
      if (item.answerSingleChoice == key) {
        return true;
      }
      return false;
    },
    userAnswerSingleChoice(item, key) {
      if (item.userAnswerSingleChoice == null) {
        return false;
      }
      if (item.userAnswerSingleChoice == key) {
        return true;
      }
      return false;
    },
    correctAnswerMultiChoice(item, key) {
      if (item.answers == null) {
        return false;
      }
      if (item.answers.includes(key)) {
        return true;
      }
      return false;
    },
    userAnswerMultipleChoice(item, key) {
      if (item.userAnswers == null) {
        return false;
      }
      if (item.userAnswers.includes(key)) {
        return true;
      }
      return false;
    },

    async openQFromDialog(sIndex, qIndex) {
      this.$store.commit("updateCurrentTestState", {
        qIndex: qIndex,
        sectionIndex: sIndex,
      });

      this.questionDialog = false;

      setTimeout(() => {
        window.MathJax.typesetPromise();
      }, 10);
      //this.chatbotAllowed = (this.currentSection.moduleSubjectType == "readingandwriting" || this.isDevEnvironment);
      this.chatbotAllowed = true;
      await this.$refs.questionActions.getNudgeInfo(this.currentQ);
    },
    getMarkDownHtml(markdown) {
      if (markdown == null) {
        return "";
      }
      let markDownText = markdown;
      let out = false;
      const regex = /\\/gm;
      const escapedString = markDownText.replace(regex, "\\\\");

      out = marked.parseInline(escapedString, { renderer });

      return out;
    },
    async nextQuestion() {
      if (
        this.currentTestState.qIndex ==
        this.currentTestResultJson[this.currentTestState.sectionIndex].questions.length -
          1
      ) {
        if (this.currentTestState.sectionIndex == this.currentTestResultJson.length - 1) {
          // console.log("review");
          // this.reviewScreen = true
          this.backFromDialog();
          return;
        } else {
          this.$store.commit("updateCurrentTestState", {
            qIndex: 0,
            sectionIndex: this.currentTestState.sectionIndex + 1,
          });
        }
      } else {
        this.$store.commit("updateCurrentTestState", {
          qIndex: this.currentTestState.qIndex + 1,
          sectionIndex: this.currentTestState.sectionIndex,
        });
      }

      setTimeout(() => {
        window.MathJax.typesetPromise();
      }, 10);
      console.log(this.currentQ);
      //this.chatbotAllowed = (this.currentSection.moduleSubjectType == "readingandwriting" || this.isDevEnvironment);
      this.chatbotAllowed = true;
      await this.$refs.questionActions.getNudgeInfo(this.currentQ);
    },
    async prevQuestion() {
      if (this.currentTestState.qIndex == 0) {
        if (this.currentTestState.sectionIndex == 0) {
          console.log("first section");
        } else {
          this.$store.commit("updateCurrentTestState", {
            qIndex:
              this.currentTestResultJson[this.currentTestState.sectionIndex - 1].questions
                .length - 1,
            sectionIndex: this.currentTestState.sectionIndex - 1,
          });
        }
      } else {
        // changes ui to next question
        this.$store.commit("updateCurrentTestState", {
          qIndex: this.currentTestState.qIndex - 1,
          sectionIndex: this.currentTestState.sectionIndex,
        });
      }

      setTimeout(() => {
        window.MathJax.typesetPromise();
      }, 10);
      //this.chatbotAllowed = (this.currentSection.moduleSubjectType == "readingandwriting" || this.isDevEnvironment);
      this.chatbotAllowed = true;
      await this.$refs.questionActions.getNudgeInfo(this.currentQ);
    },
    backFromDialog() {
      let currentActivity = {};
      if (this.newLearnNodeActivity && this.newLearnNodeActivity.skillUpgradeQuiz) {
        this.newLearnNodeActivity.skillUpgradeQuiz.forEach((item) => {
          if (item["activityId"] == this.currentActivityId) {
            currentActivity = item;
          }
        });
      }
      let event = {
        topicId: this.currentTopicId,
        topicName: this.newLearnNodeActivity.topicName,
        currentSkill: this.newLearnNodeActivity.skill,
        isReady: true,
        activityId: this.currentActivityId,
        activityType: "skillUpgradeQuiz",
        attempted: currentActivity.attempted,
        recommended: currentActivity.recommended,
        testId: this.testId,
        testInstanceId: this.testInstanceId,
      };
      if (this.isFromReport) {
        recordAnalyticsEvent(
          AnalyticsEvents.v2LearnTopicQuizReportViewSolutionClosedAfterSubmit,
          event
        );
      } else {
        recordAnalyticsEvent(
          AnalyticsEvents.v2LearnTopicQuizAttemptReportViewSolutionClosed,
          event
        );
      }
      this.exitDialog = false;
      this.$router.back();
    },
    getInitials(fullName) {
      const allNames = fullName.trim().split(" ");
      const initials = allNames.reduce((acc, curr, index) => {
        if (index === 0 || index === allNames.length - 1) {
          acc = `${acc}${curr.charAt(0).toUpperCase()}`;
        }
        return acc;
      }, "");
      return initials[0];
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },
    toggleSolution(item) {
      if (item && this.currentSection.moduleSubjectType == "math") {
        setTimeout(() => {
          window.MathJax.typesetPromise();
        }, 10);
      }
      setShowSolution(item);
    },
    closeNotesDialog() {
      this.screenshotElement.style.height = 'fit-content';
      let event= {
        testInstanceId: this.testInstanceId,
        questionId: this.currentQ.qId,
      };
      recordAnalyticsEvent(AnalyticsEvents.v2NotesClosed, event);  
      this.showScribbleImage = false;
    },
    upateTeacherNote() {
      this.currentTestResultJson[this.currentTestState.sectionIndex].questions[
        this.currentTestState.qIndex
      ].isTeacherNotesAttached = true;
    },
    showScribble() {
      if (this.isOpenedFromParentView) {
        return false;
      }
      if (this.isOpenedFromTeacherView) {
        return true;
      } else {
        return this.currentQ.isTeacherNotesAttached;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.correct {
  background: #26b99aa6 !important;
  color: white !important;
}

.success {
  background: #26b99aa6 !important;
}

.timer-color {
  color: #26b99aa6 !important;
}

.unanswered {
  border: 2px solid #949494 !important;
}

.marked {
  border: 2.5px solid #ff894f !important;
}

.option {
  /* box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important; */
  border-radius: 10px;
  border: 1px rgb(var(--v-theme-secondaryBackground)) solid;
}

.v-sheet.on-hover {
  cursor: pointer;
  /* border: 1px solid rgb(var(--v-theme-primary)); */
  border-color: transparent;
  background-color: white;
  box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;
}

.green-border {
  border: 2.5px solid green !important;
}

.red-border {
  border: 2.5px solid #ff8a80 !important;
}

.incorrect {
  background: #ff8a80 !important;
  color: white !important;
}

.btn-grad {
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%);
  border-radius: 29.4079px;
}

.btn-prev {
  border-radius: 29.4079px;
}

.bgWhite {
  background-color: white !important;
}

.unattempted {
  border: 2px solid #949494 !important;
}

.notesAttached {
  position: absolute;
  top: -2px;
  right: 0;
}
.notesAttached2 {
  position: absolute;
  top: 0;
  right: 14px;
}

.makePositionRelative {
  position: relative;
}

.bx {
  background-color: rgb(var(--v-theme-navColor));
  border: 1px solid #e7e7e7;
  border-radius: 4px;
}

.card-bg-color {
  background-color: rgb(var(--v-theme-navColor)) !important;
  border: 0.5px solid rgba(0, 0, 0, 0.15) !important;
  border-radius: 12px !important;
}

.outline-color {
  border-color: rgb(var(--v-theme-secondaryBackground)) !important;
}

.bottom {
  position: absolute !important;
  bottom: 10px !important;
}

.w-70 {
  max-width: 70%;
}

.v-overlay__content {
  position: absolute !important;
  bottom: 10px !important;
}

.whiteBackground {
  background-color: white !important;
}

.bgW {
  height: 100%;
  width: 100%;
  background-color: white;
}

.scroll {
}

.dialog-bx {
  position: fixed !important;
}

.htmlClassTestOption span {
  top: 0 !important;
  vertical-align: middle;
}

.htmlClassTest img {
  top: 0 !important;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

.icon-opacity {
  opacity: 0.4;
  /* color: #A7A6B0; */
}

.mw {
  max-width: 150px;
}

.qNoClass {
  font-size: 0.875rem;
  font-family: "Inter", sans-serif !important;
}

/* chatbot css */

.v-textarea input::placeholder {
  color: red;
  /* Your desired color */
}

.btn-rd {
  border-radius: 100px !important;
  border: 1.5px solid #966dec;
  background: #fff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.12);
}

.bot-msg {
  background: rgba(230, 228, 233, 0.46);
}

.user-msg {
  /* background-color: rgb(var(--v-theme-primary)) !important;
	border-radius: 30px; */
}

.chatbot-card {
  border-radius: 12px;
  border-color: transparent;
  box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;
}

.chatbot-bg {
  background-color: white !important;
  overflow-y: auto;
  /* -ms-overflow-style: none; */
  /* IE and Edge */
  /* scrollbar-width: none; */
  /* Firefox */
  overflow-x: hidden;
}

.chatbot-bg::-webkit-scrollbar {
  /* display: none; */
}

.chatbot-bg::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.chatbot-bg::-webkit-scrollbar-track {
  background-color: #d3d3d3;
}

/* Handle */
.chatbot-bg::-webkit-scrollbar-thumb {
  background-color: rgb(var(--v-theme-breadCrumbBackColor)) !important;
  cursor: pointer;
}

/* Handle on hover */
.chatbot-bg::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
}

.dround {
  border-radius: 10px !important;
}

.chatbotText {
  color: rgba(0, 0, 0, 0.8);
  font-family: Inter;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
}

/* Hide scrollbar for IE, Edge and Firefox */

.chatbot {
  position: fixed;
  right: 30px;
  bottom: 10%;
  z-index: 1005;
}

.chatbot-box {
  position: fixed;
  right: 20px;
  bottom: 10%;
  z-index: 1005;
}

.chatbot-header {
  background-color: rgb(var(--v-theme-breadCrumbBackColor)) !important;
}

.chatbot-bottom {
  background-color: rgb(var(--v-theme-primaryPurple)) !important;
}

.chatbotsendbuttondisabled {
  pointer-events: none;
}

.dialog-rounded .overlay-scrim {
  border-radius: 10px !important;
}

.noClick {
  pointer-events: none;
}

.w450px {
  width: 450px;
}

.w240px {
  width: 370px;
}

.slide-fade-enter-active {
  transition: all 0.3s linear;
}

.slide-fade-leave-active {
  transition: all 0.3s linear;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translatey(100px);
  opacity: 0;
}

.mento {
  color: #fefefe;
  font-family: Prompt;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  /* 24px */
}

.mind {
  color: #a174fd;
  font-family: Prompt;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  /* 24px */
  letter-spacing: 0.48px;
}

.testCard {
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  background: rgb(var(--v-theme-cardBackgroundColor));
  cursor: pointer;
  border-radius: 0 !important;
}

.filter-switch {
  width: 160px !important;
}

.selected {
  cursor: pointer;
  /* border: 1px solid rgb(var(--v-theme-primary)); */
  border-color: transparent;
  background-color: rgb(var(--v-theme-primaryPurple)) !important;
  color: white !important;
}

.outline-color-grey {
  border-color: #010101b3 !important;
  border: 1px solid;
  color: #010101b3 !important;
}

.outline-color-white {
  border-color: white !important;
  background-color: white;
}

.text-primaryPurple {
  color: rgb(var(--v-theme-primaryPurple)) !important;
}
</style>
<style>
.htmlClassTestOption span {
  top: 0 !important;
  vertical-align: middle;
}

.htmlClassTest img {
  top: 0 !important;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

.htmlClassTest span {
  top: 0 !important;
  vertical-align: middle;
}

.testOption .v-selection-control--inline {
  min-width: 100% !important;
}

.testOption .v-label {
  width: 100% !important;
  opacity: 1 !important;
}

.v-label {
  opacity: 1 !important;
}

.custom-radio{
  margin: 0;
  padding: 0;
  width: 100%;
}

.custom-radio .v-selection-control__wrapper {
  display: none;
}

.inputText {
  color: black !important;

  /* 114.286% */
}

.dialog-rounded .v-overlay__scrim {
  border-radius: 10px !important;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
