<template>
        <v-dialog style="backdrop-filter: blur(12.5px)" persistent no-click-animation :model-value="dialog" width="600"
        :height="$vuetify.display.xs ? '100%' : '60%'" :fullscreen="$vuetify.display.xs">
        <v-card elevation="0" class="assignment-form text-white">
            <v-toolbar class="assignment-form-title text-white">
                <v-toolbar-title>
                    <v-row class="">
                        <v-col cols="auto" class="d-flex align-center">
                            <span class="t-h5Reg" :class="{
                                    't-h125Reg': !xs
                                }">{{ formTitle }}</span>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="auto">
                            <v-icon @click.stop="close" class="mr-4"> mdi-close</v-icon>
                        </v-col>
                    </v-row>
                </v-toolbar-title>
            </v-toolbar>
            <v-divider class="mb-2" :thickness="2" color="background"></v-divider>
            <v-card-text class="pt-4 px-6 assignment-form-main">
                <v-form ref="form" v-model="formValue">
                    <v-row dense>
                        <v-col cols="12">
                            <v-select class="filter-box" base-color="#ffffff" color="#ffffff" item-color="black" flat density="compact"
                                :items="paidPackageList" v-model="selectedPackages" label="Licenses" item-title="packageName"
                                item-value="packageId" multiple variant="outlined" :rules="[(v) => (v.length>0) ||  'Please select atleast one license']" prepend-inner-icon="mdi-license"
                                >
                                <template v-slot:prepend-item>
                                <v-list-item title="All" @click="togglePackageList">
                                    <template v-slot:prepend>
                                    <v-checkbox-btn :indeterminate="selectedPackages.length > 0 && !(selectedPackages.length == paidPackageList.length)" :model-value="selectedPackages.length == paidPackageList.length"></v-checkbox-btn>
                                    </template>
                                </v-list-item>

                                <v-divider class="mt-2"></v-divider>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                <span v-if="selectedPackages.length == paidPackageList.length &&
                                    index == 0
                                    ">All</span>
                                <span v-else-if="selectedPackages.length > 1 && index == 0">
                                    +{{ selectedPackages.length }}
                                </span>
                                <span v-else-if="index == 0">
                                    {{ item.title }}
                                </span>
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row v-for="(index, packageId) in editedItem.packages" :key="packageId" justify="space-between" align="start" class="ma-0 pa-0">
                        <v-col cols="10" sm="11" class="ma-0 pa-0 pa-2">
                            <v-row class="ma-0 pa-0" align="start" justify="space-between">
                                <v-col cols="12" sm="8" class="ma-0 pa-0" :class="{
                                    'mr-0': xs
                                }">
                                    {{ getPackageName(packageId) }}
                                </v-col>
                                <v-col cols="12" sm="3" class="ma-0 pa-0" :class="{
                                    'mt-2': xs
                                }">
                                    <v-text-field v-model="editedItem.packages[packageId].packageCount" label="No. of Licenses*" density="compact" variant="outlined" dense
                                    type="number" :rules="requiredRule"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="auto" class="ma-0 pa-0 pt-2">
                            <v-icon
                                small
                                @click.stop="removePackage(packageId)"
                                color="white"
                            >
                            mdi-delete
                            </v-icon>
                        </v-col>
                    </v-row>

                    
                </v-form>
            </v-card-text>
            <v-divider :thickness="2" color="background"></v-divider>
            <v-card-actions class="px-6 my-2">
                <v-spacer></v-spacer>
                <v-btn class="cancel-btn t-btn text-capitalize text-primaryTextColor mr-2" density="default" variant="flat"
                height="40" @click.stop="close">Cancel</v-btn>
                <v-btn class="continue-btn t-btn text-capitalize text-primaryTextColor ml-2" density="default"
                variant="flat" height="40" @click.stop="purchasePackage">Continue to purchase</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
        
</template>
    
<script>

import _, { cloneDeep, forEach, isEmpty } from "lodash";
import { useDisplay } from "vuetify";
import { mapState } from "vuex";
import {
  AnalyticsEvents,
  recordAnalyticsEvent,
} from "@/services/awsPinpointAnalyticsService";

export default {
    name: "AdminLicenseDialogComponent",
    setup() {
    // Destructure only the keys we want to use
        const { xs, smAndUp, mdAndUp } = useDisplay();

        return { xs, smAndUp, mdAndUp };
    },
    data: () => ({
        dialog: true,
        defaultItem: {
            packages: {}
        },
        editedItem: {},
        formValue: false,
        requiredRule: [(v) => !!v || "Required"],
        selectedPackages : []
    }),
    props: ["licenseType"],
    emits: ['close'],
    components: {},
    async created() {
        recordAnalyticsEvent(AnalyticsEvents.v2AdminLicenseDialogOpen, {
            customerId: this.courseId
        });
        this.editedItem = cloneDeep(this.defaultItem);
    },
    computed: {
        ...mapState(['courseDetails', 'organizationDetails', 'courseId', 'packageList']),
        formTitle() {
          return this.licenseType === "students" ? "Buy Licenses for Students"  : "Buy Licenses for Tutor" ;
        },
        paidPackageList: {
            get() {
                let list = (this.licenseType === "students" ? _.cloneDeep(this.$store.state.packageList.studentPackages) : _.cloneDeep(this.$store.state.packageList.tutorPackages)) ?? [];
                list = list.filter((e)=> e.packageType == 'paid' && e.subscriptionType == 'onetime');
                list = _.orderBy(list, [license => license?.packageName?.toLowerCase()], "asc");
                return list;
            }
        },
    },

    watch: {
        selectedPackages:{
            handler(val){
                const packages = {};
                forEach(val, packageId =>{
                    packages[[packageId]] =
                    isEmpty(this.editedItem.packages[[packageId]])?
                    {
                        packageId: packageId,
                        packageCount: 10
                    }: this.editedItem.packages[[packageId]];
                })
                this.editedItem.packages= packages;
            },
            deep: true,
            immediate: true
        }
    },

    methods: {
        close(){
            recordAnalyticsEvent(AnalyticsEvents.v2AdminLicenseDialogClose, {
                customerId: this.courseId
            });
            this.$emit("close");
        },
        togglePackageList() {
            if (this.selectedPackages.length == this.paidPackageList.length) {
                this.selectedPackages = [];
            } else {
                this.selectedPackages = this.paidPackageList
                .slice()
                .map((e) => e.packageId);
            }
        },
        getPackageName(packageId) {
            if (packageId == "") {
                return "-";
            }
            for (let x of this.paidPackageList) {
                if (packageId == x.packageId) {
                return x.packageName;
                }
            }
            return "-";
        },
        removePackage(packageId){
            const index = this.selectedPackages.indexOf(packageId);
            if (index !== -1) { // Check if the element exists in the array
                this.selectedPackages.splice(index, 1);
            }
        },
        async purchasePackage() {
            const { valid } = await this.$refs.form.validate();
            if (valid) {
                // Add pinpoint
                const reqData = {
                    licenseType: this.licenseType,
                    route: this.$route.path,
                    packages: []
                };
                forEach(this.editedItem.packages, (packageData, key) => {
                    reqData['packages'].push(packageData)
                });
                recordAnalyticsEvent(AnalyticsEvents.v2AdminLicenseDialogContiueButtonClicked, {
                    customerId: this.courseId,
                    licenseType: this.licenseType,
                    products: JSON.stringify(reqData['packages'])
                });
                const response = await this.$store.dispatch("createLicenseCheckout", reqData);
                if (response["url"]) {
                    window.open(response["url"], "_self");
                }
            }
        },
    },
};
</script>
    
<style scoped>

.main-heading {
  font-size: 1.25rem;
  color: rgb(var(--v-theme-white));
}

.assignment-card {
  background: rgb(var(--v-theme-report-subCardBg));
  border-radius: 12px !important;
}

.assignment-form {
  border-radius: 12px !important;
  border: 2px solid rgba(255, 255, 255, 0.22);
  background-color: rgb(var(--v-theme-reportBgColor));
  height: 100%;
}

.assignment-form-title {
  background: rgb(var(--v-theme-breadCrumbBackColor));
}

.assignment-btn {
  border-radius: 12px !important;
  /* border: 2px solid rgb(var(--v-theme-primaryGrey)); */
  background: rgb(var(--v-theme-report-subCardBg));
  /* color: rgb(var(--v-theme-primaryGrey)); */
  /* background: transparent; */
  /* padding: 10px 15px !important; */
  cursor: pointer;
}

.pointer-cursor {
  cursor: pointer;
}

.cancel-btn {
  border: 2px solid rgba(255, 255, 255, 0.22);
  color: white !important;
  background: rgb(var(--v-theme-cardBackgroundColor));
  border-radius: 29.4079px;
  width: 100px;
}

.more-btn {
  border: 2px solid rgba(255, 255, 255, 0.22);
  color: white !important;
  background: rgb(var(--v-theme-cardBackgroundColor));
}

.continue-btn {
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%) !important;
  border-radius: 29.4079px;
  width: 200px;
}

.btn-border{
  border-radius: 29.4079px !important;
}


.assignment-form-main {
  overflow: auto !important;
  /* scrollbar-color: rgb(var(--v-theme-breadCrumbBackColor)) #d3d3d3;
  scrollbar-width: thin !important; */
}

.assignment-form-main::-webkit-scrollbar {
  /* display: none; */
  width: 6px;
  border-radius: 10px !important;
  height: 8px !important;
}

/* Track */
.assignment-form-main::-webkit-scrollbar-track {
  background: rgb(var(--v-theme-report-subCardBg));
  border-radius: 10px !important;
  height: 8px !important;
}

/* Handle */
.assignment-form-main::-webkit-scrollbar-thumb {
  /* background: rgb(var(--v-theme-)); */
  background-color: rgba(156, 156, 156, 0.4);

  border-radius: 20px !important;

  cursor: pointer;
  height: 8px !important;
}

/* Handle on hover */
.assignment-form-main::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
}
</style>  