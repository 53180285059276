<template>
    <v-container v-if="pageLoaded" fluid class="bg-transparent">
        <v-row class="mb-4" align="center" justify="space-between">
            <v-col cols="auto" md="6">
                <span class="text-white t-h125">Get your AI Insights</span>
            </v-col>

            <v-col cols="12" sm="auto" class="py-0 ma-0">
                <v-sheet class="toggler-outer-bg text-primaryTextColor pa-1 px-0 px-1 w-100 d-flex"
                    ref="LearnPageScreen--subjectToggleBtn">

                    <v-btn v-for="tab in subjectsList" :key="tab.value" @click="changeSubjectType(tab.value)"
                        :value="tab.value" class="py-2 px-3 flex-grow-1"
                        :class="[tab.value == subjectType ? 'active-button' : 'normal-button']"
                        :variant="tab.value == subjectType ? 'elevated' : 'plain'" elevation="0">
                        <span class="text-none button-text t-h6Reg">{{ tab.name }}</span>
                    </v-btn>

                </v-sheet>
            </v-col>

        </v-row>

        <v-row no-gutters v-if="aiInsights.length > 0" :justify="mdAndDown ? 'center' : 'space-between'">

            <v-sheet class="bg-transparent mb-4 w-100">
                <template v-for="category in subjectData.categories" :key="category.categoryId">

                    <v-sheet class="bg-category pa-3 mb-4 w-100 aiBox">
                        <v-row justify="space-between" class="ma-0 pa-3 pt-1">
                            <v-col cols="auto" class="pt-2 pa-0">
                                <span class="t-1rem text-primaryTextColor">{{ category.categoryName
                                    }}</span>
                            </v-col>
                            <v-col cols="auto" class="pt-2 pa-0">
                                <v-btn @click="resetInsights(category.categoryId)" prepend-icon="mdi-replay"
                                    class="t-btn text-white activity-btn text-none px-4" density="default"
                                    variant="flat" :class="{ 'disabledOpacity': disableInsights }">
                                    <template v-slot:prepend>
                                        <v-icon color="white"></v-icon>
                                    </template>
                                    Get AI Insights
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-sheet class="align-center justify-center bg-transparent">
                            <v-row v-for="(item, i) in category.topics" :key="i"
                                class="ma-1 pa-2 bg-transparent width-adjustment">
                                <v-col cols="12">

                                    <v-card variant="flat" class="topicCard">
                                        <v-card-text class="">

                                            <v-row dense class="t-h6 text-white topicbg ma-n4 pa-3"
                                                justify="space-between">
                                                <v-col cols="auto">
                                                    {{ item.topicName }}
                                                </v-col>
                                                <v-col cols="auto" v-if="item.insertedAt != null">
                                                    {{ getDate(item.insertedAt) }}
                                                </v-col>
                                            </v-row>

                                            <div v-if="item.aiInsights != null" class="mt-8">
                                                <span class=" htmlAI " v-html="item.html">
                                                </span>
                                                <span v-if="item.topicId == currentTopicStreamed">
                                                    <v-icon class="ic" size="17"
                                                        color="primaryPurple">mdi-circle</v-icon>
                                                </span>
                                            </div>
                                            <div v-else class=" htmlAI mt-8">
                                                No Insights
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-sheet>
                    </v-sheet>


                </template>
            </v-sheet>
        </v-row>


    </v-container>
</template>

<script>
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
import { checkSubscription } from "@/services/subscriptionService";

import { mapGetters, mapState } from 'vuex';
import { useDisplay } from 'vuetify';
import _ from "lodash";
import { updateHighlightedElements } from "@/shared/selfHelpGuide/updateHighlightedElement";
import SelfHelpGuideTogglerComponent from "@/components/selfHelpGuide/SelfHelpGuideTogglerComponent.vue";
import InfoComponentLearnPage from "@/components/infoComponent/InfoComponentLearnPage.vue";
import axios from "axios";
import {
    getLoggedInUserCurrentDomain,
    getLoggedInUserId,
    getLoggedInUserOrganization,
} from "@/services/authService";
import { securePostAPI } from "@/services/apiService";
import { v4 as uuidv4 } from "uuid";
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

import moment from "moment-timezone";


const marked = require("marked");

const renderer = new marked.Renderer();
renderer.link = function link(href, title, text) {
    return `<a href="${href}" title="${title}" target="_blank">${text}</a>`;
};
TimeAgo.addDefaultLocale(en)
const timeAgo = new TimeAgo('en-US')
export default {
    name: "AIInsights",
    components: {},
    setup() {
        // Destructure only the keys we want to use
        const { xs, smAndUp, smAndDown, mdAndDown, mdAndUp, lgAndUp, lg, xl, xxl } = useDisplay();
        return { xs, smAndUp, smAndDown, mdAndDown, mdAndUp, lgAndUp, lg, xl, xxl };
    },
    async created() {
        this.pageLoaded = false;
        let userId = getLoggedInUserId()
        if (this.$route.fullPath.startsWith('/teacher')) {
            userId = this.studentId
        }
        let apiPath = `mentoapp/aihub/getAIInsights`;
        this.aiInsights = await securePostAPI({ userId: userId }, apiPath, true, false)
        console.log(this.aiInsights);
        this.aiInsights = this.aiInsights.map((subjectData) => ({
            ...subjectData,
            categories: subjectData.categories.map((category) => ({
                ...category,
                topics: category.topics.map((item) => ({
                    ...item,
                    html: item.aiInsights != null ? this.getMarkDownHtml(item.aiInsights) : null,
                })),
            })),
        }));
        setTimeout(() => {
            window.MathJax.typesetPromise();
        }, 1000);
        // if (localStorage.getItem("newUser") == "true" && localStorage.getItem("newLearn") == "true") {
        //   this.showInfoTab = true;
        // }
        this.pageLoaded = true;
    },
    mounted() {
        this.$store.commit("updateCurrentScreenName", this.currentScreenName);
    },
    unmounted() {
        this.$store.commit("updateCurrentScreenName", null);
    },
    data: () => ({
        currentScreenName: 'AIInsights',
        pageLoaded: false,
        searchText: "",
        filterSearchVal: "",
        showInfoTab: false,
        disableInsights: false,
        subjectType: 'readingandwriting',
        subjectsList: [
            {
                name: 'Reading and Writing',
                value: 'readingandwriting'
            },
            {
                name: 'Math',
                value: 'math'
            },
        ],
        aiInsights: [],
        currentTopicStreamed: null,
        currentTopicStreamedObject: null,
        currentCategoryStreamed: null,
    }),
    props: ["studentId"],

    computed: {
        ...mapState(["userCurrentDomain",]),
        ...mapGetters(['currentHighlightedElements', "showSelfHelpGuideFeature"]),
        computedInsights: {
            get() {
                console.log("ccc");

                let x = this.aiInsights.map((subjectData) => ({
                    ...subjectData,
                    categories: subjectData.categories.map((category) => ({
                        ...category,
                        topics: category.topics.map((item) => ({
                            ...item,
                            html: item.aiInsights != null ? this.getMarkDownHtml(item.aiInsights) : null,
                        })),
                    })),
                }));
                console.log(x);

                return x
            }
        },
        computedTopicStreamed: {
            get() {
                if (this.currentTopicStreamed != null) {
                    let item = _.filter(this.subjectData.categories, (item) => {
                        return item.categoryId == this.currentCateogryStreamed;
                    })
                    return item[0];
                }
                return ''
            }
        },
        subjectData: {
            get() {
                console.log("subtype", this.subjectType);

                let item = _.filter(this.aiInsights, (item) => {
                    return item.subjectType == this.subjectType;
                })
                return item[0];
            }
        },
        computedCategoryStreaming: {
            get() {
                console.log("this.currentCateogryStreamed");

                let item = _.filter(this.subjectData.categories, (item) => {
                    return item.categoryId == this.currentCategoryStreamed;
                })
                return item[0];
            }
        }
    },
    methods: {
        async resetInsights(categoryId) {
            this.disableInsights = true
            this.currentCategoryStreamed = categoryId
            console.log("xx", this.computedCategoryStreaming.topics);

            this.currentTopicStreamedObject = this.computedCategoryStreaming.topics[0]
            this.currentTopicStreamed = this.currentTopicStreamedObject.topicId
            this.currentTopicStreamedObject.aiInsights = ''
            this.currentTopicStreamedObject.html = ''
            let stuentUserId = getLoggedInUserId()
            if (this.$route.fullPath.startsWith('/teacher')) {
                stuentUserId = this.studentId
            }
            let aiInput = {
                userId: getLoggedInUserId(),
                organizationId: getLoggedInUserOrganization(),
                categoryId: categoryId,
                sessionId: uuidv4(),
                stuentUserId: stuentUserId
            }
            const response = await axios.post(
                "https://aifunctions.devtest.thestudypod.com/aiinsights",
                aiInput,
                {
                    responseType: "stream",
                    adapter: "fetch",
                }
            );
            console.log(response);
            const decoder = new TextDecoder();
            const stream = response.data;

            for await (const chunk of stream) {
                const decodedChunk = decoder.decode(chunk, { stream: true });
                console.log(decodedChunk);
                const regex = /<chunk>(.*?)<\/chunk>/gs;
                const matches = decodedChunk.matchAll(regex);
                const matchArray = Array.from(matches);
                if (decodedChunk.includes('chunk')) {
                    for (const match of matchArray) {
                        let jsonData = JSON.parse(match[1]);
                        if (
                            "aiError" in jsonData &&
                            jsonData.aiError != null &&
                            jsonData.aiError.length > 0
                        ) {
                            if (jsonData.aiError == "token_limit_exceeded") {
                                this.limitReachedDialogMsg =
                                    jsonData.limitReachedDialogMsg ??
                                    "Daily limit has reached. You can try tomorrow";
                                this.limitReachedDialog = true;
                            } else {
                                this.limitReachedDialogMsg = jsonData.aiError;
                                this.aiErrorDialog = true;
                            }

                            this.showStreamingPanel = [];
                            this.streaming = false;

                            // show dialog
                            return;
                        }
                        if ("topicId" in jsonData) {
                            this.currentTopicStreamed = jsonData.topicId
                            this.currentTopicStreamedObject = this.getCurrentStreamingObject(this.currentTopicStreamed)
                            console.log(this.currentTopicStreamedObject);
                            this.currentTopicStreamedObject.aiInsights = ''
                            this.currentTopicStreamedObject.insertedAt = Date.now()
                            this.currentTopicStreamedObject.html = ''
                        }
                        setTimeout(() => {
                            window.MathJax.typesetPromise();
                        }, 0);
                    }
                }
                else {
                    this.currentTopicStreamedObject.aiInsights = this.currentTopicStreamedObject.aiInsights + decodedChunk
                    this.currentTopicStreamedObject.html = this.getMarkDownHtml(this.currentTopicStreamedObject.aiInsights)
                    // this.currentTopicStreamedObject =  Object.assign({}, {...this.currentTopicStreamedObject, html : decodedChunk })
                    console.log(this.computedInsights);
                    console.log(this.aiInsights);


                }
            }
            this.disableInsights = false
            this.currentCategoryStreamed = null
            this.currentTopicStreamed = null
            this.currentTopicStreamedObject = null
        },
        getCurrentStreamingObject(topicId) {
            return this.computedCategoryStreaming.topics.filter((e) => e.topicId == topicId)[0]
        },
        getCurrentCategoryStreamingbject(topicId) {
            return this.computedCategoryStreaming.topics.filter((e) => e.topicId == topicId)[0]
        },
        getMarkDownHtml(markdown) {
            console.log("md");

            try {
                let out = markdown
                const regex = /\\/gm;
                const escapedString = markdown.replace(regex, "\\\\");

                out = marked.parse(escapedString, {
                    "async": false,
                    "breaks": false,
                    "extensions": null,
                    "gfm": true,
                    "hooks": null,
                    "pedantic": false,
                    "silent": false,
                    "tokenizer": null,
                    "walkTokens": null
                });

                out = out.replace(/\\\\+/g, '\\');
                out = out.replace(/\\n/g, '<br>');

                return out;
            } catch (error) {
                return ""
            }

        },

        changeSubjectType(value) {
            this.subjectType = value

        },
        categoryActive(value) {
            if (value == this.moduleCategory) {
                return true;
            }
            return false;
        },
        getSkillName(skill) {
            skill = skill.toLowerCase();
            if (skill == "notset") {
                return "Get started";
            }
            else {
                return this.getCapitalizedText(skill);
            }
        },
        getSkillLevelTextColor(skillLevel) {
            skillLevel = skillLevel.toLowerCase();
            let color = "#323232";
            if (skillLevel) {
                if (skillLevel != "notset") {
                    color = "#FFFFFF";
                }
            }
            return color;
        },
        getSkillLevelColor(skillLevel) {
            skillLevel = skillLevel.toLowerCase();
            let color = "#D1D1D1";
            if (skillLevel) {
                if (skillLevel == "beginner") {
                    color = "#FF894F";
                }
                else if (skillLevel == "intermediate") {
                    color = "#55B56A";
                }
                else if (skillLevel == "advanced") {
                    color = "#01ACBE";
                }
                else if (skillLevel == "mastery") {
                    color = "#1975CF";
                }
            }
            return color;
        },
        getSkillLevelIcon(isReady, skillLevel) {
            skillLevel = skillLevel.toLowerCase();
            let icon = "assets/new_images/medal_coming_soon.svg";
            if (isReady && skillLevel) {
                if (skillLevel == "notset") {
                    icon = "assets/new_images/medal_start_enable.svg";
                }
                else if (skillLevel == "beginner") {
                    icon = "assets/new_images/medal_beginner_enable.svg";
                }
                else if (skillLevel == "intermediate") {
                    icon = "assets/new_images/medal_intermediate_enable.svg";
                }
                else if (skillLevel == "advanced") {
                    icon = "assets/new_images/medal_advanced_enable.svg";
                }
                else if (skillLevel == "mastery") {
                    icon = "assets/new_images/medal_mastery_enable.svg";
                }
            }
            return this.getIconSrc(icon);
        },
        getIconSrc(url) {
            return require(`@/${url}`);
        }, getDate(date) {
          
            return timeAgo.format(new Date(date))


        },
        getCapitalizedText(text) {
            return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
        },
        isEven(number) {
            if (number % 2 === 0) {
                return true;
            }
            else {
                return false;
            }
        },
        closeInfoTab() {
            // localStorage.setItem("newLearn", false);
            // localStorage.setItem("newUser", false);
            this.showInfoTab = false;
        }
    },
    watch: {

    },
};
</script>

<style scoped>
.t-86rem {
    font-size: 0.86rem !important;
}

.bg-transparent {
    background-color: transparent;
}

.ic {
    animation: grow 2s ease-in-out infinite alternate;
    font-size: 30px;
}

@keyframes grow {
    0% {
        transform: scale(1);
    }

    /* Initial state */
    50% {
        transform: scale(1.2);
    }

    /* Slightly larger */
    100% {
        transform: scale(1);
    }

    /* Back to original */
}

.toggler-outer-bg {
    border-radius: 200px !important;
    /* border: 2px solid rgba(255, 255, 255, 0.12); */
    background: #2A2941;
}

.active-button {
    border-radius: 200px;
    /* background-color: rgb(var(--v-theme-background)) !important; */
    /* background-color: #494960 !important; */
    /* border: 1.4px solid var(--Purple-G-1, #AB77FF); */
    background: linear-gradient(#494960, #494960) padding-box,
        linear-gradient(180deg, #AB77FF 0%, #585EF3 100%) border-box;
    border: 2px solid transparent;
    color: rgb(var(--v-theme-primaryTextColor)) !important;
}

.normal-button {
    border-radius: 200px;
    background: none;
    color: rgb(var(--v-theme-primaryTextColor)) !important;
    border: 2px solid transparent;
}

.html {
    padding: 12px;
}

.button-text {
    letter-spacing: -0.08px;
}

/* .normal-button:hover{
      background: none;
      color: rgb(var(--v-theme-primaryTextColor)) !important;
    } */

/* .entity-container {
    padding-left: 8%;
    padding-right: 8%;
    background-color: transparent;
}

.entity-container-xl {
    padding-left: 17%;
    padding-right: 17%;
    background-color: transparent;
}

.entity-container-xxl {
    padding-left: 28%;
    padding-right: 28%;
    background-color: transparent;
} */

.v-sheet.on-hover {
    cursor: pointer;
    /* border: 1px solid rgb(var(--v-theme-primary)); */
    border-color: transparent;
    background-color: rgb(var(--v-theme-navColor));
    box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;
}

.disabledOpacity {
    opacity: var(--v-disabled-opacity);
    pointer-events: none
}

.skill-container {
    display: inline-flex;
    width: fit-content;
    border-radius: 8px;
    padding: 5px 10px;
}

.aiBox {
    background: rgb(var(--v-theme-report-subCardBg));
    padding: 10px 15px;
    margin-right: 12px !important;
    border: 2px solid transparent;
    border-radius: 12px;
}


.not-activity-btn {
    border: 2px solid rgb(var(--v-theme-primaryPurple));
    border-radius: 12px;
    background: rgb(var(--v-theme-cardBackgroundColor));
}

.activity-btn {
    background: linear-gradient(180deg, #AB77FF 0%, #585EF3 100%);
    border-radius: 12px;
}

.topicCard {
    background-color: rgb(var(--v-theme-cardBackgroundColor2));
    border-radius: 10px;
}

@media only screen and (max-width: 600px) {
    .width-adjustment {
        width: 100%;
    }

    .entity-item {
        width: 100%;
        height: 140px;
    }
}



.v-overlay.v-overlay--active {
    background: rgba(0, 0, 0, 0.75) !important;
    backdrop-filter: blur(12.5px) !important;
}

.bg-category {
    border-radius: 12px;
    background: rgb(var(--v-theme-report-subCardBg));
}

.lock-topic {
    border-color: white;
}

.heightBread {
    height: 80px;
}

.htmlAI {
    color: rgb(255 255 255 / 85%) !important
}

.htmlAI * {
    padding: revert !important;
    margin: revert !important;
}

.topicbg {
    background: linear-gradient(93deg, #AB77FF -1.51%, #585EF3 100.54%);
}
</style>

<style>
.htmlAI * {
    padding: revert !important;
    margin: revert !important;
    font-weight: normal !important;

}
</style>