<template>
  <v-dialog v-model="qDialog" no-click-animation persistent z-index="1200" fullscreen scrollable class="xyz"
    transition="scale-transition" origin="center center" eager :retain-focus="false">
    <v-card>

      <v-card-text class="scroll">
        <v-form ref="form">

          <v-row dense class="ma-0 pa-0">

            <v-col cols="12" class="t-938remReg text-black ma-0 pa-0">
              <span class="edtr-required">
                Passage
              </span>
              <rte-editor toolbar="bullist" :required="true" :initialVData="editedItem.questionDesc"
                v-model="editedItem.questionDesc" class="mt-1"></rte-editor>

            </v-col>
          </v-row>

          <v-row dense class="ma-0 pa-0">

            <v-col cols="12" class="t-938remReg text-black ma-0 pa-0 mt-4">
                Question Text
              <rte-editor toolbar="bullist" :required="true" :initialVData="editedItem.questionTextForSecondColumn"
                v-model="editedItem.questionTextForSecondColumn" class="mt-1"></rte-editor>

            </v-col>
          </v-row>

          <v-row dense class="mt-4">

            <v-col cols="12" class="t-938remReg text-black mb-0 pb-0">
              <span class="edtr-required">
                Question Type
              </span>
              <v-radio-group hide-details :rules="requiredRule" color="primaryPurple" inline
                v-model="editedItem.questionTypeId" class="t-h6Reg ml-n2">
                <v-radio hide-details v-for="item, index in questionTypes" class="t-h6Reg mr-2 testOption"
                  :label="item.questionType" :value="item.questionTypeId" :key=index></v-radio>

              </v-radio-group>

            </v-col>
          </v-row>



          <!-- single choice question -->

          <section v-if="editedItem.questionTypeId == '1'">
            <v-row dense class=" mt-6">
              <v-col cols="12" class="t-938remReg text-black ">
                <span class="edtr-required">
                  Option A
                </span>
                <div>
                  <rte-editor v-model="editedItem.a" :initialVData="editedItem.a" :height="120" :required="true"
                    class="mt-1"></rte-editor>

                </div>

              </v-col>
            </v-row>
            <v-row dense class=" mt-3">

              <v-col cols="12" class="t-938remReg text-black ">
                <span class="edtr-required">
                  Option B
                </span>
                <rte-editor v-model="editedItem.b" :initialVData="editedItem.b" :height="120" :required="true"
                  class="mt-1"></rte-editor>

              </v-col>
            </v-row>


            <v-row dense class=" mt-3">
              <v-col cols="12" class="t-938remReg text-black">
                <span class="edtr-required">
                  Option C
                </span>
                <rte-editor v-model="editedItem.c" :initialVData="editedItem.c" class="mt-1" :required="true"
                  :height="120"> </rte-editor>
              </v-col>
            </v-row>

            <v-row dense class=" mt-3">

              <v-col cols="12" class="t-938remReg text-black ">
                <span class="edtr-required">
                  Option D
                </span>
                <rte-editor v-model="editedItem.d" :initialVData="editedItem.d" class="mt-1" :height="120"
                  :required="true"></rte-editor>

              </v-col>
            </v-row>
            <v-row dense class=" mt-3">

              <v-col cols="12" class="t-938remReg text-black mb-0 pb-0">
                <span class="edtr-required">
                  Correct Answer
                </span>
                <v-radio-group :rules="requiredRule" color="primaryPurple" inline
                  v-model="editedItem.answerSingleChoice" class="t-h6Reg ml-n2">
                  <v-radio class="t-h6Reg mr-1 testOption " label="a" value="a"></v-radio>
                  <v-radio class="t-h6Reg mx-1 testOption " label="b" value="b"></v-radio>
                  <v-radio class="t-h6Reg mx-1 testOption " label="c" value="c"></v-radio>
                  <v-radio class="t-h6Reg mx-1 testOption " label="d" value="d"></v-radio>
                </v-radio-group>

              </v-col>
            </v-row>
          </section>

          <!-- integer question -->
          <section v-if="editedItem.questionTypeId == '3'">
            <v-row dense class="mb-3 mt-2">
              <v-col cols="12" sm="5" md="3" class="t-938remReg text-black ">
                <div>
                  <v-text-field label="Integer Answer" v-model="editedItem.answerInteger" variant="underlined">
                  </v-text-field>
                </div>
              </v-col>
            </v-row>
          </section>

          <!-- decimal question -->

          <section v-if="editedItem.questionTypeId == '4'">
            <v-row dense class="mb-3 mt-2">
              <v-col cols="12" sm="5" md="3" class="t-938remReg text-black ">
                <div>
                  <v-text-field label="Decimal Answer Low *" v-model="editedItem.answerDecimalLow" variant="underlined">
                  </v-text-field>
                </div>

              </v-col>
              <v-col cols="12" sm="5" md="3" class="t-938remReg text-black ml-sm-5">
                <div>
                  <v-text-field label="Decimal Answer High *" v-model="editedItem.answerDecimalHigh"
                    variant="underlined">
                  </v-text-field>

                </div>

              </v-col>
            </v-row>
          </section>


          <v-row dense class="">
            <v-col cols="12" class="t-938remReg text-black  mb-0 pb-0">
              <span class="">
                Solution
              </span>
              <rte-editor toolbar="bullist" v-model="editedItem.solutionText" :initialVData="editedItem.solutionText"
                class="mt-1" :required="true"></rte-editor>

            </v-col>
          </v-row>

          <v-row dense class="mt-4">

            <v-col cols="12" class="t-938remReg text-black  mb-0 pb-0">
              <v-select :items="subjectList" :rules="requiredRule"
                :item-title="(e) => e.subjectType == 'readingandwriting' ? 'Reading and Writing' : e.subjectName"
                item-value="subjectId" v-model="editedItem.subjectId" label="Subject" variant="outlined" dense
                density="compact" class="mt-2">

              </v-select>

            </v-col>
          </v-row>

          <v-row dense class="mt-4">

            <v-col cols="12" class="t-938remReg text-black  mb-0 pb-0">
              <v-select :items="topicsList" :rules="requiredRule" item-title="entityLabel" item-value="entityId"
                v-model="editedItem.topicId" label="Topic" variant="outlined" dense density="compact" class="mt-2">

              </v-select>

            </v-col>
          </v-row>

          <v-row dense class="mt-4">

            <v-col cols="12" class="t-938remReg text-black mb-0 pb-0">
              <span class="edtr-required">
                Difficulty Level
              </span>
              <v-radio-group :rules="requiredRule" color="primaryPurple" inline v-model="editedItem.difficulty"
                class="t-h6Reg ml-n2">
                <v-radio v-for="item, index in difficultyList" class="t-h6Reg mr-2 testOption"
                  :label="item.difficultyTitle" :value="item.difficultyValue" :key=index></v-radio>

              </v-radio-group>

            </v-col>
          </v-row>

          <v-row dense class="mt-1" v-if="validateError != null">

            <v-col cols="12" class="t-1remReg text-error mb-0 pb-0">
              Please fill all the blank field

            </v-col>
          </v-row>

        </v-form>

      </v-card-text>

      <v-card-actions class="mr-4">
        <v-spacer></v-spacer>

        <v-btn class="t-btn cancel-btn text-capitalize mr-2" density="default" variant="flat" width="150" height="50"
          color="secondaryBackground" elevation="0" @click.stop="close">Cancel</v-btn>
        <v-btn class="save-btn t-btn text-capitalize text-primaryTextColor ml-2" density="default" variant="flat"
          width="150" height="50" @click="saveQuestion">Save Question</v-btn>


      </v-card-actions>

    </v-card>

  </v-dialog>

</template>

<script>
import rteEditor from '@/shared/components/rte-editor.vue';
import { securePostAPI } from '@/services/apiService';
import _ from "lodash";
import { mapState } from "vuex";
import $ from "jquery";

export default {
  name: 'EditQuestionComponent',
  components: { rteEditor },
  async created() {
    console.log("here inside", this.questionItem);
    this.editQuestion();
  },
  props: [
    'questionItem'
  ],
  computed: {
    ...mapState(["newLearnNodes"]),
    subjectList: {
      get() {
        return _.map(this.newLearnNodes, (item) =>
          _.pick(item, ["subjectId", "subjectName", "subjectType"])
        );
      },
    },

    categoryList: {
      get() {
        let item = _.find(this.newLearnNodes, (item) => {
          return item.subjectId == this.editedItem.subjectId;
        });
        let data = [];
        if (item && item.categories != null) {
          for (let category of item.categories) {
            data.push(category);
          }
        }
        return data;
      },
    },
    topicsList: {
      get() {
        let item = _.find(this.newLearnNodes, (item) => {
          return item.subjectId == this.editedItem.subjectId;
        });
        let topicData = [];
        if (item && item.categories != null) {
          for (let categories of item.categories) {
            topicData.push(...categories.entity);
          }
        }
        return topicData;
      },
    },

  },
  data: () => ({
    qDialog: false,
    selectedSubjectId: "",
    requiredRule: [(v) => !!v || "Required",],
    validateError: null,
    editedItem: {
      "questionPartitionKey": "",
      "aiQuestionId": "",
      "questionDesc": "",
      "questionTypeId": "",
      "a": "",
      "b": "",
      "c": "",
      "d": "",
      "answerSingleChoice": "",
      "difficultyLevel": "",
      "answerDecimalHigh": "",
      "answerDecimalLow": "",
      "answerInteger": "",
      "domainId": "",
      "insertedBy": "",
      "insertedTimeStamp": 0,
      "categoryId": "",
      "solutionText": "",
      "subjectId": "",
      "topicId": "",
      "questionTextForSecondColumn" : ""
    },
    defaultItem: {
      "questionPartitionKey": "",
      "aiQuestionId": "",
      "questionDesc": "",
      "questionTypeId": "1",
      "a": "",
      "b": "",
      "c": "",
      "d": "",
      "answerSingleChoice": "",
      "difficultyLevel": "",
      "domainId": "",
      "insertedBy": "",
      "answerDecimalHigh": "",
      "answerDecimalLow": "",
      "answerInteger": "",
      "insertedTimeStamp": 0,
      "categoryId": "",
      "solutionText": "",
      "subjectId": "",
      "topicId": "",
      "questionTextForSecondColumn" : ""
    },
    difficultyList: [
      {
        difficultyTitle: "Easy",
        difficultyValue: "Easy",
      },
      {
        difficultyTitle: "Medium",
        difficultyValue: "Medium",
      },
      {
        difficultyTitle: "Hard",
        difficultyValue: "Hard",
      },
    ],
    questionTypes: [
      {
        questionType: "Single Choice",
        questionTypeId: "1",
      },
      {
        questionType: "Integer",
        questionTypeId: "3",
      },
      {
        questionType: "Decimal",
        questionTypeId: "4",
      },
    ],
    questionSnackbarMsg: '',
  }),
  methods: {
    async saveQuestion() {
      this.validateError = null
      const { valid } = await this.$refs.form.validate();
      console.log("valid", valid);
      if (!valid) {
        this.validateError = true
        $(".scroll").stop().animate({ scrollTop: $(".scroll")[0].scrollHeight }, 1000);

        return
      }

      if (
        this.editedItem.questionDesc.trim().length == 0
      ) {
        $(".scroll").stop().animate({ scrollTop: $(".scroll")[0].scrollHeight }, 1000);
        this.validateError = true
        return
      }

      console.log(this.editedItem);
      if (this.editedItem.questionTypeId == '1' && (this.editedItem.a.trim().length == 0
        || this.editedItem.b.trim().length == 0
        || this.editedItem.c.trim().length == 0
        || this.editedItem.d.trim().length == 0)) {
        $(".scroll").stop().animate({ scrollTop: $(".scroll")[0].scrollHeight }, 1000);
        this.validateError = true
        return
      }
      if (this.editedItem.difficulty == 'Easy') {
        this.editedItem.difficultyLevel = "2";
      }
      else if (this.editedItem.difficulty == 'Medium') {
        this.editedItem.difficultyLevel = "5";
      }
      else if (this.editedItem.difficulty == 'Hard') {
        this.editedItem.difficultyLevel = "8";
      }
      let category = this.getCategoryFromTopicId(this.editedItem.topicId)
      this.editedItem.categoryId = category.categoryId
      this.editedItem.categoryName = category.categoryName

      let topic = this.getTopicFromTopicId(this.editedItem.topicId)
      this.editedItem.topicId = topic.entityId
      this.editedItem.topicName = topic.entityLabel

      console.log(this.editedItem);
      console.log("Test");
      let item = _.cloneDeep(this.editedItem)
      this.editedItem = Object.assign({}, this.defaultItem);
      this.qDialog = false;

      let url = ""
      let isSaveQuestion = false;
      if ("collectionId" in item && item.collectionId.length > 0) {
        url = 'mentoapp/ai/addQuestion'
        isSaveQuestion = false;
      }
      else {
        url = 'mentoapp/ai/saveQuestion'
        isSaveQuestion = true;
      }
      console.log("rrl ", url);
      let response = await securePostAPI(item, url);
      if ('success' in response && response.success == false) {
        let statement = isSaveQuestion == true ? 'Question could not be saved successfully' : 'Question could not be added to the collection';
        this.questionSnackbarMsg = ('error' in response && response?.error != '') ? response.error : statement;
        this.$emit("questionSnackbarMsg", this.questionSnackbarMsg);
        this.$emit('showQuestionSnackbar');
        this.close();
      }
      else {
        let statement = isSaveQuestion == true ? 'Question saved successfully' : 'Question successfully added to the collection';
        this.questionSnackbarMsg = ('message' in response && response.message != '') ? response.message : statement;
        this.$emit("questionSnackbarMsg", this.questionSnackbarMsg);
        this.$emit('showQuestionSnackbar');
        this.$emit("saveQuestionResponse", item);
        this.close();
        setTimeout(() => {
                window.MathJax.typesetPromise();
            }, 0);
        
      }
    },
    getCategoryFromTopicId(topicId) {
      let id = ''
      console.log(this.categoryList);
      for (let item of this.categoryList) {
        if (item.entity.find((e) => e.entityId == topicId)) {
          console.log("item.categoryId", item);
          return item
        }
      }
    },
    getTopicFromTopicId(topicId) {
      let item = this.topicsList.find((e) => e.entityId == topicId) ?? ""
      return item
    },
    close() {
      this.qDialog = false;
      this.$emit("closeQuestionDialog");
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    editQuestion() {
      this.editedItem = Object.assign({}, this.questionItem);
      console.log(this.editedItem);
      this.qDialog = true
    },
  },

}
</script>

<style scoped>
.cancel-btn {
  border-radius: 29.4079px;
  width: 100px;
}

.save-btn {
  color: white !important;
  border-radius: 29.4079px;
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%);
}
</style>

<style>
.testOption .v-label {
  width: 100% !important;
  opacity: 1 !important;
}

.edtr-required:after {
  content: "  *";
  color: red;
}
</style>