<template>
    <v-row class="ma-0 pt-4 px-4" :class="{ heightBread: $vuetify.display.mdAndUp }">
        <v-col cols="12" class="ma-0 pa-0">
            <v-breadcrumbs :items="displayBreadCrumbs" class="no-new-line t-h6Reg text-white text-primaryTextColor">
                <template v-slot:divider>
                    <v-icon icon="mdi-chevron-right"></v-icon>
                </template>
                <template v-slot:prepend>
                    <v-btn v-if="breadCrumbs.length > 0" color="#26253A" class="ma-0 mr-2 text-primaryTextColor"
                        size="x-small" @click="back()" icon="mdi-chevron-left">
                    </v-btn>
                </template>
                <template v-slot:title="{ item }">
                    <span @click.stop="breadCrumbRoute(item)" class="breadcrumb-title">{{ item.title }}</span>
                </template>
            </v-breadcrumbs>
        </v-col>
        <v-spacer></v-spacer>
        <v-divider></v-divider>
    </v-row>
    <v-container style="position: relative" fluid class="ma-0 pa-4 bg-transparent">
        <div class="mt-4" :class="{
            'entity-container': lg,
            'entity-container-xl': xl,
            'entity-container-xxl': xxl,
        }">
            <v-row no-gutters :justify="mdAndDown ? 'center' : 'space-between'">


                <v-col cols="12">
                    <v-card variant="flat" class="topicCard">
                        <v-card-text class="">
                            <v-row dense class="t-h6 text-white topicbg ma-n4 pa-3" justify="space-between">
                                <v-col cols="auto">
                                    {{ currentTopicName }}
                                </v-col>
                            </v-row>

                            <div class="mt-8">
                                <span class="t-h6 htmlAI" v-html="streamingHtml">
                                </span>
                                <span v-if="streaming">
                                    <v-icon class="ic" size="17" color="primaryPurple">mdi-circle</v-icon>
                                </span>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>



        </div>
    </v-container>

</template>

<script>
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";
import { useDisplay } from 'vuetify';
import { mapState } from "vuex";
import moment from 'moment'
import { v4 as uuidv4 } from "uuid";
import { getLoggedInUserId, getLoggedInUserOrganizationId } from "@/services/authService";
import axios from "axios";
import {
    breadcrumbHandler,
} from "@/services/breadcrumbHandler";

const marked = require("marked");

const renderer = new marked.Renderer();
renderer.link = function link(href, title, text) {
    return `<a href="${href}" title="${title}" target="_blank">${text}</a>`;
};
export default {
    name: 'AIAnalytics',
    setup() {
        // Destructure only the keys we want to use
        const { xs, smAndUp, smAndDown, mdAndDown, mdAndUp, lgAndUp, lg, xl, xxl } = useDisplay();
        return { xs, smAndUp, smAndDown, mdAndDown, mdAndUp, lgAndUp, lg, xl, xxl };
    }, data: () => ({
        streaming: false,
        streamingHtml: "",
        streamingText: ""
    }),
    async created() {
        breadcrumbHandler(this);
        this.streaming = true
        this.streamingText = ""
        let stuentUserId = getLoggedInUserId()
        let userTestInstanceId = null
        let topicId = null
        console.log(this.$route.params);

        if (this.$route.params.testInstanceId) {
            userTestInstanceId = this.$route.params.testInstanceId
        }
        else {
            this.$router.back()
        }
        if (this.$route.params.entityId) {
            topicId = this.$route.params.entityId
        }
        else {
            this.$router.back()
        }
        let aiInput = {
            userId: getLoggedInUserId(),
            organizationId: getLoggedInUserOrganizationId(),
            sessionId: uuidv4(),
            stuentUserId: stuentUserId,
            userTestInstanceId: userTestInstanceId,
            topicId: topicId,
            testType: this.$route.query.type ?? 'practice',
            username: getLoggedInUserId()
        }
        const response = await axios.post(
            "https://aifunctions.devtest.thestudypod.com/usertestinsights",
            aiInput,
            {
                responseType: "stream",
                adapter: "fetch",
            }
        );
        console.log(response);
        const decoder = new TextDecoder();
        const stream = response.data;

        for await (const chunk of stream) {
            const decodedChunk = decoder.decode(chunk, { stream: true });
            if (decodedChunk.startsWith("error:")) {
                //
                this.streamingText = this.streamingText + decodedChunk.split(":")[1]
                this.streamingHtml = this.getMarkDownHtml(this.streamingText)
                this.streaming = false
                return
            }
            this.streamingText = this.streamingText + decodedChunk
            this.streamingHtml = this.getMarkDownHtml(this.streamingText)

        }
        // document.querySelectorAll('a').forEach(function (link) {
        //     link.setAttribute('target', '_blank');
        // });
        this.streaming = false
    },

    methods: {
        getMarkDownHtml(markdown) {
            console.log("md");

            try {
                let out = markdown
                const regex = /\\/gm;
                const escapedString = markdown.replace(regex, "\\\\");

                out = marked.parse(escapedString, {
                    "async": false,
                    "breaks": false,
                    "extensions": null,
                    "gfm": true,
                    "hooks": null,
                    "pedantic": false,
                    "silent": false,
                    "tokenizer": null,
                    "walkTokens": null
                });

                out = out.replace(/\\\\+/g, '\\');
                out = out.replace(/\\n/g, '<br>');

                return out;
            } catch (error) {
                return ""
            }

        },
        breadCrumbRoute(item) {
            this.$router.replace(item.pageRoute)
        }, back() {
            let page = this.breadCrumbs[this.breadCrumbs.length - 2].pageRoute
            this.$router.replace(page)
            this.$router.back();
        },
    },
    computed: {
        ...mapState(["currentTopicId", "breadCrumbs", "currentTopicName"]),
        displayBreadCrumbs: {
            get() {
                let breadCrumbs = this.breadCrumbs;
                console.log(this.currentTopicName);

                if (this.currentTopicName != "") {
                    breadCrumbs[breadCrumbs.length - 2].title = this.currentTopicName;
                }
                else {
                    breadCrumbs[breadCrumbs.length - 2].title = 'Topic'
                }

                return breadCrumbs;
            }
        },
    },
}
</script>

<style scoped>
.entity-container {
    padding-left: 8%;
    padding-right: 8%;
    background-color: transparent;
}

.entity-container-xl {
    padding-left: 17%;
    padding-right: 17%;
    background-color: transparent;
}

.entity-container-xxl {
    padding-left: 28%;
    padding-right: 28%;
    background-color: transparent;
}

.topicCard {
    background-color: rgb(var(--v-theme-cardBackgroundColor2));
    border-radius: 10px;
}

.topicbg {
    background: linear-gradient(93deg, #AB77FF -1.51%, #585EF3 100.54%);
}

.t-86rem {
    font-size: 0.86rem !important;
}

.bg-transparent {
    background-color: transparent;
}

.ic {
    animation: grow 2s ease-in-out infinite alternate;
    font-size: 30px;
}

@keyframes grow {
    0% {
        transform: scale(1);
    }

    /* Initial state */
    50% {
        transform: scale(1.2);
    }

    /* Slightly larger */
    100% {
        transform: scale(1);
    }

    /* Back to original */
}

a {
    color: white !important;
}
</style>
<style>
.htmlAI * {
    padding: revert !important;
    margin: revert !important;
    font-weight: normal !important;
    color: rgb(255 255 255 / 85%) !important


}

.htmlAI a {
    color: white !important;
    text-decoration: underline !important;
    font-weight: bold !important;
}
</style>