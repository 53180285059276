const FlavorType = {
  DEV: 'DEV',
  PROD: 'PROD',
  MENTOMINDUSPROD: 'MENTOMINDUSPROD',
  DEMOPROD: 'DEMOPROD',
  MMEDUCATOR: 'MMEDUCATOR',
  B2C: 'B2C',
};

const APP_VERSION = "3.15.1";
const APP_BUILDNUMBER = "376";
const APP_FLAVOR = FlavorType.B2C;

export default { FlavorType, APP_VERSION, APP_BUILDNUMBER, APP_FLAVOR }
