<template>
	<div>
		<v-navigation-drawer v-if="!xs" location="right" :width="56" permanent>
			<v-list density="compact" class="question-nav-item">
				<v-list-item class="ma-0 pa-0 question-nav" v-if="chatbotAllowed">
					<template v-slot:prepend>
						<v-tooltip text="Mento Chat" location="left">
							<template v-slot:activator="{ props }">
								<v-btn
									class="pa-0 btn-border"
									:class="{ 'selected-icon': iconIndex == 1 }"
									elevation="0"
									v-bind="props"
									@click="openChatbot"
									>
									<v-img height="24" width="24" src="@/assets/mentochat.png"></v-img>
									</v-btn>
							</template>
						</v-tooltip>
					</template>
				</v-list-item>
				<v-list-item class="ma-0 pa-0 question-nav">
					<template v-slot:prepend>
						<v-tooltip :text="isQuestionBookmark()?'Bookmarked':'Bookmark'" location="left">
							<template v-slot:activator="{ props }">
								<v-btn
									class="pa-0 ma-0 btn-border"
									:class="{ 'selected-icon': iconIndex == 2 }"
									elevation="0"
									v-bind="props"
									@click="showBookmarkDialog"
									>
									<v-icon
										:icon="isQuestionBookmark() ?'mdi-bookmark':'mdi-bookmark-outline'"
										size="large"
										:color="(isQuestionBookmark()? 'primaryPurple' : 'black')"
									></v-icon>
									</v-btn>
							</template>
						</v-tooltip>
					</template>
				</v-list-item>
				<v-list-item class="ma-0 pa-0 question-nav">
					<template v-slot:prepend>
						<v-tooltip text="Report a problem" location="left" v-if="!isQuestionReviewer">
							<template v-slot:activator="{ props }">
								<!-- <v-avatar class="action-icon" v-bind="props" variant="outlined" elevation="0" size="36"
									:class="{ 'selected-icon': iconIndex == 3 }" @click="showReportDialog">
									<v-icon size="16">mdi-message-alert-outline</v-icon>
								</v-avatar> -->
								<!-- <v-icon size="36" v-bind="props" @click="showReportDialog">mdi-message-alert-outline</v-icon> -->
								<v-btn
									class="pa-0 ma-0 btn-border"
									:class="{ 'selected-icon': iconIndex == 3 }"
									elevation="0"
									v-bind="props"
									@click="showReportDialog"
									>
									<v-icon
										icon="mdi-message-alert-outline"
										size="large"
										color="black"
									></v-icon>
									</v-btn>
							</template>
							
						</v-tooltip>
					</template>
				</v-list-item>
			</v-list>
		</v-navigation-drawer>
		<div class="question-action-box" v-else>
			<Transition name="slide-fade">
				<v-row class="d-flex action-box ga-2">
					<v-col cols="auto" class="pa-0 ma-0">

						<v-tooltip text="Mento Chat" location="left">
							<template v-slot:activator="{ props }">
								<v-avatar color="actionIcon" size="36" @click="openChatbot" v-if="chatbotAllowed"
									v-bind="props">
									<v-img size="16" src="@/assets/mentochat.png"></v-img>
								</v-avatar>
							</template>
						</v-tooltip>
					</v-col>
					<v-col cols="auto" class="pa-0 ma-0">
						<v-tooltip :text="isQuestionBookmark()?'Bookmarked':'Bookmark'" location="left">
							<template v-slot:activator="{ props }">
								<v-avatar class="action-icon" :class="{ 'active-icon': isQuestionBookmark() }"
									v-bind="props" variant="outlined" elevation="0" size="36"
									@click="showBookmarkDialog">
									<v-icon size="16">{{ isQuestionBookmark() ?'mdi-bookmark':'mdi-bookmark-outline' }}</v-icon>
								</v-avatar>
							</template>
						</v-tooltip>
					</v-col>
					<v-col cols="auto" class="pa-0 ma-0">
						<v-tooltip text="Report a problem" location="left" v-if="!isQuestionReviewer">
							<template v-slot:activator="{ props }">
								<v-avatar class="action-icon" v-bind="props" variant="outlined" elevation="0" size="36"
									@click="showReportDialog">
									<v-icon size="16">mdi-message-alert-outline</v-icon>
								</v-avatar>
							</template>
						</v-tooltip>
					</v-col>
				</v-row>

			</Transition>

		</div>
		<ChatBotComponent :screenshotQuestionElement="screenshotQuestionElement" :screenshotSolutionElement="screenshotSolutionElement" :chatboxClass="`question-chatbot ${xs? 'question-chatbot-xs' :''}`" :testId="testId" :testInstanceId="testInstanceId"
			ref="chatbot" :showIcon="false" v-if="chatbotAllowed" 
			@closeChatbot="showActionIcons = true, iconIndex = -1" 
			:currentQResponseProp="currentQResponseProp"
			:updateCrossedOptionsBot="updateCrossedOptionsBot">
		</ChatBotComponent>
		<div class="question-tool-box" :class="{ 'question-tool-box-xs': xs }" v-if="!showActionIcons">
			<Transition name="slide-fade" v-if="feedbackOrReportDialog">
				<ReportComponent class="report-box"
					@closeDialog="feedbackOrReportDialog = false, showActionIcons = true, iconIndex = -1"
					:test-type="testType" :q-id="qId" :test-id="testId" :test-instance-id="testInstanceId"
					:q-obj="qObj">
				</ReportComponent>
			</Transition>
			<Transition name="slide-fade" v-if="bookmarkDialog">
				<BookmarkComponent class="report-box"
					@closeDialog="bookmarkDialog = false, showActionIcons = true, iconIndex = -1" :test-type="testType"
					:q-id="qId" :test-id="testId" :test-instance-id="testInstanceId" :q-obj="qObj"
					:bookmark-obj="questionBookmark"></BookmarkComponent>
			</Transition>

		</div>
	</div>
</template>
<script>
import { useDisplay } from "vuetify";
import { mapGetters } from "vuex";
import _ from "lodash";
import {
	AnalyticsEvents,
	recordAnalyticsEvent,
} from "@/services/awsPinpointAnalyticsService";
import ReportComponent from "@/components/questionTools/ReportComponent.vue";
import BookmarkComponent from "@/components/questionTools/BookmarkComponent.vue";
import ChatBotComponent from "@/components/practice/ChatBotComponent.vue";

export default {
	name: "QuestionActionsComponent",
	setup() {
		// Destructure only the keys we want to use
		const { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp } = useDisplay();

		return { xs, smAndUp, mdAndUp, lgAndUp, xlAndUp };
	},
	components: {
		ReportComponent, BookmarkComponent, ChatBotComponent
	},
	props: {
		qId: String,
		testType: String,
		testId: String,
		testInstanceId: String,
		qObj: Object,
		isAIQuestion: {
			type: Boolean,
			default: false,
		},
		chatbotAllowed: {
			type: Boolean,
			default: true,
		},
		screenshotQuestionElement: {
            type: Object,
			default: () => ({})
        },
		screenshotSolutionElement: {
            type: Object
        },
		chatbotConfig: Object,
		currentQResponseProp: Object,
		updateCrossedOptionsBot: Function
	},
	data: () => ({
		showActionIcons: true,
		feedbackOrReportDialog: false,
		bookmarkDialog: false,
		chatbotDialog: false,
		iconIndex: -1,
	}),
	async created() {
		recordAnalyticsEvent(AnalyticsEvents.v2QuestionActionToolsOpen, {
			qId: this.qObj.qId
		});
		this.iconIndex = -1;
	},
	async mounted() {
		
		this.$store.dispatch('getQuestionBookmarkAndQueryDetails', {
			qId: this.qObj.qId
		})
		// this.iconIndex = -1;

		if (this.chatbotAllowed) {
			this.showActionIcons = true;
			this.$refs.chatbot.hideChatbot();
			this.$refs.chatbot?.setCurrentQuestion(this.qObj);

			await this.$refs.chatbot?.getNudgeInfoForCurrentQuestion(
				this.chatbotConfig.currentMode,
				this.chatbotConfig.chatbotMode
			);
			this.$refs.chatbot?.showChatbot();

			if (this.$vuetify.display.smAndDown) {
				this.iconIndex = -1;
				this.$refs.chatbot?.closeChatbot();
			} else {
				this.iconIndex = 1;
				this.$refs.chatbot?.openChatbot();
			}
		} else {
			this.$refs.chatbot?.hideChatbot();
		}

	},
	computed: {
		...mapGetters(['isQuestionReviewer',]),
		questionBookmark: {
			get() {
				return this.$store.state.questionBookmark;
			}
		}
	},

	watch: {
		iconIndex: {
			handler(val){
				console.log("iconIndex", val);
			},
			deep: true
		},
		chatbotAllowed: {
			handler(val){
				setTimeout(async () => {

					if(this.chatbotAllowed){
						this.showActionIcons = true;
						console.log("Chatbot allowed in watch", this.$refs.chatbot);
						
						this.$refs.chatbot?.hideChatbot();
						this.$refs.chatbot?.setCurrentQuestion(this.qObj);

						this.$refs.chatbot?.showChatbot();
						await this.$refs.chatbot?.getNudgeInfoForCurrentQuestion(
							this.chatbotConfig.currentMode,
							this.chatbotConfig.chatbotMode
						);
						
						if (this.$vuetify.display.smAndDown) {
							this.iconIndex = -1;
							this.$refs.chatbot?.closeChatbot();
						} else {
							console.log("Chatbot in open", this.$refs.chatbot);
							this.iconIndex = 1;
							this.$refs.chatbot?.openChatbot();
						}
					}
				}, 10);
			},
			deep: true
		}
	},

	methods: {
		isQuestionBookmark() {
			return !_.isEmpty(this.questionBookmark) && _.get(this.questionBookmark, 'bookmark', true);
		},
		showBookmarkDialog() {
			recordAnalyticsEvent(AnalyticsEvents.v2QuestionActionBookmarkToolClicked, {
				qId: this.qObj.qId
			});
			this.$refs.chatbot?.closeChatbot();
			this.iconIndex = 2;
			this.feedbackOrReportDialog = false;
			this.showActionIcons = false;
			this.bookmarkDialog = true;
		},
		showReportDialog() {
			recordAnalyticsEvent(AnalyticsEvents.v2QuestionActionReportToolClicked, {
				qId: this.qObj.qId
			});
			this.$refs.chatbot?.closeChatbot();
			this.iconIndex = 3;	
			this.bookmarkDialog = false;
			this.showActionIcons = false;
			this.feedbackOrReportDialog = true;
		},
		async openChatbot() {
			recordAnalyticsEvent(AnalyticsEvents.v2QuestionActionChatbotToolClicked, {
				qId: this.qObj.qId
			});
			this.iconIndex = 1;
			this.bookmarkDialog = false;
			this.feedbackOrReportDialog = false;
			this.showActionIcons = true;
			this.$refs.chatbot?.hideChatbot();
			if(!this.$refs.chatbot?.isSameQuestion(this.qObj)){
				this.$refs.chatbot?.setCurrentQuestion(this.qObj);
				await this.$refs.chatbot?.getNudgeInfoForCurrentQuestion(
					this.chatbotConfig.currentMode,
					this.chatbotConfig.chatbotMode
				);
			}
			this.$refs.chatbot?.showChatbot();
			this.$refs.chatbot?.openChatbot();
		},
		async getNudgeInfo(currentQ) {
			if (this.chatbotAllowed) {
				this.$refs.chatbot?.setCurrentQuestion(currentQ);
				await this.$refs.chatbot?.getNudgeInfoForCurrentQuestion(
					this.chatbotConfig.currentMode,
					this.chatbotConfig.chatbotMode
				);
			}
			this.$store.dispatch('getQuestionBookmarkAndQueryDetails', {
				qId: currentQ.qId
			})
		}
	},
};
</script>
<style scoped>
/* Hide scrollbar for IE, Edge and Firefox */

.question-nav-item{
	/* padding: 2px 9.5px !important; */
}

.btn-border{
	/* min-width: 56px !important;
	width: 56px !important;
	border-radius: unset; */
	min-width: 54px !important;
	border-radius: unset;
	height: 40px !important;
	background: white;
}

.action-box {
	background: #35354E;
	border-radius: 16px;
	color: white;
	padding: 10px;
}

.action-icon {
	background: rgb(var(--v-theme-actionIcon));
	color: white;
	border: none;
}

.active-icon {
	background: rgb(var(--v-theme-primaryPurple));
}

.question-tool-box {
	position: fixed;
	right: 56px;
	top: 65px;
	bottom: 87px;
	z-index: 1010;
	width: 95%;
	min-width: 320px;
	max-width: 30%;
}

.report-box {
	height: 100%;
	border-radius: 12px;
	border-color: transparent;
	box-shadow: 0px 2px 8px rgba(112, 144, 300, 0.2) !important;
}

.question-action-box {
	position: absolute;
	right: 20px;
	top: 65px;
	z-index: 2000;
}

.question-tool-box-xs {
	right: 0 !important;
	z-index: 2001;
	max-width: 100% !important;
	width: 100% !important;
	left: 0 !important;
	top: 0 !important;
	bottom: 0 !important;
}

.selected-item {
	border-radius: 10px;
}

.selected-icon{
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
  background: #908f8f3b !important; */
  /* box-shadow: 0 -5px 8px 0 rgba(0, 0, 0, 0.2) !important; */
  background: #f0f0f2 !important;
}
</style>
<style>
.question-chatbot {
	position: fixed !important;
    right: 56px !important;
    top: 65px !important;
    bottom: 87px !important;
    z-index: 1010 !important;
    width: 95% !important;
    min-width: 350px !important;
    max-width: 30% !important;
}

.question-chatbot-xs{
	right: 0 !important;
	z-index: 2001 !important;
	max-width: 100% !important;
	width: 100% !important;
	left: 0 !important;
	top: 0 !important;
	bottom: 0 !important;
}
</style>