<template lang="html">
  <section>
    <div class="editor" v-bind:class="{ 'required-field': showRequiredMsg }">
      <editor :init="tinymceConfig" v-model="content" outputFormat="html" @keyup="update" @change="update"
        @redo="update" @undo="update" @init="handleInit" />
    </div>
    <span v-show="showRequiredMsg" class="required-prop-text">
      Required.
    </span>
  </section>
</template>

<script lang="js">

import 'tinymce'
import Editor from "@tinymce/tinymce-vue"

/* Required TinyMCE components */
import 'tinymce/icons/default/icons.min.js';
import 'tinymce/themes/silver/theme.min.js';
import 'tinymce/models/dom/model.min.js';

/* Import a skin (can be a custom skin instead of the default) */
import 'tinymce/skins/ui/oxide/skin.js';

/* Import plugins */
import 'tinymce/plugins/accordion';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/autosave';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/code';
import 'tinymce/plugins/codesample';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/emoticons/js/emojis';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/help';
import 'tinymce/plugins/image';
import 'tinymce/plugins/importcss';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/quickbars';
import 'tinymce/plugins/save';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/table';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/wordcount';


/* content UI CSS is required */
import 'tinymce/skins/ui/oxide/content.js';

/* The default content CSS can be changed or replaced with appropriate CSS for the editor content. */
import 'tinymce/skins/content/default/content.js';

import { uploadFile } from "@/services/storageService.js"
import * as MathLive from 'mathlive';
import { ourKeyBoardLayers, ourKeyboards } from '../mathlive-keyboard/keyboard.js';
var dialogConfig = {
  title: 'Maths Equations',
  size: 'large',
  body: {
    type: 'panel',
    items: [
      {
        type: "htmlpanel", // A HTML panel component
        // Mathlive HTML customization and event registration
        html: `<math-field
                      style="
                          font-size: 24px;
                      "
                      id="formula"
                      virtual-keyboard-mode="manual"

                      oninput="(function(){
                            const mf = document.querySelector('#formula');
                            document.querySelector('#latex').value = mf.value;
                          })()"
                    >
                    </math-field>

                    <textarea
                      id="latex"
                      class="math-field-textarea"

                      oninput="(function(){
                              const mf = document.querySelector('#formula');
                              const latexField = document.querySelector('#latex');
                              mf.setValue(latexField.value);
                          })()"
                    ></textarea>`
      },
    ]
  },
  buttons: [
    {
      type: 'cancel',
      name: 'closeButton',
      text: 'Cancel'
    },
    {
      type: 'submit',
      name: 'submitButton',
      text: 'Insert',
      primary: true
    }
  ],
  initialData: {
  },
  onSubmit: function (api) {
    var formula = document.querySelector('#latex').value;
    var mathjaxFormula = "\\(" + formula + "\\)"
    var content = '&nbsp;' + mathjaxFormula + '&nbsp;';
    // retrive the content and use set content instead of insertContent
    window.tinymce.activeEditor.insertContent(content);
    api.close();
  }
};

export default {
  name: 'rte-editor',
  components: {
    editor: Editor
  },
  props: {

    plugins: {
      type: [String, Array],
      default: "image  fullscreen lists table quickbars"
    },
    toolbar: {
      type: [String, Array],

      default:
        ` `
    },
    quickbars_selection_toolbar: {
      type: [String, Array],

      default: 'bold italic underline strikethrough | mathlive | forecolor backcolor'
    },
    modelValue: { type: String, default: '' },
    initialVData: { type: String, default: '' },
    height: { type: Number, default: 200 },
    width: { type: Number, default: 400 },
    required: { type: Boolean, default: false }
  },
  data() {
    return {
      showRequiredMsg: false,
      content: this.modelValue,
      editorRef: false,
      editorConfig: this.$store.state.config,
      s3Url : this.$store.state.app_s3url 
    }

  },

  watch: {

    initialVData: function (newVal) {
      this.content = newVal
      // this.$emit('update:modelValue', newVal);
    },

    content: function (newVal) {
      console.log("cccc", newVal);
      if (newVal.length !== 0) {
        this.showRequiredMsg = false;
      }
      if (newVal != this.initialVData) {
        this.$emit('update:modelValue', newVal);
      }
    }
  },
  created() {
    this.init();

  },
  methods: {
    init() {
      const component = this;
      this.tinymceConfig = {
        promotion: false,
        license_key: 'gpl',
        height: this.height,
        initialValue: this.modelValue,
        width: this.weight,
        relative_urls: true,
        // resize: "both",
        document_base_url: this.s3Url ,
        menubar: "false",
        plugins: component.plugins,
        paste_data_images: true,
        max_height: 600,
        forced_root_block: "p",
        forced_root_block_attrs: {
          'class': 'mce-make-root-block-inline'
        },
        newline_behavior: 'invert',

        skin: "material-outline",
        file_picker_types: "image",
        images_file_types: "jpg,jpeg,png",
        image_dimensions: false,
        image_title: true,
        content_style: `body {background-color: #FFFFFF; margin: 2px } p {margin: 2px} img {max-width: 100%;} .tox-dialog__body-content { width: 700px !important; overflow: hidden !important;
            } .mce-content-body div.mce-resizehandle { background-color: #4099ff; border-color: #4099ff; border-style: solid; border-width: 1px; box-sizing: border-box; height: 10px; position: absolute; width: 10px; z-index: 1298 } .mce-content-body .mce-clonedresizable { cursor: default; opacity: .5; outline: 1px dashed #000; position: absolute; z-index: 10001 }
            .mce-content-body img::-moz-selection{background-color: transparent; outline: 3px solid #b4d7ff;}.mce-content-body img::selection{background-color: transparent; outline: 3px solid #b4d7ff;}`,
        automatic_uploads: true,
        branding: false,
        toolbar: `bold italic underline strikethrough ${component.toolbar} Formatting mathlive `,
        toolbar_groups: {
          formatting: {
            icon: 'more-drawer',
            tooltip: 'Formatting',
            items: 'undo redo | numlist bullist | superscript subscript | removeformat | image | table'
          }
        },
        // quickbars_selection_toolbar: component.quickbars_selection_toolbar,
        quickbars_insert_toolbar: false,
        images_upload_handler: (blobInfo, progress) => new Promise((resolve, reject) => uploadFile(blobInfo, resolve, reject, progress)),
        toolbar_mode: 'floating',
        statusbar: false,

        image_description: false,
        highlight_on_focus: false,
        file_picker_callback: function (cb) {
          var input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.setAttribute('accept', 'image/jpg,image/jpeg,image/png');

          input.onchange = function () {
            var file = this.files[0];

            var reader = new FileReader();
            reader.onload = function () {
              const id = file.name.replace(/[`~!@#$%^&*()|+\-=?;:'",<>\\{\\}\\[\]\\\\/]/gi, '').substr(0, file.name.lastIndexOf('.') - 2);
              var blobCache = component.editorRef.editorUpload.blobCache;
              var base64 = reader.result.split(',')[1];
              var blobInfo = blobCache.create(id, file, base64);
              blobCache.add(blobInfo);

              /* call the callback and populate the Title field with the file name */
              cb(blobInfo.blobUri(), { title: file.name });
            };
            reader.readAsDataURL(file);
          };
          input.click();
        },
        setup: function (editor) {
          // add plugin
          editor.ui.registry.addButton('mathlive', {
            text: "",
            icon: "insert-character",
            onAction: async function () {
              editor.windowManager.open(dialogConfig);
              // wait for math-field to set
              await window.customElements.whenDefined('math-field');
              // below code is required to fix mathlive initialization issue
              window.addEventListener('DOMContentLoaded',
                () => MathLive.renderMathInDocument()
              );
              const mf = document.getElementById('formula');
              mf.setOptions({
                customVirtualKeyboardLayers: ourKeyBoardLayers,
                customVirtualKeyboards: ourKeyboards,
                virtualKeyboardMode: "manual",
                virtualKeyboards: 'all summation-keyboard trigonometry-keyboard matrices-keyboard'
              });
              let selectedText = editor.selection.getNode().textContent;
              mf.value = selectedText.substring(
                selectedText.lastIndexOf("\\(") + 2,
                selectedText.lastIndexOf("\\)")
              );
            }
          })
        },
        valid_classes: {
          'p': 'mce-make-root-block-inline',
        }
      }
    },
    update: function (event, editor) {
      // pass updated content back to the parent
      // console.log("update-editor", editor.getContent());
      this.content = editor.getContent();
      let content = editor.getContent().replace(/<\/?p[^>]*>/g, "")
        .replace(/&nbsp;/g, "").replace(/<br\s*\/?>/g, "");
      if (this.required && !(content.length !== 0)) {
        this.showRequiredMsg = true;
      } else {
        this.showRequiredMsg = false;
      }
      // console.log("upfate", this.content);
      // this.$emit('update:modelValue', this.content);
    },
    handleInit: function (event, editor) {
      if (this.content !== this.initialVData) {
        this.content = this.initialVData
      }
      this.showRequiredMsg = false;
      this.editorRef = editor;
    }
  },
}
</script>

<style>
.editor {
  padding: auto;
}

.required-prop-text {
  color: #ff5252 !important;
}

.required-field {
  border: 2px solid #ff5252 !important;
  border-radius: 10px;
}

.mce-make-root-block-inline {
  display: inline !important;
}

.tox .tox-toolbar__group {
  padding: 2px 6px !important;
}

.tox .tox-tbtn {
  margin: 0 2px 0 0 !important;
}
</style>
