<template>
	<v-dialog persistent style="backdrop-filter: blur(12.5px)" v-model="showRedirectTab" transition="dialog-top-transition"
		width="1024">
		<v-row class="pa-0 ma-0" justify="center">
			<v-col class="pa-0 ma-0" cols="12" sm="10">
				<v-card class="redirect-card text-white pa-4 pa-sm-12 pa-md-16 justify-sm-center align-center">
					<v-card-text class="py-0 px-3 d-flex justify-center align-center">
						<v-container class="px-0">
							<v-row justify="center" align-content="center" align-center>
								<v-col cols="12" class="py-0">
									<v-img class="mento-img mx-auto" aspect-ratio="1" width="100"
										src="@/assets/mento_loading_icon.png"></v-img>
								</v-col>
								<v-col cols="12" class="redirect-title text-center pb-0 pt-sm-8">
									Welcome to Digital SAT Prep !
								</v-col>
								<v-col cols="12" class="redirect-subtitle text-center pt-sm-4">
									Let's get started
								</v-col>

								<v-col cols="12"
									class="options ma-0 pa-0 d-flex align-center flex-sm-row flex-column justify-space-evenly">
									<v-hover v-slot="{ isHovering, props }">
										<v-sheet @click="toLearn" :width="smAndUp ? 251 : '100%'" :height="smAndUp ? 169 : '100%'"
											class="ma-2 mt-2 px-4 py-7 btnStyle d-flex justify-center align-center"
											:class="{ 'on-hover': isHovering }" v-bind="props">
											<v-row justify="center" style="min-height: 125px" align-content="center">
												<v-col class="ma-0 pa-0 text-center" cols="12" md="10">
													<v-avatar class="ma-0" size="50px" rounded="0">
														<v-img src="@/assets/learn_active.svg"></v-img>
													</v-avatar>
												</v-col>
												<v-col class="ma-0 pa-0" cols="12">
													<v-card-title class="n-o-title ma-0 pa-0 text-h5 text-center text-wrap mt-4">
														Play a quiz to start learning
													</v-card-title>
												</v-col>
											</v-row>
										</v-sheet>

									</v-hover>

									<v-hover v-slot="{ isHovering, props }">
										<v-sheet @click="toChallenge" :width="smAndUp ? 251 : '100%'" :height="smAndUp ? 169 : '100%'"
											class="ma-2 mt-2 px-4 py-7 btnStyle d-flex justify-center align-center"
											:class="{ 'on-hover': isHovering }" v-bind="props">
											<v-row justify="center" style="min-height: 125px" align-content="center">
												<v-col class="ma-0 pa-0 text-center" cols="12">
													<v-avatar class="ma-0" size="50px" rounded="0">
														<v-img src="@/assets/challenge_active.svg"></v-img>
													</v-avatar>
												</v-col>
												<v-col class="ma-0 pa-0" cols="12">
													<v-card-title class="n-o-title ma-0 pa-0 text-h5 text-center text-wrap mt-4">
														Plan a fun challenge
													</v-card-title>
												</v-col>
											</v-row>
										</v-sheet>
									</v-hover>
								</v-col>
							</v-row>
						</v-container>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-dialog>
</template>

<script>
import { useDisplay } from "vuetify";
export default {
	setup() {
		// Destructure only the keys we want to use
		const { xs, smAndUp, mdAndUp } = useDisplay();
		return { xs, smAndUp, mdAndUp };
	},
	created() {
		if (localStorage.getItem('newScreen') != 'true') {
			this.$router.replace('/learn');
		}
		if (
			localStorage.getItem("newUser") == "true" &&
			localStorage.getItem("newScreen") == "true"
		) {
			this.showRedirectTab = true;
		}
	},
	data: () => ({
		showRedirectTab: false,
	}),
	methods: {
		toLearn() {
			this.$router.push('./learn');
			localStorage.setItem('newScreen', false);
		},
		toChallenge() {
			this.$router.push('./challenge');
			localStorage.setItem('newScreen', false);
		},
	}
};
</script>

<style scoped>
.redirect-card {
	background: #3D3D54;
	/* box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important; */
	border-radius: 15px !important;
}

.mento-img {
	width: 130px !important;
}

.redirect-title {
	font-size: 25px;
	font-weight: 700;
}

@media only screen and (max-width: 600px) {

	.mento-img {
		width: 100px !important;
	}

	.redirect-title {
		font-size: 21px;
		line-height: 1.2;
	}


}

.redirect-subtitle {
	font-size: 0.875rem;
	font-weight: 600;
	color: #c4c4ce;
}

.btnStyle {
	border-radius: 20px !important;
	background: #23223A;
	transition: all 0.3s ease;
	border: 4px solid #A174FD !important;
	color: #fff !important;
	border-color: transparent !important;
	cursor: pointer;
}

.on-hover {
	cursor: pointer;
	/* border: 1px solid #e7e7e7 !important; */
	background: #23223A;
	color: #fff !important;
	filter: drop-shadow(0px 0px 25px #A274FD80);
	/* border: 4px solid #538dfe !important; */
	border: 4px solid #A174FD !important;

}

.n-o-title {
	font-size: 18px !important;
	font-weight: 500 !important;
}
</style>
