<template>
  <v-dialog class="dialog-bx" scrollable scroll-strategy="reposition" :fullscreen="$vuetify.display.smAndDown"
    v-model="instructionsDialog" max-width="800px" min-height="400px" max-height="1000px">
    <v-card height="700px" color="background">
      <v-card-text>
        <v-row justify="space-around" align="center" class="mt-3 ml-2">
          <v-col cols="10">
            <v-row>
              <v-icon class="mr-3"> mdi-note-multiple-outline </v-icon>
              <span class="t-h5">{{ currentSection.sectionName }}
              </span>
            </v-row>
          </v-col>
          <v-col class="text-end" cols="2">
            <v-icon @click="instructionsDialog = false"> mdi-close </v-icon>
          </v-col>
        </v-row>
        <v-row class="mt-5 mb-6">
          <v-divider></v-divider>
        </v-row>
        <v-row class="px-4">
          <HtmlRender class="htmlClass" :html-string="currentSection.instructions"></HtmlRender>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
  <v-dialog v-if="testType != 'quiz'" v-model="warningDialog" class="dialog-bx" width="auto">
    <v-card>
      <v-card-title class="t-h5 text-first-capitalize">
        {{ this.warningTimeLeft }} mins remaning
        <v-divider class="mt-3"> </v-divider>
      </v-card-title>
      <v-card-text class="mb-3">Only {{ this.warningTimeLeft }} mins are left to complete the
        test</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="primary" variant="text" @click="warningDialog = false">
          Okay
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <DragableFeedbackDialog v-if="reportDialog" @closeDialog="reportDialog = false" test-type="quiz" :q-id="currentQ.qId"
          :test-id="currentTestId" :test-instance-id="startTestInfo.testInstanceId"
				:q-obj="currentQ"></DragableFeedbackDialog>

  <v-app-bar color="breadCrumbBackColor" v-if="dataReady" class="d-flex w-100 justify-start">
    <v-app-bar-title style="flex: 1 46%">
      <template v-slot:text>
        <div class="d-flex align-center">
          <v-avatar class="mr-1" :size="$vuetify.display.xs ? 35 : 46">
            <v-img :height="$vuetify.display.xs ? 35 : 46" src="@/assets/test_practice_1.png" />
          </v-avatar>
          <div :class="{ 'w-75': xs }">
            <div class="text-ellipse" v-if="currentSection.visibleOnApp">
              {{ currentSection.sectionName }}
            </div>
            <div v-if="!$vuetify.display.mdAndUp && !reviewScreen" class="d-flex">
              <v-icon v-if="!timeOver" :color="remaningTimeInSeconds < 60 ? '#FF786E' : 'primaryPurple'
                ">
                mdi-timer</v-icon>
              <div v-if="!timeOver" class="t-h6 text-primaryTextColor ml-2"
                :class="{ 'redTimer blink': remaningTimeInSeconds < 60 }">
                {{ getRemainingTime }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </v-app-bar-title>

    <!-- <v-spacer v-if="$vuetify.display.mdAndUp"></v-spacer> -->

    <div v-if="$vuetify.display.mdAndUp && !reviewScreen" class="d-flex flex-column" style="flex: 1 auto">
      <div class="pl-7">
        <v-icon v-if="!timeOver && !showingTime"
          :color="remaningTimeInSeconds < 60 ? '#FF786E' : 'primaryPurple'">mdi-timer</v-icon>
      </div>

      <div v-if="!timeOver && showingTime" class="t-h6 text-primaryTextColor time-width"
        :class="{ 'redTimer blink': remaningTimeInSeconds < 60 }">
        {{ getRemainingTime }}
      </div>
      <v-btn variant="plain" width="80px" height="20px" :flex="true" @click="showingTime = !showingTime"
        class="text-none t-subtitle text-center pa-0">{{ showingTime ? "Hide" : "Show" }}</v-btn>
    </div>
    <div class="d-flex align-center justify-end" :class="mdAndUp ? 'pr-5' : 'pr-2'" style="flex: 1 49%">
      <v-btn v-if="xs && currentSection.subjectType == 'math' && !reviewScreen" icon="mdi-calculator" color="white"
        size="small" density="comfortable" class="text-white more-btn mr-2" @click="openCalculator">
      </v-btn>
      <v-btn v-else-if="currentSection.subjectType == 'math' && !reviewScreen" prepend-icon="mdi-calculator" color="white"
        class="t-btn text-white more-btn text-none mr-3" @click="openCalculator">
        Calculator
      </v-btn>

      <v-btn v-if="xs && currentSection.subjectType == 'math' && !reviewScreen" icon="mdi-format-superscript"
        color="white" size="small" density="comfortable" class="text-white more-btn mr-2" @click="openReferences">
      </v-btn>
      <v-btn v-else-if="currentSection.subjectType == 'math' && !reviewScreen" prepend-icon="mdi-format-superscript"
        color="white" class="t-btn text-white more-btn text-none mr-3" @click="openReferences">
        References
      </v-btn>

      <v-menu :class="mdAndUp ? 'mr-5' : 'mr-2'">
        <template v-slot:activator="{ props }">
          <v-btn v-if="xs" icon="mdi-dots-vertical" color="white" size="small" v-bind="props" density="comfortable"
            class="text-white more-btn">
          </v-btn>
          <v-btn v-else prepend-icon="mdi-dots-vertical" color="white" v-bind="props"
            class="t-btn text-white more-btn text-none">
            More
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-if="testType != 'quiz'" @click="showInstructions()">
            <v-list-item-title>Module Instructions</v-list-item-title>
          </v-list-item>
          <v-list-item @click="exitTest()">
            <v-list-item-title>Leave Test</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <!-- <v-spacer v-if="$vuetify.display.mdAndUp"></v-spacer> -->
  </v-app-bar>

  <div :class="{ bgW: !reviewScreen, 'bgP py-8': reviewScreen }" v-if="dataReady">
    <div v-if="reviewScreen" class="px-4 bgPColor">
      <v-alert type="warning" title="Please try submitting again" v-if="showSubmitAlert" class="mx-8 mt-4 mb-8"
        text="There was an issue, please try submitting again"></v-alert>
        <v-alert type="error" title="Please try attempting previous question again!" v-if="showQuestionAttemptAlert" class="mx-8 mt-4 mb-8"
				text="Error has occurred while saving previous question to server. Please check your internet connection or try attempting previous question again. If this issue still persists, please contact Support Team!"></v-alert>
      <v-row class=""> </v-row>
      <v-row v-if="!timeOver" class="align-center" justify="center">
        <v-col cols="12" class="text-center t-h6Bold text-primaryTextColor timer">
          Remaining Time
        </v-col>
      </v-row>
      <v-row v-if="!timeOver" class="align-center mt-0 pt-0" justify="center">
        <v-col cols="12" class="text-center t-h125 font-weight-bold timer-color">
          {{ getRemainingTime }}
        </v-col>
      </v-row>
      <v-row class="align-center mt-8" justify="center">
        <v-col cols="12" class="t-h125 font-weight-bold text-primaryTextColor text-center">
          <span v-if="timeOver">
            Module time is over, please wait to next module
          </span>
          <span v-else> Check your work </span>
        </v-col>
      </v-row>
      <v-row class="align-center" justify="center">
        <v-col cols="12" class="mt-n5 text-center t-h6Reg subColor">
          <span v-if="timeOver">
            This Module will be auto submitted and next module will start
          </span>
          <span v-else>
            On test day, you won’t be able to move on to the next module until
            time express.
            <br />
            <br />
            For practice questions, simply select "Next module" when you're
            prepared to proceed
          </span>
        </v-col>
      </v-row>
      <v-row justify="center" class="mt-8">
        <v-col cols="12" md="10" lg="8" xl="6">
          <!-- <v-row>

                <v-col cols="12" class="t-h5Reg text-center mb-4">
                  Do you want to review marked questions before submitting ?
                </v-col>
              </v-row> -->

          <v-sheet elevation="0" class="card-bg-color px-10 py-10">
            <v-row justify="space-between" align="start" align-md="center">
              <v-col cols="6" md="auto" class="text-center text-md-center">
                <v-row align-md="center">
                  <v-col cols="12" md="auto" class="ma-0 pa-0">
                    <v-avatar size="40" class="totalQ text-primaryTextColor">
                      {{ questionCounts.total }}
                    </v-avatar>
                  </v-col>
                  <v-col cols="12" md="auto" class="ma-0 pa-0 mt-2 ml-0 mt-md-0 ml-md-2 mb-8 mb-md-0">
                    <span class="text-primaryTextColor t-h6Reg">
                      Total Questions
                    </span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6" md="auto" class="text-center">
                <v-row align-md="center">
                  <v-col cols="12" md="auto" class="ma-0 pa-0">
                    <v-avatar size="40" class="unansweredQ text-primaryTextColor">
                      {{ questionCounts.unanswered }}
                    </v-avatar>
                  </v-col>
                  <v-col cols="12" md="auto" class="ma-0 pa-0 mt-2 ml-0 mt-md-0 ml-md-2 mb-8 mb-md-0">
                    <span class="text-primaryTextColor t-h6Reg">
                      Not answered
                    </span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6" md="auto" class="text-center">
                <v-row align-md="center">
                  <v-col cols="12" md="auto" class="ma-0 pa-0">
                    <v-avatar size="40" class="correctQ text-primaryTextColor">
                      {{ questionCounts.answered }}
                    </v-avatar>
                  </v-col>
                  <v-col cols="12" md="auto" class="ma-0 pa-0 mt-2 ml-0 mt-md-0 ml-md-2">
                    <span class="text-primaryTextColor t-h6Reg">
                      Answered
                    </span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6" md="auto" class="text-center">
                <v-row align-md="center">
                  <v-col cols="12" md="auto" class="ma-0 pa-0">
                    <v-avatar size="40" class="mr-2 markedQ text-primaryTextColor t-h6Reg">
                      {{ questionCounts.marked }}
                    </v-avatar>
                  </v-col>
                  <v-col cols="12" md="auto" class="ma-0 pa-0 mt-2 ml-0 mt-md-0 ml-md-2">
                    <span class="text-primaryTextColor t-h6Reg">
                      Marked Review
                    </span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row justify="center" class="mt-10 px-10">
        <v-col cols="12" md="10" lg="8" xl="6">
          <v-row class="" v-for="(item, sIndex) in qResponsesState" :key="sIndex">
            <v-col v-if="item.visibleOnApp" cols="12" class="t-h5Reg my-4 text-white">
              {{ item.sectionName }}
            </v-col>

            <v-row justify="start">
              <v-col cols="auto" class="" v-for="(item, qIndex) in item.responses" :key="qIndex">
                <v-avatar style="cursor: pointer" @click="timeOver ? null : openQFromReview(sIndex, qIndex)" size="40"
                  :class="{
                    correctQ: checkIfQuestionAnswered(item),
                    markedQ: item.markedForReview,
                    unansweredQ: !checkIfQuestionAnswered(item),
                  }" class="text-primaryTextColor">
                  {{ item.qNo }}
                </v-avatar>
              </v-col>
            </v-row>
          </v-row>
        </v-col>
      </v-row>

      <v-row class="align-center mt-10 mt-md-15" justify="center">
        <v-col cols="auto">
          <v-btn :loading="submitLoading" :size="$vuetify.display.smAndUp ? 'large' : 'default'" :width="$vuetify.display.mdAndUp ? '130' : '125'"
            class="text-none btn-grad text-primaryTextColor submitFont" @click="submitTest()" variant="flat">
            Yes, submit
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn  @click="backFromReview()" color="white" :disabled="timeOver || submitLoading" class="text-none btn-prev submitFont"
            :size="$vuetify.display.smAndUp ? 'large' : 'default'" :width="$vuetify.display.mdAndUp ? '130' : '125'"
            variant="outlined">
            No, wait
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <div v-show="!reviewScreen" class="bgWColor" id="screenshot">
      <v-dialog absolute class="bottom dialog-bx" height="700px" width="800px" v-model="questionDialog">
        <v-card height="700px" color="background">
          <v-card-text class="pa-8">
            <v-row justify="space-between" class="t-h5 px-3">
              <v-col cols="auto" class="ml-0 pl-0"> Your responses </v-col>
              <v-col class="text-right">
                <v-icon @click="questionDialog = false"> mdi-close </v-icon>
              </v-col>
            </v-row>
            <v-row>
              <v-divider class="mt-2 mb-5"> </v-divider>
            </v-row>
            <v-row>
              <v-col cols="auto" class="d-flex align-center">
                <v-img height="20" width="40" class="mr-3" src="@/assets/current_location.png"></v-img>

                <span> Current Question </span>
              </v-col>
              <v-col cols="auto" class="">
                <v-avatar size="40" variant="outlined" class="mr-2 correct">
                  {{ questionCounts.answered }} </v-avatar><span> Answered </span>
              </v-col>
              <v-col cols="auto" class="">
                <v-avatar size="40" variant="outlined" class="mr-2 unanswered">
                  {{ questionCounts.unanswered }} </v-avatar><span> Not answered </span> </v-col><v-col cols="auto"
                class="">
                <v-avatar size="40" variant="outlined" class="mr-2 marked">
                  {{ questionCounts.marked }} </v-avatar><span> Marked for review </span>
              </v-col>
            </v-row>
            <v-row>
              <v-divider class="my-5"> </v-divider>
            </v-row>

            <v-row class="mt-4" v-for="(item, sIndex) in qResponsesState" :key="sIndex">
              <v-col v-if="item.visibleOnApp" cols="12" class="t-h5Reg">
                {{ item.sectionName }}
              </v-col>

              <v-row>
                <v-col cols="auto" class="mx-1" v-for="(item, qIndex) in item.responses" :key="qIndex">
                  <v-img v-if="item.qId === currentQ.qId" height="20" src="@/assets/current_location.png"></v-img>
                  <div v-else style="height: 20px"></div>

                  <v-avatar style="cursor: pointer" @click="openQFromDialog(sIndex, qIndex)" size="40" variant="outlined"
                    :class="{
                      correct: checkIfQuestionAnswered(item),
                      marked: item.markedForReview,
                      unanswered: !checkIfQuestionAnswered(item),
                    }">
                    {{ item.qNo }}
                  </v-avatar>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-6">
                <v-col cols="auto" class="align-center">
                  <v-btn
                  @click="openReviewPage"
                  class="t-btn text-none text-capitalize btn-prev"
                  size="large"
                  elevation="0"
                  density="compact"
                  color="secondaryBackground"
                  >
                    Go to review page 
                  </v-btn>
                </v-col>
              </v-row>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <div v-show="calculatorDialog" class="main-draggable">
        <Vue3DraggableResizable class="calculator-parent" :minW="450" :minH="600" :draggable="draggable" :resizable="false"
          v-model:x="calculatorDimension.x" v-model:y="calculatorDimension.y" v-model:w="calculatorDimension.w"
          v-model:h="calculatorDimension.h" :parent="true">
          <v-card height="100%" width="100%">
            <v-row class="calculator-heading ma-0 align-center">
              <v-col cols="5">
                <span class="text-white t-h5">Calculator</span>
              </v-col>
              <v-col cols="2" class="d-flex align-center justify-center ma-0 pa-0">
                <v-tooltip location="top" z-index="3050">
                  <template #activator="{ props }">
                    <v-icon v-bind="props" color="white"  class="draggable"  @mousemove.stop="draggableStart()" @mouseleave.stop="draggableEnd()">
                      mdi-drag-horizontal
                    </v-icon>
                  </template>
                  <span>Press, hold, and drag to move</span>
                </v-tooltip>
              </v-col>
              <v-col cols="5" class="d-flex">
                <v-row dense class="align-center justify-end">
                  <v-col cols="auto">
                    <v-btn :prepend-icon="isCalculatorExpanded ? 'mdi-arrow-collapse' : 'mdi-arrow-expand'"
                      class="t-btn text-dialogHeader t-h5 more-btn text-none mr-3" @click="expandCalculatorDialog">{{
        isCalculatorExpanded ? 'Collapse' : 'Expand' }}</v-btn>
                  </v-col>
                  <v-col cols="auto">
                    <v-icon color="white" @click="closeCalculator">
                      mdi-close
                    </v-icon>
                  </v-col>
                </v-row>

              </v-col>
            </v-row>
            <iframe src="https://www.desmos.com/calculator" class="frame"></iframe>
          </v-card>
        </Vue3DraggableResizable>
      </div>

      <div v-show="referenceDialog" class="main-draggable">
        <Vue3DraggableResizable class="calculator-parent" :minW="$vuetify.display.xs ? 300 : 500"
          :minH="$vuetify.display.xs ? 400 : 500" :draggable="referenceDraggable" :resizable="!$vuetify.display.xs"
          v-model:x="selectedReferenceDimension.x" v-model:y="selectedReferenceDimension.y"
          v-model:w="selectedReferenceDimension.w" v-model:h="selectedReferenceDimension.h" :parent="true"
          @dragging="changeXYDetails">
          <v-card height="100%" width="100%">
            <v-row class="calculator-heading ma-0 align-center">
              <v-col cols="5">
                <span class="text-white t-h5">Reference Sheet</span>
              </v-col>
              <v-col cols="2" class="d-flex align-center justify-center">
                <v-tooltip location="top" z-index="3050">
                  <template #activator="{ props }">
                    <v-icon v-bind="props" color="white"  class="draggable"  @mousemove.stop="!$vuetify.display.xs&&referenceDraggableStart()" @mouseleave.stop="!$vuetify.display.xs&&referenceDraggableEnd()">
                      mdi-drag-horizontal
                    </v-icon>
                  </template>
                  <span>Press, hold, and drag to move</span>
                </v-tooltip>
              </v-col>
              <v-col cols="3"></v-col>
              <v-col cols="2" class="d-flex justify-end">
                <v-icon color="white" @click="referenceDialog = false">
                  mdi-close
                </v-icon>
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-4 reference-body">
              <v-col cols="12">
                <v-row>
                  <v-col cols="auto" v-for="(image, index ) in geometriesImages" :key="index">
                    <v-img :width="index == 4 ? 200 : 100" height="100" :src="image"></v-img>
                  </v-col>
                </v-row>
                <v-divider class="mt-2"></v-divider>
                <v-row dense class="mt-2">
                  <v-col cols="12" class="d-flex">
                    <v-img width="16" height="16" :inline="true" class="pa-0 pr-1" src="@/assets/pin_icon.png"></v-img>
                    <span class="type-circle ma-0">The number of degrees of arc in a circle is 360.</span>
                  </v-col>
                  <v-col cols="12" class="d-flex">
                    <v-img width="16" height="16" :inline="true" class="pa-0 pr-1" src="@/assets/pin_icon.png"></v-img>
                    <span class="type-circle ma-0">The number of radians of arc in a circle is 2&pi;.</span>
                  </v-col>
                  <v-col cols="12" class="d-flex">
                    <v-img width="16" height="16" :inline="true" class="pa-0 pr-1" src="@/assets/pin_icon.png"></v-img>
                    <span class="type-circle ma-0">The sum of the measures in degrees of the angles of a triangle is
                      180.</span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

          </v-card>
        </Vue3DraggableResizable>
      </div>


      <!-- <v-dialog class="dialog-bx" :fullscreen="$vuetify.display.smAndDown" v-model="reportDialog" max-width="800px"
        min-height="400px" max-height="1000px">
        <v-card height="700px" color="background">
          <v-card-text>


            <v-row justify="space-around" class=" mt-3 ml-2">
              <v-col cols="10">
                <v-row>
                  <v-icon class="mr-3">
                    mdi-note-multiple-outline

                  </v-icon>
                  <span class="t-h5
                  "> {{ startTestInfo.testmetadata.testName }}
                  </span>
                </v-row>
              </v-col>
              <v-col align-self="end" offset="1" cols="1">
                <v-icon @click="testInstructionsDialog = false">
                  mdi-close
                </v-icon>
              </v-col>
            </v-row>
            <v-row class="mt-5 mb-6">
              <v-divider></v-divider>
            </v-row>
            <v-row class=" px-4">
              <HtmlRender class="htmlClass" :html-string="startTestInfo.testmetadata.instructions"></HtmlRender>

            </v-row>
          </v-card-text>

        </v-card>
      </v-dialog> -->

      <!-- <v-dialog absolute class="bottom dialog-bx" max-width="800px" v-model="reportDialog">
        <FeedbackComponent @closeDialog="reportDialog = false" test-type="quiz" :q-id="currentQ.qId"
          :test-id="currentTestId" :test-instance-id="startTestInfo.testInstanceId" :currentQ="currentQ"></FeedbackComponent>
      </v-dialog> -->

      

      <!-- 2 columns layout -->
      <v-container v-if="$vuetify.display.mdAndUp && currentQ.columnLayout == 2" fluid class="">
        <v-row justify="center">
          <v-col cols="12">
            <v-card elevation="0" class="" color="tranparent">
              <v-alert type="error" title="Please try attempting previous question again!"
                v-if="showQuestionAttemptAlert" class="mx-8 mt-4 mb-8"
                text="Error has occurred while saving previous question to server. Please check your internet connection or try attempting previous question again. If this issue still persists, please contact LMS Support Team!"></v-alert>
              <section class="mx-5 d-flex">
                <div class="w-50 py-6 mr-5 px-5 slide-height border-right">
                  <section>
                    <div v-html="getMarkDownHtml(currentQ.qText)" class="htmlClassTest"></div>
                  </section>
                </div>

                <div class="w-50 px-5 pt-6 slide-height">
                  <div class="mb-5">
                    <v-row class="align-center" style="width: 100%">
                      <span class="mx-3 qNoClass font-weight-bold">
                        Question {{ currentQ.qNo }}
                      </span>
                      <div class="ml-5" v-if="currentQResponse.markedForReview">
                        <v-tooltip text="Mark For Review" location="start">
                          <template v-slot:activator="{ props }">
                            <v-btn elevation="0" theme="dark" v-bind="props" size="small"
                              @click="currentQResponse.markedForReview = false" color="#FF894F" icon="mdi-flag"></v-btn>
                          </template>
                        </v-tooltip>
                      </div>
                      <div class="ml-5" v-else>
                        <v-tooltip text="Mark For Review" location="start">
                          <template v-slot:activator="{ props }">
                            <v-btn size="small" v-bind="props" class="outline-color" variant="outlined"
                              @click="currentQResponse.markedForReview = true" icon="mdi-flag-outline"></v-btn>
                          </template>
                        </v-tooltip>
                      </div>
                      <div v-if="currentQ.qType == '1' || currentQ.qType == '2'">
                        <div class="ml-5" v-if="currentQResponse.crossedOutOptions">
                          <v-tooltip text="Cross out options" location="end">
                            <template v-slot:activator="{ props }">
                              <v-btn elevation="0" theme="dark" v-bind="props" size="small"
                                @click="hideCrossedOutOptions()" color="primaryPurple"
                                icon="mdi-alphabetical-variant-off"></v-btn>
                            </template>
                          </v-tooltip>
                        </div>
                        <div class="ml-5" v-else>
                          <v-tooltip text="Cross out options" location="end">
                            <template v-slot:activator="{ props }">
                              <v-btn size="small" v-bind="props" class="outline-color" variant="outlined"
                                @click="showCrossedOutOptions()" icon="mdi-alphabetical-variant-off"></v-btn>
                            </template>
                          </v-tooltip>
                        </div>
                      </div>

                      <v-spacer> </v-spacer>

                      <v-tooltip :text="feedbackOrQuestionReiewIconTooltipText" location="end">
                        <template v-slot:activator="{ props }">
                          <v-btn size="small" v-bind="props" variant="outlined" class="outline-color"
                            @click="showReportDialog()" :icon="feedbackOrQuestionReiewIconName"></v-btn>
                        </template>
                      </v-tooltip>
                    </v-row>
                  </div>
                  <v-divider class="mb-3"></v-divider>
                  <section>
                    <div v-html="getMarkDownHtml(currentQ.qTextForSecondColumn)" class="htmlClassTest mb-4"></div>
                  </section>
                  <!-- case 1 -->
                  <section v-if="currentQ.qType == '1'">
                    <v-radio-group v-model="currentQResponse.checkedOption" :ripple="false">
                      <v-row v-for="option in currentQ.options" :key="option.key" style="position: relative"
                        align="center">
                        <v-col cols="" class="">
                          <v-hover v-slot="{ isHovering, props }">
                            <v-sheet :class="{
                              'on-hover':
                                isHovering && $vuetify.display.smAndUp,
                              selected:
                                currentQResponse.checkedOption == option.key,
                            }" v-bind="props" class="pa-0 ma-0 bx" style="position: relative">
                              <div class="strike" v-if="checkOptionExistsInCrossedList(option.key) &&
                                currentQResponse.crossedOutOptions
                                "></div>
                              <v-radio :ripple="false" density="compact" color="white"
                                class="ma-0 pa-0 w-100  custom-radio testOption" dense :value="option.key" :class="{
                                  disabledOpacity:
                                    checkOptionExistsInCrossedList(
                                      option.key
                                    ) && currentQResponse.crossedOutOptions,
                                }">
                                <div class="strike" v-if="checkOptionExistsInCrossedList(
                                  option.key
                                ) && currentQResponse.crossedOutOptions
                                  "></div>
                                <template v-slot:label>
                                  <v-row class=" ma-0 pa-0 pr-3 pl-3" align="center">
                                    <v-col class="ma-0 pa-0" cols="auto">
                                      <v-avatar elevation="2" size="25" class="t-h6 " variant="outlined" :class="{
                                        'outline-color-white  text-primaryPurple': currentQResponse.checkedOption == option.key,
                                        'outline-color-grey  ': !(currentQResponse.checkedOption == option.key),

                                      }">
                                        {{ option.key.toUpperCase() }}
                                      </v-avatar>
                                    </v-col>
                                    <v-col class="ma-0 pa-0">
                                      <div class="htmlClassTest htmlClassTestOption ml-2 py-4 w-100 "
                                        v-html="getMarkDownHtml(option.value)"> </div>
                                    </v-col>

                                  </v-row>

                                </template>
                              </v-radio>
                            </v-sheet>
                          </v-hover>
                        </v-col>
                        <v-col cols="auto" class="px-0 mx-0" v-if="currentQResponse.crossedOutOptions">
                          <v-avatar class="outline-color text-capitalize text-decoration-line-through" variant="outlined"
                            elevation="0" size="24" v-if="!checkOptionExistsInCrossedList(option.key)"
                            @click="updateCrossedOptions(option.key, true)" style="cursor: pointer">
                            {{ option.key }}
                            <!-- <div class="strike pr-3" >

                            </div> -->
                          </v-avatar>
                          <v-avatar v-else class="outline-color" elevation="0" size="24"
                            @click="updateCrossedOptions(option.key, false)" style="cursor: pointer">
                            <v-icon> mdi-rotate-right </v-icon>
                          </v-avatar>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </section>

                  <section v-if="currentQ.qType == '2'">
                    <v-row v-for="option in currentQ.options" :key="option.key" align="center">
                      <v-col cols="">
                        <v-hover v-slot="{ isHovering, props }">
                          <v-sheet :class="{
                            'on-hover':
                              isHovering && $vuetify.display.smAndUp,
                            selected: getMultichoiceSelected(option.key),
                          }" v-bind="props" class="pa-0  ma-0 bx" style="position: relative">
                            <div class="strike" v-if="checkOptionExistsInCrossedList(option.key) &&
                              currentQResponse.crossedOutOptions
                              "></div>

                            <v-checkbox density="compact" multiple hide-details size color="white"
                              v-model="currentQResponse.checkedOptions"
                              class="tqp ma-0 pa-0 w-100 testOption custom-radio" dense :value="option.key" :class="{
                                disabledOpacity:
                                  checkOptionExistsInCrossedList(option.key) &&
                                  currentQResponse.crossedOutOptions,
                              }">
                              <template v-slot:label>
                                <v-row class=" ma-0 pa-0 pr-3 pl-3" align="center">
                                  <v-col class="ma-0 pa-0" cols="auto">
                                    <v-avatar elevation="2" size="25" class="t-h6 " variant="outlined" :class="{
                                      'outline-color-white  text-primaryPurple': getMultichoiceSelected(option.key),
                                      'outline-color-grey  ': !(getMultichoiceSelected(option.key)),

                                    }">
                                      {{ option.key.toUpperCase() }}
                                    </v-avatar>
                                  </v-col>
                                  <v-col class="ma-0 pa-0">
                                    <div class="htmlClassTest htmlClassTestOption ml-2 py-4 w-100 "
                                      v-html="getMarkDownHtml(option.value)"> </div>
                                  </v-col>

                                </v-row>

                              </template>
                            </v-checkbox>
                          </v-sheet>
                        </v-hover>
                      </v-col>
                      <v-col cols="auto" class="px-0 mx-0" v-if="currentQResponse.crossedOutOptions">
                        <v-avatar class="outline-color text-capitalize text-decoration-line-through" variant="outlined"
                          elevation="0" size="24" v-if="!checkOptionExistsInCrossedList(option.key)"
                          style="cursor: pointer" @click="updateCrossedOptions(option.key, true)">
                          {{ option.key }}
                          <!-- <div class="strike pr-3" >

                            </div> -->
                        </v-avatar>
                        <v-avatar v-else class="outline-color" elevation="0" size="24"
                          @click="updateCrossedOptions(option.key, false)" style="cursor: pointer">
                          <v-icon> mdi-rotate-right </v-icon>
                        </v-avatar>
                      </v-col>
                    </v-row>
                  </section>

                  <section v-if="currentQ.qType == '3'">
                    <v-row dense>
                      <v-col cols="8" md="3">
                        <v-text-field label="Answer" v-model="currentQResponse.integerOption"
                          :rules="currentSection.subjectType == 'math' ? mathRules : []" :hide-details="true"
                          :maxLength="currentSection.subjectType == 'math' ? (currentQResponse.integerOption[0] == '-' ? 6 : 5) : null"
                          @keyup="isMobile?((currentSection.subjectType == 'math') ? integerChangedValue() : null) :null"
                          @keydown="isMobile?null:((currentSection.subjectType == 'math') ? checkValue($event) : null)"
                          @dragover="currentSection.subjectType == 'math' ? preventDrag($event) : null"
                          @paste="currentSection.subjectType == 'math' ? preventDrag($event) : null"
                          @drop="currentSection.subjectType == 'math' ? preventDrag($event) : null"
                            @cut="currentSection.subjectType == 'math' ? preventDrag($event) : null"
                          variant="outlined"></v-text-field>
                      </v-col>
                      <v-col cols="12" class="ma-0 pa-0"
                        v-if="currentSection.subjectType == 'math' && mathFormatError(currentQResponse.integerOption)">
                        <span class="t-h6 text-red"> <v-icon color="red" size="14">mdi-alert-circle-outline</v-icon>You've
                          entered a decimal point or slash in the wrong position.</span>
                      </v-col>
                    </v-row>
                  </section>

                  <section v-if="currentQ.qType == '4'">
                    <v-row dense>
                      <v-col cols="8" md="3">
                        <v-text-field label="Answer" v-model="currentQResponse.decimalOption"
                          :rules="currentSection.subjectType == 'math' ? mathRules : []" :hide-details="true"
                          :maxLength="currentSection.subjectType == 'math' ? (currentQResponse.decimalOption[0] == '-' ? 6 : 5) : null"
                          @keyup="isMobile?((currentSection.subjectType == 'math') ? decimalChangedValue() : null) :null"
                          @keydown="isMobile?null:((currentSection.subjectType == 'math') ? checkValue($event) : null)"
                          @dragover="currentSection.subjectType == 'math' ? preventDrag($event) : null"
                          @paste="currentSection.subjectType == 'math' ? preventDrag($event) : null"
                          @drop="currentSection.subjectType == 'math' ? preventDrag($event) : null"
                            @cut="currentSection.subjectType == 'math' ? preventDrag($event) : null"
                          variant="outlined"></v-text-field>
                      </v-col>
                      <v-col cols="12" class="ma-0 pa-0"
                        v-if="currentSection.subjectType == 'math' && mathFormatError(currentQResponse.decimalOption)">
                        <span class="t-h6 text-red"> <v-icon color="red" size="14">mdi-alert-circle-outline</v-icon>You've
                          entered a decimal point or slash in the wrong position.</span>
                      </v-col>
                    </v-row>
                  </section>
                </div>
              </section>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <!-- Single column layout -->
      <v-container v-else fluid class="">
        <v-row justify="center">
          <v-col cols="12">
            <v-card elevation="0" class="" color="tranparent">
              <v-alert type="error" title="Please try attempting previous question again!"
              v-if="showQuestionAttemptAlert" class="mx-8 mt-4 mb-8"
              text="Error has occurred while saving previous question to server. Please check your internet connection or try attempting previous question again. If this issue still persists, please contact LMS Support Team!"></v-alert>
              <section :class="{
                'single-layout-question':
                  $vuetify.display.mdAndUp &&
                  !(['3', '4'].includes(currentQ.qType) &&
                    currentSection.subjectType == 'math'),
                'mx-5 d-flex':
                  !$vuetify.display.xs &&
                  ['3', '4'].includes(currentQ.qType) &&
                  currentSection.subjectType == 'math',
                'slide-height small-height': !$vuetify.display.mdAndUp,
                'slide-in': $vuetify.display.mdAndUp && calculatorDialog && isSlideTranslation && !['3', '4'].includes(currentQ.qType) && currentSection.subjectType == 'math',
                'slide-out': $vuetify.display.mdAndUp && !calculatorDialog && isSlideTranslation && !['3', '4'].includes(currentQ.qType) && currentSection.subjectType == 'math',
              }">
                <div v-if="currentSection.subjectType == 'math'" :class="{
                  'py-6  d-flex flex-column': ['3', '4'].includes(
                    currentQ.qType
                  ),
                  'd-none': !['3', '4'].includes(currentQ.qType),
                  'w-50 slide-height mr-5 px-5 border-right':
                    !$vuetify.display.xs &&
                    ['3', '4'].includes(currentQ.qType),
                  'w-100':
                    $vuetify.display.xs &&
                    ['3', '4'].includes(currentQ.qType),
                }">
                  <div class="mb-1 t-h125">
                    Student-produced response directions
                  </div>
                  <ul class="type-circle">
                    <li>
                      If you find
                      <strong>more than one correct answer,</strong> enter only
                      one answer.
                    </li>
                    <li>
                      You can enter up to 5 characters for a
                      <strong>positive</strong> answer and up to 6 characters
                      (including the negative sign) for a negative answer.
                    </li>
                    <li>
                      If your answer is a <strong>fraction</strong> that doesn't
                      fit in the provided space, enter the decimal equivalent.
                    </li>
                    <li>
                      If your answer is a <strong>decimal</strong> that doesn't
                      fit in the provided space, enter it by truncating or
                      rounding at the fourth digit.
                    </li>
                    <li>
                      If your answer is a <strong>mixed number</strong> (such as
                      3 &frac12;), enter it as an improper fraction (7/2) or its
                      decimal equivalent (3.5).
                    </li>
                    <li>
                      Don't enter <strong>symbols</strong> such as a percent
                      sign, comma, or dollar sign.
                    </li>
                  </ul>
                  <div class="text-center mt-3">Examples</div>
                  <table class="align-center mt-2 w-100">
                    <tr>
                      <td style="width: 60px">Answer</td>
                      <td style="width: 140px">
                        Acceptable ways to enter answer
                      </td>
                      <td style="width: 140px">
                        Unacceptable: will NOT receive credit
                      </td>
                    </tr>
                    <tr>
                      <td><strong>3.5</strong></td>
                      <td>
                        <div>3.5</div>
                        <div>3.50</div>
                        <div>7/2</div>
                      </td>
                      <td>
                        <div>31/2</div>
                        <div>3 1/2</div>
                      </td>
                    </tr>
                    <tr>
                      <td><strong><span class="math display MathJax_Display text-center">\[\frac{2}{3}\]</span></strong></td>
                      <td>
                        <div>2/3</div>
                        <div>.6666</div>
                        <div>.6667</div>
                        <div>.666</div>
                        <div>.667</div>
                      </td>
                      <td>
                        <div>0.66</div>
                        <div>.66</div>
                        <div>0.67</div>
                        <div>.67</div>
                      </td>
                    </tr>
                    <tr>
                      <td><strong><span class="math display">\[ - \frac{1}{3}\]</span></strong></td>
                      <td>
                        <div>-1/3</div>
                        <div>-.3333</div>
                        <div>-0.333</div>
                      </td>
                      <td>
                        <div>-.33</div>
                        <div>-0.33</div>
                      </td>
                    </tr>
                  </table>
                </div>
                <div :class="{
                  'px-5':
                    ['3', '4'].includes(currentQ.qType) &&
                    currentSection.subjectType == 'math',
                  'w-50 slide-height':
                    !$vuetify.display.xs &&
                    ['3', '4'].includes(currentQ.qType) &&
                    currentSection.subjectType == 'math',
                  'w-100 slide-height':
                    $vuetify.display.xs &&
                    ['3', '4'].includes(currentQ.qType) &&
                    currentSection.subjectType == 'math',
                }" class="pt-6">
                  <div class="mb-5">
                    <v-row class="align-center" style="width: 100%">
                      <span class="mx-3 qNoClass font-weight-bold">
                        Question {{ currentQ.qNo }}
                      </span>
                      <div class="ml-5" v-if="currentQResponse.markedForReview">
                        <v-tooltip text="Mark For Review" location="start">
                          <template v-slot:activator="{ props }">
                            <v-btn elevation="0" theme="dark" v-bind="props" size="small"
                              @click="currentQResponse.markedForReview = false" color="#FF894F" icon="mdi-flag"></v-btn>
                          </template>
                        </v-tooltip>
                      </div>
                      <div class="ml-5" v-else>
                        <v-tooltip text="Mark For Review" location="start">
                          <template v-slot:activator="{ props }">
                            <v-btn size="small" v-bind="props" class="outline-color" variant="outlined"
                              @click="currentQResponse.markedForReview = true" icon="mdi-flag-outline"></v-btn>
                          </template>
                        </v-tooltip>
                      </div>
                      <div v-if="currentQ.qType == '1' || currentQ.qType == '2'">
                        <div class="ml-5" v-if="currentQResponse.crossedOutOptions">
                          <v-tooltip text="Cross out options" location="end">
                            <template v-slot:activator="{ props }">
                              <v-btn elevation="0" theme="dark" v-bind="props" size="small"
                                @click="hideCrossedOutOptions()" color="primaryPurple"
                                icon="mdi-alphabetical-variant-off"></v-btn>
                            </template>
                          </v-tooltip>
                        </div>
                        <div class="ml-5" v-else>
                          <v-tooltip text="Cross out options" location="end">
                            <template v-slot:activator="{ props }">
                              <v-btn size="small" v-bind="props" class="outline-color" variant="outlined"
                                @click="showCrossedOutOptions()" icon="mdi-alphabetical-variant-off"></v-btn>
                            </template>
                          </v-tooltip>
                        </div>
                      </div>

                      <v-spacer> </v-spacer>

                      <v-tooltip text="Report a problem" location="end">
                        <template v-slot:activator="{ props }">
                          <v-btn size="small" v-bind="props" variant="outlined" class="outline-color"
                            @click="showReportDialog()" :icon="feedbackOrQuestionReiewIconName"></v-btn>
                        </template>
                      </v-tooltip>
                    </v-row>
                  </div>
                  <v-divider class="mb-3"></v-divider>
                  <section>
                    <div v-html="getMarkDownHtml(currentQ.qText)" class="htmlClassTest"></div>
                  </section>
                  <section>
                    <div v-html="getMarkDownHtml(currentQ.qTextForSecondColumn)" class="htmlClassTest mt-1"></div>
                  </section>

                  <div class="mt-15">
                    <!-- case 1 -->
                    <section v-if="currentQ.qType == '1'">
                      <v-radio-group v-model="currentQResponse.checkedOption" :ripple="false">
                        <v-row v-for="option in currentQ.options" :key="option.key" style="position: relative"
                          align="center">
                          <v-col cols="" class="">
                            <v-hover v-slot="{ isHovering, props }">
                              <v-sheet :class="{
                                'on-hover':
                                  isHovering && $vuetify.display.smAndUp,
                                selected:
                                  currentQResponse.checkedOption ==
                                  option.key,
                              }" v-bind="props" class="pa-0  ma-0 bx" style="position: relative">
                                <div class="strike" v-if="checkOptionExistsInCrossedList(
                                  option.key
                                ) && currentQResponse.crossedOutOptions
                                  "></div>
                                <v-radio :ripple="false" density="compact" color="white"
                                  class="ma-0 pa-0 w-100 testOption  custom-radio" dense :value="option.key" :class="{
                                    disabledOpacity:
                                      checkOptionExistsInCrossedList(
                                        option.key
                                      ) && currentQResponse.crossedOutOptions,
                                  }">
                                  <div class="strike" v-if="checkOptionExistsInCrossedList(
                                    option.key
                                  ) && currentQResponse.crossedOutOptions
                                    "></div>
                                  <template v-slot:label>
                                    <v-row class=" ma-0 pa-0 pr-3 pl-3" align="center">
                                      <v-col class="ma-0 pa-0" cols="auto">
                                        <v-avatar elevation="2" size="25" class="t-h6 " variant="outlined" :class="{
                                          'outline-color-white  text-primaryPurple': currentQResponse.checkedOption == option.key,
                                          'outline-color-grey  ': !(currentQResponse.checkedOption == option.key),

                                        }">
                                          {{ option.key.toUpperCase() }}
                                        </v-avatar>
                                      </v-col>
                                      <v-col class="ma-0 pa-0">
                                        <div class="htmlClassTest htmlClassTestOption ml-2 py-4 w-100 "
                                          v-html="getMarkDownHtml(option.value)"> </div>
                                      </v-col>

                                    </v-row>

                                  </template>

                                </v-radio>
                              </v-sheet>
                            </v-hover>
                          </v-col>
                          <v-col cols="auto" class="px-0 mx-0 mr-4" v-if="currentQResponse.crossedOutOptions">
                            <v-avatar class="outline-color text-capitalize text-decoration-line-through"
                              variant="outlined" elevation="0" size="24"
                              v-if="!checkOptionExistsInCrossedList(option.key)"
                              @click="updateCrossedOptions(option.key, true)" style="cursor: pointer">
                              {{ option.key }}
                              <!-- <div class="strike pr-3" >

                            </div> -->
                            </v-avatar>
                            <v-avatar v-else class="outline-color" elevation="0" size="24"
                              @click="updateCrossedOptions(option.key, false)" style="cursor: pointer">
                              <v-icon> mdi-rotate-right </v-icon>
                            </v-avatar>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </section>

                    <section v-if="currentQ.qType == '2'">
                      <v-row v-for="option in currentQ.options" :key="option.key" align="center">
                        <v-col cols="">
                          <v-hover v-slot="{ isHovering, props }">
                            <v-sheet :class="{
                              'on-hover':
                                isHovering && $vuetify.display.smAndUp,
                              selected: getMultichoiceSelected(option.key),
                            }" v-bind="props" class="pa-0  ma-0 bx" style="position: relative">
                              <div class="strike" v-if="checkOptionExistsInCrossedList(option.key) &&
                                currentQResponse.crossedOutOptions
                                "></div>

                              <v-checkbox density="compact" multiple hide-details size color="white"
                                v-model="currentQResponse.checkedOptions"
                                class="tqp ma-0 pa-0 w-100 testOption custom-radio" dense :value="option.key" :class="{
                                  disabledOpacity:
                                    checkOptionExistsInCrossedList(
                                      option.key
                                    ) && currentQResponse.crossedOutOptions,
                                }">
                                <template v-slot:label>
                                  <v-row class=" ma-0 pa-0 pr-3 pl-3" align="center">
                                    <v-col class="ma-0 pa-0" cols="auto">
                                      <v-avatar elevation="2" size="25" class="t-h6 " variant="outlined" :class="{
                                        'outline-color-white  text-primaryPurple': getMultichoiceSelected(option.key),
                                        'outline-color-grey  ': !(getMultichoiceSelected(option.key)),

                                      }">
                                        {{ option.key.toUpperCase() }}
                                      </v-avatar>
                                    </v-col>
                                    <v-col class="ma-0 pa-0">
                                      <div class="htmlClassTest htmlClassTestOption ml-2 py-4 w-100 "
                                        v-html="getMarkDownHtml(option.value)"> </div>
                                    </v-col>

                                  </v-row>

                                </template>
                              </v-checkbox>
                            </v-sheet>
                          </v-hover>
                        </v-col>
                        <v-col cols="auto" class="px-0 mx-0 mr-4" v-if="currentQResponse.crossedOutOptions">
                          <v-avatar class="outline-color text-capitalize text-decoration-line-through" variant="outlined"
                            elevation="0" size="24" v-if="!checkOptionExistsInCrossedList(option.key)"
                            style="cursor: pointer" @click="updateCrossedOptions(option.key, true)">
                            {{ option.key }}
                            <!-- <div class="strike pr-3" >

                            </div> -->
                          </v-avatar>
                          <v-avatar v-else class="outline-color" elevation="0" size="24"
                            @click="updateCrossedOptions(option.key, false)" style="cursor: pointer">
                            <v-icon> mdi-rotate-right </v-icon>
                          </v-avatar>
                        </v-col>
                      </v-row>
                    </section>

                    <section v-if="currentQ.qType == '3'">
                      <v-row dense>
                        <v-col cols="8" md="3">
                          <v-text-field label="Answer" v-model="currentQResponse.integerOption"
                            :rules="currentSection.subjectType == 'math' ? mathRules : []" :hide-details="true"
                            :maxLength="currentSection.subjectType == 'math' ? (currentQResponse.integerOption[0] == '-' ? 6 : 5) : null"
                            @keyup="isMobile?((currentSection.subjectType == 'math') ? integerChangedValue() : null) :null"
                            @keydown="isMobile?null:((currentSection.subjectType == 'math') ? checkValue($event) : null)"
                            @dragover="currentSection.subjectType == 'math' ? preventDrag($event) : null"
                            @paste="currentSection.subjectType == 'math' ? preventDrag($event) : null"
                            @drop="currentSection.subjectType == 'math' ? preventDrag($event) : null"
                            @cut="currentSection.subjectType == 'math' ? preventDrag($event) : null"
                            variant="outlined"></v-text-field>
                        </v-col>
                        <v-col cols="12" class="ma-0 pa-0"
                          v-if="currentSection.subjectType == 'math' && mathFormatError(currentQResponse.integerOption)">
                          <span class="t-h6 text-red"> <v-icon color="red"
                              size="14">mdi-alert-circle-outline</v-icon>You've entered a decimal point or slash in the
                            wrong position.</span>
                        </v-col>
                      </v-row>
                    </section>

                    <section v-if="currentQ.qType == '4'">
                      <v-row dense>
                        <v-col cols="8" md="3">
                          <v-text-field label="Answer" v-model="currentQResponse.decimalOption"
                            :rules="currentSection.subjectType == 'math' ? mathRules : []" :hide-details="true"
                            :maxLength="currentSection.subjectType == 'math' ? (currentQResponse.decimalOption[0] == '-' ? 6 : 5) : null"
                            @keyup="isMobile?((currentSection.subjectType == 'math') ? decimalChangedValue() : null) :null"
                          @keydown="isMobile?null:((currentSection.subjectType == 'math') ? checkValue($event) : null)"
                            @dragover="currentSection.subjectType == 'math' ? preventDrag($event) : null"
                            @paste="currentSection.subjectType == 'math' ? preventDrag($event) : null"
                            @drop="currentSection.subjectType == 'math' ? preventDrag($event) : null"
                            @cut="currentSection.subjectType == 'math' ? preventDrag($event) : null"
                            variant="outlined"></v-text-field>
                        </v-col>
                        <v-col cols="12" class="ma-0 pa-0"
                          v-if="currentSection.subjectType == 'math' && mathFormatError(currentQResponse.decimalOption)">
                          <span class="t-h6 text-red"> <v-icon color="red"
                              size="14">mdi-alert-circle-outline</v-icon>You've entered a decimal point or slash in the
                            wrong position.</span>
                        </v-col>
                      </v-row>
                    </section>
                  </div>
                </div>
              </section>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-footer v-if="!reviewScreen && dataReady" app class="bg-background pa-5" elevation="2">
      <v-row justify="space-between" class="align-center">
        <v-col class="align-center">
          <v-btn v-if="$vuetify.display.smAndUp" class="t-btn text-capitalize btn-prev" :disabled="prevButtonDisabled"
            @click="prevQuestion" :size="$vuetify.display.smAndUp ? 'large' : 'default'"
            :width="$vuetify.display.mdAndUp ? '130' : '125'" elevation="0"
            :color="!prevButtonDisabled ? 'secondaryBackground' : undefined">
            Previous
            <template v-slot:prepend>
              <v-icon>mdi-chevron-left</v-icon>
            </template>
          </v-btn>

          <v-btn v-else density="compact" size="large" icon="mdi-chevron-left" :disabled="prevButtonDisabled"
            @click="prevQuestion">
          </v-btn>

          <v-btn v-if="$vuetify.display.mdAndUp" size="large" class="t-btn text-none text-black ml-3"
            @click="clearResponse" elevation="0" color="transparent">
            Clear response
            <template v-slot:prepend>
              <v-icon color="primaryPurple">mdi-rotate-right</v-icon>
            </template>
          </v-btn>
          <v-icon v-else class="ml-2" color="primaryPurple" @click="clearResponse" elevation="0">
            mdi-rotate-right
          </v-icon>
        </v-col>

        <v-col cols="auto">
          <v-btn @click="openQuestionDialog()" color="secondaryBackground" density="compact" elevation="0" size="large"
            :icon="questionDialog == true ? 'mdi-chevron-down' : 'mdi-chevron-up'
              "></v-btn>
          <span class="ml-2 t-1remReg">
            {{ currentQ.qNo }} of
            {{
              currentTestJson[currentTestState.sectionIndex].questions.length
            }}
          </span>
        </v-col>

        <v-col class="text-end align-center">
          <v-btn v-if="$vuetify.display.smAndUp" class="t-btn text-capitalize btn-grad text-primaryTextColor"
            :size="$vuetify.display.smAndUp ? 'large' : 'default'" :width="$vuetify.display.mdAndUp ? '130' : '125'"
            elevation="0" @click="nextQuestion">
            Next
            <template v-slot:append>
              <v-icon color="white">mdi-chevron-right</v-icon>
            </template>
          </v-btn>
          <v-btn v-else density="compact" size="large" icon="mdi-chevron-right" @click="nextQuestion"
            color="primaryPurple">
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import _ from "lodash";
import { useDisplay } from "vuetify";
import DragableFeedbackDialog from "@/components/Test/TestQuestionPageComponents/DragableFeedbackDialog.vue";
import HtmlRender from "@/components/Test/HtmlRender.vue";
import Vue3DraggableResizable from 'vue3-draggable-resizable'
//default styles
import 'vue3-draggable-resizable/dist/Vue3DraggableResizable.css'

import {
  AnalyticsEvents,
  recordAnalyticsEvent,
} from "@/services/awsPinpointAnalyticsService";
import html2canvas from "html2canvas";
import {setLocalForageItem } from "@/services/localforageService";

const marked = require("marked");

const renderer = new marked.Renderer();
renderer.link = function link(href, title, text) {
  return `<a href="${href}" title="${title}" target="_blank">${text}</a>`;
};
export default {
  name: "TestQuestionPage",
  setup() {
    // Destructure only the keys we want to use
    const { mdAndDown, xs, mdAndUp, width, height } = useDisplay();

    return { xs, mdAndDown, mdAndUp, width, height };
  },
  data: () => ({
    testType: "test",
    submitLoading: false,
    isMobile: false,
    showingTime: true,
    questionDialog: false,
    calculatorDialog: false,
    isCalculatorExpanded: false,
    isSlideTranslation: false,
    referenceDialog: false,
    submitTimer: null,
    submitTimerTime: 60,
    baseLineClockDrift: 0,
    timeOver: false,
    reportDialog: false,
    remaningTimeInSeconds: 0,
    warningDialog: false,
    warningTimeLeft: 10,
    serverTimeCounter: 0,
    intervalId: null,
    serverTime: null,
    reviewScreen: false,
    instructionsDialog: false,
    testInstructionsDialog: false,
    showSubmitAlert: false,
    showQuestionAttemptAlert: false,
    dataReady: false,
    curentQDuration: {
      qId: "",
      startTime: "",
      endTime: "",
      sentToServer: false,
    },

    currentQResponse: {
      qId: "",
      qType: "",
      checkedOption: "",
      checkedOptions: [],
      integerOption: "",
      decimalOption: "",
      markedForReview: false,
      crossedOutOptions: false,
      crossedOutOptionsList: [],
    },
    defualtCurrentQResponse: {
      qId: "",
      qType: "",
      checkedOption: "",
      checkedOptions: [],
      integerOption: "",
      decimalOption: "",
      markedForReview: false,
      crossedOutOptions: false,
      crossedOutOptionsList: [],
    },
    calculatorDimension: {
      x: 50,
      y: 50,
      h: 600,
      w: 450,
    },
    selectedReferenceDimension: {},
    referenceDimensionMobile: {
      x: 10,
      y: 10,
      h: 400,
      w: 320,
    },
    referenceDimension: {
      x: 400,
      y: 100,
      h: 500,
      w: 500,
    },
    geometriesImages: [
      require('@/assets/geometries/1.png'),
      require('@/assets/geometries/2.png'),
      require('@/assets/geometries/3.png'),
      require('@/assets/geometries/4.png'),
      require('@/assets/geometries/5.png'),
      require('@/assets/geometries/6.png'),
      require('@/assets/geometries/7.png'),
      require('@/assets/geometries/8.png'),
      require('@/assets/geometries/9.png'),
      require('@/assets/geometries/10.png'),
    ],
    mathRules: [(v) => /^-?(\d*)?(\d*\.\d*)?(\d+\/(\d*\.\d*)?\d*)?$/.test(v) || "You've entered a decimal point or slash in the wrong position.",],
    draggable: false,
		referenceDraggable: false
  }),
  components: {
    HtmlRender,
    DragableFeedbackDialog,
    Vue3DraggableResizable
  },
  unmounted() {
    this.$store.commit("updateShowBackground", true);
  },
  async created() {
    this.isMobile = navigator.userAgent.match(/Android|iPhone|iPad|iPod/i);
    this.dataReady = false;
    this.$store.commit("updateShowBackground", false);
    recordAnalyticsEvent(AnalyticsEvents.v2MockTestQuestionScreenOpen, {
      testId: this.currentTestId,
      testInstanceId: this.startTestInfo.testInstanceId,
      moduleId: this.selectedTest.moduleId,
      questionId: this.currentQ.qId,
    });

    if ("type" in this.$route.query) {
      let testType = this.$route.query.type;
      this.testType = testType;
    }

    this.remaningTimeInSeconds =
      this.syncTimerData.testRemainingDurationInSeconds;
    console.log("remaining time", this.remaningTimeInSeconds, this.syncTimerData );
    this.startTimer();
    this.$store.commit("updateTestRoutingAllowed", false);

    this.serverTime = this.syncTimerData.serverTimeStamp;
    this.curentQDuration.startTime = this.serverTime;
    this.baseLineClockDrift = Math.abs(
      this.getCurrentServerTime() - this.getCurrentClientTimeInSeconds()
    );
    // console.log(this.getCurrentServerTime());
    // console.log(this.getCurrentClientTimeInSeconds());
    this.setQIdAndQTypeAfterPrevAndNext();
    this.dataReady = true;

    setTimeout(() => {
      window.MathJax.typesetPromise();
      this.instructionsDialog = true;
    }, 0);
  },
  computed: {
    ...mapState([
      "currentTestJson",
      "currentTestState",
      "startTestInfo",
      "qResponsesState",
      "syncTimerData",
      "currentTestId",
      "submitError",
      "selectedTest",
      "questionAttemptError",
    ]),
    ...mapGetters(["isQuestionReviewer", "isLevel1Reviewer", "isLevel2Reviewer", "isLevel3Reviewer"]),
    currentQ: {
      get() {
        return this.currentTestJson[this.currentTestState.sectionIndex]
          .questions[this.currentTestState.qIndex];
      },
    },
    currentSection: {
      get() {
        return this.startTestInfo.testmetadata.sections[
          this.currentTestState.sectionIndex
        ];
      },
    },
    questionCounts: {
      get() {
        let res = {
          answered: 0,
          marked: 0,
          unanswered: 0,
          total:
            this.currentTestJson[this.currentTestState.sectionIndex].questions
              .length,
        };

        let arr = [];

        for (let item of this.qResponsesState) {
          arr.push(...item.responses);
        }

        for (let item of arr) {
          if (item.markedForReview) {
            res.marked++;
          }
          if (this.checkIfQuestionAnswered(item)) {
            res.answered++;
          } else {
            res.unanswered++;
          }
        }
        return res;
      },
    },
    prevButtonDisabled: {
      get() {
        return this.currentTestState.qIndex == 0 &&
          this.currentTestState.sectionIndex == 0
          ? true
          : false;
      },
    },
    getRemainingTime: {
      get() {
        if (this.intervalId == null) {
          return "";
        }
        const res = this.toHoursAndMinutes(this.remaningTimeInSeconds);
        
        // let result = `${this.minTwoDigits(res.h)> 0? this.minTwoDigits(res.h) : } : ${this.minTwoDigits(res.m)} : ${this.minTwoDigits(res.s)}`
        let result = (this.minTwoDigits(res.h)> 0? this.minTwoDigits(res.h) + ' : ' : '') + this.minTwoDigits(res.m) + ' : ' + this.minTwoDigits(res.s);
        // console.log("response remaining time", res, result);
        return result;
      },
    },
    submitFormattedTime: {
      get() {
        if (this.submitTimer == null) {
          return "";
        }
        const res = this.toHoursAndMinutes(this.submitTimerTime);
        return ` ${this.minTwoDigits(res.m)} : ${this.minTwoDigits(res.s)}`;
      },
    },
    feedbackOrQuestionReiewIconName: {
			get() {
				var retVal = "mdi-message-alert-outline"; //"Report a Problem" for users/students
				if(this.isQuestionReviewer){
					retVal = "mdi-shield-check-outline"; // for internal Question reviewers
				}
				return retVal;
			},
		},
		feedbackOrQuestionReiewIconTooltipText: {
			get() {
				var retVal = "Report a problem" ////"Report a Problem" for users/students
				if(this.isQuestionReviewer){
					retVal = "Review Feedback & Corrections"; // for internal Question reviewers
				}
				return retVal;
			},
		},
  },

  watch: {
    remaningTimeInSeconds(val) {
      if (val == 600) {
        // this.warningDialog = true;
        this.warningTimeLeft = 10;
      }
      if (val == 120) {
        // this.warningDialog = true;

        this.warningTimeLeft = 2;
      }
      if (val == 0) {
        clearInterval(this.intervalId);
        // window.alert("time over")
        this.intervalId = null;
        this.updateNextQDurationAndSendLastQToServer();
        this.timeOver = true;
        this.reviewScreen = true;
        //this.submitTest()
        this.submitTimer = setInterval(() => {
          this.submitTimerTime = this.submitTimerTime - 1;
        }, 1000);
      }
    },
    submitTimerTime(val) {
      if (val == 0) {
        clearInterval(this.submitTimer);
        // console.log("submit test");
        recordAnalyticsEvent(
          AnalyticsEvents.v2MockTestModuleYesSubmitButtonClicked,
          {
            testId: this.currentTestId,
            testInstanceId: this.startTestInfo.testInstanceId,
            moduleId: this.selectedTest.moduleId,
            testSubmission: false,
          }
        );
        this.updateNextQDurationAndSendLastQToServer().then(()=>{
          this.$store.commit("updateTestRoutingAllowed", true);

          this.$store.dispatch("submitTestModule").then(() => {
            if (this.testType == "quiz") {
              this.$store.commit("appbarVisible", true);
              this.$store.commit("navbarVisible", true);
              this.$router.replace(
                "/quizreport/" +
                this.currentTestId +
                "/" +
                this.startTestInfo.testInstanceId
              );
            } else {
              this.$router.replace("/testwaitingpage");
            }
          });
        });
      }
    },
    currentTestState: {
      handler(newValue) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        if (
          newValue.qIndex == 0 &&
          this.currentSection.showSectionInstructions
        ) {
          this.instructionsDialog = true;
        }
      },
      deep: true,
    },
  },

  methods: {
    radioGroupUpdate() {
      console.log("update");
      let currentVal = this.currentQResponse.checkedOption;
      this.currentQResponse.crossedOutOptionsList =
        this.currentQResponse.crossedOutOptionsList.filter(
          (item) => item !== currentVal
        );
    },
    checkBoxesUpdate(val) {
      console.log("update checkbox", val);
      let currentVals = this.currentQResponse.checkedOptions;
      this.currentQResponse.crossedOutOptionsList =
        this.currentQResponse.crossedOutOptionsList.filter(
          (item) => !currentVals.includes(item)
        );
    },
    showCrossedOutOptions() {
      this.currentQResponse.crossedOutOptions = true;
      // let val = null
      if (this.currentQ.qType == "1") {
        this.currentQResponse.crossedOutOptionsList =
          this.currentQResponse.crossedOutOptionsList.filter(
            (item) => item !== this.currentQResponse.checkedOption
          );
      }

      if (this.currentQ.qType == "2") {
        this.currentQResponse.crossedOutOptionsList =
          this.currentQResponse.crossedOutOptionsList.filter(
            (item) => !this.currentQResponse.checkedOptions.includes(item)
          );
      }
    },
    hideCrossedOutOptions() {
      this.currentQResponse.crossedOutOptions = false;
    },
    checkOptionExistsInCrossedList(val) {
      let find =
        this.currentQResponse.crossedOutOptionsList.find((e) => e == val) ??
        null;
      if (find == null) {
        return false;
      }
      return true;
    },
    updateCrossedOptions(val, add) {
      if (add) {
        this.currentQResponse.crossedOutOptionsList.push(val);
        if (this.currentQ.qType == "1") {
          if (this.currentQResponse.checkedOption == val) {
            this.currentQResponse.checkedOption = "";
          }
        }
        if (this.currentQ.qType == "2") {
          console.log(val);
          if (this.currentQResponse.checkedOptions.includes(val)) {
            this.currentQResponse.checkedOptions =
              this.currentQResponse.checkedOptions.filter(
                (item) => item !== val
              );
          }
        }
      } else {
        this.currentQResponse.crossedOutOptionsList =
          this.currentQResponse.crossedOutOptionsList.filter(
            (item) => item !== val
          );
      }
      console.log(this.currentQResponse);
    },
    getMultichoiceSelected(key) {
      return this.currentQResponse.checkedOptions.includes(key);
    },
    getCurrentClientTimeInSeconds() {
      return Math.floor(Date.now() / 1000);
    },
    startTimer() {
      this.intervalId = setInterval(() => {
        this.remaningTimeInSeconds = this.remaningTimeInSeconds - 1;
        this.serverTimeCounter += 1;
      }, 1000);
    },
    minTwoDigits(n) {
      return (n < 10 ? "0" : "") + n;
    },
    openQFromDialog(sIndex, qIndex) {
      // don't do anything if on the same question, needs discussion

      this.updateNextQDurationAndSendLastQToServer();
      this.$store.commit("updateCurrentTestState", {
        qIndex: qIndex,
        sectionIndex: sIndex,
      });

      this.setQIdAndQTypeAfterPrevAndNext();
      this.questionDialog = false;

      setTimeout(() => {
        window.MathJax.typesetPromise();
      }, 0);
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    openQFromReview(sIndex, qIndex) {
      this.updateNextQDurationAndSendLastQToServer()

      // don't do anything if on the same question, needs discussion

      this.curentQDuration.startTime = this.getCurrentServerTime();

      this.$store.commit("updateCurrentTestState", {
        qIndex: qIndex,
        sectionIndex: sIndex,
      });

      this.setQIdAndQTypeAfterPrevAndNext();
      this.reviewScreen = false;

      setTimeout(() => {
        window.MathJax.typesetPromise();
      }, 0);
    },
    getMarkDownHtml(markdown) {
      if (markdown == null) {
        return "";
      }
      let markDownText = markdown;
      let out = false;
      const regex = /\\/gm;
      const escapedString = markDownText.replace(regex, "\\\\");

      out = marked.parseInline(escapedString, { renderer });

      return out;
    },
    nextQuestion() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
      recordAnalyticsEvent(
        AnalyticsEvents.v2MockTestQuestionNextButtonClicked,
        {
          testId: this.currentTestId,
          testInstanceId: this.startTestInfo.testInstanceId,
          moduleId: this.selectedTest.moduleId,
          questionId: this.currentQ.qId,
        }
      );

      this.updateNextQDurationAndSendLastQToServer();

      if (
        this.currentTestState.qIndex ==
        this.currentTestJson[this.currentTestState.sectionIndex].questions
          .length -
        1
      ) {
        if (
          this.currentTestState.sectionIndex ==
          this.currentTestJson.length - 1
        ) {
          console.log("review");
          this.reviewScreen = true;
          recordAnalyticsEvent(AnalyticsEvents.v2MockTestReviewScreenOpen, {
            testId: this.currentTestId,
            testInstanceId: this.startTestInfo.testInstanceId,
            moduleId: this.selectedTest.moduleId,
          });
        } else {
          this.$store.commit("updateCurrentTestState", {
            qIndex: 0,
            sectionIndex: this.currentTestState.sectionIndex + 1,
          });
        }
      } else {
        // changes ui to next question
        this.$store.commit("updateCurrentTestState", {
          qIndex: this.currentTestState.qIndex + 1,
          sectionIndex: this.currentTestState.sectionIndex,
        });
      }
      this.setQIdAndQTypeAfterPrevAndNext();

      setTimeout(() => {
        window.MathJax.typesetPromise();
      }, 0);
    },
    prevQuestion() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
      recordAnalyticsEvent(
        AnalyticsEvents.v2MockTestQuestionPreviousButtonClicked,
        {
          testId: this.currentTestId,
          testInstanceId: this.startTestInfo.testInstanceId,
          moduleId: this.selectedTest.moduleId,
          questionId: this.currentQ.qId,
        }
      );
      this.updateNextQDurationAndSendLastQToServer();

      if (this.currentTestState.qIndex == 0) {
        if (this.currentTestState.sectionIndex == 0) {
          console.log("first section");
        } else {
          this.$store.commit("updateCurrentTestState", {
            qIndex:
              this.currentTestJson[this.currentTestState.sectionIndex - 1]
                .questions.length - 1,
            sectionIndex: this.currentTestState.sectionIndex - 1,
          });
        }
      } else {
        // changes ui to next question
        this.$store.commit("updateCurrentTestState", {
          qIndex: this.currentTestState.qIndex - 1,
          sectionIndex: this.currentTestState.sectionIndex,
        });
      }

      this.setQIdAndQTypeAfterPrevAndNext();

      setTimeout(() => {
        window.MathJax.typesetPromise();
      }, 0);
    },
    updateNextQDurationAndSendLastQToServer() {
      try {
				this.showQuestionAttemptAlert = false;
				this.$store.commit("updateQuestionAttemptError", "");
				this.checkClockDrift();
				const lastAttemptQDuration = _.cloneDeep(this.curentQDuration);
				const lasAttemptQResponse = _.cloneDeep(this.currentQResponse);
				//

				if (!Array.isArray(lasAttemptQResponse.checkedOptions)) {
					lasAttemptQResponse.checkedOptions = [
						lasAttemptQResponse.checkedOptions,
					]; //[null]
				}

				if (Array.isArray(lasAttemptQResponse.checkedOption)) {
					lasAttemptQResponse.checkedOption =
						lasAttemptQResponse.checkedOption[0]; //[null]
				}
				lastAttemptQDuration.endTime = this.getCurrentServerTime();

				this.curentQDuration.startTime = this.getCurrentServerTime();

				recordAnalyticsEvent(AnalyticsEvents.v2TestQuestionResponseSentToServer, {
					testId: this.currentTestId,
					testName: this.startTestInfo.testmetadata.testName,
					testInstanceId: this.startTestInfo.testInstanceId,
					questionNumber: this.currentQ.qNo,
					questionType: this.currentQ.qType,
					currentQResponse: this.getAnswerValueForQuestionType(),
					sectionIndex: this.currentTestState.sectionIndex,
				});

				this.$store
					.dispatch("updateQuestionDurationAndResponse", {
						qDuration: lastAttemptQDuration,
						qResponse: lasAttemptQResponse,
					})
					.then(() => {
            console.log("Error", this.questionAttemptError);
						if (
							this.questionAttemptError.length !== 0 &&
							(lasAttemptQResponse.checkedOption === null || lasAttemptQResponse.checkedOption.length === 0) &&
							(lasAttemptQResponse.checkedOptions === null || lasAttemptQResponse.checkedOptions.length === 0) &&
							(lasAttemptQResponse.integerOption === null || lasAttemptQResponse.integerOption.length === 0) &&
							(lasAttemptQResponse.decimalOption === null || lasAttemptQResponse.decimalOption.length === 0)
						) {
							this.showQuestionAttemptAlert = false;
						} else if (this.questionAttemptError.length !== 0) {
							this.showQuestionAttemptAlert = true;
						}
					}).catch(err => {
            console.log("Error", err);
            let stackTrace;

            if (err && err.stack) {
                stackTrace = JSON.stringify(err.stack);
            } else if (err) {
                stackTrace = JSON.stringify(err);
            } else {
                stackTrace = 'No error information available';
            }

            stackTrace = stackTrace.slice(0, 199);

						recordAnalyticsEvent(AnalyticsEvents.v2TestUserAttemptMethodInStoreFailed, {
							message: `userAttemptInStoreFailed`,
							error: stackTrace,
							testId: this.currentTestId,
							testName: this.startTestInfo.testmetadata.testName,
							testInstanceId: this.startTestInfo.testInstanceId,
							questionNumber: this.currentQ.qNo,
							questionType: this.currentQ.qType,
							currentQResponse: this.getAnswerValueForQuestionType(),
							sectionIndex: this.currentTestState.sectionIndex,
							qDuration: JSON.stringify(lastAttemptQDuration),
							qResponse1: JSON.stringify(lasAttemptQResponse).slice(0, 156),
							qResponse2: JSON.stringify(lasAttemptQResponse).slice(156),
						});

						this.$store.dispatch("recordError", { Error: JSON.stringify(err.stack), API: "API_userAttemptInStoreFailed" }).then().catch(err => {
							recordAnalyticsEvent(AnalyticsEvents.v2TestRecordErrorAPIFailed, {
								message: `recordErrorAPIFailedFoStoreUserAttemptMethod`,
								error: stackTrace,
							});
						});
						this.showQuestionAttemptAlert = true;
						setLocalForageItem('qDurations', [])
						setLocalForageItem('qResponses', [])
					})
			} catch (err) {
				recordAnalyticsEvent(AnalyticsEvents.v2TestUserAttemptMethodInTestQuestionPageFailed, {
					message: `userAttemptInTestQuestionPageFailed`,
					error: JSON.stringify(err.stack).slice(0, 199),
					testId: this.currentTestId,
					testName: this.startTestInfo.testmetadata.testName,
					testInstanceId: this.startTestInfo.testInstanceId,
					questionNumber: this.currentQ.qNo,
					questionType: this.currentQ.qType,
					currentQResponse: this.getAnswerValueForQuestionType(),
					sectionIndex: this.currentTestState.sectionIndex,
					qDuration: JSON.stringify(this.curentQDuration),
					qResponse1: JSON.stringify(this.currentQResponse).slice(0, 156),
					qResponse2: JSON.stringify(this.currentQResponse).slice(156),
				});

				this.$store.dispatch("recordError", { Error: JSON.stringify(err.stack), API: "Method_userAttemptInTestQuestionPageFailed" }).then().catch((err) => {
					recordAnalyticsEvent(AnalyticsEvents.v2TestRecordErrorAPIFailed, {
						message: `recordErrorAPIFailedForTestQuestionPageUserAttemptMethod`,
						error: JSON.stringify(err.stack).slice(0, 199),
					});
				});
				this.showQuestionAttemptAlert = true;
				setLocalForageItem('qDurations', [])
				setLocalForageItem('qResponses', [])
			}
    },
    getAnswerValueForQuestionType() {
			if (this.currentQResponse.qType == 1) {
				return this.currentQResponse.checkedOption == null || this.currentQResponse.checkedOption.length == 0
					? "Not Answered"
					: this.currentQResponse.checkedOption;
			}
			if (this.currentQResponse.qType == 2) {
				return this.currentQResponse.checkedOptions == null || this.currentQResponse.checkedOptions.length == 0
					? "Not Answered"
					: JSON.stringify(this.currentQResponse.checkedOptions);
			}
			if (this.currentQResponse.qType == 3) {
				return this.currentQResponse.integerOption == null || this.currentQResponse.integerOption.length == 0
					? "Not Answered"
					: this.currentQResponse.integerOption;
			}
			if (this.currentQResponse.qType == 4) {
				return this.currentQResponse.decimalOption == null || this.currentQResponse.decimalOption.length == 0
					? "Not Answered"
					: this.currentQResponse.decimalOption;
			}
		},
    openReviewPage(){
      // console.log("open reviewn page");
      this.questionDialog = false;
      this.reviewScreen = true;
      recordAnalyticsEvent(AnalyticsEvents.v2MockTestReviewScreenOpen, {
        testId: this.currentTestId,
        testInstanceId: this.startTestInfo.testInstanceId,
        moduleId: this.selectedTest.moduleId,
      });
    },
    toHoursAndMinutes(totalSeconds) {
      const totalMinutes = Math.floor(totalSeconds / 60);

      const seconds = totalSeconds % 60;
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      return { h: parseInt(hours), m: parseInt(minutes), s: parseInt(seconds) };
    },
    getCurrentServerTime() {
      return this.serverTime + this.serverTimeCounter;
    },
    setQIdAndQTypeAfterPrevAndNext() {
      this.currentQResponse =
        this.qResponsesState[this.currentTestState.sectionIndex].responses[
        this.currentTestState.qIndex
        ];

      this.curentQDuration.qId =
        this.currentTestJson[this.currentTestState.sectionIndex].questions[
          this.currentTestState.qIndex
        ].qId;
      this.curentQDuration.sectionId =
        this.currentTestJson[this.currentTestState.sectionIndex].sectionId;
    },
    clearResponse() {
      recordAnalyticsEvent(
        AnalyticsEvents.v2MockTestQuestionClearResponseButtonClicked,
        {
          testId: this.currentTestId,
          testInstanceId: this.startTestInfo.testInstanceId,
          moduleId: this.selectedTest.moduleId,
          questionId: this.currentQ.qId,
        }
      );
      this.currentQResponse.checkedOption = "";
      this.currentQResponse.checkedOptions = [];
      this.currentQResponse.integerOption = "";
      this.currentQResponse.decimalOption = "";
    },
    getTypeOfQuestionCounts() {
      let res = {
        answered: 0,
        marked: 0,
        unanswered: 0,
      };

      for (let item of this.qResponsesState) {
        if (item.markedForReview) {
          res.marked++;
        }
        if (this.checkIfQuestionAnswered(item)) {
          res.answered += res.answered;
        } else {
          res.unanswered += res.unanswered;
        }

        return res;
      }
    },
    checkIfQuestionAnswered(q) {
      if (q.qType == 1) {
        return q.checkedOption == null || q.checkedOption.length == 0
          ? false
          : true;
      }
      if (q.qType == 2) {
        return q.checkedOptions == null || q.checkedOptions.length == 0
          ? false
          : true;
      }
      if (q.qType == 3) {
        return q.integerOption == null || q.integerOption.length == 0
          ? false
          : true;
      }
      if (q.qType == 4) {
        return q.decimalOption == null || q.decimalOption.length == 0
          ? false
          : true;
      }
    },
    checkClockDrift() {
      // console.log(this.getCurrentClientTimeInSeconds());
      // console.log(this.getCurrentServerTime());
      let currentDrift = Math.abs(
        this.getCurrentClientTimeInSeconds() - this.getCurrentServerTime()
      );
      // console.log(currentDrift);
      // console.log(this.baseLineClockDrift);

      if (Math.abs(currentDrift - this.baseLineClockDrift) >= 10) {
        console.log("drift limit exceeded, get time form server");
        this.resetTimer();
      }
    },
    backFromReview() {
      recordAnalyticsEvent(
        AnalyticsEvents.v2MockTestModuleNoWaitButtonClicked,
        {
          testId: this.currentTestId,
          testInstanceId: this.startTestInfo.testInstanceId,
          moduleId: this.selectedTest.moduleId,
        }
      );
      this.curentQDuration.startTime = this.getCurrentServerTime();
      this.setQIdAndQTypeAfterPrevAndNext();
      this.reviewScreen = false;
      setTimeout(() => {
        window.MathJax.typesetPromise();
      }, 0);
      // get request : with testInstanceId
    },
    resetTimer() {
      this.$store
        .dispatch("updateModuleSyncTimer", { state: "sync" })
        .then(() => {
          clearInterval(this.intervalId);
          this.remaningTimeInSeconds =
            this.syncTimerData.testRemainingDurationInSeconds;
          console.log("remaining time reset timer", this.remaningTimeInSeconds, this.syncTimerData );
          this.serverTime = this.syncTimerData.serverTimeStamp;
          this.serverTimeCounter = 0;

          this.baseLineClockDrift = Math.abs(
            this.getCurrentServerTime() - this.getCurrentClientTimeInSeconds()
          );
          this.startTimer();
        });
    },
    showInstructions() {
      recordAnalyticsEvent(
        AnalyticsEvents.v2MockTestQuestionModuleInstructionOpen,
        {
          testId: this.currentTestId,
          testInstanceId: this.startTestInfo.testInstanceId,
          moduleId: this.selectedTest.moduleId,
        }
      );
      this.instructionsDialog = true;
    },
    showReportDialog() {
      this.reportDialog = true;
    },
    async submitTest() {
      this.submitLoading = true;
      this.updateNextQDurationAndSendLastQToServer();
      recordAnalyticsEvent(
        AnalyticsEvents.v2MockTestModuleYesSubmitButtonClicked,
        {
          testId: this.currentTestId,
          testInstanceId: this.startTestInfo.testInstanceId,
          moduleId: this.selectedTest.moduleId,
          testSubmission: false,
        }
      );
      this.showQuestionAttemptAlert = false;
      this.showSubmitAlert = false;
      this.$store.commit("updateTestRoutingAllowed", true);
      this.$store.commit("updateSubmitError", "");
      this.$store.dispatch("submitTestModule").then(() => {
        console.log(this.submitError);
        
        if (this.submitError.length == 0) {
          if (this.testType == "quiz") {
            this.$store.commit("appbarVisible", true);
            this.$store.commit("navbarVisible", true);
            this.$router.replace(
              "/quizreport/" +
              this.currentTestId +
              "/" +
              this.startTestInfo.testInstanceId
            );
          } else {
            this.$router.replace("/testwaitingpage");
          }
        } else {
          this.showSubmitAlert = true;
        }
      });
      // this.submitLoading = false;
    },
    exitTest() {
      // this.takeScreenshot()

      recordAnalyticsEvent(AnalyticsEvents.v2MockTestLeaveTestButtonClicked, {
        testId: this.currentTestId,
        testInstanceId: this.startTestInfo.testInstanceId,
        moduleId: this.selectedTest.moduleId,
      });
      this.$router.back();
    },
    openQuestionDialog() {
      recordAnalyticsEvent(AnalyticsEvents.v2MockTestQuestionListPopupOpen, {
        testId: this.currentTestId,
        testInstanceId: this.startTestInfo.testInstanceId,
        moduleId: this.selectedTest.moduleId,
      });
      this.questionDialog = true;
    },
    takeScreenshot() {
      const screenshotTarget = document.getElementById("screenshot");

      html2canvas(screenshotTarget).then((canvas) => {
        let dataURL = canvas.toDataURL();
        console.log(dataURL);
      });
    },
    openCalculator() {
      recordAnalyticsEvent(
        AnalyticsEvents.v2MockTestQuestionCalculatorButtonClick,
        {
          testId: this.currentTestId,
          testInstanceId: this.startTestInfo.testInstanceId,
          moduleId: this.selectedTest.moduleId,
          questionId: this.currentQ.qId,
        }
      );
      if (this.$vuetify.display.mdAndUp) {
        if (this.calculatorDialog) {
          this.closeCalculator();
        }
        else {
          this.isSlideTranslation = true;
          this.calculatorDialog = true;
        }
      } else {
        window.open("https://www.desmos.com/calculator", "_blank");
      }
    },
    closeCalculator() {
      this.calculatorDialog = false;
      setTimeout(() => {
        console.log("isSlideTranslation");
        this.isSlideTranslation = false;
      }, 700);

    },
    expandCalculatorDialog() {
      this.isCalculatorExpanded = !this.isCalculatorExpanded;
      if (this.isCalculatorExpanded) {
        this.calculatorDimension = {
          ...this.calculatorDimension,
          w: this.width * 0.45 - 10,
          h: this.height * 0.75
        };
      } else {
        this.calculatorDimension = {
          ...this.calculatorDimension,
          w: 450,
          h: 500
        };
      }
    },
    openReferences() {
      recordAnalyticsEvent(
        AnalyticsEvents.v2MockTestQuestionReferencesButtonClick,
        {
          testId: this.currentTestId,
          testInstanceId: this.startTestInfo.testInstanceId,
          moduleId: this.selectedTest.moduleId,
          questionId: this.currentQ.qId,
        }
      );
      this.selectedReferenceDimension = this.xs ? this.referenceDimensionMobile : this.referenceDimension;
      this.referenceDialog = !this.referenceDialog;
    },
    changeXYDetails() {
      // console.log("changeXYDetails", val);

      // if(val.x <  -this.w/2){
      //   this.x = -this.w/2; //left- middle
      // }else if(val.x > this.width - this.w/2){
      //   this.x = this.width - this.w/2; //right- middle
      // }

      // if(val.y < 0){
      //   this.y = 0;
      // }else if(val.y > this.height - this.h){
      //   this.y = this.height - this.h;
      // }
    },
    mathFormatError(value) {
      return !(/^-?(\d*)?(\d*\.\d*)?(\d+\/(\d*\.\d*)?\d*)?$/.test(value))
    },
    checkValue(event) {
      const charCode = event.which || event.keyCode;
      if (!(/[0-9-./]$/.test(event.key)) && ![8, 35, 36, 37, 39, 46].includes(charCode)) {
        event.preventDefault();
      }
      if (event.key == '-' && event.target.value.includes('-') && event.target.selectionStart!=0) {
        event.preventDefault();
      }
    },
    preventDrag(event) {
      event.preventDefault(); // Prevent dragging within the input field
    },
    integerChangedValue(){
      let changeText = _.filter(this.currentQResponse.integerOption, (v)=>(/[0-9-./]$/.test(v)));
      if(changeText.slice(1).includes('-')){
        changeText =[changeText[0], ..._.filter(changeText.slice(1), (v)=>v!='-')];
      }
      this.currentQResponse.integerOption = changeText.join('');
    },
    decimalChangedValue(){
      let changeText = _.filter(this.currentQResponse.decimalOption, (v)=>(/[0-9-./]$/.test(v)));
      if(changeText.slice(1).includes('-')){
        changeText =[changeText[0], ..._.filter(changeText.slice(1), (v)=>v!='-')];
      }
      this.currentQResponse.decimalOption = changeText.join('');
    },
    draggableStart(){
			this.draggable = true;
		},
		draggableEnd(){
			this.draggable = false;
		},
		referenceDraggableStart(){
			this.referenceDraggable = true;
		},
		referenceDraggableEnd(){
			this.referenceDraggable = false;
		},

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.draggable {
  cursor: grab;
}

table,
td,
th {
  border: 1px solid black;
}

table {
  border-collapse: collapse;
  width: 340px;
  align-self: center;
  /* width: 100%; */
}

td {
  text-align: center;
  font-family: "Inter", sans-serif !important;
  font-size: 0.875rem !important;
  padding-top: 20px;
  padding-bottom: 20px;
}

.type-circle {
  margin-left: 28px;
  font-family: "Inter", sans-serif !important;
  font-size: 0.875rem !important;
  font-style: normal !important;
}

.list-title {
  font-family: "Inter", sans-serif !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 600;
}

.correct {
  background: #26b99aa6 !important;
  color: white !important;
}

.correctQ {
  background: #26b99a;
}

.img {
  vertical-align: middle;
}

.success {
  background: #26b99aa6 !important;
}

.totalQ {
  background: rgba(255, 255, 255, 0.15);
}

.timer-color {
  color: #26b99a !important;
}

.bgW {
  height: 100% !important;
  width: 100%;
  background-color: white;
}

.bgWColor {
  position: absolute;
  width: 100%;
  background-color: white;
}

.bgP {
  height: 100% !important;
  width: 100%;
  background-color: #2b2b45;
}

.bgPColor {
  background-color: #2b2b45;
}

.unanswered {
  border: 2px solid #949494 !important;
}

.unansweredQ {
  background: rgba(255, 255, 255, 0.15);
}

.marked {
  border: 4px solid #ff894f !important;
}

.markedQ {
  border: 4px solid #ff894f !important;
}

.btn-grad {
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%);
  border-radius: 29.4079px;
}

.btn-prev {
  border-radius: 29.4079px;
}

.bgWhite {
  background-color: white !important;
}

.disabledOpacity {
  opacity: var(--v-disabled-opacity);
}

.option {
  /* box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important; */
  border-radius: 10px;
  border: 1px rgb(var(--v-theme-secondaryBackground)) solid;
}

.subColor {
  color: rgba(255, 255, 255, 0.5) !important;
}

.v-sheet.on-hover {
  cursor: pointer;
  /* border: 1px solid rgb(var(--v-theme-primary)); */
  border-color: transparent;
  background-color: white;
  box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;
}

.redTimer {
  color: #ff786e !important;
}

.w-70 {
  max-width: 70%;
}

.submitFont {
  font-size: 0.85rem;
  font-family: "Inter", sans-serif !important;
}

.blink {
  animation: blinker 1s step-start infinite;
}

.strike {
  position: absolute;
  width: calc(100%);
  height: 2px;
  background: rgb(var(--v-theme-primaryPurple));
  left: 0px;
  top: 50%;
  opacity: 1 !important;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.bx {
  background-color: rgb(var(--v-theme-navColor));
  border: 1px solid #e7e7e7;
  border-radius: 4px;
}

.card-bg-color {
  background-color: rgb(var(--v-theme-breadCrumbBackColor)) !important;
  border-radius: 12px;
  background: #26253a;
}

.outline-color {
  border-color: rgba(0, 0, 0, 0.2) !important;
}

.bottom {
  position: absolute !important;
  bottom: 10px !important;
}

.v-overlay__content {
  position: absolute !important;
  bottom: 10px !important;
}

.layoutLarge {
  height: calc(100% - 160px);
}

.scroll {}

.blink {
  animation: blinker 1s step-start infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.selected {
  cursor: pointer;
  /* border: 1px solid rgb(var(--v-theme-primary)); */
  border-color: transparent;
  background-color: rgb(var(--v-theme-primaryPurple)) !important;
  color: white !important;
}

.calculator-heading {
  background-color: rgb(var(--v-theme-dialogHeader)) !important;
  color: white !important;
}

.qNoClass {
  font-size: 0.875rem;
  font-family: "Inter", sans-serif !important;
}

.outline-color-grey {
  border-color: #010101b3 !important;
  border: 1px solid;
  color: #010101b3 !important;

}

.outline-color-white {
  border-color: white !important;
  background-color: white;
}

.dialog-bx {
  position: fixed !important;
}

.calculator-container {
  width: fit-content;
  height: fit-content;
  position: absolute;
  z-index: 10;
  top: 15%;
  left: 5%;
}

.calculator-parent {
  /* position: absolute !important;
  top: 0px !important;
  left: 0px !important;
  margin: 0px !important; */
  z-index: 3000;
}

.reference-body {
  height: calc(100% - 60px);
  overflow: hidden;
  overflow-y: auto;
}

.main-draggable {
  position: absolute !important;
  top: 0px !important;
  left: 0px !important;
  margin: 0px !important;
  width: 100vw;
  height: 90vh;
}



.single-layout-question {
  padding: 0 30%;
  height: calc(100vh - 150px);
  overflow: hidden;
  overflow-y: auto;
}

.slide-in {
  padding-left: 50%;
  padding-right: 10%;
  transition: padding 0.7s;
}

.slide-out {
  padding-left: 30%;
  padding-right: 30%;
  transition: padding 0.7s;
}

.frame{
  height: calc(100% - 60px) !important;
  width: 100% !important;
}

</style>
<style>
.htmlClassTestOption span {
  top: 0 !important;
  vertical-align: middle;
}

.htmlClassTest img {
  top: 0 !important;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

.testOption .v-selection-control--inline {
  min-width: 100% !important;
}

.testOption .v-label {
  width: 100% !important;
  opacity: 1 !important;
}

.v-label {
  opacity: 1 !important;
}

.slide-height {
  height: calc(100vh - 150px) !important;
  overflow: hidden;
  overflow-y: auto;
}

.small-height{
  padding-bottom: 50px;
}


.border-right {
  border-right: 1px solid rgb(var(--v-theme-primaryPurple)) !important;
}

.time-width {
  width: 80px;
  text-align: center;
}

.more-btn {
  padding: 5px 10px;
  border: 1px solid white;
  border-radius: 12px;
}

.text-ellipse {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.custom-radio .v-selection-control__wrapper {
  display: none;
}
</style>
