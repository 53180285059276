<template>
  <!-- Folder or SubFolder Dialog -->
  <v-dialog style="backdrop-filter: blur(12.5px)" v-model="dialog" width="900" persistent>
    <v-card elevation="0" class="assignment-form text-white">
      <v-toolbar class="assignment-form-title text-white">
        <v-toolbar-title>
          <v-row class="">
            <v-col cols="auto" class="d-flex align-center">
              <v-avatar size="30" color="report-subCardBg" class="icon-bg ma-0 pa-0 mr-2">
                <v-icon icon="mdi-folder-open" color="#ffffff" class="" size="20"></v-icon>
              </v-avatar>
              <span class="t-h125Reg">{{ formTitle }} Folder</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-icon @click.stop="close()" class="mr-4"> mdi-close</v-icon>
            </v-col>
          </v-row>
        </v-toolbar-title>
      </v-toolbar>
      <v-divider class="mb-2" :thickness="2" color="background"></v-divider>
      <v-card-text class="pt-4 px-6 assignment-form-main">
        <v-form ref="form" validate-on="submit lazy" v-model="formValue">
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-text-field v-model="editedItem.resourceCenterFolderName" label="Name *" density="compact" variant="outlined" dense
                :rules="requiredRule"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-textarea v-model="editedItem.description" label="Description" density="compact" variant="outlined" rows="2" dense
                hide-details></v-textarea>
            </v-col>
          </v-row>
          <v-row dense class="mt-4">
            <v-col cols="auto" sm="6" class="pr-2">
              <v-text-field v-model="editedItem.order" label="Order *" density="compact" variant="outlined" dense
                type="number" :rules="requiredRule"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="pl-2">
              <div class="field-block px-3">
                <span class="field-label-name">Permission</span>
                <v-row class="pa-0 align-center">
                  <v-col cols="auto" class="pr-0 pl-1">
                    <v-radio-group v-model="editedItem.permission" inline class="" hide-details>
                      <v-radio label="Everyone" value="all"></v-radio>
                      <v-radio label="Classes" value="class"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <v-row dense v-if="editedItem.permission == 'class'">
            <v-col cols="12" sm="12">
              <v-select :items="classList" item-title="name" item-value="batchId" v-model="editedItem.classes"
                label="Assigned To Class" density="compact" variant="outlined" multiple dense>
                <template v-slot:selection="{ item, index }">
                  <span v-if="
                      editedItem.classes.length == this.classList.length &&
                      index == 0
                    ">All</span>
                  <span v-else-if="editedItem.classes.length > 1 && index == 0">
                    {{ item.title }} + {{ editedItem.classes.length - 1 }}
                  </span>
                  <span v-else-if="editedItem.classes.length == 1 && index == 0">
                    {{ item.title }}
                  </span>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row dense v-if="(selectedType == 'FOLDER') && isUserSuperAdmin">
            <v-col cols="12" class="pr-2">
              <v-autocomplete :items="customersList" item-title="courseName" item-value="courseId"
                v-model="editedItem.customerIds" label="Customers" density="compact" variant="outlined" multiple dense> 
                <template v-slot:prepend-item>
                  <v-list-item title="All" @click="toggleCustomerList">
                    <template v-slot:prepend>
                      <v-checkbox-btn :indeterminate="editedItem.customerIds.length > 0 && !(editedItem.customerIds.length == customersList.length)" :model-value="editedItem.customerIds.length == customersList.length"></v-checkbox-btn>
                    </template>
                  </v-list-item>

                  <v-divider class="mt-2"></v-divider>
                </template>  
                <template v-slot:selection="{ item, index }">
                  <span v-if="
                      editedItem.customerIds.length == customersList.length &&
                      index == 0
                    ">All</span>
                  <span v-else-if="editedItem.customerIds.length > 1 && index == 0">
                    {{ item.title }} + {{ editedItem.customerIds.length - 1 }}
                  </span>
                  <span v-else-if="editedItem.customerIds.length == 1 && index == 0">
                    {{ item.title }}
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row dense class="align-center">
            <v-col cols="auto"> Published </v-col>
            <v-col cols="auto">
              <v-switch color="primaryPurple" v-model="editedItem.isPublished" hide-details dense
                density="compact"></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider class="mb-2" :thickness="2" color="background"></v-divider>
      <v-card-actions class="px-6">
        <v-btn v-if="editedIndex != -1" class="cancel-btn delete-btn t-btn text-capitalize text-primaryTextColor mr-2"
            density="default" variant="flat" height="40" @click.stop="openDeleteDialog()">Delete</v-btn>
        <v-spacer></v-spacer>
        <v-btn class="cancel-btn t-btn text-capitalize text-primaryTextColor mr-2" density="default" variant="flat"
          height="40" @click.stop="close()">Cancel</v-btn>
        <v-btn class="save-btn t-btn text-capitalize text-primaryTextColor ml-2" density="default" variant="flat"
          height="40" @click.stop="save">{{ editedIndex == -1 ? "Add" : "Update" }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

    <!-- Link Dialog -->
    <v-dialog style="backdrop-filter: blur(12.5px)" v-model="dialogLink" width="900" persistent>
    <v-card elevation="0" class="assignment-form text-white">
      <v-toolbar class="assignment-form-title text-white">
        <v-toolbar-title>
          <v-row class="">
            <v-col cols="auto" class="d-flex align-center">
              <v-avatar size="30" color="report-subCardBg" class="icon-bg ma-0 pa-0 mr-2">
                <v-icon icon="mdi-link-variant" color="#ffffff" class="" size="20"></v-icon>
              </v-avatar>
              <span class="t-h125Reg">{{ formTitle }} Website</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-icon @click.stop="close()" class="mr-4"> mdi-close</v-icon>
            </v-col>
          </v-row>
        </v-toolbar-title>
      </v-toolbar>
      <v-divider class="mb-2" :thickness="2" color="background"></v-divider>
      <v-card-text class="pt-4 px-6 assignment-form-main">
        <v-form ref="form" validate-on="submit lazy" v-model="formValue">
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-text-field v-model="editedItem.resourceCenterFolderName" label="Name *" density="compact" variant="outlined" dense
                :rules="requiredRule"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-textarea v-model="editedItem.description" label="Description" density="compact" variant="outlined" rows="2" dense
                hide-details></v-textarea>
            </v-col>
          </v-row>
          <v-row dense class="mt-4">
            <v-col cols="12">
              <v-text-field v-model="editedItem.url" label="Link or url *" density="compact" variant="outlined"  dense
                :rules="urlRules"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col
              v-if="
                !isNodeImageEmpty(editedItem.thumbnailUrl)
              "
            >
              <!-- <v-file-input
                accept=".png, .jpeg, .jpg"
                placeholder="Upload your image here"
                prepend-inner-icon="mdi-image"
                label="Pdf Thumbnail"
                v-model="editedItem.thumbnailUrl"
              ></v-file-input> -->
              <v-file-input class="" 
                density="compact" 
                :clearable="true"
                placeholder="Upload your image here"
                label="Website Thumbnail"
                ref="imgInput" v-model="editedItem.thumbnailUrl"
                accept=".png, .jpeg, .jpg" variant="outlined"
                prepend-icon="mdi-image">
              </v-file-input>
            </v-col>
            <v-col
              v-if="
                isNodeImageEmpty(editedItem.thumbnailUrl)
              "
              cols="2"
            >
              <label style="cursor:pointer;" for="image-upload-input">
                Thumbnail Url
                <img
                  style="margin-top:15px;max-height:100%;max-width:100%;"
                  :src="getImageUrl(editedItem.thumbnailUrl)"
                />
              </label>
            </v-col>
            <v-col
              v-if="
                isNodeImageEmpty(editedItem.thumbnailUrl)
              "
              cols="1"
            >
              <v-icon small @click="removeImage()">
                mdi-close
              </v-icon>
            </v-col>
          </v-row>
          <v-row dense class="mt-4">
            <v-col cols="12" sm="6" class="pr-2">
              <v-text-field v-model="editedItem.order" label="Order *" density="compact" variant="outlined" dense
                type="number" :rules="requiredRule"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="pl-2">
              <v-row dense class="align-center">
                <v-col cols="auto"> Published </v-col>
                <v-col cols="auto">
                  <v-switch color="primaryPurple" v-model="editedItem.isPublished" hide-details dense
                    density="compact"></v-switch>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" class="pr-2">
              <v-row dense class="align-center">
                <v-col cols="auto"> Show content to main folder </v-col>
                <v-col cols="auto">
                  <v-switch color="primaryPurple" v-model="editedItem.showInMainFolder" hide-details dense
                    density="compact"></v-switch>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          
        </v-form>
      </v-card-text>
      <v-divider class="mb-2" :thickness="2" color="background"></v-divider>
      <v-card-actions class="px-6">
        <v-btn v-if="editedIndex != -1" class="cancel-btn delete-btn t-btn text-capitalize text-primaryTextColor mr-2"
            density="default" variant="flat" height="40" @click.stop="openDeleteDialog()">Delete</v-btn>
        <v-spacer></v-spacer>
        <v-btn class="cancel-btn t-btn text-capitalize text-primaryTextColor mr-2" density="default" variant="flat"
          height="40" @click.stop="close()">Cancel</v-btn>
        <v-btn class="save-btn t-btn text-capitalize text-primaryTextColor ml-2" density="default" variant="flat"
          height="40" @click.stop="save">{{ editedIndex == -1 ? "Add" : "Update" }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- Pdf Dialog -->
  <v-dialog style="backdrop-filter: blur(12.5px)" v-model="dialogPdf" width="900" persistent>
    <v-card elevation="0" class="assignment-form text-white">
      <v-toolbar class="assignment-form-title text-white">
        <v-toolbar-title>
          <v-row class="">
            <v-col cols="auto" class="d-flex align-center">
              <v-avatar size="30" color="report-subCardBg" class="icon-bg ma-0 pa-0 mr-2">
                <v-icon icon="mdi-file-pdf-box" color="#ffffff" class="" size="20"></v-icon>
              </v-avatar>
              <span class="t-h125Reg">{{ formTitle }} Pdf</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-icon @click.stop="close()" class="mr-4"> mdi-close</v-icon>
            </v-col>
          </v-row>
        </v-toolbar-title>
      </v-toolbar>
      <v-divider class="mb-2" :thickness="2" color="background"></v-divider>
      <v-card-text class="pt-4 px-6 assignment-form-main">
        <v-form ref="form" validate-on="submit lazy" v-model="formValue">
          <v-row dense>
            <v-col cols="12" sm="8" v-if="!showPdfFile">
              <v-file-input v-model="inputFile" prepend-icon="mdi-file-pdf-box"
                label="Upload your PDF here *" accept=".pdf" id="pdf-upload-input" truncate-length="30"
                dense :rules="fileRules">
              </v-file-input>
            </v-col>
            <v-col cols="12" sm="8" v-else>

              <v-text-field prepend-icon="mdi-file-pdf-box" :model-value="getPdfName()" label="Upload your PDF here *" density="compact" variant="outlined" dense
               @click:append="showPdfFile=false" class="pdf-field" disabled append-icon="mdi-close">
              </v-text-field>
            </v-col>

            <!-- <v-col cols="4">
              <div class="mt-5 text-h6 red--text font-weight-regular"
                v-if="inputFile == null || inputFile == ''">
                FILE NOT UPLOADED
              </div>
            </v-col> -->
          </v-row>
          <v-row dense>
            <v-col
              v-if="
                !isNodeImageEmpty(editedItem.thumbnailUrl)
              "
            >
              <!-- <v-file-input
                accept=".png, .jpeg, .jpg"
                placeholder="Upload your image here"
                prepend-inner-icon="mdi-image"
                label="Pdf Thumbnail"
                v-model="editedItem.thumbnailUrl"
              ></v-file-input> -->
              <v-file-input class="" 
                density="compact" 
                :clearable="true"
                placeholder="Upload your image here"
                label="Pdf Thumbnail"
                ref="imgInput" v-model="editedItem.thumbnailUrl"
                accept=".png, .jpeg, .jpg" variant="outlined"
                prepend-icon="mdi-image">
              </v-file-input>
            </v-col>
            <v-col
              v-if="
                isNodeImageEmpty(editedItem.thumbnailUrl)
              "
              cols="2"
            >
              <label style="cursor:pointer;" for="image-upload-input">
                Thumbnail Url
                <img
                  style="margin-top:15px;max-height:100%;max-width:100%;"
                  :src="getImageUrl(editedItem.thumbnailUrl)"
                />
              </label>
            </v-col>
            <v-col
              v-if="
                isNodeImageEmpty(editedItem.thumbnailUrl)
              "
              cols="1"
            >
              <v-icon small @click="removeImage()">
                mdi-close
              </v-icon>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-text-field v-model="editedItem.resourceCenterFolderContentName" label="Name *" density="compact" variant="outlined" dense
                :rules="requiredRule"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-textarea v-model="editedItem.description" label="Description" density="compact" variant="outlined" rows="2" dense
                hide-details></v-textarea>
            </v-col>
          </v-row>
          <v-row dense class="mt-4">
            <v-col cols="12" sm="6" class="pr-2">
              <v-text-field v-model="editedItem.order" label="Order *" density="compact" variant="outlined" dense
                type="number" :rules="requiredRule"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="pl-2">
              <v-row dense class="align-center">
                <v-col cols="auto"> Published </v-col>
                <v-col cols="auto">
                  <v-switch color="primaryPurple" v-model="editedItem.isPublished" hide-details dense
                    density="compact"></v-switch>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" class="pr-2">
              <v-row dense class="align-center">
                <v-col cols="auto"> Show content to main folder </v-col>
                <v-col cols="auto">
                  <v-switch color="primaryPurple" v-model="editedItem.showInMainFolder" hide-details dense
                    density="compact"></v-switch>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          
        </v-form>
      </v-card-text>
      <v-divider class="mb-2" :thickness="2" color="background"></v-divider>
      <v-card-actions class="px-6">
        <v-btn v-if="editedIndex != -1" class="cancel-btn delete-btn t-btn text-capitalize text-primaryTextColor mr-2"
            density="default" variant="flat" height="40" @click.stop="openDeleteDialog()">Delete</v-btn>
        <v-spacer></v-spacer>
        <v-btn class="cancel-btn t-btn text-capitalize text-primaryTextColor mr-2" density="default" variant="flat"
          height="40" @click.stop="close()">Cancel</v-btn>
        <v-btn class="save-btn t-btn text-capitalize text-primaryTextColor ml-2" density="default" variant="flat"
          height="40" @click.stop="savePdfContent">{{ editedIndex == -1 ? "Add" : "Update" }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- Youtube Url Dialog -->
  <v-dialog style="backdrop-filter: blur(12.5px)" v-model="dialogYtVideo" width="900" persistent>
    <v-card elevation="0" class="assignment-form text-white">
      <v-toolbar class="assignment-form-title text-white">
        <v-toolbar-title>
          <v-row class="">
            <v-col cols="auto" class="d-flex align-center">
              <v-avatar size="30" color="report-subCardBg" class="icon-bg ma-0 pa-0 mr-2">
                <v-icon icon="mdi-video-outline" color="#ffffff" class="" size="20"></v-icon>
              </v-avatar>
              <span class="t-h125Reg">{{ formTitle }} Youtube Url</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-icon @click.stop="close()" class="mr-4"> mdi-close</v-icon>
            </v-col>
          </v-row>
        </v-toolbar-title>
      </v-toolbar>
      <v-divider class="mb-2" :thickness="2" color="background"></v-divider>
      <v-card-text class="pt-4 px-6 assignment-form-main">
        <v-form ref="form" validate-on="submit lazy" v-model="formValue">
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-text-field v-model="editedItem.resourceCenterFolderContentName" label="Name *" density="compact" variant="outlined" dense
                :rules="requiredRule"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-textarea v-model="editedItem.description" label="Description" density="compact" variant="outlined" rows="2" dense
                hide-details></v-textarea>
            </v-col>
          </v-row>
          <v-row dense class="mt-4">
            <v-col cols="12">
              <v-text-field v-model="editedItem.youTubeUrl" label="Youtube Url *" density="compact" variant="outlined"  dense
              :rules="requiredRule"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6" class="pr-2">
              <v-text-field v-model="editedItem.order" label="Order *" density="compact" variant="outlined" dense
                type="number" :rules="requiredRule"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="pl-2">
              <v-row dense class="align-center">
                <v-col cols="auto"> Published </v-col>
                <v-col cols="auto">
                  <v-switch color="primaryPurple" v-model="editedItem.isPublished" hide-details dense
                    density="compact"></v-switch>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" class="pr-2">
              <v-row dense class="align-center">
                <v-col cols="auto"> Show content to main folder </v-col>
                <v-col cols="auto">
                  <v-switch color="primaryPurple" v-model="editedItem.showInMainFolder" hide-details dense
                    density="compact"></v-switch>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          
        </v-form>
      </v-card-text>
      <v-divider class="mb-2" :thickness="2" color="background"></v-divider>
      <v-card-actions class="px-6">
        <v-btn v-if="editedIndex != -1" class="cancel-btn delete-btn t-btn text-capitalize text-primaryTextColor mr-2"
            density="default" variant="flat" height="40" @click.stop="openDeleteDialog()">Delete</v-btn>
        <v-spacer></v-spacer>
        <v-btn class="cancel-btn t-btn text-capitalize text-primaryTextColor mr-2" density="default" variant="flat"
          height="40" @click.stop="close()">Cancel</v-btn>
        <v-btn class="save-btn t-btn text-capitalize text-primaryTextColor ml-2" density="default" variant="flat"
          height="40" @click.stop="saveNodeContent">{{ editedIndex == -1 ? "Add" : "Update" }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- Delete Content Dialog -->
  <v-dialog
    style="backdrop-filter: blur(12.5px)"
    persistent
    v-model="dialogDelete"
    max-width="400px"
  >
    <v-card elevation="0" class="assignment-form text-white pa-4" :width="$vuetify.display.smAndUp ? 400 : '95%'">
      <v-card-text class="text-white pa-0 ma-0"

        >
        <span class="assignment-form-title">Are you sure you want to delete 
        '{{ getNodeName(this.editedItem) }}' ?</span>
        </v-card-text
      >
      <v-card-text></v-card-text>
      <v-card-actions class="pa-0 ma-0">
        <v-spacer></v-spacer>
        <v-btn
          class="cancel-btn t-btn text-capitalize text-primaryTextColor mr-2"
          density="default"
          variant="flat"
          height="40"
          @click.stop="closeDeleteDialog()"
          >No</v-btn
        >
        <v-btn
          class="save-btn t-btn text-capitalize text-primaryTextColor ml-2"
          density="default"
          variant="flat"
          height="40"
          @click.stop="deleteResource()"
          >Yes</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- Share Link Dialog -->
  <v-dialog style="backdrop-filter: blur(12.5px)" v-model="dialogShareLink" width="fit-content">
    <v-card elevation="0" class="assignment-form text-white" width="fit-content">
      <v-card-text class="text-white px-2  pb-3">
        <v-row dense class="t-1remReg mb-1 mx-2" justify="center">
          <v-col class="text-center">
            Share your resource
          </v-col>
          <v-col class="text-right" cols=auto>
            <v-icon @click.stop="dialogShareLink = false" size=20>mdi-close</v-icon>
          </v-col>
        </v-row>
        <v-row dense class="t-subtitle text-center mb-7 mx-4 mx-sm-auto" justify="center">
          Copy the link below and share it with your students.
        </v-row>

        <v-row v-if="$vuetify.display.smAndUp" justify="space-between" align="center"
          class=" my-4 mx-2 border-code py-2">
          <v-col cols="auto" class="my-0 py-0 t-subtitle  text-primaryTextColor"> {{ shareableLink }} </v-col>
          <v-col cols="" class="my-0 py-0 text-right">
            <v-icon @click="copyCode()" color="grey" size="20" class="copy">
              mdi-content-copy
            </v-icon>
          </v-col>
        </v-row>

        <v-row v-else justify="center" align="center" class="mx-2 mb-4">
          <v-col cols="auto" class="pa-0">
            <v-btn append-icon="mdi-content-copy" class="t-btn text-white activity-btn text-none px-3 pr-5"
              density="default" variant="flat" @click="copyCode()">
              <template v-slot:append>
                <v-icon color="primaryTextColor"></v-icon>
              </template>
              Copy Link
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>

  <!-- Send Email Dialog -->
  <v-dialog style="backdrop-filter: blur(12.5px)" v-model="dialogSendEmail" width="fit-content">
    <v-card elevation="0" class="assignment-form text-white pa-4" :width="$vuetify.display.smAndUp ? 400 : '95%'">
      <v-card-text class="text-white pa-0 ma-0">
        <span class="assignment-form-title">Are you sure you want to send 
        '{{ getNodeName(this.editedItem) }}' with your students.</span>
        </v-card-text
      >
      <v-card-actions class="pa-0 ma-0">
        <v-spacer></v-spacer>
        <v-btn
          class="cancel-btn t-btn text-capitalize text-primaryTextColor mr-2"
          density="default"
          variant="flat"
          height="40"
          @click.stop="closeSendEmailDialog()"
          >No</v-btn
        >
        <v-btn
          class="save-btn t-btn text-capitalize text-primaryTextColor ml-2"
          density="default"
          variant="flat"
          height="40"
          @click.stop="sendEmailToUsers()"
          >Yes</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-row v-if="dataLoaded" class="ma-0 pt-4 px-4" :class="{ heightBread: $vuetify.display.mdAndUp }" :justify="selectedResourceFolderId!='-1'? 'space-between': 'end'" align="center">
    <v-col v-if="selectedResourceFolderId!='-1'" class="ma-0 pa-0">
      <v-breadcrumbs :items="learnNodesBreadcrumbs" class="no-new-line t-h6Reg text-white text-primaryTextColor">
        <template v-slot:divider>
          <v-icon icon="mdi-chevron-right"></v-icon>
        </template>
        <template v-slot:prepend>
          <v-btn v-if="learnNodesBreadcrumbs.length > 0" color="#26253A" class="ma-0 mr-2 text-primaryTextColor"
            size="x-small" @click="back()" icon="mdi-chevron-left">
          </v-btn>
        </template>
        <template v-slot:title="{item}">
          <span @click.stop="breadCrumbRoute(item)" class="breadcrumb-title">{{ item.title }}</span>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col cols="auto" class="pa-0">
        <SelfHelpGuideTogglerComponent />
      </v-col>
    <v-divider></v-divider>
  </v-row>

  <v-layout v-if="dataLoaded" :class="{ layoutLarge: $vuetify.display.mdAndUp }">
    <v-container fluid class="mt-3 container-width" >
      <v-row v-if="selectedResourceFolderId=='-1'" align="center" justify="start" dense class="mb-2 main-lg" >
        <v-col cols="auto">
          <span class="t-h125 text-center mb-1 text-primaryTextColor">Resources
            <span v-if="folderNodeContents.length > 0"> ({{ folderNodeContents.length }})</span></span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto">
          <v-menu >
            <template v-slot:activator="{ props }">
              <v-btn class="t-btn text-white activity-btn text-none t-1remReg" density="default" variant="flat"
                height="40" append-icon="mdi-chevron-down" v-bind="props">Create New</v-btn>
            </template>
            <v-list density="compact" nav>
              <v-list-item
                v-for="(item, index) in items"
                :key="index"
                @click="addNewNodeContent(item.key)"
              >
                <div class="d-flex align-center">
                  <v-img
                    v-if="item.image"
                    :src="item.image"
                    :height="20" :width="20" class="mr-2"
                  ></v-img>
                  <v-icon v-else :height="20" :width="20" class="mr-2">{{ item.icon }}</v-icon>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </div>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <v-row v-else align="center" justify="start" dense class="mb-2">
        <v-col cols="auto">
          <span
            class="t-h125 text-center mb-1 text-primaryTextColor">{{learnNodesBreadcrumbs[learnNodesBreadcrumbs.length-1].title}}
            <span v-if="folderNodeContents.length > 0"> ({{ folderNodeContents.length }})</span></span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto" v-if="!isShareableFolderOpen">
          <v-menu >
            <template v-slot:activator="{ props }">
              <v-btn class="t-btn text-white activity-btn text-none t-1remReg" density="default" variant="flat"
                height="40" append-icon="mdi-chevron-down" v-bind="props">Add New
                Content</v-btn>
            </template>
            <v-list density="compact" nav>
              <v-list-item
                v-for="(item, index) in folderItems"
                :key="index"
                @click="addNewNodeContent(item.key)"
              >
                <div class="d-flex align-center">
                  <v-img
                    v-if="item.image"
                    :src="item.image"
                    :height="20" :width="20" class="mr-2"
                  ></v-img>
                  <v-icon v-else :height="20" :width="20" class="mr-2">{{ item.icon }}</v-icon>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </div>
              </v-list-item>
            </v-list>
          </v-menu>

        </v-col>
      </v-row>
      <template v-if="folderNodeContents.length > 0">
        <v-card elevation="0" class="my-2 pa-0 main-card card-bg card-border" v-for="item in folderNodeContents"
          :key="item.resourceCenterFolderContentId" @click="learnNodeClicked(item)">
          <v-card-text class="pa-2 text-primaryTextColor">
            <v-row class="ma-0" align="center" justify="start" dense :class="{ 'pt-1': $vuetify.display.xs }">
              <v-col cols="auto">
                <v-avatar :size="40">
                  <v-img height="24" v-if="item.resourceCenterFolderImage || item.resourceCenterFolderContentImage" :src="getNodeImageURL(item)"></v-img>
                  <v-icon v-else :icon="getPlaceHolderIcon(item)" class="" size="30" :color="getPlaceHolderIconColor(item)"></v-icon>
                </v-avatar>
              </v-col>
              <v-col cols="8" md="9">
                <v-row dense>
                  <v-col cols="12">
                    {{ getNodeName(item) }}

                  </v-col>
                  <v-col cols="12" v-if="item.description">
                    {{ item.description }}
                  </v-col>
                </v-row>

              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto" class="d-flex justify-end">
                <v-tooltip
                  location="top"
                  v-if="!isFolderShareabled(item)"
                >
                  <template #activator="{ props }">
                    <v-icon
                      small
                        @click.stop="editNodeItem(item, item.resourceCenterFolderType || item.resourceCenterFolderContentType)"
                      v-bind="props"
                      end
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Edit Resource</span>
                </v-tooltip>
                <v-tooltip
                  location="top"
                  v-else-if="(isPDFContent(item) || isVideoContent(item) || isFolder(item) || isSubFolder(item)) && item.isPublished"
                >
                  <template #activator="{ props }">
                    <v-icon
                      small
                        @click.stop="sendEmail(item)"
                      v-bind="props"
                      end
                    >
                    mdi-file-send
                    </v-icon>
                  </template>
                  <span>Notify Students</span>
                </v-tooltip>
                <v-menu v-if="!isFolderShareabled(item) || ((isPDFContent(item) || isVideoContent(item) || isFolder(item) || isSubFolder(item)) && item.isPublished)">
                  <template v-slot:activator="{ props }">
                    <v-icon
                      v-bind="props"
                    >
                      mdi-chevron-down
                    </v-icon>
                  </template>
                  <v-list density="compact" nav>
                    <v-list-item 
                      v-if="!isFolderShareabled(item)"
                      @click.stop="editNodeItem(item, item.resourceCenterFolderType || item.resourceCenterFolderContentType)">
                      <div class="d-flex align-center">
                        <v-icon :height="20" :width="20" class="mr-2">
                          mdi-pencil
                        </v-icon>
                        <v-list-item-title>
                          Edit Resource
                        </v-list-item-title>
                      </div>
                    </v-list-item>   
                    <v-list-item 
                      v-if="(isPDFContent(item) || isVideoContent(item) || isFolder(item) || isSubFolder(item)) && item.isPublished"  
                      @click.stop="sendEmail(item)">
                      <div class="d-flex align-center">
                        <v-icon :height="20" :width="20" class="mr-2">
                          mdi-file-send
                        </v-icon>
                        <v-list-item-title>
                          Notify Students
                        </v-list-item-title>
                      </div>
                    </v-list-item>
                    <v-list-item 
                      v-if="(isPDFContent(item) || isVideoContent(item) || isFolder(item) || isSubFolder(item)) && item.isPublished"  
                      @click.stop="shareLink(item)">
                      <div class="d-flex align-center">
                        <v-icon :height="20" :width="20" class="mr-2">
                          mdi-share
                        </v-icon>
                        <v-list-item-title>
                          Share Resource
                        </v-list-item-title>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>

            </v-row>
          </v-card-text>
        </v-card>
      </template>

      <v-card v-if="folderNodeContents.length <= 0" class="card-bg card-border px-2 mb-3 py-2" elevation="0">
        <v-card-text class="text-primaryTextColor pa-0">
          <v-row justify="space-between" align="center" class="ma-0 px-1">
            <v-col cols="12" class="d-flex pa-3 align-center justify-center pa-1">
              <div class="pb-0 wrap-text">
                <span class="t-h6Reg">No Folder found</span>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
    <template>
      <v-dialog v-model="ytVideoDialog" persistent width="auto">
        <v-card class="pa-1">
          <iframe :width="smAndUp ? 600 : 368" elevation="0" :height="smAndUp ? 400 : 207" :src="playVideoUrl"
            frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" variant="text" @click="ytVideoDialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template>
      <v-dialog v-model="privateVideoDialog" persistent width="auto" ref="pvtVideoDialog">
        <v-card class="pa-1">
          <video id="videopId1" controls ref="privateVideoPlayer" :width="smAndUp ? 600 : 368" elevation="0"
            :height="smAndUp ? 400 : 207"></video>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" variant="text" @click="privateVideoDialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template>

      <v-dialog v-model="pdfViewerDialog" persistent fullscreen>
        <v-card class="pa-1">
          <PDFJSExpressViewer :fileUrl='pdfFileUrl'></PDFJSExpressViewer>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" variant="text" @click="pdfViewerDialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-layout>

  <v-snackbar v-model="showSnackBar" :timeout="-1" color="cardBackgroundColor" location="bottom">
    <span class="text-easyColor">{{ snackBarMsg.text }}</span>
    <template v-slot:actions>
      <v-btn density="compact" color="#8e8ea7" @click.stop="closeSnackBar()" icon="mdi-close"></v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState } from "vuex";
import {
  AnalyticsEvents,
  recordAnalyticsEvent,
} from "@/services/awsPinpointAnalyticsService";
import _ from "lodash";
import PDFJSExpressViewer from "@/components/learn/PDFJSExpressViewer.vue";
import Hls from 'hls.js';
import { useDisplay } from 'vuetify';
import { uploadVideoFile, uploadFiles } from "../../services/storageService";
import SelfHelpGuideTogglerComponent from "@/components/selfHelpGuide/SelfHelpGuideTogglerComponent.vue";
export default {
  name: "AdminResourcePage",

  setup() {
    // Destructure only the keys we want to use
    const { xs, smAndUp, mdAndUp } = useDisplay()

    return { xs, smAndUp, mdAndUp }
  },

  async created() {
    recordAnalyticsEvent(AnalyticsEvents.v2AdminResourceScreenOpen, {
      customerId: this.courseId,
    });
    this.$store.commit("appbarVisible", true);
    this.$store.commit("navbarVisible", true);
    this.$store.commit("loadFolderBreadCrumbs", [])
    if (!this.isUserAdmin) {
      if (this.isUserTeacher) {

        this.$router.replace("/teacher/students");
      } else {
        this.$router.replace("/learn");
      }
    } else {
      this.$store.commit("setResourceFolderId", "-1");
      this.$store.dispatch("getClassList", { courseId: this.courseId });
      await this.$store.dispatch("loadAllResourceCenterFolderContent", {resourceCenterFolderId: '-1'});
      this.dataLoaded = true;
    }
  },
  mounted(){
    this.$store.commit("updateCurrentScreenName", this.currentScreenName);
  },
  unmounted(){
    this.$store.commit("updateCurrentScreenName", null);
  },
  components:
  {
    PDFJSExpressViewer, SelfHelpGuideTogglerComponent
  },
  data: () => ({
    currentScreenName: 'AdminResourceCenterPage',
    dataLoaded: false,
    formValue: false,
    dialog: false,
    dialogDelete: false,
    dialogShareLink: false,
    dialogSubFolder: false,
    dialogLink: false,
    dialogPdf: false,
    dialogYtVideo: false,
    dialogSendEmail: false,
    videoDialog: false,
    ytVideoDialog: false,
    playVideoUrl: "",
    inputFile: null,
    showPdfFile: false,
    editedIndex: -1,
    defaultItem: {
      parentResourceCenterFolderId: "-1",
      isRoot: false,
      permission: "all",
      classes: [],
      order: 0,
      resourceCenterFolderType: "FOLDER",
      isPublished: false,
      description: "",
      isLeaf: false,
      resourceCenterFolderName: "",
      resourceCenterFolderImage: null,
      url: "",
      showInMainFolder: false,
      customerIds: [],
      thumbnailUrl: null
    },
    editedItem: {},
    requiredRule: [(v) => !!v || "Required"],
    searchText: '',
    filterSearchVal: '',
    shareableLink: null,
    showSnackBar: false,
    snackBarMsg: null,
    shareId: null,
    items: [
      {
        key: "FOLDER",
        title: "New Folder",
        icon: "mdi-folder-open"
      },
      {
        key: "PDF",
        title: "Pdf",
        icon: "mdi-file-pdf-box"
      },
      {
        key: "VIDEO",
        title: "Youtube Link",
        icon: "mdi-video-outline"
      },
      {
        key: "LINK",
        title: "Website",
        icon: "mdi-link-variant"
      }
    ],
    folderItems: [
      {
        key: "SUBFOLDER",
        title: "New Sub Folder",
        icon: "mdi-folder-open"
      },
      {
        key: "PDF",
        title: "Pdf",
        icon: "mdi-file-pdf-box"
      },
      {
        key: "VIDEO",
        title: "Youtube Link",
        icon: "mdi-video-outline"
      },
      {
        key: "LINK",
        title: "Website",
        icon: "mdi-link-variant"
      }
    ],
    defaultNodeContent: {
      resourceCenterFolderId: "",
      resourceCenterFolderContentType: "",
      order: 0,
      isPublished: false,
      description: "",
      resourceCenterFolderContentName: "",
      resourceCenterFolderContentImage: null,
      pdfUrl: "",
      youTubeOrS3:"",
      youTubeUrl: "",
      thumbnailUrl: null,
      showInMainFolder: false,
    },
    fileRules: [
      value => value?.length > 0 || 'At least one file is required',
      value => value?.every(file => file.type === 'application/pdf') || 'Only PDF files are allowed'
    ],
    imageRules: [
      value => value.every(file => file.type.startsWith('image/')) || 'Only Image files are allowed.',
      value => value.every(file => file.size < 10000000) || 'Each file size should be less than 2 MB!',
    ],
    urlRules: [
      value => value.length > 0 || 'Link or url is required',
      value => /^(http|https):\/\/[^ "]+$/.test(value) || 'Invalid link or url'
    ],
    privateVideoDialog: false,
    pvtVideoDomControl: '',
    hlsInstance: null,
    pdfViewerDialog: false,
    pdfFileUrl: '',
    download: false,
    uploadPdfItems: [],
    selectedType: "",
    isShareableFolderOpen: false
  }),

  computed: {
    ...mapState([
      "courseId",
      "isUserAdmin",
      "isUserTeacher",
      "selectedResourceFolderId",
      "isUserSuperAdmin"
    ]),
    folderNodeContents: {
      get() {
        let resourceCenterFolderContentList  = this.$store.state.resourceCenterFolderContentList.get(this.selectedResourceFolderId) || [];
        return _.orderBy(resourceCenterFolderContentList, ["order"], "asc");
      },
    },
    formTitle() {
      return this.editedIndex === -1 ? "New " : "Edit ";
    },
    classList: {
      get() {
        let list = this.$store.state.classList;
        list = _.orderBy(list, [(batch) => batch?.name?.toLowerCase()], "asc");
        return list;
      },
    },
    learnNodesBreadcrumbs: {
      get() {
        let folders = this.$store.state.folderBreadCrumbs;
        let breadCrumbs = [];
        breadCrumbs.push({
          title: "Resources",
          disabled: false,
          parentNodeIdKeyinChildNodesMap: "-1",
          pageRoute: `/resource`,
        });
        for(let folder of folders){
          breadCrumbs.push({
            title: folder.folderName,
            disabled: false,
            parentNodeIdKeyinChildNodesMap: folder.folderId,
            pageRoute: `/resource`
          });
        }
        breadCrumbs[breadCrumbs.length-1].disabled = true;
        console.log("breadCrumbs", breadCrumbs)
        return breadCrumbs;
      }
    },
    showSelfHelpGuide: {
      get(){
        return this.$store.state.showSelfHelpGuide;
      },
      set(value){
        this.$store.commit('toggleSelfHelpGuide', value);
      }
    },
    customersList: {
      get() {
        let customersList = _.filter(this.$store.state.customersList, (customer)=> customer.courseId != this.courseId);
        return _.orderBy(customersList, [customer => customer.courseName?.toLowerCase()], "asc");
      }
    },
  },

  watch: {
    selectedResourceFolderId:{
      handler(val){
        if(val=='-1'){
          this.isShareableFolderOpen = false;
        }
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    close() {
      switch(this.selectedType){
        case "FOLDER":
          this.dialog = false;
          break;
        case "SUBFOLDER":
          this.dialog = false;
          break;
        case "PDF":
          this.dialogPdf = false;
          this.inputFile = null;
          break;
        case "VIDEO":
          this.dialogYtVideo = false;
          break;
        case "LINK":
          this.dialogLink = false;
          break;
      }
        // this.editedItem = {};
        this.editedIndex = -1;
        this.selectedType = "";
        this.showPdfFile = false;
    },
    
    async save() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.editedItem.thumbnailUrl = await this.onImageUpload(this.editedItem.thumbnailUrl);
        if(this.editedItem.permission == 'all'){
          this.editedItem.classes = [];
        }
        if(this.editedIndex == -1){
          recordAnalyticsEvent(AnalyticsEvents.v2AdminResourceAddButtonClicked, {
            customerId: this.courseId,
            folderId: this.selectedResourceFolderId,
            contentType: this.selectedType
          });
          this.$store.dispatch("addResourceCenterFolder", this.editedItem).then((res) => {
            if (!_.isEmpty(res)) {
              if(this.selectedType == "FOLDER"){
                this.$store.commit("addResourceCenterFolderContent", res);
              } else if(this.selectedType == "SUBFOLDER"){
                this.$store.commit("addResourceCenterFolderContent", res);
              } else if(this.selectedType == "LINK"){
                this.$store.commit("addResourceCenterFolderContent", res);
              }
              this.close();
            }
          });
        }else{
          recordAnalyticsEvent(AnalyticsEvents.v2AdminResourceEditButtonClicked, {
            customerId: this.courseId,
            folderId: this.selectedResourceFolderId,
            contentType: this.selectedType,
            contentId: this.editedItem['resourceCenterFolderId']
          });
          this.$store.dispatch("updateResourceCenterFolder", this.editedItem).then((res) => {
            if (!_.isEmpty(res)) {
              if(this.selectedType == "FOLDER"){
                this.$store.commit("updateResourceCenterFolderContent", res);
              } else if(this.selectedType == "SUBFOLDER"){
                this.$store.commit("updateResourceCenterFolderContent", res);
              } else if(this.selectedType == "LINK"){
                this.$store.commit("updateResourceCenterFolderContent", res);
              }
              this.close();
            }
          });
        }
      }
    },
    async initialCall(id){
        recordAnalyticsEvent(AnalyticsEvents.v2AdminResourceFolderFilesOpen, {
          customerId: this.courseId,
          folderId: id
        });
       
        this.$store.dispatch("loadAllResourceCenterFolderContent", {resourceCenterFolderId: id})
    },
    openFolderDetails(item){
      this.$store.commit("setResourceFolderId", item.resourceCenterFolderId);
      this.initialCall(item.resourceCenterFolderId);
      this.$store.commit("addFolderBreadCrumbs", {
        folderName: item.resourceCenterFolderName,
        folderId: item.resourceCenterFolderId
      })
    },
    isVideoPDFOrLink(item) {
      let isVideoPDFOrLink = false;
      if (this.isVideoContent(item)) {
        isVideoPDFOrLink = true;
      } else if (this.isPDFContent(item)) {
        isVideoPDFOrLink = true;
      } else if (this.isLink(item)) {
        isVideoPDFOrLink = true;
      }
      return isVideoPDFOrLink;
    },
    isVideoContent(item) {
      let isVideoContent = false;
      if (item.resourceCenterFolderContentType == 'VIDEO') {
        isVideoContent = true;
      }
      return isVideoContent;
    },
    isPDFContent(item) {
      let isPdfContent = false;
      if (item.resourceCenterFolderContentType == 'PDF') {
        isPdfContent = true;
      }
      return isPdfContent;
    },
    isLink(item) {
      return (item.resourceCenterFolderType == "LINK");
    },
    isFolder(item) {
      return (item.resourceCenterFolderType == "FOLDER");
    },
    isSubFolder(item) {
      return (item.resourceCenterFolderType == "SUBFOLDER");
    },
    getNodeName(item) {
      let nodeName = '';
      if (item.resourceCenterFolderName) {
        nodeName = item.resourceCenterFolderName;
      } else if (item.resourceCenterFolderContentName) {
        nodeName = item.resourceCenterFolderContentName;
      } else if (item.resourceCenterFolderContentType == "VIDEO") {
        nodeName = item.resourceCenterFolderContentName;
      } else if (item.resourceCenterFolderContentType == "PDF") {
        nodeName = item.resourceCenterFolderContentName;
      } else if (item.pdfUrl) {
        nodeName = item.pdfUrl;
      }
      return nodeName;
    },
    getNodeImageURL(item) {
      let imageSrc = '';
      if (item.resourceCenterFolderImage || item.resourceCenterFolderContentImage) {
        imageSrc = item.resourceCenterFolderImage || item.resourceCenterFolderContentImage
      }
      return this.$store.state.app_s3url + imageSrc;
    },
    getPlaceHolderIcon(item) {
      let iconName = 'mdi-folder-outline';
      if (item.resourceCenterFolderType == "FOLDER" || item.resourceCenterFolderType == "SUBFOLDER") {
        iconName = 'mdi-folder-open';
      } else if (item.resourceCenterFolderContentType == 'VIDEO') {
        iconName = 'mdi-video-outline';
      } else if (item.resourceCenterFolderContentType == 'PDF') {
        iconName = 'mdi-file-pdf-box';
      } else if (item.resourceCenterFolderType == "LINK") {
        iconName = 'mdi-link-variant';
      }
      return iconName;
    },
    getPlaceHolderIconColor(item) {
      let iconColor = 'grey';
      if (item.resourceCenterFolderType == "FOLDER" || item.resourceCenterFolderType == "SUBFOLDER") {
        iconColor = 'orange';
      } else if (item.resourceCenterFolderContentType == 'VIDEO') {
        iconColor = 'white';
      } else if (item.resourceCenterFolderContentType == 'PDF') {
        iconColor = 'red';
      } else if (item.resourceCenterFolderType == "LINK") {
        iconColor = 'blue';
      }
      return iconColor;
    },
    clearSearch() {
      this.filterSearchVal = this.searchText;
      //console.log(`clearSearch called:: this.filterSearchVal: ${this.filterSearchVal}`);
    },
    debounceSearch: _.debounce(function () {
      this.filterSearchVal = this.searchText;
      if (this.filterSearchVal) {
        // recordAnalyticsEvent(AnalyticsEvents.v2LearnSearch, { searchText: this.filterSearchVal });
      }
      //console.log(`debounceSearch called: filterSearchVal:: ${this.filterSearchVal}`);
    }, 500),
    learnNodeClicked(item) {
      if (this.isLink(item)) {
        recordAnalyticsEvent(AnalyticsEvents.v2AdminResourceFolderLinkOpened,{
         customerId: this.courseId,
         folderId: this.selectedResourceFolderId,
         contentType: 'LINK',
         contentId: item['resourceCenterFolderId'],
        });
        window.open(item.url); 
      } else if (this.isVideoContent(item)) {
        if ((item.youTubeOrS3 && item.youTubeOrS3.toLowerCase() == 'youtube')) {
          this.openYoutubeVideoPlayer(item);
        }
      } else if (this.isPDFContent(item)) {
        if (!this.download) {
          this.openPDfViewer(item);
        }
      } else {
        if(this.selectedResourceFolderId == '-1'){
          this.isShareableFolderOpen = this.isFolderShareabled(item);
        }
        this.openFolderDetails(item);
      }
    },
    openYoutubeVideoPlayer(item) {
      let regex = 
        /(?:youtube(?:-nocookie)?\.com\/(?:[^\n\s]+\/\S+\/|(?:v|e(?:mbed)?|live)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
      let itemYTURL = item.youTubeUrl;
      let temp = itemYTURL.match(regex)[1];
      const youtubeEmbedTemplate = "https://www.youtube.com/embed/";
      const topOfQueue = youtubeEmbedTemplate.concat(temp);
      this.playVideoUrl = topOfQueue;
      this.ytVideoDialog = true;
      recordAnalyticsEvent(AnalyticsEvents.v2AdminResourceFolderVideoPlayed,{
        customerId: this.courseId,
        folderId: this.selectedResourceFolderId,
        contentType: 'VIDEO',
        contentId: item['resourceCenterFolderContentId'],
        youtubeUrl: item.youTubeUrl
      });
    },


    // openprivateVideoPlayer(item) {
    //   // console.log(item.videoUrl)
    //   // console.log("Url : ", this.app_s3privateUrl)
    //   this.privateVideoDialog = true;
    //   this.$nextTick(function () {
    //     // console.log(`privateVideoDialog dom element loaded`);
    //     this.pvtVideoDomControl = this.$refs.privateVideoPlayer;
    //     // console.log(`this.pvtVideoDomControl 11 | ${this.pvtVideoDomControl}`);
    //     let videoURl = item.videoUrl ?? item.video.videoUrl;
    //     let stream = this.app_s3privateUrl + videoURl;
    //     // recordAnalyticsEvent(AnalyticsEvents.v2LearnPlayVideo,
    //     //   {
    //     //     videoType: 'private', videoName: item.name || item.video.name,
    //     //     videoUrl: videoURl
    //     //   });
    //     console.log(`stream: ${stream}`)
    //     if (Hls.isSupported()) {
    //       console.log(`Hls.isSupported is true`);
    //       if (!this.hlsInstance) {
    //         var config = {
    //           xhrSetup: function (xhr, url) {
    //             console.log(`Hls config xhrSetup urk: ${url}`);
    //             xhr.withCredentials = true; // do send cookies
    //           },
    //         };
    //         this.hlsInstance = new Hls(config);
    //         console.log(`Created new Hls js instance  ${this.hlsInstance}`)
    //       } else {
    //         console.log(`reusing hls js instance: ${this.hlsInstance}`)
    //       }
    //       this.hlsInstance.loadSource(stream);
    //       this.hlsInstance.attachMedia(this.pvtVideoDomControl);
    //       this.hlsInstance.on(Hls.Events.MANIFEST_PARSED, function () {
    //         console.log(`inside Hls.Events.MANIFEST_PARSED, function ...`);
    //         let videoElement = document.getElementById(`videopId1`);
    //         console.log(`trying to play thls video: ${videoElement} `)
    //         videoElement.play();
    //       });
    //     } else if (this.pvtVideoDomControl.canPlayType('application/vnd.apple.mpegurl')) {
    //       this.pvtVideoDomControl.src = stream;
    //     }
    //   })
    // },

    openPDfViewer(item) {
      //console.log(`inside openPDfViewer(item) : ${JSON.stringify(item)}`);
      let pdfURL = item.pdfUrl;
      this.$store.dispatch('getPdfUrl', pdfURL).then((pdf) => {
        this.pdfFileUrl = pdf.url;
        this.pdfViewerDialog = true;
        recordAnalyticsEvent(AnalyticsEvents.v2AdminResourceFolderPdfOpened,{
          customerId: this.courseId,
          folderId: this.selectedResourceFolderId,
          contentType: 'PDF',
          contentId: item['resourceCenterFolderContentId'],
          // pdfUrl: this.pdfFileUrl
        });
      })

      //console.log(`pdf dialog should be visible now : ${fullpdfFileURL}`)
    },
  
    // downloadPDF(item) {
    //   let userAgent = navigator.userAgent;
    //   this.download = true;
    //   let pdfURL = item.pdfUrl ?? item.pdf.pdfUrl;
    //   this.$store.dispatch('getPdfUrl', pdfURL).then((pdf) => {
    //     if (userAgent.indexOf('Firefox') > -1) {
    //       console.log("In firefox")
    //       if (parent.window.open(pdf.url, '_blank') === null) {
    //         window.addEventListener('click', () => {
    //           console.log("Ok")
    //         });
    //         window.alert("Download Pdf")
    //       }
    //     } else {
    //       window.open(pdf.url, '_blank');
    //     }
    //     // recordAnalyticsEvent(AnalyticsEvents.v2LearnDownloadPdf,
    //     //   {
    //     //     pdfName: item.name || item.pdf.name,
    //     //     pdfUrl: pdfURL
    //     //   });
    //     this.download = false;
    //   })
    // },
    breadCrumbRoute(item){
      this.$store.commit("setResourceFolderId", item.parentNodeIdKeyinChildNodesMap);
      this.$store.commit("goToFolderBreadCrumbs", item.parentNodeIdKeyinChildNodesMap);
    },
    back() {
      let selectedBreadcrumbs = this.learnNodesBreadcrumbs[this.learnNodesBreadcrumbs.length - 2];
      this.breadCrumbRoute(selectedBreadcrumbs);
    },
    addNewNodeContent(resourceCenterFolderContentType){
      this.selectedType = resourceCenterFolderContentType;
      switch (resourceCenterFolderContentType) {
        case "FOLDER":
          this.dialog = true;
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedItem.resourceCenterFolderType = "FOLDER";
          this.editedIndex = -1;
          this.editedItem.parentResourceCenterFolderId = "-1";
          break;
        case "SUBFOLDER":
          this.dialog = true;
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedItem.resourceCenterFolderType = "SUBFOLDER";
          this.editedIndex = -1;
          this.editedItem.parentResourceCenterFolderId = this.selectedResourceFolderId;
          break;
        case "PDF":
          this.dialogPdf = true;
          this.editedItem = Object.assign({}, this.defaultNodeContent);
          this.editedItem.resourceCenterFolderContentType = "PDF";
          this.editedIndex = -1;
          this.inputFile = null;
          this.showPdfFile = false;
          this.editedItem.resourceCenterFolderId = this.selectedResourceFolderId;
          this.uploadPdfItems = [];
          break;
        case "VIDEO":
          this.dialogYtVideo = true;
          this.editedItem = Object.assign({}, this.defaultNodeContent);
          this.editedItem.resourceCenterFolderContentType = "VIDEO";
          this.editedItem.youTubeOrS3 = "youtube";
          this.editedIndex = -1;
          this.editedItem.resourceCenterFolderId = this.selectedResourceFolderId;
          break;
        case "LINK":
          this.dialogLink = true;
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedItem.resourceCenterFolderType = "LINK";
          this.editedIndex = -1;
          this.editedItem.parentResourceCenterFolderId = this.selectedResourceFolderId;
          break;
      }
      if(this.folderNodeContents.length>0){
        this.editedItem.order = parseInt(this.folderNodeContents[this.folderNodeContents.length - 1].order) + 1;
      }else{
        this.editedItem.order = 1;
      }
    },
    editNodeItem(Item,resourceCenterFolderContentType){
      this.selectedType = resourceCenterFolderContentType;
      switch (resourceCenterFolderContentType) {
        case "FOLDER":
          this.dialog = true;
          this.editedItem = Object.assign({}, Item);
          this.editedIndex = 1;
          break;
        case "SUBFOLDER":
          this.dialog = true;
          this.editedItem = Object.assign({}, Item);
          this.editedIndex = 1;
          break;
        case "PDF":
          this.dialogPdf = true;
          this.editedItem = Object.assign({}, Item);
          console.log("PDF", this.editedItem);
          this.editedIndex = 1;
          this.inputFile = null;
          this.showPdfFile = true;
          this.uploadPdfItems = [];
          break;
        case "VIDEO":
          this.dialogYtVideo = true;
          this.editedItem = Object.assign({}, Item);
          console.log("VIDEO", this.editedItem);
          this.editedIndex = 1;
          break;
        case "LINK":
          this.dialogLink = true;
          this.editedItem = Object.assign({}, Item);
          this.editedIndex = 1;
          break;
      }
    },
    async uploadNewPdfAction() {
      let url = "";
      console.log("this.inputFile", this.inputFile.slice());
      if (this.inputFile.length != 0){
        console.log("out");
        await this.onFileUpload(this.inputFile.slice());
        url = this.uploadPdfItems[0].pdfUrl;
        return url;
      } 
      return url;
    },
    async onFileUpload(file) {
      this.$store.commit ('increamentLoader');
      this.$store.commit("showPdfProgress", true);

      Array.from(file).forEach((e, index) => {
        console.log(e.name);
        if (e) {
          this.uploadPdfItems.push({
            pdfUrl: "",
            pdfUploadingProgress: "0",
            paused: false,
            pdfUploading: true,
            fileName: `${e.name}`,
            uploadPdf: null
          });

          this.$store.commit("setPdfItems", {
            pdfUploadingProgress: "0",
            fileName: `${e.name}`
          });

          try {
            const result = uploadVideoFile(
              this.$store.state.config.bucket.scribbleBucket,
              e,
              `resourcecenter/${this.courseId}`,
              this.updatePdfProgess,
              this.pdfUploadCompleted,
              index
            );

            this.uploadPdfItems[index].uploadPdf = result;
          } catch (error) {
            console.log("Error uploading file: ", error);
          }
        }
      });
    },
    updatePdfProgess(progress, index) {
      this.uploadPdfItems[index].pdfUploadingProgress = progress;
      this.$store.commit("updatePdfProgress", {
        progress,
        index
      });
    },
    async pdfUploadCompleted(path, index) {
      console.log("the s3 pdf is uploaded pdfUploadCompleted");
      console.log(path);
      this.uploadPdfItems[index].pdfUrl = path;

      console.log(index, "complete upload");
      console.log("key", this.uploadPdfItems[index].uploadPdf);
      console.log(this.uploadPdfItems[index].uploadPdf.isInProgress);
      this.editedItem.pdfUrl = path;
      if(this.editedIndex == -1){
        recordAnalyticsEvent(AnalyticsEvents.v2AdminResourceAddButtonClicked, {
          customerId: this.courseId,
          folderId: this.selectedResourceFolderId,
          contentType: this.selectedType
        });
        this.$store.dispatch("addResourceCenterFolderContent", this.editedItem).then((res) => {
          if (!_.isEmpty(res)) {
            this.$store.commit("addResourceCenterFolderContent", res);
            this.close();
          }
        });
      }else{
        recordAnalyticsEvent(AnalyticsEvents.v2AdminResourceEditButtonClicked, {
          customerId: this.courseId,
          folderId: this.selectedResourceFolderId,
          contentType: this.selectedType,
          contentId: this.editedItem['resourceCenterFolderContentId']
        });
        this.$store.dispatch("updateResourceCenterFolderContent", this.editedItem).then((res) => {
          if (!_.isEmpty(res)) {
            this.$store.commit("updateResourceCenterFolderContent", res);
            this.close();
          }
        });
      }

      if (this.uploadPdfItems.every(e => e.pdfUploadingProgress == "100")) {
        this.$store.commit("clearPdfsItems");
        this.$store.commit("showPdfProgress", false);
        this.$store.commit("showSuccessPdfSnackbar", true);
        this.$store.commit ('decreamentLoader');
        console.log("All Files are uploaded 100%");
      }
    },
    async saveNodeContent(){
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        if(this.editedIndex == -1){
          recordAnalyticsEvent(AnalyticsEvents.v2AdminResourceAddButtonClicked, {
            customerId: this.courseId,
            folderId: this.selectedResourceFolderId,
            contentType: this.selectedType
          });
          this.$store.dispatch("addResourceCenterFolderContent", this.editedItem).then((res) => {
            if (!_.isEmpty(res)) {
              this.$store.commit("addResourceCenterFolderContent", res);
              this.close();
            }
          });
        }else{
          recordAnalyticsEvent(AnalyticsEvents.v2AdminResourceEditButtonClicked, {
            customerId: this.courseId,
            folderId: this.selectedResourceFolderId,
            contentType: this.selectedType,
            contentId: this.editedItem['resourceCenterFolderContentId']
          });
          this.$store.dispatch("updateResourceCenterFolderContent", this.editedItem).then((res) => {
            if (!_.isEmpty(res)) {
              this.$store.commit("updateResourceCenterFolderContent", res);
              this.close();
            }
          });
        }
      }
    },
    async savePdfContent(){
      const { valid } = await this.$refs.form.validate();
      console.log("savePdfContent", valid);
      if (valid) {
        this.editedItem.thumbnailUrl = await this.onImageUpload(this.editedItem.thumbnailUrl);
        if(this.inputFile){
          await this.uploadNewPdfAction();
        }else{
          if(this.editedIndex == -1){
            recordAnalyticsEvent(AnalyticsEvents.v2AdminResourceAddButtonClicked, {
              customerId: this.courseId,
              folderId: this.selectedResourceFolderId,
              contentType: this.selectedType
            });
            this.$store.dispatch("addResourceCenterFolderContent", this.editedItem).then((res) => {
              if (!_.isEmpty(res)) {
                this.$store.commit("addResourceCenterFolderContent", res);
                this.close();
              }
            });
          }else{
            recordAnalyticsEvent(AnalyticsEvents.v2AdminResourceEditButtonClicked, {
              customerId: this.courseId,
              folderId: this.selectedResourceFolderId,
              contentType: this.selectedType,
              contentId: this.editedItem['resourceCenterFolderContentId']
            });
            this.$store.dispatch("updateResourceCenterFolderContent", this.editedItem).then((res) => {
              if (!_.isEmpty(res)) {
                this.$store.commit("updateResourceCenterFolderContent", res);
                this.close();
              }
            });
          }
        }

      }  
    },
    openDeleteDialog(){
      switch (this.selectedType) {
        case "FOLDER":
          this.dialog = false;
          this.dialogDelete = true;
          break;
        case "SUBFOLDER":
          this.dialog = false;
          this.dialogDelete = true;
          break;
        case "PDF":
          this.dialogPdf = false;
          this.dialogDelete = true;
          break;
        case "VIDEO":
          this.dialogYtVideo = false;
          this.dialogDelete = true;
          break;
        case "LINK":
          this.dialogLink = false;
          this.dialogDelete = true;
          break;
      }
    },
    closeDeleteDialog(){
      switch (this.selectedType) {
        case "FOLDER":
          this.dialogDelete = false;
          this.dialog = true;
          break;
        case "SUBFOLDER":
          this.dialogDelete = false;
          this.dialog = true;
          break;
        case "PDF":
          this.dialogDelete = false;
          this.dialogPdf = true;
          break;
        case "VIDEO":
          this.dialogDelete = false;
          this.dialogYtVideo = true;
          break;
        case "LINK":
          this.dialogDelete = false;
          this.dialogLink = true;
          break;
      }
    },
    async deleteResource(){
      recordAnalyticsEvent(AnalyticsEvents.v2AdminResourceDeleteButtonClicked, {
        customerId: this.courseId,
        folderId: this.selectedResourceFolderId,
        contentType: this.selectedType,
        contentId: this.editedItem['resourceCenterFolderContentId'] || this.editedItem['resourceCenterFolderId']
      });
      let reqData = {};
      switch (this.selectedType){
        case "FOLDER":
        case "SUBFOLDER":
        case "LINK":
          reqData = {
            resourceCenterFolderId: this.editedItem['resourceCenterFolderId'],
            resourceCenterFolderType: this.editedItem['resourceCenterFolderType'],
          }
          this.$store.dispatch("deleteResourceCenterFolder", reqData).then((res) => {
            if (res.success) {
              if(this.selectedType == "FOLDER"){
                this.$store.commit("deleteResourceCenterFolderContent", reqData);
              } else if(this.selectedType == "SUBFOLDER"){
                this.$store.commit("deleteResourceCenterFolderContent", reqData);
              } else if(this.selectedType == "LINK"){
                this.$store.commit("deleteResourceCenterFolderContent", reqData);
              }
              this.closeDelete();
            }
          });
          // await this.$store.dispatch("deleteTeacherAssignment", reqData);
          break;
        case "PDF":
        case "VIDEO":
          reqData = {
            resourceCenterFolderId: this.editedItem['resourceCenterFolderId'],
            resourceCenterFolderContentId: this.editedItem['resourceCenterFolderContentId'],
            resourceCenterFolderContentType: this.editedItem['resourceCenterFolderContentType'],

          }
          this.$store.dispatch("deleteResourceCenterFolderContent", reqData).then((res) => {
            if (res.success) {
              this.$store.commit("deleteResourceCenterFolderContent", reqData);
              this.closeDelete();
            }
          });
          break;
      }
      
    },
    closeDelete(){
      // this.nextTick(()=>{
        this.dialogDelete = false;
        this.selectedType="";
        this.editedItem = {};
      // })
    },
    shareLink(item) {
      if(this.isPDFContent(item) || this.isVideoContent(item)){
        this.shareId = item.resourceCenterFolderContentId;
        this.shareableLink = this.getUrl(item.resourceCenterFolderId,item.resourceCenterFolderContentId);
        this.selectedType = item.resourceCenterFolderContentType;
        recordAnalyticsEvent(AnalyticsEvents.v2AdminResourceContentShareButtonClicked, {
          customerId: this.courseId,
          folderId: this.selectedResourceFolderId,
          contentType: this.selectedType,
          contentId: this.shareId
        });
      }else{
        this.shareId = item.resourceCenterFolderId;
        this.shareableLink = this.getUrlFolder(item.resourceCenterFolderId);
        this.selectedType = 'SubFolder';
        recordAnalyticsEvent(AnalyticsEvents.v2AdminResourceFolderShareButtonClicked, {
          customerId: this.courseId,
          folderId: this.shareId
        });
      }

      this.dialogShareLink = true
    },
    getUrlFolder(id) {
      return window.location.origin + "/rc/f/" + id
    },
    getUrl(fileId, contentId) {
      return window.location.origin + `/rc/f/${fileId}/c/${contentId}`
    },
    async copyCode() {
      if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(this.shareableLink);

        if(this.selectedType == "SubFolder"){
          recordAnalyticsEvent(AnalyticsEvents.v2AdminResourceFolderShareLinkCopied, {
            customerId: this.courseId,
            folderId: this.shareId
          });
        }else{
          recordAnalyticsEvent(AnalyticsEvents.v2AdminResourceContentShareLinkCopied, {
            customerId: this.courseId,
            folderId: this.selectedResourceFolderId,
            contentType: this.selectedType,
            contentId: this.shareId
          });
        }

        this.updateSnackBarMsg({ text: "Your resource link has been copied to clipboard." })
        this.openSnackBar()
      }
      else {
        console.log({ text: 'The clipboard API is not available.' });
      }
    },
    updateSnackBarMsg(msg){
      this.snackBarMsg = msg;
    },
    openSnackBar(){
      this.showSnackBar = true;
    },
    closeSnackBar(){
      this.snackBarMsg = "";
      this.showSnackBar = false;
      // this.selectedType = "";
      this.shareId = null;
    },
    getPdfName(){
      let pdfFolders = this.editedItem.pdfUrl.split('/');
      let pdfName = pdfFolders[pdfFolders.length - 1];
      return pdfName.split('_').slice(1).join('_');
    },
    sendEmail(item) {
      this.editedItem = Object.assign({}, item);
      if( "resourceCenterFolderContentId" in item){
        this.shareableLink = this.getUrl(item.resourceCenterFolderId,item.resourceCenterFolderContentId);
      }else{
        this.shareableLink = this.getUrlFolder(item.resourceCenterFolderId);
      }
      this.dialogSendEmail = true;
    },
    async sendEmailToUsers(){
      const reqData = {
        folderId: this.editedItem.resourceCenterFolderId,
        url: this.shareableLink
      }
      if( "resourceCenterFolderContentId" in this.editedItem){
        recordAnalyticsEvent(AnalyticsEvents.v2AdminResourceContentSendEmailButtonClicked, {
          customerId: this.courseId,
          folderId: this.editedItem.resourceCenterFolderId,
          contentType: this.editedItem.resourceCenterFolderContentType,
          contentId: this.editedItem.resourceCenterFolderContentId
        });
      }else{
        recordAnalyticsEvent(AnalyticsEvents.v2AdminResourceFolderSendEmailButtonClicked, {
          customerId: this.courseId,
          folderId: this.editedItem.resourceCenterFolderId
        });
      }
      const res = await this.$store.dispatch("sendResourceToUser", reqData);
      if(res && res.success){
        this.updateSnackBarMsg({ text: "Your resource link has been sent to students." });
        this.openSnackBar()
      }else{
        this.updateSnackBarMsg({ text: "Please try again to sent your resource link to students." });
        this.openSnackBar()
      }
      this.closeSendEmailDialog();
    },
    closeSendEmailDialog(){
      this.dialogSendEmail = false;
    },
    isNodeImageEmpty(image) {
      return !_.isEmpty(image);
    },
    async onImageUpload(file) {
      console.log("onImageUpload", file, !_.isString(file));
      if (!_.isEmpty(file) && _.isArray(file) && file.length>0) {
        this.$store.commit ('increamentLoader');
        const dirName = `resourcecenter/${this.courseId}/thumbnail`;
        try {
          const result = await uploadFiles(file[0], dirName, this.$store.state.config.bucket.thumbnailBucket);
          this.$store.commit ('decreamentLoader');
          return result.key;
        } catch (error) {
          console.log("Error uploading file: ", error);
          this.$store.commit ('decreamentLoader');
        }
        
      }else if(!_.isEmpty(file) && _.isString(file)){
        return file;
      }
      return "";
    },
    removeImage() {
      this.editedItem.thumbnailUrl = null;
    },
    getImageUrl(image){
      let path = "";
      if(_.isString(image)){
        path = this.$store.state.app_s3url + image;
      }else{
        path = URL.createObjectURL(image[0]);
      }
      return path;
    },
    toggleCustomerList() {
      if (this.editedItem.customerIds.length == this.customersList.length) {
        this.editedItem.customerIds = [];
      } else {
        this.editedItem.customerIds = this.customersList
          .slice()
          .map((e) => e.courseId);
      }
    },
    isFolderShareabled(item){
      return (item.customerId != this.courseId);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.heightBread {
  height: 80px;
}

/* .layoutLarge {
  height: 100%;
} */

.container-width {
  max-width: 1080px;
}

.main-lg {
  padding-top: 50px;
}

.main-md {
  padding-top: 50px;
}

.main-card {
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  background: rgb(var(--v-theme-cardBackgroundColor));
  cursor: pointer;
}

.main-card-header {
  background: #2b2b44;
  padding-top: 15px;
  padding-left: 15px;
  padding-bottom: 10px;
}

.main-heading {
  font-size: 1.5rem;
  color: rgb(var(--v-theme-primaryPurple));
}

.assignment-card {
  background: #303146;
}

.card-bg {
  background: rgb(var(--v-theme-cardBackgroundColor2));
  border-radius: 12px;
}

.card-border {
  border: 2px solid transparent;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  cursor: pointer;
}

/* .card-border:hover{
  border: 2px solid #9461FC;
} */

.icon-bg {
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%);
}

.parameter-style {
  opacity: 0.4;
}

.activity-btn {
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%) !important;
  border-radius: 12px;
  color: white !important;
}

.assignment-form-main {
  overflow: auto !important;
}

.assignment-form {
  /* background: rgb(var(--v-theme-primaryGrey)); */
  border-radius: 12px !important;
  /* border: 2px solid #c3c3c3; */
  border: 2px solid rgba(255, 255, 255, 0.22);
  /* background: #232238; */
  background-color: rgb(var(--v-theme-reportBgColor));
  height: 100%;
}

.assignment-form-title {
  background: rgb(var(--v-theme-breadCrumbBackColor));
}

.cancel-btn {
  border: 2px solid rgba(255, 255, 255, 0.22);
  color: white !important;
  background: rgb(var(--v-theme-cardBackgroundColor));
  border-radius: 29.4079px;
  width: 100px;
}

.delete-btn {
  border: 2px solid red !important;
}

.save-btn {
  color: white !important;
  border-radius: 29.4079px;
  width: 100px;
  background: linear-gradient(180deg, #ab77ff 0%, #585ef3 100%);
}

.field-block {
  border-radius: 4px !important;
  border: 1px solid rgb(118, 118, 118);
  position: relative;
}

.field-label-name {
  font-size: 0.75em !important;
  position: absolute;
  padding: 0 6px;
  transform: translateY(-50%);
  transform-origin: center;
  background-color: rgb(var(--v-theme-reportBgColor));
}

.share-btn {
  background: #393850;
  border-radius: 12px;
}

.border-code {
  background: rgba(241, 241, 241, 0.05);
  border-radius: 8px;
}
</style>
