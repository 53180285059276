<template>
	<div>
		<!-- Only this part needed -->

		<!-- Feedback Form -->
		<DragableFeedbackDialog v-if="reportDialog" @closeDialog="reportDialog = false"  
		test-type="practiceTest" :q-id="currentQ.qId"
		:test-id="practiceInfo.testId" :test-instance-id="practiceInfo.testInstanceId"
          :q-obj="currentQ"></DragableFeedbackDialog>
		<!-- <v-dialog absolute class="bottom dialog-bx" max-width="800px" v-model="reportDialog">
			<FeedbackComponent @closeDialog="reportDialog = false" test-type="practiceTest" :q-id="currentQ.qId"
				:test-id="practiceInfo.testId" :test-instance-id="practiceInfo.testInstanceId" :currentQ="currentQ"></FeedbackComponent>
		</v-dialog> -->

		<v-container class="bgw" fluid>
			<v-row justify="start" :class="{ 'w-70': $vuetify.display.mdAndUp }">
				<v-col cols="12">
					<v-card elevation="0" color="white" class="pb-14 pt-6" scrollable>
						<section class=" mx-5" ref="mainSolutionScreen" style="background-color: white;" id="mainSolutionScreen">

							<div class="mb-8">
								<v-row class="align-center">
									<span class="mx-3 qNoClass font-weight-bold ">
										Question {{ currentQ.qDisplayNo }}
									</span>

									<v-spacer></v-spacer>

									<!-- Button to show report/feedback dialog -->
									<v-tooltip :text="feedbackOrQuestionReiewIconTooltipText" location="end" v-if="isQuestionReviewer">
										<template v-slot:activator="{ props }">
											<v-btn size="small" v-bind="props" variant="outlined" class="outline-color"
												@click="showReportDialog()" :icon="feedbackOrQuestionReiewIconName"></v-btn>
										</template>
									</v-tooltip>
								</v-row>

							</div>

							<!-- Question Text -->
							<section>
								<div>
									<HtmlRender :htmlString="currentQ.questionDesc" class="htmlClassTest"></HtmlRender>
								</div>
							</section>

							<section>
								<div class="mt-1">
									<HtmlRender :htmlString="currentQ.qTextForSecondColumn" class="htmlClassTest"></HtmlRender>
								</div>
							</section>


							<div class="mt-15">

								<!-- Question Not Attempted-->
								<!-- <v-row class="mt-4 mb-10 mx-1" v-if="currentQ.isCorrect == null">
									<v-alert color="info" icon="$info" variant="tonal" text="This question was not attempted"></v-alert>
								</v-row> -->

								<!-- case 1 -->
								<section v-if="currentQ.questionTypeId == '1'">
									<v-radio-group v-model="currentQResponse.checkedOption" readonly>

										<v-row v-for="option in currentQ.options" :key="option.key">
											<v-col class="mb-0 pb-0" cols="12"
												v-if="userAnswerSingleChoice(currentQResponse, option.key)">

												<span v-if="correctAnswerSingleChoice(currentQSolution, option.key)"
													class="text-green">
													Your Answer - Correct
												</span>
												<span v-if="!correctAnswerSingleChoice(currentQSolution, option.key)"
													class="text-red">
													Your Answer - Incorrect
												</span>
											</v-col>
											<v-col class="mb-0 pb-0" cols="12"
												v-if="!userAnswerSingleChoice(currentQResponse, option.key) && correctAnswerSingleChoice(currentQSolution, option.key)">

												<span class="text-green">
													Correct Answer
												</span>

											</v-col>
											<v-col cols="12">
												<v-hover v-slot="{ isHovering, props }">

													<v-sheet class="bx" :class="{
														'on-hover': isHovering,
														'green-border': correctAnswerSingleChoice(currentQSolution, option.key),
														'red-border': !correctAnswerSingleChoice(currentQSolution, option.key) && userAnswerSingleChoice(currentQResponse, option.key)
													}" v-bind="props">

														<v-radio density="compact" color="primary"
															class="ma-0 pa-0 w-100  custom-radio" dense :value="option.key">

															<template v-slot:label>
																<v-row class=" ma-0 pa-0 pr-3 pl-3" align="center">
																	<v-col class="ma-0 pa-0" cols="auto">
																		<v-avatar elevation="2" size="25"
																			class="t-h6  outline-color-grey"
																			variant="outlined">
																			{{ option.key.toUpperCase() }}
																		</v-avatar>
																	</v-col>
																	<v-col class="ma-0 pa-0">
																		<HtmlRender
																			class="htmlClassTest htmlClassTestOption ml-2 py-4 w-100 mb-1"
																			:htmlString="option.value">
																		</HtmlRender>
																	</v-col>

																</v-row>

															</template>
														</v-radio>
													</v-sheet>
												</v-hover>
											</v-col>

										</v-row>

									</v-radio-group>
								</section>

								<!-- case 2-->
								<section v-if="currentQ.questionTypeId == '2'">

									<v-row v-for="option in currentQ.options" :key="option.key">
										<v-col class="mb-0 pb-0" cols="12"
											v-if="userAnswerMultipleChoice(currentQResponse, option.key)">

											<span v-if="correctAnswerMultiChoice(currentQSolution, option.key)"
												class="text-green">
												Your Answer - Correct
											</span>
											<span v-if="!correctAnswerMultiChoice(currentQSolution, option.key)"
												class="text-red">
												Your Answer - Incorrect
											</span>
										</v-col>
										<v-col class="mb-0 pb-0" cols="12"
											v-if="!userAnswerMultipleChoice(currentQResponse, option.key) && correctAnswerMultiChoice(currentQSolution, option.key)">

											<span class="text-green">
												Correct Answer
											</span>

										</v-col>
										<v-col cols="12">
											<v-hover v-slot="{ isHovering, props }">

												<v-sheet v-bind="props" class="bx pa-0 ma-0" :class="{
													'on-hover': isHovering,
													'green-border': correctAnswerMultiChoice(currentQSolution, option.key),
													'red-border': !correctAnswerMultiChoice(currentQSolution, option.key) && userAnswerMultipleChoice(currentQResponse, option.key)
												}">
													<v-checkbox density="compact" hide-details
														v-model="currentQResponse.checkedOptions" readonly color="primary"
														class=" testOption  custom-radio" :value="option.key">
														<template v-slot:label>
															<v-row class=" ma-0 pa-0 pr-3 pl-3" align="center">
																<v-col class="ma-0 pa-0" cols="auto">
																	<v-avatar elevation="2" size="25"
																		class="t-h6  outline-color-grey" variant="outlined">
																		{{ option.key.toUpperCase() }}
																	</v-avatar>
																</v-col>
																<v-col class="ma-0 pa-0">
																	<HtmlRender
																		class="htmlClassTest htmlClassTestOption ml-2 py-4 w-100 mb-1"
																		:htmlString="option.value">
																	</HtmlRender>
																</v-col>

															</v-row>

														</template>
													</v-checkbox>
												</v-sheet>
											</v-hover>
										</v-col>

									</v-row>

								</section>

								<!-- case 3-->
								<section v-if="currentQ.questionTypeId == '3'">

									<v-row>
											<v-col class="mb-0 pb-0" cols="12">
												<span v-if="currentQSolution.correct != null">

													<span v-if="currentQSolution.correct" class="text-green">
														Your Answer - Correct
													</span>
													<span v-if="!currentQSolution.correct" class="text-red">
														Your Answer - Incorrect
													</span>
												</span>

											</v-col>
											<v-col v-if="currentQSolution.correct != null" cols="8" md="3" lg="2">
												<v-text-field @click.prevent="" readonly
													v-model="currentQResponse.integerOption" label="Your Answer"
													variant="outlined" :color="currentQSolution.correct ? 'green' : 'red'"
													focused></v-text-field>

											</v-col>

										</v-row>

										<v-row v-if="currentQSolution.correct == null || !currentQSolution.correct">
											<v-col class="mb-0 pb-0 text-green" cols="12">
												<span>
													Correct Answer
												</span>
											</v-col>
											<v-col cols="8" md="3" lg="2">
												<v-text-field readonly v-model="currentQOnlySolution.answerInteger" label="Answer"
													variant="outlined" color="green" focused
													base-color="green"></v-text-field>

											</v-col>
										</v-row>


								</section>

								<!-- case 4-->
								<section v-if="currentQ.questionTypeId == '4'">

									<v-row>
										<v-col class="mb-0 pb-0" cols="12">
											<span v-if="currentQSolution.correct != null">
												<span v-if="currentQSolution.correct" class="text-green">
													Your Answer - Correct
												</span>
												<span v-if="!currentQSolution.correct" class="text-red">
													Your Answer - Incorrect
												</span>
											</span>

										</v-col>
										<v-col v-if="currentQSolution.correct != null" cols="8" md="3" lg="2">
											<v-text-field readonly label="Answer" v-model="currentQResponse.decimalOption"
												:color="currentQSolution.correct ? 'green' : 'red'" focused
												variant="outlined"></v-text-field>

										</v-col>

									</v-row>

							
									<v-row>
										<v-col class="mb-0 pb-0 text-green" cols="12">
											<span>
												Correct Answer
											</span>
										</v-col>
										<v-col cols="8" md="3" lg="2">
											<v-text-field readonly v-model="correctAnswerDecimal" label="Answer"
												variant="outlined" color="green" focused></v-text-field>

										</v-col>
									</v-row>

								</section>

							</div>
						</section>

						<!-- Solution -->
						<section ref="solutionSection" style="background-color: white;" id="solutionSection" class="mx-4 mb-2" v-if="currentQSolution.solution.solutionText != null">
							<v-row class="t-h5 mt-4 ">
								<v-col cols="12">
									Solution
								</v-col>

							</v-row>
							<v-row>

								<div>
									<v-col cols="12">

										<HtmlRender :htmlString="currentQSolution.solution.solutionText
											" class="htmlClassTest"></HtmlRender>
									</v-col>

								</div>
							</v-row>

						</section>

					</v-card>
				</v-col>
			</v-row>
		</v-container>

	</div>
</template>
      
<script>
import { mapState, mapGetters } from "vuex";
// import _ from "lodash";
import HtmlRender from "@/components/Test/HtmlRender.vue";
import DragableFeedbackDialog from "@/components/Test/TestQuestionPageComponents/DragableFeedbackDialog.vue";
import { AnalyticsEvents, recordAnalyticsEvent } from "@/services/awsPinpointAnalyticsService";


const marked = require("marked");

const renderer = new marked.Renderer();
renderer.link = function link(href, title, text) {
	return `<a href="${href}" title="${title}" target="_blank">${text}</a>`;
};
export default {
	name: 'TestResultPage',
	data: () => ({
		questionDialog: false,
		loadedFromServer: false,
		exitDialog: false,
		testId: "",
		testInstanceId: "",
		reportDialog: false,
		isFromReport: false,
		testType: ""
	}),
	components:
	{
		HtmlRender,
		DragableFeedbackDialog
	},
	props: ['currentQuestion', 'currentQResponseProp', 'currentQSolutionProp', 'practiceInfo'],
	async created() {
		this.$store.commit('appbarVisible', false);
		this.$store.commit('navbarVisible', false);
		this.$store.commit("showNotification", false);
		setTimeout(() => {
			window.MathJax.typesetPromise();

		}, 10);

	},
	computed: {
		...mapState(["currentTestResult", "currentTestResultJson", "currentTestState", "newLearnNodeActivity", "currentActivityId", "currentTopicId"]),
		...mapGetters(["isQuestionReviewer", "isLevel1Reviewer", "isLevel2Reviewer", "isLevel3Reviewer"]),
		testInstanceQuestionSummary: {
			get() {
				return this.currentTestResult.testInstanceQuestionSummary
			}
		},
		currentQResponse: {
			get() {
				return this.currentQResponseProp;
			}
		},
		currentQSolution: {
			get() {
				console.log("currentQSolutionProp is ", this.currentQSolutionProp);
				return this.currentQSolutionProp;
			}
		},
		currentQOnlySolution: {
			get() {
				console.log("currentQSolutionProp is ", this.currentQSolutionProp);
				return this.currentQSolutionProp.solution;
			}
		},
		currentQ: {
			get() {
				return this.currentQuestion
			}
		},
		currentQNo: {
			get() {
				return this.currentTestState.qIndex + 1
			}
		},
		currentSection: {
			get() {
				return this.currentTestResultJson[this.currentTestState.sectionIndex]
			}
		},
		prevButtonDisabled: {
			get() {
				return this.currentTestState.qIndex == 0 && this.currentTestState.sectionIndex == 0 ? true : false
			}
		},
		correctAnswerDecimal: {
			get() {
				let x = ""
				if (this.currentQOnlySolution.answerDecimalHigh == this.currentQOnlySolution.answerDecimalLow) {
					x = this.currentQOnlySolution.answerDecimalHigh
				}
				else {
					x = `${this.currentQOnlySolution.answerDecimalHigh} - ${this.currentQOnlySolution.answerDecimalLow}`;
				}
				return x
			},
		},
		feedbackOrQuestionReiewIconName: {
			get() {
				var retVal = "mdi-message-alert-outline"; //"Report a Problem" for users/students
				if(this.isQuestionReviewer){
					retVal = "mdi-shield-check-outline"; // for internal Question reviewers
				}
				return retVal;
			},
		},
		feedbackOrQuestionReiewIconTooltipText: {
			get() {
				var retVal = "Report a problem" ////"Report a Problem" for users/students
				if(this.isQuestionReviewer){
					retVal = "Review Feedback & Corrections"; // for internal Question reviewers
				}
				return retVal;
			},
		},

	},

	watch: {
		currentQuestion:{
			handler(val) {
				console.log("currentQuestion", val);
				const element = document.getElementsByClassName('solution-height');
				if (element && element[0]) {
					element[0].scrollIntoView({
						behavior: "smooth",
					})
				}

			},
			deep: true
		}
	},

	methods: {
		showReportDialog() {

			// recordAnalyticsEvent(AnalyticsEvents.v2TestSectionInstructionsDuringTest, {
			//   testId: this.currentTestId,
			//   testName: this.startTestInfo.testmetadata.testName,
			//   testInstanceId: this.startTestInfo.testInstanceId,
			//   sectionId: this.currentSection.sectionId,

			// });
			this.reportDialog = true
		},
		correctAnswerSingleChoice(item, key) {
			if (item.solution.answerSingleChoice == null) {
				return false
			}
			if (item.solution.answerSingleChoice == key) {
				return true
			}
			return false
		},
		userAnswerSingleChoice(item, key) {

			if (item.checkedOption == null) {
				return false
			}
			if (item.checkedOption == key) {
				return true
			}
			return false
		},
		correctAnswerMultiChoice(item, key) {

			if (item.solution.answers == null) {
				return false
			}
			if (item.solution.answers.includes(key)) {
				return true
			}
			return false
		},
		userAnswerMultipleChoice(item, key) {
			if (item.checkedOptions == null) {
				return false
			}
			if (item.checkedOptions.includes(key)) {
				return true
			}
			return false
		},
		getMarkDownHtml(markdown) {
			if (markdown == null) {
				return ""
			}
			let markDownText = markdown
			let out = false;
			const regex = /\\/gm;
			const escapedString = markDownText.replace(regex, "\\\\");

			out = marked.parseInline(escapedString, { renderer });

			return out;
		},
	}

}
</script>

<style scoped>
.correct {
	background: #26B99AA6 !important;
	color: white !important;
	;
}

.success {
	background: #26B99AA6 !important;

}

.timer-color {
	color: #26B99AA6 !important;
}

.unanswered {
	border: 2px solid #949494 !important;
}

.marked {
	border: 2.5px solid #FF894F !important;
}

.outline-color-grey {
	border-color: #010101b3 !important;
	border: 1px solid;
	color: #010101b3 !important;

}

.outline-color-white {
	border-color: white !important;
	background-color: white;
}

.option {

	/* box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important; */
	border-radius: 10px;
	border: 1px rgb(var(--v-theme-secondaryBackground)) solid;
}

.v-sheet.on-hover {
	cursor: pointer;
	/* border: 1px solid rgb(var(--v-theme-primary)); */
	border-color: transparent;
	background-color: white;
	box-shadow: 0px 2px 8px rgba(112, 144, 240, 0.2) !important;

}

.green-border {
	border: 2.5px solid green !important;
}

.red-border {
	border: 2.5px solid #ff8a80 !important;
}

.incorrect {
	background: #ff8a80 !important;
	color: white !important;
}

.btn-grad {
	background: linear-gradient(180deg, #AB77FF 0%, #585EF3 100%);
	border-radius: 29.4079px;
}

.btn-prev {
	border-radius: 29.4079px;
}

.bgWhite {
	background-color: white !important
}
.bgW {
	height: 100% !important;
	width: 100%;
	background-color: white;
}

.bgWColor {
	width: 100%;
	background-color: white;
}

.unattempted {
	border: 2px solid #949494 !important;
}

.bx {
	background-color: rgb(var(--v-theme-navColor));
	border: 1px solid #E7E7E7;
	border-radius: 4px;
}

.card-bg-color {
	background-color: rgb(var(--v-theme-navColor)) !important;
	border: 0.5px solid rgba(0, 0, 0, 0.15) !important;
	border-radius: 12px !important;

}

.outline-color {
	border-color: rgb(var(--v-theme-secondaryBackground)) !important;
	;

}

.bottom {
	position: absolute !important;
	bottom: 10px !important;
}

.w-70 {
	max-width: 70%;
}

.v-overlay__content {
	position: absolute !important;
	bottom: 10px !important;

}

.bgW {
	height: 100%;
	width: 100%;
	background-color: white;
}

.scroll {}

.dialog-bx {
	position: fixed !important;
}

.htmlClassTestOption span {
	top: 0 !important;
	vertical-align: middle;
}

.htmlClassTest img {
	top: 0 !important;
	vertical-align: middle;
	max-width: 100%;
	height: auto;
}

.testOption .v-selection-control--inline {

	min-width: 100% !important;
}

.testOption .v-label {

	width: 100% !important;
}

.qNoClass {
	font-size: 0.875rem;
	font-family: "Inter", sans-serif !important;

}
</style>
<style>
.htmlClassTestOption span {
	top: 0 !important;
	vertical-align: middle;
}

.htmlClassTest img {
	top: 0 !important;
	vertical-align: middle;
	max-width: 100%;
	height: auto;
}

.testOption .v-selection-control--inline {

	min-width: 100% !important;
}

.testOption .v-label {

	width: 100% !important;
	opacity: 1 !important;
}

.custom-radio .v-selection-control__wrapper {
	display: none;
}

.v-label {

	opacity: 1 !important;
}</style>
